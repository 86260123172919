export const Incident_TYPE_REQUEST = "Incident_TYPE_REQUEST";
export const Incident_TYPE_SUCCESS = "Incident_TYPE_SUCCESS";
export const Incident_TYPE_FAILURE = "Incident_TYPE_FAILURE";

export const Incident_DDL_REQUEST = "Incident_DDL_REQUEST";
export const Incident_DDL_SUCCESS = "Incident_DDLIncident_SUCCESS";
export const Incident_DDL_FAILURE = "Incident_DDL_FAILURE";
export const Incident_DDL_RESET = "Incident_DDL_RESET";

export const Incident_ADD_REQUEST = "Incident_ADD_REQUEST";
export const Incident_ADD_SUCCESS = "Incident_ADD_SUCCESS";
export const Incident_ADD_FAILURE = "Incident_ADD_FAILURE";

export const Incident_DELETE_REQUEST = "Incident_DELETE_REQUEST";
export const Incident_DELETE_SUCCESS = "Incident_DELETE_SUCCESS";
export const Incident_DELETE_FAILURE = "Incident_DELETE_FAILURE";

export const Incident_UPDATE_REQUEST = "Incident_UPDATE_REQUEST";
export const Incident_UPDATE_SUCCESS = "Incident_UPDATE_SUCCESS";
export const Incident_UPDATE_FAILURE = "Incident_UPDATE_FAILURE";
export const Incident_UPDATE_RESET = "Incident_UPDATE_RESET";

export const Incident_DETAILS_REQUEST = "Incident_DETAILS_REQUEST";
export const Incident_DETAILS_SUCCESS = "Incident_DETAILS_SUCCESS";
export const Incident_DETAILS_FAILURE = "Incident_DETAILS_FAILURE";
export const Incident_DETAILS_RESET = "Incident_DETAILS_RESET";

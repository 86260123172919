export const GET_MEALS_REQUEST = "GET_MEALS_REQUEST";
export const GET_MEALS_SUCCESS = "GET_MEALS_SUCCESS";
export const GET_MEALS_FAILURE = "GET_MEALS_FAILURE";

export const MEALS_DDL_REQUEST =
	"MEALS_DDL_REQUEST";
export const MEALS_DDL_SUCCESS =
	"MEALS_DDL_SUCCESS";
export const MEALS_DDL_FAILURE =
	"MEALS_DDL_FAILURE";
export const MEALS_DDL_RESET =
	"MEALS_DDL_RESET";

export const ADD_MEAL_REQUEST = "ADD_MEAL_REQUEST";
export const ADD_MEAL_SUCCESS = "ADD_MEAL_SUCCESS";
export const ADD_MEAL_FAILURE = "ADD_MEAL_FAILURE";

export const DELETE_MEALS_REQUEST = "DELETE_MEALS_REQUEST";
export const DELETE_MEALS_SUCCESS = "DELETE_MEALS_SUCCESS";
export const DELETE_MEALS_FAILURE = "DELETE_MEALS_FAILURE";

export const UPDATE_MEAL_REQUEST = "UPDATE_MEAL_REQUEST";
export const UPDATE_MEAL_SUCCESS = "UPDATE_MEAL_SUCCESS";
export const UPDATE_MEAL_FAILURE = "UPDATE_MEAL_FAILURE";
export const UPDATE_MEAL_RESET = "UPDATE_MEAL_RESET";

export const MEAL_DETAILS_RESET = "MEAL_DETAILS_RESET";
export const MEAL_DETAILS_REQUEST = "MEAL_DETAILS_REQUEST";
export const MEAL_DETAILS_SUCCESS = "MEAL_DETAILS_SUCCESS";
export const MEAL_DETAILS_FAILURE = "MEAL_DETAILS_FAILURE";

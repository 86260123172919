export const GET_EQUIPMENTS_REQUEST = "GET_EQUIPMENTS_REQUEST";
export const GET_EQUIPMENTS_SUCCESS = "GET_EQUIPMENTS_SUCCESS";
export const GET_EQUIPMENTS_FAILURE = "GET_EQUIPMENTS_FAILURE";

export const DDL_VISITORS_REQUEST = "DDL_VISITORS_REQUEST";
export const DDL_VISITORS_SUCCESS = "DDL_VISITORS_SUCCESS";
export const DDL_VISITORS_FAILURE = "DDL_VISITORS_FAILURE";
export const DDL_VISITORS_RESET = "DDL_VISITORS_FAILURE_RESET";

export const ADD_EQUIPMENT_REQUEST = "ADD_EQUIPMENT_REQUEST";
export const ADD_EQUIPMENT_SUCCESS = "ADD_EQUIPMENT_SUCCESS";
export const ADD_EQUIPMENT_FAILURE = "ADD_EQUIPMENT_FAILURE";

export const DELETE_EQUIPMENTS_REQUEST = "DELETE_EQUIPMENTS_REQUEST";
export const DELETE_EQUIPMENTS_SUCCESS = "DELETE_EQUIPMENTS_SUCCESS";
export const DELETE_EQUIPMENTS_FAILURE = "DELETE_EQUIPMENTS_FAILURE";

export const UPDATE_STATUS_EQUIPMENT_REQUEST =
	"UPDATE_STATUS_EQUIPMENT_REQUEST";
export const UPDATE_STATUS_EQUIPMENT_SUCCESS =
	"UPDATE_STATUS_EQUIPMENT_SUCCESS";
export const UPDATE_STATUS_EQUIPMENT_FAILURE =
	"UPDATE_STATUS_EQUIPMENT_FAILURE";


export const SHARE_SELF_LINK_EQUIPMENT_REQUEST =
	"SHARE_SELF_LINK_EQUIPMENT_REQUEST";
export const SHARE_SELF_LINK_EQUIPMENT_SUCCESS =
	"SHARE_SELF_LINK_EQUIPMENT_SUCCESS";
export const SHARE_SELF_LINK_EQUIPMENT_FAILURE =
	"SHARE_SELF_LINK_EQUIPMENT_FAILURE";

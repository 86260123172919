import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Badge, Tooltip, Typography } from "antd";
import moment from "moment";

const { Text } = Typography;

export const loopString = () => {};

export const displayCols = (columns, locale, tableId) => {
	return columns?.map((column) => {
		const columnsData = {
			title:
				locale === "en" ? column.columnLabelEn : column.columnLabelAr,
			dataIndex: column.columnValue,
			key: column.columnValue,
			id: column.id,
			visible: column.isVisible,
		};
		if (column.columnValue === "isOnline") {
			return {
				...columnsData,
				render: (text) => (
					<Badge
						style={{
							width: "10px",
							height: "10px",
							display: "inline",
						}}
						status={text ? "success" : "error"}
						text={text ? "Online" : "Offline"}
					/>
				),
			};
		} else if (column.columnValue === "isAvailable") {
			return {
				...columnsData,
				render: (text) =>
					text === "true" ? (
						<CheckCircleOutlined
							style={{ color: "#00FF66", fontSize: 25 }}
						/>
					) : (
						<CloseCircleOutlined
							style={{ color: "#F01A24", fontSize: 25 }}
						/>
					),
			};
		} else if (column.columnValue === "clockingType") {
			return {
				...columnsData,
				render: (text) =>
					text === 1 ? <Text>Check In</Text> : <Text>Check Out</Text>,
			};
		} else if (column.columnValue === "isValid") {
			return {
				...columnsData,
				render: (text) =>
					text === true ? (
						<Text>Successful</Text>
					) : (
						<Text>Unsuccessful</Text>
					),
			};
		} else if (
			column.columnValue === "startDate" ||
			column.columnValue === "endDate" ||
			column.columnValue === "manufactureDate"
		) {
			return {
				...columnsData,
				render: (text) =>
					text !== null &&
					text !== "" &&
					tableId !== 7 &&
					tableId !== 10 ? (
						<Text>{moment(text).format("DD/MM/YYYY")}</Text>
					) : (
						text
					),
			};
		} else if (
			column.columnValue === "createdOn" ||
			column.columnValue === "updatedOn" ||
			column.columnValue === "hireDate"
		) {
			return {
				...columnsData,
				render: (text) =>
					text !== null &&
					text !== "" && (
						<Text>{moment(text).format("DD/MM/YYYY")}</Text>
					),
			};
		} else if (column.columnValue === "mealName" || "areasName") {
			return {
				...columnsData,
				render: (text) =>
					Array.isArray(text) && text.length > 3 ? (
						<Tooltip placement="rightTop" title={text.join(", ")}>
							{text.slice(0, 3).join(", ")}...
						</Tooltip>
					) : Array.isArray(text) && text.length <= 3 ? (
						<Text>{text.join(", ")}</Text>
					) : (
						<Text>{text}</Text>
					),
			};
		} else {
			return columnsData;
		}
	});
};

export const displayOptions = (columns, locale) => {
	return columns?.map((column) => {
		return {
			key: column.columnValue,
			label:
				locale === "en" ? column.columnLabelEn : column.columnLabelAr,
			value: column.columnValue,
			visible: column.isVisible,
			id: column.id,
		};
	});
};
// export const displayOptionsDays = (optionDays, locale) => {
// 	return optionDays.map((day) => {
// 		return {
// 			key: day.key,
// 			label:day.label,
// 			value: day.Value,

// 		};
// 	});
// };
export const displayItemsCols = (columns) => {
	return columns.map((column) => {
		return {
			key: column.id,
			label: column.nameEn,
			value: column.id,
		};
	});
};

export const displayDropmenuItems = (columns) => {
	return columns.map((column) => {
		return {
			key: column.id,
			label: column.name,
			icon: <UserOutlined />,
		};
	});
};

export const displaySundayEvent = (daysApi) => {
	return daysApi.Sunday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.sundayStart, "HH:mm").weekday(0).toDate(),
			end: moment(event.sundayEnd, "HH:mm").weekday(0).toDate(),
		};
	});
};
export const displayMondayEvent = (daysApi) => {
	return daysApi.Monday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.mondayStart, "HH:mm").weekday(1).toDate(),
			end: moment(event.mondayEnd, "HH:mm").weekday(1).toDate(),
		};
	});
};
export const displayTuesdayEvent = (daysApi) => {
	return daysApi.Tuesday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.tuesdayStart, "HH:mm").weekday(2).toDate(),
			end: moment(event.tuesdayEnd, "HH:mm").weekday(2).toDate(),
		};
	});
};
export const displayWednesdayEvent = (daysApi) => {
	return daysApi.Wednesday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.wednesdayStart, "HH:mm").weekday(3).toDate(),
			end: moment(event.wednesdayEnd, "HH:mm").weekday(3).toDate(),
		};
	});
};

export const displayThursdayEvent = (daysApi) => {
	return daysApi.Thursday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.thursdayStart, "HH:mm").weekday(4).toDate(),
			end: moment(event.thursdayEnd, "HH:mm").weekday(4).toDate(),
		};
	});
};

export const displayFridayEvent = (daysApi) => {
	return daysApi.Friday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.fridayStart, "HH:mm").weekday(5).toDate(),
			end: moment(event.fridayEnd, "HH:mm").weekday(5).toDate(),
		};
	});
};
export const displaySaturdayEvent = (daysApi) => {
	return daysApi.Saturday?.map((event, index) => {
		return {
			// title:event.nameEn,
			start: moment(event.saturdayStart, "HH:mm").weekday(6).toDate(),
			end: moment(event.saturdayEnd, "HH:mm").weekday(6).toDate(),
		};
	});
};

import React from 'react';

const assetUrl = "/assets/images";

const CustomizeRenderEmpty = () => (
	<div style={{ textAlign: 'center' }}>
	 <img src = {`${assetUrl}/icons/empty.svg`} alt =" empty" />
	</div>
  );

  export default CustomizeRenderEmpty;

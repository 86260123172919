export const USER_GROUP_REQUEST = "USER_GROUP_REQUEST";
export const USER_GROUP_SUCCESS = "USER_GROUP_SUCCESS";
export const USER_GROUP_FAILURE = "USER_GROUP_FAILURE";

export const UserGroups_DDL_REQUEST = "UserGroups_DDL_REQUEST";
export const UserGroups_DDL_SUCCESS = "UserGroups_DDL_SUCCESS";
export const UserGroups_DDL_FAILURE = "UserGroups_DDL_FAILURE";
export const UserGroups_DDL_RESET = "UserGroups_DDL_RESET";

export const ADD_USERGROUP_REQUEST = "ADD_USERGROUP_REQUEST";
export const ADD_USERGROUP_SUCCESS = "ADD_USERGROUP_SUCCESS";
export const ADD_USERGROUP_FAILURE = "ADD_USERGROUP_FAILURE";

export const USERGROUP_DETAILS_REQUEST = "USERGROUP_DETAILS_REQUEST";
export const USERGROUP_DETAILS_SUCCESS = "USERGROUP_DETAILS_SUCCESS";
export const USERGROUP_DETAILS_FAILURE = "USERGROUP_DETAILS_FAILURE";
export const USERGROUP_DETAILS_RESET = "USERGROUP_DETAILS_RESET";

export const USERGROUP_UPDATE_REQUEST = "USERGROUP_UPDATE_REQUEST";
export const USERGROUP_UPDATE_SUCCESS = "USERGROUP_UPDATE_SUCCESS";
export const USERGROUP_UPDATE_FAILURE = "USERGROUP_UPDATE_FAILURE";
export const USERGROUP_UPDATE_RESET = "USERGROUP_UPDATE_RESET";

export const USERGROUP_DELETE_REQUEST = "USERGROUP_DELETE_REQUEST";
export const USERGROUP_DELETE_SUCCESS = "USERGROUP_DELETE_SUCCESS";
export const USERGROUP_DELETE_FAILURE = "USERGROUP_DELETE_FAILURE";

export const USERGROUP_USERS_REQUEST = "USERGROUP_USERS_REQUEST";
export const USERGROUP_USERS_SUCCESS = "USERGROUP_USERS_SUCCESS";
export const USERGROUP_USERS_FAILURE = "USERGROUP_USERS_FAILURE";
export const USERGROUP_USERS_RESET = "USERGROUP_USERS_RESET";

export const USERGROUP_USERS_DELETE_REQUEST = "USERGROUP_USERS_DELETE_REQUEST";

export const USERGROUP_USERS_DELETE_SUCCESS = "USERGROUP_USERS_DELETE_SUCCESS";

export const USERGROUP_USERS_DELETE_FAILURE = "USERGROUP_USERS_DELETE_FAILURE";

export const USERGROUP_ADD_USERS_REQUEST = "USERGROUP_ADD_USERS_REQUEST";

export const USERGROUP_ADD_USERS_SUCCESS = "USERGROUP_ADD_USERS_SUCCESS";

export const USERGROUP_ADD_USERS_FAILURE = "USERGROUP_ADD_USERS_FAILURE";

export const UserGroup_Import_REQUEST = "UserGroup_Import_REQUEST";
export const UserGroup_Import_SUCCESS = "UserGroup_Import_SUCCESS";
export const UserGroup_Import_FAILURE = "UserGroup_Import_FAILURE";

export const UserGroup_Export_REQUEST = "UserGroup_Export_REQUEST";
export const UserGroup_Export_SUCCESS = "UserGroup_Export_SUCCESS";
export const UserGroup_Export_FAILURE = "UserGroup_Export_FAILURE";
import {
	PROFILE_USER_REQUEST,
	PROFILE_USER_FORM_SUCCESS,
	PROFILE_USER_FORM_FAILURE,
	DELETE_USER_REQUEST,
	DELETE_USER_SUCCESS,
	DELETE_USER_FAILURE,
	USER_DETAILS_REQUEST,
	USER_DETAILS_SUCCESS,
	USER_DETAILS_FAILURE,
	USER_DETAILS_RESET,
	USER_UPDATE_REQUEST,
	USER_UPDATE_SUCCESS,
	USER_UPDATE_FAILURE,
	USER_UPDATE_RESET,
	LOCK_USER_REQUEST,
	UNLOCK_USER_REQUEST,
	UNLOCK_USER_SUCCESS,
	LOCK_USER_SUCCESS,
	LOCK_USER_FAILURE,
	UNLOCK_USER_FAILURE,
	LOCK_USER_RESET,
	UNLOCK_USER_RESET,
	CARD_USER_REQUEST,
	CARD_USER_SUCCESS,
	CARD_USER_FAILURE,
	CARD_USER_RESET,
	ADD_CARD_REQUEST,
	ADD_CARD_SUCCESS,
	ADD_CARD_FAILURE,
	ADD_CARD_RESET,
	DELETE_CARD_REQUEST,
	DELETE_CARD_SUCCESS,
	DELETE_CARD_FAILURE,
	PROFILE_DATA_REQUEST,
	PROFILE_DATA_SUCCESS,
	PROFILE_DATA_FAILURE,
	USERS_DDL_REQUEST,
	USERS_DDL_SUCCESS,
	USERS_DDL_FAILURE,
	SEND_SELF_LINK_REQUEST,
	SEND_SELF_LINK_SUCCESS,
	SEND_SELF_LINK_FAILURE,
	USERS_DDL_RESET,
	PIN_USER_REQUEST,
	PIN_USER_SUCCESS,
	PIN_USER_FAILURE,
	PIN_USER_RESET,
	USER_Import_REQUEST,
	USER_Import_SUCCESS,
	USER_Import_FAILURE,
	USER_Export_REQUEST,
	USER_Export_SUCCESS,
	USER_Export_FAILURE,
	USER_REQUEST,
	USER_SUCCESS,
	USER_FAILURE,
	USERS_DDL_TYPE_REQUEST,
	USERS_DDL_TYPE_SUCCESS,
	USERS_DDL_TYPE_FAILURE,
	USERS_DDL_TYPE_RESET,
} from "./userTypesConstants";

export const userReducer = (
	initialState = {
		users: [],
		isHasUsers: false,
	},
	action
) => {
	switch (action.type) {
		// Profile Form
		case PROFILE_USER_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case PROFILE_USER_FORM_SUCCESS:
			return {
				...initialState,
				loading: false,
				user: action.payload,
			};

		case PROFILE_USER_FORM_FAILURE:
			return {
				...initialState,
				loading: false,
				addError: action.payload,
			};
		//get user
		case USER_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case USER_SUCCESS:
			return {
				...initialState,
				loading: false,
			};
		case USER_FAILURE:
			return {
				...initialState,
				loading: false,
				deleteUsersError: action.payload,
			};
		//import
		case USER_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case USER_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case USER_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		//Export
		case USER_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case USER_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case USER_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		// delete user
		case DELETE_USER_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_USER_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_USER_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		// ddl users
		case USERS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				users: [],
			};
		case USERS_DDL_SUCCESS:
			return {
				...initialState,
				loading: false,
				users: action.payload.result.data,
				isHasUsers: action.payload.result.data?.length ? true : false,
			};
		case USERS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLUsersError: action.payload,
			};
		case USERS_DDL_RESET:
			return {
				...initialState,
				isHasUsers: false,
			};

		// Get User Type DDL
		case USERS_DDL_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				usersType: [],
			};
		case USERS_DDL_TYPE_SUCCESS:
			return {
				...initialState,
				loading: false,
				usersType: action.payload.result.data,
			};
		case USERS_DDL_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLUsersTypeError: action.payload,
			};
		case USERS_DDL_TYPE_RESET:
			return {
				...initialState,
				isHasUsersType: false,
			};

		// SEND SELF LINK CARD
		case SEND_SELF_LINK_REQUEST:
			return {
				...initialState,
				sendLinkLoading: true,
			};
		case SEND_SELF_LINK_SUCCESS:
			return {
				...initialState,
				sendLinkLoading: false,
			};
		case SEND_SELF_LINK_FAILURE:
			return {
				...initialState,
				sendLinkLoading: false,
				sendLinkError: action.payload,
			};
		default:
			return initialState;
	}
};

export const userDetailsReducer = (state = { userDetails: {} }, action) => {
	switch (action.type) {
		case USER_DETAILS_REQUEST:
			return { ...state, loading: true };
		case USER_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				userDetails: action.payload.result.data,
			};
		case USER_DETAILS_FAILURE:
			return { ...state, loading: false, detailsError: action.payload };
		case USER_DETAILS_RESET:
			return { ...state, userDetails: {} };
		// User Update
		case USER_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case USER_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case USER_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case USER_UPDATE_RESET:
			return { ...state, user: {} };
		// User Lock / UnLock
		case LOCK_USER_REQUEST:
		case UNLOCK_USER_REQUEST:
			return {
				...state,
				lockLoading: true,
			};
		case LOCK_USER_SUCCESS:
		case UNLOCK_USER_SUCCESS:
			return {
				...state,
				lockLoading: false,
				lockSuccess: true,
			};
		case LOCK_USER_FAILURE:
		case UNLOCK_USER_FAILURE:
			return {
				...state,
				lockLoading: false,
				lockError: action.payload,
			};
		case LOCK_USER_RESET:
		case UNLOCK_USER_RESET:
			return {
				...state,
				user: {},
			};
		case PROFILE_DATA_REQUEST:
			return { ...state, profileLoading: true };
		case PROFILE_DATA_SUCCESS:
			return {
				...state,
				profileLoading: false,
				profileData: action.payload,
			};
		case PROFILE_DATA_FAILURE:
			return {
				...state,
				profileLoading: false,
				profileError: action.payload,
			};
		default:
			return state;
	}
};

// add card credentials
export const UserCredentialsReducer = (
	state = { cards: [], pins: [] },
	action
) => {
	switch (action.type) {
		case CARD_USER_REQUEST:
			return { ...state, cardLoading: true };
		case CARD_USER_SUCCESS:
			return { ...state, cardLoading: false, cards: action.payload };
		case CARD_USER_FAILURE:
			return {
				...state,
				cardLoading: false,
				getCardError: action.payload,
			};
		case CARD_USER_RESET:
			return { cards: [] };
		case PIN_USER_REQUEST:
			return { ...state, pinLoading: true };
		case PIN_USER_SUCCESS:
			return { ...state, pinLoading: false, pins: action.payload };
		case PIN_USER_FAILURE:
			return { ...state, pinLoading: false, getPinError: action.payload };
		case PIN_USER_RESET:
			return { pins: [] };
		case ADD_CARD_REQUEST:
			return { ...state, addLoading: true };
		case ADD_CARD_SUCCESS:
			return { ...state, addLoading: false, cards: action.payload };
		case ADD_CARD_FAILURE:
			return {
				...state,
				addLoading: false,
				addError: action.payload,
			};
		case ADD_CARD_RESET:
			return { ...state, cards: [] };
		case DELETE_CARD_REQUEST:
			return { ...state, deleteLoading: true };
		case DELETE_CARD_SUCCESS:
			return { ...state, deleteLoading: false };
		case DELETE_CARD_FAILURE:
			return {
				...state,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return state;
	}
};

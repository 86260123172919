import {
	ADD_OUTSIDE_WITNESS_FAILURE,
	ADD_OUTSIDE_WITNESS_REQUEST,
	ADD_OUTSIDE_WITNESS_SUCCESS,
	DELETE_OUTSIDE_WITNESS_FAILURE,
	DELETE_OUTSIDE_WITNESS_REQUEST,
	DELETE_OUTSIDE_WITNESS_SUCCESS,
	OUTSIDE_WITNESS_FAILURE,
	OUTSIDE_WITNESS_REQUEST,
	OUTSIDE_WITNESS_Reset,
	OUTSIDE_WITNESS_SUCCESS,
} from "./OutsideWitnessConstant";

// Outside Witness
export const outsideExternalWitness = (
	initialState = {
		OutsideWitness: [],
		GetOutsideWitnessLoading: false,
		addOutsideWitnessLoading: false,
		deleteOutsideWitnessLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Outside Witness
		case OUTSIDE_WITNESS_REQUEST:
			return {
				...initialState,
				GetOutsideWitnessLoading: true,
			};
		case OUTSIDE_WITNESS_SUCCESS:
			return {
				...initialState,
				OutsideWitness: action.payload.result.data,
				GetOutsideWitnessLoading: false,
			};
		case OUTSIDE_WITNESS_FAILURE:
			return {
				...initialState,
				GetOutsideWitnessLoading: false,
				getError: action.payload,
			};
		case OUTSIDE_WITNESS_Reset:
			return {
				...initialState,
				OutsideWitness: [],
			};
		// Add Outside Witness
		case ADD_OUTSIDE_WITNESS_REQUEST:
			return {
				...initialState,
				addOutsideWitnessLoading: true,
			};
		case ADD_OUTSIDE_WITNESS_SUCCESS:
			return {
				...initialState,
				addOutsideWitnessLoading: false,
			};
		case ADD_OUTSIDE_WITNESS_FAILURE:
			return {
				...initialState,
				addOutsideWitnessLoading: false,
				addError: action.payload,
			};

		// Delete Outside Witness
		case DELETE_OUTSIDE_WITNESS_REQUEST:
			return {
				...initialState,
				deleteOutsideWitnessLoading: true,
			};
		case DELETE_OUTSIDE_WITNESS_SUCCESS:
			return {
				...initialState,
				deleteOutsideWitnessLoading: false,
			};
		case DELETE_OUTSIDE_WITNESS_FAILURE:
			return {
				...initialState,
				deleteOutsideWitnessLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

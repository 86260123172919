export const Branches_TYPE_REQUEST = "Branches_TYPE_REQUEST";
export const Branches_TYPE_SUCCESS = "Branches_TYPE_SUCCESS";
export const Branches_TYPE_FAILURE = "Branches_TYPE_FAILURE";

export const Branches_ADD_REQUEST = "Branches_ADD_REQUEST";
export const Branches_ADD_SUCCESS = "Branches_ADD_SUCCESS";
export const Branches_ADD_FAILURE = "Branches_ADD_FAILURE";

export const Branches_DDL_REQUEST = "Branches_DDL_REQUEST";
export const Branches_DDL_SUCCESS = "Branches_DDL_SUCCESS";
export const Branches_DDL_FAILURE = "Branches_DDL_FAILURE";
export const Branches_DDL_RESET = "Branches_DDL_RESET";

export const Branches_DELETE_REQUEST = "Branches_DELETE_REQUEST";
export const Branches_DELETE_SUCCESS = "Branches_DELETE_SUCCESS";
export const Branches_DELETE_FAILURE = "Branches_DELETE_FAILURE";

export const Branches_UPDATE_REQUEST = "Branches_UPDATE_REQUEST";
export const Branches_UPDATE_SUCCESS = "Branches_UPDATE_SUCCESS";
export const Branches_UPDATE_FAILURE = "Branches_UPDATE_FAILURE";
export const Branches_UPDATE_RESET = "Branches_UPDATE_RESET";

export const Branches_DETAILS_REQUEST = "Branches_DETAILS_REQUEST";
export const Branches_DETAILS_SUCCESS = "Branches_DETAILS_SUCCESS";
export const Branches_DETAILS_FAILURE = "Branches_DETAILS_FAILURE";
export const Branches_DETAILS_RESET = "Branches_DETAILS_RESET";

export const CommercialRegisterBranchAttachment_TYPE_REQUEST =
	"CommercialRegisterBranchAttachment_TYPE_REQUEST";
export const CommercialRegisterBranchAttachment_TYPE_SUCCESS =
	"CommercialRegisterBranchAttachment_TYPE_SUCCESS";
export const CommercialRegisterBranchAttachment_TYPE_FAILURE =
	"CommercialRegisterBranchAttachment_TYPE_FAILURE";

export const CommercialRegisterBranchAttachment_DELETE_REQUEST =
	"CommercialRegisterBranchAttachment_DELETE_REQUEST";
export const CommercialRegisterBranchAttachment_DELETE_SUCCESS =
	"CommercialRegisterBranchAttachment_DELETE_SUCCESS";
export const CommercialRegisterBranchAttachment_DELETE_FAILURE =
	"CommercialRegisterBranchAttachment_DELETE_FAILURE";

export const CommercialRegisterBranchAttachment_ADD_REQUEST =
	"CommercialRegisterBranchAttachment_ADD_REQUEST";
export const CommercialRegisterBranchAttachment_ADD_SUCCESS =
	"CommercialRegisterBranchAttachment_ADD_SUCCESS";
export const CommercialRegisterBranchAttachment_ADD_FAILURE =
	"CommercialRegisterBranchAttachment_ADD_FAILURE";

export const InsurancePoicyBranchAttachment_TYPE_REQUEST =
	"InsurancePoicyBranchAttachment_TYPE_REQUEST";
export const InsurancePoicyBranchAttachment_TYPE_SUCCESS =
	"InsurancePoicyBranchAttachment_TYPE_SUCCESS";
export const InsurancePoicyBranchAttachment_TYPE_FAILURE =
	"InsurancePoicyBranchAttachment_TYPE_FAILURE";

export const InsurancePoicyBranchAttachment_DELETE_REQUEST =
	"InsurancePoicyBranchAttachment_DELETE_REQUEST";
export const InsurancePoicyBranchAttachment_DELETE_SUCCESS =
	"InsurancePoicyBranchAttachment_DELETE_SUCCESS";
export const InsurancePoicyBranchAttachment_DELETE_FAILURE =
	"InsurancePoicyBranchAttachment_DELETE_FAILURE";

export const InsurancePoicyBranchAttachment_ADD_REQUEST =
	"InsurancePoicyBranchAttachment_ADD_REQUEST";
export const InsurancePoicyBranchAttachment_ADD_SUCCESS =
	"InsurancePoicyBranchAttachment_ADD_SUCCESS";
export const InsurancePoicyBranchAttachment_ADD_FAILURE =
	"InsurancePoicyBranchAttachment_ADD_FAILURE";

export const InsurancePoicyBranchAttachment_Edit_REQUEST =
	"InsurancePoicyBranchAttachment_Edit_REQUEST";
export const InsurancePoicyBranchAttachment_Edit_SUCCESS =
	"InsurancePoicyBranchAttachment_Edit_SUCCESS";
export const InsurancePoicyBranchAttachment_Edit_FAILURE =
	"InsurancePoicyBranchAttachment_Edit_FAILURE";

export const GET_ACCESS_POINT_CANTEEN_REQUEST = "GET_ACCESS_POINT_CANTEEN_REQUEST";
export const GET_ACCESS_POINT_CANTEEN_SUCCESS = "GET_ACCESS_POINT_CANTEEN_SUCCESS";
export const GET_ACCESS_POINT_CANTEEN_FAILURE = "GET_ACCESS_POINT_CANTEEN_FAILURE";

export const ACCESS_POINTS_CANTEEN_DDL_REQUEST =
	"ACCESS_POINTS_CANTEEN_DDL_REQUEST";
export const ACCESS_POINTS_CANTEEN_DDL_SUCCESS =
	"ACCESS_POINTS_CANTEEN_DDL_SUCCESS";
export const ACCESS_POINTS_CANTEEN_DDL_FAILURE =
	"ACCESS_POINTS_CANTEEN_DDL_FAILURE";
export const ACCESS_POINTS_CANTEEN_DDL_RESET =
	"ACCESS_POINTS_CANTEEN_DDL_RESET";

export const DELETE_ACCESS_POINT_CANTEEN_REQUEST = "DELETE_ACCESS_POINT_CANTEEN_REQUEST";
export const DELETE_ACCESS_POINT_CANTEEN_SUCCESS = "DELETE_ACCESS_POINT_CANTEEN_SUCCESS";
export const DELETE_ACCESS_POINT_CANTEEN_FAILURE = "DELETE_ACCESS_POINT_CANTEEN_FAILURE";

export const ADD_ACCESS_POINT_CANTEEN_REQUEST = "ADD_ACCESS_POINT_CANTEEN_REQUEST";
export const ADD_ACCESS_POINT_CANTEEN_SUCCESS = "ADD_ACCESS_POINT_CANTEEN_SUCCESS";
export const ADD_ACCESS_POINT_CANTEEN_FAILURE = "ADD_ACCESS_POINT_CANTEEN_FAILURE";

export const GET_ACCESS_POINT_CANTEEN_DETAILS_REQUEST =
	"GET_ACCESS_POINT_CANTEEN_DETAILS_REQUEST";
export const GET_ACCESS_POINT_CANTEEN_DETAILS_SUCCESS =
	"GET_ACCESS_POINT_CANTEEN_DETAILS_SUCCESS";
export const GET_ACCESS_POINT_CANTEEN_DETAILS_FAILURE =
	"GET_ACCESS_POINT_CANTEEN_DETAILS_FAILURE";
export const GET_ACCESS_POINT_CANTEEN_DETAILS_RESET = "GET_ACCESS_POINT_CANTEEN_DETAILS_RESET";


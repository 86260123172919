import {
	GET_ACCESS_POINT_CANTEEN_REQUEST,
	GET_ACCESS_POINT_CANTEEN_SUCCESS,
	GET_ACCESS_POINT_CANTEEN_FAILURE,
	DELETE_ACCESS_POINT_CANTEEN_REQUEST,
	DELETE_ACCESS_POINT_CANTEEN_SUCCESS,
	DELETE_ACCESS_POINT_CANTEEN_FAILURE,
	ADD_ACCESS_POINT_CANTEEN_REQUEST,
	ADD_ACCESS_POINT_CANTEEN_SUCCESS,
	ADD_ACCESS_POINT_CANTEEN_FAILURE,
	GET_ACCESS_POINT_CANTEEN_DETAILS_REQUEST,
	GET_ACCESS_POINT_CANTEEN_DETAILS_SUCCESS,
	GET_ACCESS_POINT_CANTEEN_DETAILS_FAILURE,
	GET_ACCESS_POINT_CANTEEN_DETAILS_RESET,
	ACCESS_POINTS_CANTEEN_DDL_REQUEST,
	ACCESS_POINTS_CANTEEN_DDL_SUCCESS,
	ACCESS_POINTS_CANTEEN_DDL_FAILURE,
	ACCESS_POINTS_CANTEEN_DDL_RESET,
} from "./AccessPointsCanteenTypeConstants";

export const accessPointCanteenReducer = (
	initialState = { accessPointCanteens: [], isHasAccessPointCanteen: false },
	action
) => {
	switch (action.type) {
		case GET_ACCESS_POINT_CANTEEN_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_ACCESS_POINT_CANTEEN_SUCCESS:
			return {
				...initialState,
				getAllAccessPointCanteen: action.payload.result.data,
				loading: false,
			};

		case GET_ACCESS_POINT_CANTEEN_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case ACCESS_POINTS_CANTEEN_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				accessPointCanteens: [],
			};
		case ACCESS_POINTS_CANTEEN_DDL_SUCCESS:
			return {
				...initialState,
				accessPointCanteens: action.payload.result.data,
				loading: false,
				isHasAccessPointCanteen:
					action.payload.result.data?.length > 0 ? true : false,
			};

		case ACCESS_POINTS_CANTEEN_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case ACCESS_POINTS_CANTEEN_DDL_RESET:
			return {
				...initialState,
				isHasAccessPointCanteen: false,
			};

		case DELETE_ACCESS_POINT_CANTEEN_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_ACCESS_POINT_CANTEEN_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_ACCESS_POINT_CANTEEN_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		case ADD_ACCESS_POINT_CANTEEN_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_ACCESS_POINT_CANTEEN_SUCCESS: {
			return {
				...initialState,
				addLoading: false,
			};
		}
		case ADD_ACCESS_POINT_CANTEEN_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		case GET_ACCESS_POINT_CANTEEN_DETAILS_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
			};
		case GET_ACCESS_POINT_CANTEEN_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				accessPointDetails: action.payload,
			};
		case GET_ACCESS_POINT_CANTEEN_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case GET_ACCESS_POINT_CANTEEN_DETAILS_RESET:
			return {
				...initialState,
				accessPointDetails: {},
			};
		default:
			return initialState;
	}
};

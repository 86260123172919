import api from "../../../util/Api";
import {
	GET_ACCESS_POINT_REQUEST,
	GET_ACCESS_POINT_SUCCESS,
	GET_ACCESS_POINT_FAILURE,
	DELETE_ACCESS_POINT_REQUEST,
	DELETE_ACCESS_POINT_SUCCESS,
	DELETE_ACCESS_POINT_FAILURE,
	ADD_ACCESS_POINT_REQUEST,
	ADD_ACCESS_POINT_SUCCESS,
	ADD_ACCESS_POINT_FAILURE,
	GET_ACCESS_POINT_DETAILS_REQUEST,
	GET_ACCESS_POINT_DETAILS_SUCCESS,
	GET_ACCESS_POINT_DETAILS_FAILURE,
	CONFIGURE_ACCESS_POINT_REQUEST,
	CONFIGURE_ACCESS_POINT_SUCCESS,
	CONFIGURE_ACCESS_POINT_FAILURE,
	ACCESS_POINT_UPDATE_REQUEST,
	ACCESS_POINT_UPDATE_SUCCESS,
	ACCESS_POINT_UPDATE_FAILURE,
	ACCESS_POINTS_DDL_REQUEST,
	ACCESS_POINTS_DDL_SUCCESS,
	ACCESS_POINTS_DDL_FAILURE,
	LAST_NUMBER_ACCESS_POINT_REQUEST,
	LAST_NUMBER_ACCESS_POINT_SUCCESS,
	LAST_NUMBER_ACCESS_POINT_FAILURE,
	AccessPoint_Import_REQUEST,
	AccessPoint_Import_SUCCESS,
	AccessPoint_Import_FAILURE,
	AccessPoint_Export_REQUEST,
	AccessPoint_Export_SUCCESS,
	AccessPoint_Export_FAILURE,
} from "./AccessPointTypeConstants";

export const getAccessPoint =
	(
		pageNumber = 1,
		pageSize = 50,
		searchText = "",
		areaIds = [],
		buildingIds = [],
		accessPointGroupIds = [],
		deviceIds = []
	) =>
	async (dispatch) => {
		try {
			dispatch({ type: GET_ACCESS_POINT_REQUEST });
			const { data } = await api.post("AccessControl/GetAccessPoints", {
				pageNumber,
				pageSize,
				searchText,
				deviceIds,
				accessPointGroupIds,
				buildingIds,
				areaIds,
			});

			return dispatch({
				type: GET_ACCESS_POINT_SUCCESS,
				payload: data,
			});
		} catch (error) {
			return dispatch({
				type: GET_ACCESS_POINT_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};

export const DDLAccessPoints = (isHasAccessPoints) => async (dispatch) => {
	try {
		if (isHasAccessPoints === false) {
			dispatch({ type: ACCESS_POINTS_DDL_REQUEST });
			const { data: result } = await api.post(
				"AccessControl/GetDDLAccessPoints"
			);
			return dispatch({
				type: ACCESS_POINTS_DDL_SUCCESS,
				payload: result,
			});
		}
	} catch (error) {
		dispatch({
			type: ACCESS_POINTS_DDL_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const exportAccessPoints = (request) => async (dispatch) => {
	dispatch({ type: AccessPoint_Export_REQUEST });
	try {
		const data = await api.post(
			"AccessControl/ExportAccessPoints",
			request,
			{
				responseType: "arraybuffer",
			}
		);
		return dispatch({ type: AccessPoint_Export_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: AccessPoint_Export_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};
//import
export const handleImportAccessPoints = (request) => async (dispatch) => {
	dispatch({ type: AccessPoint_Import_REQUEST });
	try {
		const data = await api.post(
			"AccessControl/ImportAccessPoints",
			request
		);
		return dispatch({ type: AccessPoint_Import_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: AccessPoint_Import_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const deleteAccessPoint = (accessPointIds) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_ACCESS_POINT_REQUEST });
		const response = await api.delete("AccessControl/DeleteAccessPoint", {
			data: { accessPointIds },
		});
		return dispatch({ type: DELETE_ACCESS_POINT_SUCCESS, response });
	} catch (error) {
		return dispatch({
			type: DELETE_ACCESS_POINT_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const addAccessPoint = (accessPoint) => {
	return async (dispatch) => {
		dispatch({ type: ADD_ACCESS_POINT_REQUEST });
		try {
			const { data } = await api.post(
				"AccessControl/AddAccessPoint",
				accessPoint
			);
			return dispatch({ type: ADD_ACCESS_POINT_SUCCESS, payload: data });
		} catch (error) {
			return dispatch({
				type: ADD_ACCESS_POINT_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const getAccessPointDetails = (accessPointId) => async (dispatch) => {
	try {
		dispatch({ type: GET_ACCESS_POINT_DETAILS_REQUEST });
		const { data } = await api.post(
			`AccessControl/GetAccessPoint/${accessPointId}`
		);
		return dispatch({
			type: GET_ACCESS_POINT_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: GET_ACCESS_POINT_DETAILS_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const configureAccessPoint =
	(accessPointId, relayDelay, doorState) => async (dispatch) => {
		try {
			dispatch({ type: CONFIGURE_ACCESS_POINT_REQUEST });
			const { data } = await api.post(
				"AccessControl/ConfigureAccessPoint",
				{
					accessPointId,
					relayDelay,
					doorState,
				}
			);

			return dispatch({
				type: CONFIGURE_ACCESS_POINT_SUCCESS,
				payload: data,
			});
		} catch (error) {
			return dispatch({
				type: CONFIGURE_ACCESS_POINT_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};

export const updateAccessPoint = (accessPointId, accessPoint) => {
	return async (dispatch) => {
		try {
			dispatch({ type: ACCESS_POINT_UPDATE_REQUEST });
			const data = await api.put(
				`AccessControl/ModifyAccessPoint/${accessPointId}`,
				{
					id: accessPointId,
					...accessPoint,
				}
			);
			return dispatch({
				type: ACCESS_POINT_UPDATE_SUCCESS,
				payload: data,
			});
		} catch (error) {
			dispatch({
				type: ACCESS_POINT_UPDATE_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const getLastNumberAccessPoint = (accessPointId) => {
	return async (dispatch) => {
		dispatch({ type: LAST_NUMBER_ACCESS_POINT_REQUEST });
		try {
			const { data } = await api.get(
				`AccessControl/GetLastCardNoByAccessPoint/${accessPointId}`
			);
			return dispatch({
				type: LAST_NUMBER_ACCESS_POINT_SUCCESS,
				payload: { data },
			});
		} catch (error) {
			dispatch({
				type: LAST_NUMBER_ACCESS_POINT_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

import {
	GET_MEALS_REQUEST,
	GET_MEALS_SUCCESS,
	GET_MEALS_FAILURE,
	ADD_MEAL_REQUEST,
	ADD_MEAL_SUCCESS,
	ADD_MEAL_FAILURE,
	DELETE_MEALS_REQUEST,
	DELETE_MEALS_SUCCESS,
	DELETE_MEALS_FAILURE,
	MEAL_DETAILS_REQUEST,
	MEAL_DETAILS_SUCCESS,
	MEAL_DETAILS_FAILURE,
	MEAL_DETAILS_RESET,
	UPDATE_MEAL_REQUEST,
	UPDATE_MEAL_SUCCESS,
	UPDATE_MEAL_FAILURE,
	UPDATE_MEAL_RESET,
	MEALS_DDL_REQUEST,
	MEALS_DDL_SUCCESS,
	MEALS_DDL_FAILURE,
	MEALS_DDL_RESET,
} from "./MealsCanteenTypeConstants";

export const mealsCanteenReducer = (
	initialState = { meals: [], meal: [], isHasMeals: false },
	action
) => {
	switch (action.type) {
		case GET_MEALS_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_MEALS_SUCCESS:
			return {
				...initialState,
				getAllMeals: action.payload.result.data,
				loading: false,
			};
		case GET_MEALS_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case MEALS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				meals: [],
			};
		case MEALS_DDL_SUCCESS:
			return {
				...initialState,
				meals: action.payload.result.data,
				loading: false,
				isHasMeals: action.payload.result?.data?.length ? true : false,
			};

		case MEALS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case MEALS_DDL_RESET:
			return {
				...initialState,
				isHasMeals: false,
			};

		case ADD_MEAL_REQUEST:
			return { ...initialState, addLoading: true };
		case ADD_MEAL_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				meal: action.payload,
			};
		case ADD_MEAL_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		case DELETE_MEALS_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_MEALS_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_MEALS_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		default:
			return initialState;
	}
};

//MEAL details & edit
export const mealDetailsCanteenReducer = (state = { meal: {} }, action) => {
	switch (action.type) {
		case MEAL_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case MEAL_DETAILS_SUCCESS:
			return { ...state, detailsLoading: false, meal: action.payload };
		case MEAL_DETAILS_FAILURE:
			return { ...state, detailsLoading: false, detailsError: action.payload };
		case MEAL_DETAILS_RESET:
			return { ...state, meal: {} };
		case UPDATE_MEAL_REQUEST:
			return { ...state, updateLoading: true };
		case UPDATE_MEAL_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case UPDATE_MEAL_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case UPDATE_MEAL_RESET:
			return { ...state, meal: {} };
		default:
			return state;
	}
};

import IntlMessages from "../../util/IntlMessages";

const languageData = [
	{
		languageId: "english",
		locale: "en",
		name: <IntlMessages id="english" />,
		icon: "us",
	},
	{
		languageId: "saudi-arabia",
		locale: "ar",
		name: <IntlMessages id="arabic" />,
		icon: "sa",
	},
];
export default languageData;

import {
	USER_GROUP_REQUEST,
	USER_GROUP_SUCCESS,
	USER_GROUP_FAILURE,
	ADD_USERGROUP_REQUEST,
	ADD_USERGROUP_SUCCESS,
	ADD_USERGROUP_FAILURE,
	USERGROUP_DETAILS_REQUEST,
	USERGROUP_DETAILS_SUCCESS,
	USERGROUP_DETAILS_FAILURE,
	USERGROUP_DETAILS_RESET,
	USERGROUP_UPDATE_REQUEST,
	USERGROUP_UPDATE_SUCCESS,
	USERGROUP_UPDATE_FAILURE,
	USERGROUP_UPDATE_RESET,
	USERGROUP_DELETE_REQUEST,
	USERGROUP_DELETE_SUCCESS,
	USERGROUP_DELETE_FAILURE,
	USERGROUP_USERS_REQUEST,
	USERGROUP_USERS_SUCCESS,
	USERGROUP_USERS_FAILURE,
	USERGROUP_USERS_RESET,
	USERGROUP_USERS_DELETE_REQUEST,
	USERGROUP_USERS_DELETE_SUCCESS,
	USERGROUP_USERS_DELETE_FAILURE,
	USERGROUP_ADD_USERS_REQUEST,
	USERGROUP_ADD_USERS_SUCCESS,
	USERGROUP_ADD_USERS_FAILURE,
	UserGroups_DDL_REQUEST,
	UserGroups_DDL_SUCCESS,
	UserGroups_DDL_FAILURE,
	UserGroups_DDL_RESET,
	UserGroup_Import_REQUEST,
	UserGroup_Import_SUCCESS,
	UserGroup_Import_FAILURE,
	UserGroup_Export_REQUEST,
	UserGroup_Export_SUCCESS,
	UserGroup_Export_FAILURE,
} from "./userGroupTypeContants";

export const userGroupReducer = (
	initialState = {
		userGroups: [],
		isHasUserGroups: false,
	},
	action
) => {
	switch (action.type) {
		/* Get UserGroups */
		case USER_GROUP_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllUserGroups: [],
			};
		case USER_GROUP_SUCCESS:
			return {
				...initialState,
				getAllUserGroups: action.payload.result.data,
				loading: false,
			};

		case USER_GROUP_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//import
		case UserGroup_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case UserGroup_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case UserGroup_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		//Export
		case UserGroup_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case UserGroup_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case UserGroup_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};

		case UserGroups_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				userGroups: [],
			};
		case UserGroups_DDL_SUCCESS:
			return {
				...initialState,
				userGroups: action.payload.result.data,
				loading: false,
				isHasUserGroups: action.payload.result.data?.length
					? true
					: false,
			};

		case UserGroups_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLUserGroupsError: action.payload,
			};
		case UserGroups_DDL_RESET:
			return {
				...initialState,
				isHasUserGroups: false,
			};

		/* Add UserGroup */
		case ADD_USERGROUP_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_USERGROUP_SUCCESS:
			return {
				...initialState,
				addLoading: false,
			};
		case ADD_USERGROUP_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		/* Get UserGroup Details */

		case USERGROUP_DETAILS_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
			};
		case USERGROUP_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				userGroup: action.payload,
			};
		case USERGROUP_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case USERGROUP_DETAILS_RESET:
			return {
				...initialState,
				userGroup: {},
			};

		/* Update UserGroup */
		case USERGROUP_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case USERGROUP_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case USERGROUP_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case USERGROUP_UPDATE_RESET:
			return { ...initialState, userGroup: {} };
		case USERGROUP_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case USERGROUP_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case USERGROUP_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		/* Get UserGroups Users */
		case USERGROUP_USERS_REQUEST:
			return {
				...initialState,
				userGroupsUserLoading: true,
			};
		case USERGROUP_USERS_SUCCESS:
			return {
				...initialState,
				userGroupUsers: action.payload.result.data,
				userGroupsUserLoading: false,
			};
		case USERGROUP_USERS_FAILURE:
			return {
				...initialState,
				userGroupsUserLoading: false,
				getError: action.payload,
			};
		case USERGROUP_USERS_RESET:
			return {
				...initialState,
				userGroupUsers: [],
			};

		/* Delete UserGroups Users */
		case USERGROUP_USERS_DELETE_REQUEST:
			return {
				...initialState,
				deleteUserLoading: true,
			};
		case USERGROUP_USERS_DELETE_SUCCESS:
			return {
				...initialState,
				deleteUserLoading: false,
			};
		case USERGROUP_USERS_DELETE_FAILURE:
			return {
				...initialState,
				deleteUserLoading: false,
				deleteError: action.payload,
			};
		/* Add UserGroups Users */
		case USERGROUP_ADD_USERS_REQUEST:
			return {
				...initialState,
				addUserLoading: true,
			};
		case USERGROUP_ADD_USERS_SUCCESS:
			return {
				...initialState,
				addUserLoading: false,
			};
		case USERGROUP_ADD_USERS_FAILURE:
			return {
				...initialState,
				addUserLoading: false,
				addError: action.payload,
			};
		default:
			return initialState;
	}
};

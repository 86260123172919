import {
	AREAS_USER_REQUEST,
	AREAS_USER_SUCCESS,
	AREAS_USER_FAILURE,
	ADD_AREA_REQUEST,
	ADD_AREA_SUCCESS,
	ADD_AREA_FAILURE,
	DELETE_AREA_REQUEST,
	DELETE_AREA_SUCCESS,
	DELETE_AREA_FAILURE,
	UPDATE_AREA_REQUEST,
	UPDATE_AREA_SUCCESS,
	UPDATE_AREA_FAILURE,
	UPDATE_AREA_RESET,
	DETAILS_AREA_REQUEST,
	DETAILS_AREA_SUCCESS,
	DETAILS_AREA_FAILURE,
	DETAILS_AREA_RESET,
	AREAS_DDL_REQUEST,
	AREAS_DDL_SUCCESS,
	AREAS_DDL_FAILURE,
	AREAS_DDL_RESET,
	Area_Import_REQUEST,
	Area_Import_SUCCESS,
	Area_Import_FAILURE,
	Area_Export_REQUEST,
	Area_Export_SUCCESS,
	Area_Export_FAILURE,
} from "./areasTypesConstants";

export const areaReducer = (
	initialState = { areas: [], isHasAreas: false },
	action
) => {
	switch (action.type) {
		//Get Areas
		case AREAS_USER_REQUEST:
			return {
				...initialState,
				loading: true,
				allAreas: [],
			};
		case AREAS_USER_SUCCESS:
			return {
				...initialState,
				allAreas: action.payload.result.data,
				loading: false,
			};

		case AREAS_USER_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//import
		case Area_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Area_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Area_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		//Export
		case Area_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Area_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Area_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		//Get DDLAreas
		case AREAS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				areas: [],
			};
		case AREAS_DDL_SUCCESS:
			return {
				...initialState,
				areas: action.payload.result.data,
				loading: false,
				isHasAreas: action.payload.result.data?.length ? true : false,
			};

		case AREAS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLAreaError: action.payload,
			};
		case AREAS_DDL_RESET:
			return {
				...initialState,
				isHasAreas: false,
			};
		// Add Area
		case ADD_AREA_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_AREA_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				area: action.payload.area,
			};
		case ADD_AREA_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		// Delete Area
		case DELETE_AREA_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_AREA_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_AREA_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		// Update Area
		case UPDATE_AREA_REQUEST:
			return { ...initialState, updateLoading: true };
		case UPDATE_AREA_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case UPDATE_AREA_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case UPDATE_AREA_RESET:
			return { ...initialState, area: {} };

		// Area Details
		case DETAILS_AREA_REQUEST:
			return { ...initialState, detailsLoading: true };
		case DETAILS_AREA_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				area: action.payload,
			};
		case DETAILS_AREA_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case DETAILS_AREA_RESET:
			return { ...initialState, area: {} };

		default:
			return initialState;
	}
};

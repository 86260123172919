import {
	TaskAttachmentReport_ADD_FAILURE,
	TaskAttachmentReport_ADD_REQUEST,
	TaskAttachmentReport_ADD_SUCCESS,
	TaskAttachmentReport_DELETE_FAILURE,
	TaskAttachmentReport_DELETE_REQUEST,
	TaskAttachmentReport_DELETE_SUCCESS,
	TaskAttachmentReport_get_FAILURE,
	TaskAttachmentReport_get_REQUEST,
	TaskAttachmentReport_get_SUCCESS,
} from "./TasksReportConstant";

export const TasksReportReducer = (
	initialState = {
		TaskReport: [],
	},
	action
) => {
	switch (action.type) {
		case TaskAttachmentReport_get_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllTaskReport: [],
			};
		case TaskAttachmentReport_get_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllTaskReport: action.payload.result.data,
				loading: false,
			};

		case TaskAttachmentReport_get_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//add Tasks
		case TaskAttachmentReport_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case TaskAttachmentReport_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case TaskAttachmentReport_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Tasks

		case TaskAttachmentReport_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case TaskAttachmentReport_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case TaskAttachmentReport_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

export const Asset_TYPE_REQUEST = "Asset_TYPE_REQUEST";
export const Asset_TYPE_SUCCESS = "Asset_TYPE_SUCCESS";
export const Asset_TYPE_FAILURE = "Asset_TYPE_FAILURE";

export const Asset_ADD_REQUEST = "Asset_ADD_REQUEST";
export const Asset_ADD_SUCCESS = "Asset_ADD_SUCCESS";
export const Asset_ADD_FAILURE = "Asset_ADD_FAILURE";

export const Assets_Export_REQUEST = "Assets_Export_REQUEST";
export const Assets_Export_SUCCESS = "Assets_Export_SUCCESS";
export const Assets_Export_FAILURE = "Assets_Export_FAILURE";

export const Asset_DELETE_REQUEST = "Asset_DELETE_REQUEST";
export const Asset_DELETE_SUCCESS = "Asset_DELETE_SUCCESS";
export const Asset_DELETE_FAILURE = "Asset_DELETE_FAILURE";

export const Asset_UPDATE_REQUEST = "Asset_UPDATE_REQUEST";
export const Asset_UPDATE_SUCCESS = "Asset_UPDATE_SUCCESS";
export const Asset_UPDATE_FAILURE = "Asset_UPDATE_FAILURE";
export const Asset_UPDATE_RESET = "Asset_UPDATE_RESET";

export const Asset_DETAILS_REQUEST = "Asset_DETAILS_REQUEST";
export const Asset_DETAILS_SUCCESS = "Asset_DETAILS_SUCCESS";
export const Asset_DETAILS_FAILURE = "Asset_DETAILS_FAILURE";
export const Asset_DETAILS_RESET = "Asset_DETAILS_RESET";

export const AssetAttachment_TYPE_REQUEST = "AssetAttachment_TYPE_REQUEST";
export const AssetAttachment_TYPE_SUCCESS = "AssetAttachment_TYPE_SUCCESS";
export const AssetAttachment_TYPE_FAILURE = "AssetAttachment_TYPE_FAILURE";

export const AssetAttachment_DELETE_REQUEST = "AssetAttachment_DELETE_REQUEST";
export const AssetAttachment_DELETE_SUCCESS = "AssetAttachment_DELETE_SUCCESS";
export const AssetAttachment_DELETE_FAILURE = "AssetAttachment_DELETE_FAILURE";

export const AssetAttachment_ADD_REQUEST = "AssetAttachment_ADD_REQUEST";
export const AssetAttachment_ADD_SUCCESS = "AssetAttachment_ADD_SUCCESS";
export const AssetAttachment_ADD_FAILURE = "AssetAttachment_ADD_FAILURE";

export const Asset_BarCode_REQUEST = "Asset_BarCode_REQUEST";
export const Asset_BarCode_SUCCESS = "Asset_BarCode_SUCCESS";
export const Asset_BarCode_FAILURE = "Asset_BarCode_FAILURE";

export const Assets_Import_REQUEST = "Assets_Import_REQUEST";
export const Assets_Import_SUCCESS = "Assets_Import_SUCCESS";
export const Assets_Import_FAILURE = "Assets_Import_FAILURE";

import {
	TaskType_TYPE_REQUEST,
	TaskType_TYPE_SUCCESS,
	TaskType_TYPE_FAILURE,
	TaskType_ADD_FAILURE,
	TaskType_ADD_SUCCESS,
	TaskType_ADD_REQUEST,
	TaskType_DELETE_REQUEST,
	TaskType_DELETE_SUCCESS,
	TaskType_DELETE_FAILURE,
	TaskType_DETAILS_REQUEST,
	TaskType_DETAILS_SUCCESS,
	TaskType_DETAILS_FAILURE,
	TaskType_DETAILS_RESET,
	TaskType_UPDATE_REQUEST,
	TaskType_UPDATE_SUCCESS,
	TaskType_UPDATE_FAILURE,
	TaskType_UPDATE_RESET,
	TaskTypes_DDL_REQUEST,
	TaskTypes_DDL_SUCCESS,
	TaskTypes_DDL_FAILURE,
	TaskTypes_DDL_RESET,
} from "./TaskTypeConstant";

export const TaskTypeReducer = (
	initialState = {
		TaskTypes: [],
		isHasTaskTypes: false,
	},
	action
) => {
	switch (action.type) {
		case TaskType_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllTaskTypes: [],
			};
		case TaskType_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllTaskTypes: action.payload.result.data,
				loading: false,
			};

		case TaskType_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//ddl
		case TaskTypes_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				TaskTypes: [],
			};
		case TaskTypes_DDL_SUCCESS:
			return {
				...initialState,
				TaskTypes: action.payload.result.data,
				loading: false,
				isHasTaskTypes: action.payload.result.data?.length
					? true
					: false,
			};

		case TaskTypes_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLTaskTypesErrors: action.payload,
			};
		case TaskTypes_DDL_RESET:
			return {
				...initialState,
				isHasTaskTypes: false,
			};

		//add TaskType
		case TaskType_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case TaskType_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case TaskType_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete TaskType

		case TaskType_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case TaskType_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case TaskType_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//TaskType details & edit
export const TaskTypeDetailsReducer = (
	state = { TaskType: {} },
	action
) => {
	switch (action.type) {
		case TaskType_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case TaskType_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				TaskType: action.payload,
			};
		case TaskType_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case TaskType_DETAILS_RESET:
			return { ...state, TaskType: {} };
		//TaskType Update
		case TaskType_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case TaskType_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case TaskType_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case TaskType_UPDATE_RESET:
			return { ...state, TaskType: {} };
		default:
			return state;
	}
};


















import React from "react";
import { Button, Space } from "antd";
import IntlMessages from "../../util/IntlMessages";

const CustomNotification = ({ show, setIsVisible, processCommands }) => {
	return (
		<div
			style={{
				position: "fixed",
				bottom: 20,
				right: 20,
				backgroundColor: "#fff",
				padding: 16,
				boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				borderRadius: 4,
			}}
		>
			<p style={{fontWeight: 600, fontSize: "17px"}}>
				<IntlMessages id="updatesAvailable" />
			</p>
			<p style={{fontWeight: 400, fontSize: "14px"}}>
				<IntlMessages id="newUpdatesPrompt" />
			</p>
			<Space>
				<Button
					type="primary"
					size="small"
					style={{padding: "13px", display: "flex", alignItems: "center", justifyContent: "center"}}
					onClick={() => {
						processCommands();
						setIsVisible(false);
					}}
				>
					<IntlMessages id="Confirm" />
				</Button>
				<Button
					type="primary"
					size="small"
					style={{padding: "13px", display: "flex", alignItems: "center", justifyContent: "center"}}
					onClick={() => {
						setIsVisible(false);
					}}
				>
					<IntlMessages id="button.cancel" />
				</Button>
			</Space>
		</div>
	);
};

const Notification = ({ show, setShow, processCommands }) => {
	// const playNotificationSound = () => {
	//   const audio = new Audio("/assets/sound.mp3");
	//   audio.play();
	// };

	const openNotification = () => {
		// playNotificationSound();
		return (
			<CustomNotification
				isVisible={show}
				setIsVisible={setShow}
				processCommands={processCommands}
			/>
		);
	};

	return <>{show && openNotification()}</>;
};

export default Notification;

export const ADDOwner_DASHBOARD_REQUEST = "ADDOwner_DASHBOARD_REQUEST";
export const ADDOwner_DASHBOARD_SUCCESS = "ADDOwner_DASHBOARD_SUCCESS";
export const ADDOwner_DASHBOARD__FAILURE = "ADDOwner_DASHBOARD__FAILURE";

export const DASHBOARD_OWNER_REQUEST = "DASHBOARD_OWNER_REQUEST";
export const DASHBOARD_OWNER_SUCCESS = "DASHBOARD_OWNER_SUCCESS";
export const DASHBOARD__OWNER_FAILURE = "DASHBOARD__OWNER_FAILURE";

export const DELETE_Owner_DASHBOARD_REQUEST = "DELETE_Owner_DASHBOARD_REQUEST";
export const DELETE_Owner_DASHBOARD_SUCCESS = "DELETE_Owner_DASHBOARD_SUCCESS";
export const DELETE_Owner_DASHBOARD__FAILURE =
	"DELETE_Owner_DASHBOARD__FAILURE";

export const Owner_DASHBOARD_REQUEST = "Owner_DASHBOARD_REQUEST";
export const Owner_DASHBOARD_SUCCESS = "Owner_DASHBOARD_SUCCESS";
export const Owner_DASHBOARD__FAILURE = "Owner_DASHBOARD__FAILURE";

export const ADD_CARD_DASHBOARDOWNER_REQUEST = "ADD_CARD_DASHBOARDOWNERREQUEST";
export const ADD_CARD_DASHBOARDOWNER_SUCCESS =
	"ADD_CARD_DASHBOARDOWNER_SUCCESS";
export const ADD_CARD_DASHBOARDOWNER__FAILURE =
	"ADD_CARD_DASHBOARDOWNER__FAILURE";

export const DASHBOARDOWNER_ITEMS_REQUEST = "DASHBOARDOWNER_ITEMS_REQUEST";
export const DASHBOARDOWNER_ITEMS_SUCCESS = "DASHBOARDOWNER_ITEMS_SUCCESS";
export const DASHBOARDOWNER_ITEMS_FAILURE = "DASHBOARDOWNER_ITEMS_FAILURE";

export const DELETE_CARD_DASHBOARDOWNER_REQUEST =
	"DELETE_CARD_DASHBOARDOWNER_REQUEST";
export const DELETE_CARD_DASHBOARDOWNER_SUCCESS =
	"DELETE_CARD_DASHBOARDOWNER_SUCCESS";
export const DELETE_CARD_DASHBOARDOWNER__FAILURE =
	"DELETE_CARD_DASHBOARDOWNER__FAILURE";

export const UpdateOwnerPlacements_DASHBOARD_REQUEST =
	"UpdateOwnerPlacements_DASHBOARD_REQUEST";
export const UpdateOwnerPlacements_DASHBOARD_SUCCESS =
	"UpdateOwnerPlacements_DASHBOARD_SUCCESS";
export const UpdateOwnerPlacements_DASHBOARD__FAILURE =
	"UpdateOwnerPlacements_DASHBOARD__FAILURE";

export const UpdateOwnerItemPlacements_DASHBOARD_REQUEST =
	"UpdateOwnerItemPlacements_DASHBOARD_REQUEST";
export const UpdateOwnerItemPlacements_DASHBOARD_SUCCESS =
	"UpdateOwnerItemPlacements_DASHBOARD_SUCCESS";
export const UpdateOwnerItemPlacements_DASHBOARD__FAILURE =
	"UpdateOwnerItemPlacements_DASHBOARD__FAILURE";

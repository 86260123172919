import {
	ADD_WITNESS_EMPLOYEE_FAILURE,
	ADD_WITNESS_EMPLOYEE_REQUEST,
	ADD_WITNESS_EMPLOYEE_SUCCESS,
	DELETE_WITNESS_EMPLOYEE_FAILURE,
	DELETE_WITNESS_EMPLOYEE_REQUEST,
	DELETE_WITNESS_EMPLOYEE_SUCCESS,
	WITNESS_EMPLOYEE_FAILURE,
	WITNESS_EMPLOYEE_REQUEST,
	WITNESS_EMPLOYEE_Reset,
	WITNESS_EMPLOYEE_SUCCESS,
} from "./EmployeeWitnessConstant";

// Witness Employees
export const WitnessEmployeesReducer = (
	initialState = {
		WitnessEmployess: [],
		GetWitnessEmployeesLoading: false,
		addWitnessEmployeesLoading: false,
		deleteWitnessEmployeesLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Witness Employees
		case WITNESS_EMPLOYEE_REQUEST:
			return {
				...initialState,
				GetWitnessEmployeesLoading: true,
			};
		case WITNESS_EMPLOYEE_SUCCESS:
			return {
				...initialState,
				WitnessEmployess: action.payload.result.data,
				GetWitnessEmployeesLoading: false,
			};
		case WITNESS_EMPLOYEE_FAILURE:
			return {
				...initialState,
				GetWitnessEmployeesLoading: false,
				getError: action.payload,
			};
		case WITNESS_EMPLOYEE_Reset:
			return {
				...initialState,
				WitnessEmployess: [],
			};
		// Add Witness Employees
		case ADD_WITNESS_EMPLOYEE_REQUEST:
			return {
				...initialState,
				addWitnessEmployeesLoading: true,
			};
		case ADD_WITNESS_EMPLOYEE_SUCCESS:
			return {
				...initialState,
				addWitnessEmployeesLoading: false,
			};
		case ADD_WITNESS_EMPLOYEE_FAILURE:
			return {
				...initialState,
				addWitnessEmployeesLoading: false,
				addError: action.payload,
			};

		// Delete Witness Employees
		case DELETE_WITNESS_EMPLOYEE_REQUEST:
			return {
				...initialState,
				deleteWitnessEmployeesLoading: true,
			};
		case DELETE_WITNESS_EMPLOYEE_SUCCESS:
			return {
				...initialState,
				deleteWitnessEmployeesLoading: false,
			};
		case DELETE_WITNESS_EMPLOYEE_FAILURE:
			return {
				...initialState,
				deleteWitnessEmployeesLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

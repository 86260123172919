export const GET_ACCESS_POINT_VISITOR_REQUEST =
	"GET_ACCESS_POINT_VISITOR_REQUEST";
export const GET_ACCESS_POINT_VISITOR_SUCCESS =
	"GET_ACCESS_POINT_VISITOR_SUCCESS";
export const GET_ACCESS_POINT_VISITOR_FAILURE =
	"GET_ACCESS_POINT_VISITOR_FAILURE";

export const AccessPoints_DDLVisitor_REQUEST =
	"AccessPoints_DDLVisitor_REQUEST";
export const AccessPoints_DDLVisitor_SUCCESS =
	"AccessPoints_DDLVisitor_SUCCESS";
export const AccessPoints_DDLVisitor_FAILURE =
	"AccessPoints_DDLVisitor_FAILURE";
export const AccessPoints_DDLVisitor_RESET = "AccessPoints_DDLVisitor_RESET";

export const DELETE_ACCESS_POINT_VISITOR_REQUEST =
	"DELETE_ACCESS_POINT_REQUEST";
export const DELETE_ACCESS_POINT_VISITOR_SUCCESS =
	"DELETE_ACCESS_POINT_SUCCESS";
export const DELETE_ACCESS_POINT_VISITOR_FAILURE =
	"DELETE_ACCESS_POINT_FAILURE";

export const ADD_ACCESS_POINT_VISITOR_REQUEST =
	"ADD_ACCESS_POINT_VISITOR_REQUEST";
export const ADD_ACCESS_POINT_VISITOR_SUCCESS =
	"ADD_ACCESS_POINT_VISITOR_SUCCESS";
export const ADD_ACCESS_POINT_VISITOR_FAILURE =
	"ADD_ACCESS_POINT_VISITOR_FAILURE";

export const WITNESS_EMPLOYEE_REQUEST = "WITNESS_EMPLOYEE_REQUEST";
export const WITNESS_EMPLOYEE_SUCCESS = "WITNESS_EMPLOYEE_SUCCESS";
export const WITNESS_EMPLOYEE_FAILURE = "WITNESS_EMPLOYEE_FAILURE";
export const WITNESS_EMPLOYEE_Reset = "WITNESS_EMPLOYEE_Reset";

export const ADD_WITNESS_EMPLOYEE_REQUEST = "ADD_WITNESS_EMPLOYEE_REQUEST";
export const ADD_WITNESS_EMPLOYEE_SUCCESS = "ADD_WITNESS_EMPLOYEE_SUCCESS";
export const ADD_WITNESS_EMPLOYEE_FAILURE = "ADD_WITNESS_EMPLOYEE_FAILURE";

export const DELETE_WITNESS_EMPLOYEE_REQUEST = "DELETE_WITNESS_EMPLOYEE_REQUEST";
export const DELETE_WITNESS_EMPLOYEE_SUCCESS = "DELETE_WITNESS_EMPLOYEE_SUCCESS";
export const DELETE_WITNESS_EMPLOYEE_FAILURE = "DELETE_WITNESS_EMPLOYEE_FAILURE";
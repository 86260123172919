/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import SVGInject from "@iconfu/svg-inject";
import './sidebarStyle.css'

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
	// const permissions = useMemo(() => {
	// 	return JSON.parse(localStorage.getItem("permissionsName"));
	// }, []);
	const { themeType } = useSelector(({ settings }) => settings);
	const pathname = useSelector(({ common }) => common.pathname);
	const { locale } = useSelector(({ settings }) => settings.locale);
	const selectedKeys = pathname.substr(1);
	const path = selectedKeys.toString().split("/")[1];
	const defaultOpenKeys = selectedKeys.split("/")[1];
	const idParams = selectedKeys.split("/")[2];
	const assetUrl = "/assets/images/icons";
	const [items, setItems] = useState([]);
	const sidebarData = JSON.parse(localStorage.getItem("sidebarData"));
	
	useEffect(() => {
		const svgImages = document.querySelectorAll("img.injectable");
		if (svgImages) {
			SVGInject(svgImages);
		}
	}, [sidebarData]);

	const [Workforce, setWorkforce] = useState(false);
	const WorkforceControl = JSON.parse(
		localStorage.getItem("WorkforceControlModule")
	);
	const [WorkforceControlItems, setWorkforceControlItems] = useState([]);

	useEffect(() => {
		const WorkforceControlItems = WorkforceControl?.map((item) => {
			return {
				key: idParams
					? `${item.nameEn
							.toLowerCase()
							.replace(" ", "-")}/${path}/${idParams}`
					: path
					? `${item.nameEn.toLowerCase().replace(" ", "-")}/${path}`
					: `${item.nameEn.toLowerCase().replace(" ", "-")}`,
				label: (
					<Link to={item.route}>
						<div className="wrapper-icon gx-mb-1">
							<img
								src={`${assetUrl}/${item.icon}.svg`}
								alt={item.nameEn}
								className="injectable"
							/>
						</div>
						<span>
							{locale === "ar" ? item.nameAr : item.nameEn}
						</span>
					</Link>
				),
			};
		});
		setWorkforceControlItems(WorkforceControlItems);
	}, [locale, pathname]);

	useEffect(() => {
		const sidebarItems = sidebarData?.map((item) => {
			if (item.nameEn === "Workforce Control") {
				setWorkforce(true);
				return null;
			} else {
				setWorkforce(false);
			}
			return {
				key: idParams
					? `${item.nameEn
							.toLowerCase()
							.replace(" ", "-")}/${path}/${idParams}`
					: path
					? `${item.nameEn.toLowerCase().replace(" ", "-")}/${path}`
					: `${item.nameEn.toLowerCase().replace(" ", "-")}`,
				label: (
					<Link to={item.route}>
						<div className="wrapper-icon gx-mb-1">
							<img
								src={`${assetUrl}/${item.icon}.svg`}
								alt={item.nameEn}
								className="injectable"
							/>
						</div>
						<span>
							{locale === "ar" ? item.nameAr : item.nameEn}
						</span>
					</Link>
				),
			};
		});
		const filteredSidebarItems = sidebarItems.filter(
			(item) => item !== null
		);

		setItems(filteredSidebarItems);
	}, [locale, pathname]);

	const settingsMenuItem = items.find(
		(item) => item.label.props.to === "/settings"
	);
	const updatedItems = items.filter(
		(item) => item.label.props.to !== "/settings"
	);

	const dashboard = locale === "ar" ? "لوحة القيادة" : "Dashboard";
	const itemsDashboard = [
		{
			key: "dashboard",
			label: (
				<Link to="/dashboard">
					<div className="wrapper-icon gx-mb-1">
						<img
							src={`${assetUrl}/dashboard.svg`}
							alt="dashboard"
							className="injectable"
						/>
					</div>
					<span>{dashboard}</span>
				</Link>
			),
		},
	];

	// const itemmaintenance = [
	// 	permissions.includes("hardware.get") && {
	// 		key: "maintenance-control",
	// 		label: (
	// 			<Link to="/maintenance-control">
	// 				<div
	// 					style={{
	// 						marginLeft: "20px",
	// 					}}
	// 				>
	// 					<div className="wrapper-icon gx-mb-1">
	// 						<img
	// 							src={`${assetUrl}/maintenance-white.svg`}
	// 							alt="dashboard"
	// 							className="injectable"
	// 						/>
	// 					</div>
	// 					<span>
	// 						<IntlMessages id="sidebar.maintenance-control" />
	// 					</span>
	// 				</div>
	// 			</Link>
	// 		),
	// 	},
	// ];
	const [isWorkforceControlOpen, setIsWorkforceControlOpen] = useState(
		localStorage.getItem("isWorkforceControlOpen") === "true"
	);
	return (
		<>
			<SidebarLogo
				sidebarCollapsed={sidebarCollapsed}
				setSidebarCollapsed={setSidebarCollapsed}
			/>
			<div className="gx-sidebar-content">
				<CustomScrollbars className="gx-layout-sider-scrollbar mobile-styles">
					<Menu
						defaultOpenKeys={[defaultOpenKeys]}
						selectedKeys={[selectedKeys]}
						theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
						mode="inline"
						openKeys={isWorkforceControlOpen ? ["Teams/Team"] : []}
						onOpenChange={(keys) => {
							if (keys.includes("Teams/Team")) {
								setIsWorkforceControlOpen(true);
								localStorage.setItem(
									"isWorkforceControlOpen",
									"true"
								);
							} else {
								setIsWorkforceControlOpen(false);
								localStorage.setItem(
									"isWorkforceControlOpen",
									"false"
								);
							}
						}}
						items={[
							...itemsDashboard,
							...updatedItems,
							Workforce && {
								key: "Teams/Team",
								label: (
									<>
										<div className="wrapper-icon gx-mb-1">
											<img
												src={`${assetUrl}/Workforce.svg`}
												alt="dashboard"
												className={
													locale === "en"
														? "WorkforceIcon"
														: "WorkforceIcon2"
												}
											/>
										</div>
										<span
											style={{
												color: "#fff",
												fontSize: "18px",
												fontWeight: "400",
												marginRight: "-30px",
												marginLeft: "-30px",
											}}
										>
											<IntlMessages id="WorkforceControl" />
										</span>
									</>
								),
								children: WorkforceControlItems,
							},
							settingsMenuItem,
						]}
					/>
				</CustomScrollbars>
				<div className="logout-item">
					<Link
						to="/signin"
						onClick={() => {
							localStorage.clear();
							localStorage.setItem("locale", locale);
						}}
					>
						<img
							src={`${assetUrl}/logout.svg`}
							alt="powerlook-icon"
						/>
						<span
							style={{
								color: "red",
							}}
						>
							<IntlMessages id="sidebar.logout" />
						</span>
					</Link>
				</div>
			</div>
		</>
	);
};

export default React.memo(SidebarContent);

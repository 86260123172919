export const GET_ACCESS_POINT_REQUEST = "GET_ACCESS_POINT_REQUEST";
export const GET_ACCESS_POINT_SUCCESS = "GET_ACCESS_POINT_SUCCESS";
export const GET_ACCESS_POINT_FAILURE = "GET_ACCESS_POINT_FAILURE";

export const ACCESS_POINTS_DDL_REQUEST =
	"ACCESS_POINTS_DDL_REQUEST";
export const ACCESS_POINTS_DDL_SUCCESS =
	"ACCESS_POINTS_DDL_SUCCESS";
export const ACCESS_POINTS_DDL_FAILURE =
	"ACCESS_POINTS_DDL_FAILURE";
export const ACCESS_POINTS_DDL_RESET =
	"ACCESS_POINTS_DDL_RESET";

export const DELETE_ACCESS_POINT_REQUEST = "DELETE_ACCESS_POINT_REQUEST";
export const DELETE_ACCESS_POINT_SUCCESS = "DELETE_ACCESS_POINT_SUCCESS";
export const DELETE_ACCESS_POINT_FAILURE = "DELETE_ACCESS_POINT_FAILURE";

export const AccessPoint_Import_REQUEST = "AccessPoint_Import_REQUEST";
export const AccessPoint_Import_SUCCESS = "AccessPoint_Import_SUCCESS";
export const AccessPoint_Import_FAILURE = "AccessPoint_Import_FAILURE";

export const AccessPoint_Export_REQUEST = "AccessPoint_Export_REQUEST";
export const AccessPoint_Export_SUCCESS = "AccessPoint_Export_SUCCESS";
export const AccessPoint_Export_FAILURE = "AccessPoint_Export_FAILURE";

export const ADD_ACCESS_POINT_REQUEST = "ADD_ACCESS_POINT_REQUEST";
export const ADD_ACCESS_POINT_SUCCESS = "ADD_ACCESS_POINT_SUCCESS";
export const ADD_ACCESS_POINT_FAILURE = "ADD_ACCESS_POINT_FAILURE";

export const GET_ACCESS_POINT_DETAILS_REQUEST =
	"GET_ACCESS_POINT_DETAILS_REQUEST";
export const GET_ACCESS_POINT_DETAILS_SUCCESS =
	"GET_ACCESS_POINT_DETAILS_SUCCESS";
export const GET_ACCESS_POINT_DETAILS_FAILURE =
	"GET_ACCESS_POINT_DETAILS_FAILURE";
export const GET_ACCESS_POINT_DETAILS_RESET = "GET_ACCESS_POINT_DETAILS_RESET";

export const ACCESS_POINT_UPDATE_REQUEST = "ACCESS_POINT_UPDATE_REQUEST";
export const ACCESS_POINT_UPDATE_SUCCESS = "ACCESS_POINT_UPDATE_SUCCESS";
export const ACCESS_POINT_UPDATE_FAILURE = "ACCESS_POINT_UPDATE_FAILURE";

export const CONFIGURE_ACCESS_POINT_REQUEST = "CONFIGURE_ACCESS_POINT_REQUEST";
export const CONFIGURE_ACCESS_POINT_SUCCESS = "CONFIGURE_ACCESS_POINT_SUCCESS";
export const CONFIGURE_ACCESS_POINT_FAILURE = "CONFIGURE_ACCESS_POINT_FAILURE";

export const LAST_NUMBER_ACCESS_POINT_REQUEST = "LAST_NUMBER_ACCESS_POINT_REQUEST";
export const LAST_NUMBER_ACCESS_POINT_SUCCESS = "LAST_NUMBER_ACCESS_POINT_SUCCESS";
export const LAST_NUMBER_ACCESS_POINT_FAILURE = "LAST_NUMBER_ACCESS_POINT_FAILURE";



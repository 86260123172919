export const OUTSIDE_INJURED_REQUEST = "OUTSIDE_INJURED_REQUEST";
export const OUTSIDE_INJURED_SUCCESS = "OUTSIDE_INJURED_SUCCESS";
export const OUTSIDE_INJURED_FAILURE = "OUTSIDE_INJURED_FAILURE";
export const OUTSIDE_INJURED_Reset = "OUTSIDE_INJURED_Reset";

export const ADD_OUTSIDE_INJURED_REQUEST = "ADD_OUTSIDE_INJURED_REQUEST";
export const ADD_OUTSIDE_INJURED_SUCCESS = "ADD_OUTSIDE_INJURED_SUCCESS";
export const ADD_OUTSIDE_INJURED_FAILURE = "ADD_OUTSIDE_INJURED_FAILURE";

export const DELETE_OUTSIDE_INJURED_REQUEST = "DELETE_OUTSIDE_INJURED_REQUEST";
export const DELETE_OUTSIDE_INJURED_SUCCESS = "DELETE_OUTSIDE_INJURED_SUCCESS";
export const DELETE_OUTSIDE_INJURED_FAILURE = "DELETE_OUTSIDE_INJURED_FAILURE";
import {
	VISITOR_PERMITS_REQUEST,
	VISITOR_PERMITS_SUCCESS,
	VISITOR_PERMITS_FAILURE,
	DELETE_VISITOR_PERMIT_REQUEST,
	DELETE_VISITOR_PERMIT_SUCCESS,
	DELETE_VISITOR_PERMIT_FAILURE,
	ADD_VISITOR_PERMIT_REQUEST,
	ADD_VISITOR_PERMIT_SUCCESS,
	ADD_VISITOR_PERMIT_FAILURE,
	DETAILS_VISITOR_PERMIT_REQUEST,
	DETAILS_VISITOR_PERMIT_SUCCESS,
	DETAILS_VISITOR_PERMIT_FAILURE,
	DETAILS_VISITOR_PERMIT_RESET,
	UPDATE_VISITOR_PERMIT_REQUEST,
	UPDATE_VISITOR_PERMIT_SUCCESS,
	UPDATE_VISITOR_PERMIT_FAILURE,
	UPDATE_VISITOR_PERMIT_RESET,
	ADD_VISITOR_PERMIT_USERS_FAILURE,
	ADD_VISITOR_PERMIT_USERS_SUCCESS,
	ADD_VISITOR_PERMIT_USERS_REQUEST,
	DELETE_VISITOR_PERMIT_USERS_FAILURE,
	DELETE_VISITOR_PERMIT_USERS_SUCCESS,
	DELETE_VISITOR_PERMIT_USERS_REQUEST,
	VISITOR_PERMITS_USERS_FAILURE,
	VISITOR_PERMITS_USERS_SUCCESS,
	VISITOR_PERMITS_USERS_REQUEST,
	VISITOR_PERMITS_ALL_USERS_REQUEST,
	VISITOR_PERMITS_ALL_USERS_SUCCESS,
	VISITOR_PERMITS_ALL_USERS_FAILURE,
	SEND_PERMITS_FAILURE,
	SEND_PERMITS_SUCCESS,
	SEND_PERMITS_REQUEST,
	SEND_SELF_LINK_REQUEST,
	SEND_SELF_LINK_SUCCESS,
	SEND_SELF_LINK_FAILURE,
	CHECK_VISITOR_REQUEST,
	CHECK_VISITOR_FAILURE,
	CHECK_VISITOR_SUCCESS,
	VISITOR_PERMITS_USERS_RESET,
} from "./permitTypesConstants";

export const visitorPermitsReducer = (
	initialState = {
		visitorPermits: [],
		visitorPermitsUsers: [],
		visitorPermitsALLUsers: [],
		isHasVisitors: false
	},
	action
) => {
	switch (action.type) {
		case VISITOR_PERMITS_REQUEST:
			return {
				...initialState,
				loading: true,
				visitorPermits: [],
			};
		case VISITOR_PERMITS_SUCCESS:
			return {
				...initialState,
				visitorPermits: action.payload.result.data,
				loading: false,
			};

		case VISITOR_PERMITS_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		// Delete Visitor Permit
		case DELETE_VISITOR_PERMIT_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_VISITOR_PERMIT_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_VISITOR_PERMIT_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		// Add Visitor Permit
		case ADD_VISITOR_PERMIT_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_VISITOR_PERMIT_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				permit: action.payload.permit,
			};
		case ADD_VISITOR_PERMIT_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		// Details Visitor Permit
		case DETAILS_VISITOR_PERMIT_REQUEST:
			return { ...initialState, detailsLoading: true };
		case DETAILS_VISITOR_PERMIT_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				permit: action.payload,
			};
		case DETAILS_VISITOR_PERMIT_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case DETAILS_VISITOR_PERMIT_RESET:
			return { ...initialState, permit: {} };

		// Update Visitor Permit
		case UPDATE_VISITOR_PERMIT_REQUEST:
			return { ...initialState, updateLoading: true };
		case UPDATE_VISITOR_PERMIT_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case UPDATE_VISITOR_PERMIT_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case UPDATE_VISITOR_PERMIT_RESET:
			return { ...initialState, permit: {} };

		// Get Visitor Permit Users
		case VISITOR_PERMITS_USERS_REQUEST:
			return {
				...initialState,
				userPermitLoading: true,
				visitorPermitsUsers: [],
			};
		case VISITOR_PERMITS_USERS_SUCCESS:
			return {
				...initialState,
				visitorPermitsUsers: action.payload.data,
				userPermitLoading: false,
				isHasVisitors :  action.payload.data?.length ? true : false
			};

		case VISITOR_PERMITS_USERS_FAILURE:
			return {
				...initialState,
				userPermitLoading: false,
				userPermitError: action.payload,
			};
			case VISITOR_PERMITS_USERS_RESET:
			return {
				...initialState,
				isHasVisitors : false
			}

		// Delete Visitor Permit Users
		case DELETE_VISITOR_PERMIT_USERS_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_VISITOR_PERMIT_USERS_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_VISITOR_PERMIT_USERS_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteUserPermitError: action.payload,
			};

		// Add Visitor Permit Users
		case ADD_VISITOR_PERMIT_USERS_REQUEST:
			return {
				...initialState,
				addUserPermitLoading: true,
			};
		case ADD_VISITOR_PERMIT_USERS_SUCCESS:
			return {
				...initialState,
				addUserPermitLoading: false,
				permitUser: action.payload.permitUser,
			};
		case ADD_VISITOR_PERMIT_USERS_FAILURE:
			return {
				...initialState,
				addUserPermitLoading: false,
				addUserPermitError: action.payload,
			};

		//get visitor permit all users
		case VISITOR_PERMITS_ALL_USERS_REQUEST:
			return {
				...initialState,
				UsersPermitLoading: true,
				visitorPermitsALLUsers: [],
			};
		case VISITOR_PERMITS_ALL_USERS_SUCCESS:
			return {
				...initialState,
				visitorPermitsALLUsers: action.payload.data,
				UsersPermitLoading: false,
			};

		case VISITOR_PERMITS_ALL_USERS_FAILURE:
			return {
				...initialState,
				UsersPermitLoading: false,
				UsersPermitError: action.payload,
			};

		//send permit to visitors
		case SEND_PERMITS_REQUEST:
			return {
				...initialState,
				sendPermitLoading: true,
			};
		case SEND_PERMITS_SUCCESS:
			return {
				...initialState,
				sendPermitLoading: false,
			};

		case SEND_PERMITS_FAILURE:
			return {
				...initialState,
				sendPermitLoading: false,
				sendPermitError: action.payload,
			};
		// SEND SELF Visitor
		case SEND_SELF_LINK_REQUEST:
			return {
				...initialState,
				sendLinkLoading: true,
			};
		case SEND_SELF_LINK_SUCCESS:
			return {
				...initialState,
				sendLinkLoading: false,
			};
		case SEND_SELF_LINK_FAILURE:
			return {
				...initialState,
				sendLinkLoading: false,
				sendLinkError: action.payload,
			};

		//checkVisitors
		case CHECK_VISITOR_REQUEST:
			return {
				...initialState,
				checkVisitorLoading: true,
			};
		case CHECK_VISITOR_SUCCESS:
			return {
				...initialState,
				checkVisitorLoading: false,
			};

		case CHECK_VISITOR_FAILURE:
			return {
				...initialState,
				checkVisitorLoading: false,
				checkVisitorError: action.payload,
			};

		default:
			return initialState;
	}
};

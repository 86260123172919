export const TaskType_TYPE_REQUEST = "TaskType_TYPE_REQUEST";
export const TaskType_TYPE_SUCCESS = "TaskType_TYPE_SUCCESS";
export const TaskType_TYPE_FAILURE = "TaskType_TYPE_FAILURE";

export const TaskType_ADD_REQUEST = "TaskType_ADD_REQUEST";
export const TaskType_ADD_SUCCESS = "TaskType_ADD_SUCCESS";
export const TaskType_ADD_FAILURE = "TaskType_ADD_FAILURE";

export const TaskTypes_DDL_REQUEST = "TaskTypes_DDL_REQUEST";
export const TaskTypes_DDL_SUCCESS = "TaskTypes_DDLTaskTypes_SUCCESS";
export const TaskTypes_DDL_FAILURE = "TaskTypes_DDL_FAILURE";
export const TaskTypes_DDL_RESET = "TaskTypes_DDL_RESET";

export const TaskType_DELETE_REQUEST = "TaskType_DELETE_REQUEST";
export const TaskType_DELETE_SUCCESS = "TaskType_DELETE_SUCCESS";
export const TaskType_DELETE_FAILURE = "TaskType_DELETE_FAILURE";

export const TaskType_UPDATE_REQUEST = "TaskType_UPDATE_REQUEST";
export const TaskType_UPDATE_SUCCESS = "TaskType_UPDATE_SUCCESS";
export const TaskType_UPDATE_FAILURE = "TaskType_UPDATE_FAILURE";
export const TaskType_UPDATE_RESET = "TaskType_UPDATE_RESET";

export const TaskType_DETAILS_REQUEST = "TaskType_DETAILS_REQUEST";
export const TaskType_DETAILS_SUCCESS = "TaskType_DETAILS_SUCCESS";
export const TaskType_DETAILS_FAILURE = "TaskType_DETAILS_FAILURE";
export const TaskType_DETAILS_RESET = "TaskType_DETAILS_RESET";

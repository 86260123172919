// module.exports = {
// 	footerText:
// 		"All rights reserved © " + new Date().getFullYear() + " powerlook",
// };

import React from "react";
import IntlMessages from "./IntlMessages";

const FooterText = () => {
	return (
		<div>
			<IntlMessages id="All rights reserved ©" />{" "}
			{new Date().getFullYear()} powerlook ,
		</div>
	);
};

export default FooterText;

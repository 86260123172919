import { Button, Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
	SendOTPAction,
	VerifyOTPAction,
} from "../../appRedux/Auth/loginAction";
import { useIntl } from "react-intl";
import OtpInput from "react-otp-input";
import { moduleNames } from "../../util/moduleNames";
import Api from "../../util/Api";
import { dataChannels } from "../../util/dataChannels";
import Loader from "../../components/Loader";
import Error from "../../components/Message";
import "./SendOtp.css"

const assetUrl = "/assets/images";

const SendOTP = () => {
	const dispatch = useDispatch();
	const { id: loginToken } = useParams();
	const [isModelVisible, setIsModelVisible] = useState(true);
	const [methods, setMethods] = useState([]);
	const intl = useIntl();
	const locale = localStorage.getItem("locale");
	const { loading, VerifyOTPError, SendOTPError } = useSelector(
		(state) => state.recoveryPasswordReducer
	);

	useEffect(() => {
		async function fetchData() {
			const {
				data: {
					result: { data },
				},
			} = await Api.get("Setting/AllowMessagesOwnerSetting");
			const methods = data.map((channel) => {
				return {
					label: locale === "en" ? channel.nameEn : channel.nameAr,
					disabled: channel.value === false,
					value: dataChannels[channel.type].channelId,
					icon: dataChannels[channel.type].iconWhite,
				};
			});

			setMethods(methods);
		}
		fetchData();
	}, [locale]);

	const handelSendOTP = (channelId) => {
		dispatch(SendOTPAction({ loginToken, otpChannel: channelId })).then(
			(res) => {
				if (res?.payload?.data?.statusCode === 200) {
					setIsModelVisible(false);
				} else {
					const {
						payload: {
							data: { message: messageError },
						},
					} = res;
					message.error(intl.formatMessage({ id: messageError }), 5);
				}
			}
		);
	};

	const [otp, setOtp] = useState("");

	const updatedState = {
		sidebarData: [],
		dashboardModule: [],
		personnelModule: [],
		siteManagementModule: [],
		hardwareModule: [],
		accessControlModule: [],
		visitorControlModule: [],
		bookingControlModule: [],
		timeAttendanceModule: [],
		incidentControlModule: [],
		maintenanceControlModule: [],
		reportModule: [],
		monitoringModule: [],
		ownersModule: [],
		customersModule: [],
		paymentModule: [],
		settingsModule: [],
		configurationModule: [],
		canteenControlModule: [],
		organizationsModule: [],
		TeamsControlModule: [],
		TaskControlModule: [],
		assetManagementModule: [],
	};
	const handelVerifyOTP = ({ otp }) => {
		dispatch(VerifyOTPAction({ loginToken, otp })).then((res) => {
			if (res?.payload?.data?.statusCode === 200) {
				const {
					payload: {
						data: {
							result: { data },
						},
					},
				} = res;
				data.permission.forEach((item) => {
					updatedState.sidebarData.push({
						nameEn: item.nameEn,
						nameAr: item.nameAr,
						icon: item.icon,
						id: item.id,
						route: item.route,
					});

					if (item.nameEn === moduleNames.dashboard) {
						updatedState.dashboardModule = item.pages;
					} else if (item.nameEn === moduleNames.personnel) {
						updatedState.personnelModule = item.pages;
					} else if (item.nameEn === moduleNames.siteManagement) {
						updatedState.siteManagementModule = item.pages;
					} else if (item.nameEn === moduleNames.hardware) {
						updatedState.hardwareModule = item.pages;
					} else if (item.nameEn === moduleNames.accessControl) {
						updatedState.accessControlModule = item.pages;
					} else if (item.nameEn === moduleNames.canteenControl) {
						updatedState.canteenControlModule = item.pages;
					} else if (item.nameEn === moduleNames.Organizations) {
						updatedState.organizationsModule = item.pages;
					} else if (item.nameEn === moduleNames.assetManagement) {
						updatedState.assetManagementModule = item.pages;
					} else if (item.nameEn === moduleNames.TeamsControl) {
						updatedState.TeamsControlModule = item.pages;
					} else if (item.nameEn === moduleNames.TaskControl) {
						updatedState.TaskControlModule = item.pages;
					} else if (item.nameEn === moduleNames.visitorControl) {
						updatedState.visitorControlModule = item.pages;
					} else if (item.nameEn === moduleNames.bookingControl) {
						updatedState.bookingControlModule = item.pages;
					} else if (item.nameEn === moduleNames.timeAttendance) {
						updatedState.timeAttendanceModule = item.pages;
					} else if (item.nameEn === moduleNames.incidentControl) {
						updatedState.incidentControlModule = item.pages;
					} else if (item.nameEn === moduleNames.maintenanceControl) {
						updatedState.maintenanceControlModule = item.pages;
					} else if (item.nameEn === moduleNames.reports) {
						updatedState.reportModule = item.pages;
					} else if (item.nameEn === moduleNames.monitoring) {
						updatedState.monitoringModule = item.pages;
					} else if (item.nameEn === moduleNames.owners) {
						updatedState.ownersModule = item.pages;
					} else if (item.nameEn === moduleNames.customers) {
						updatedState.customersModule = item.pages;
					} else if (item.nameEn === moduleNames.payments) {
						updatedState.paymentModule = item.pages;
					} else if (item.nameEn === moduleNames.settings) {
						updatedState.settingsModule = item.pages;
					} else if (item.nameEn === moduleNames.configuration) {
						updatedState.configurationModule = item.pages;
					}
				});

				updatedState.sidebarData.length &&
					localStorage.setItem(
						"sidebarData",
						JSON.stringify(updatedState.sidebarData)
					);
				updatedState.dashboardModule.length &&
					localStorage.setItem(
						"dashboardModule",
						JSON.stringify(updatedState.dashboardModule)
					);
				updatedState.personnelModule.length &&
					localStorage.setItem(
						"personnelModule",
						JSON.stringify(updatedState.personnelModule)
					);
				updatedState.siteManagementModule.length &&
					localStorage.setItem(
						"siteManagementModule",
						JSON.stringify(updatedState.siteManagementModule)
					);
				updatedState.hardwareModule.length &&
					localStorage.setItem(
						"hardwareModule",
						JSON.stringify(updatedState.hardwareModule)
					);
				updatedState.accessControlModule.length &&
					localStorage.setItem(
						"accessControlModule",
						JSON.stringify(updatedState.accessControlModule)
					);
				updatedState.visitorControlModule.length &&
					localStorage.setItem(
						"visitorControlModule",
						JSON.stringify(updatedState.visitorControlModule)
					);
				updatedState.bookingControlModule.length &&
					localStorage.setItem(
						"bookingControlModule",
						JSON.stringify(updatedState.bookingControlModule)
					);
				updatedState.canteenControlModule.length &&
					localStorage.setItem(
						"canteenControlModule",
						JSON.stringify(updatedState.canteenControlModule)
					);
				updatedState.organizationsModule.length &&
					localStorage.setItem(
						"organizationsModule",
						JSON.stringify(updatedState.organizationsModule)
					);
				updatedState.assetManagementModule.length &&
					localStorage.setItem(
						"assetManagementModule",
						JSON.stringify(updatedState.assetManagementModule)
					);
				updatedState.TeamsControlModule.length &&
					localStorage.setItem(
						"TeamsControl",
						JSON.stringify(updatedState.TeamsControlModule)
					);
				updatedState.TaskControlModule.length &&
					localStorage.setItem(
						"TaskControlModule",
						JSON.stringify(updatedState.TaskControlModule)
					);
				updatedState.timeAttendanceModule.length &&
					localStorage.setItem(
						"timeAttendanceModule",
						JSON.stringify(updatedState.timeAttendanceModule)
					);
				updatedState.incidentControlModule.length &&
					localStorage.setItem(
						"incidentControlModule",
						JSON.stringify(updatedState.incidentControlModule)
					);
				updatedState.maintenanceControlModule.length &&
					localStorage.setItem(
						"maintenanceControlModule",
						JSON.stringify(updatedState.maintenanceControlModule)
					);
				updatedState.reportModule.length &&
					localStorage.setItem(
						"reportModule",
						JSON.stringify(updatedState.reportModule)
					);
				updatedState.monitoringModule.length &&
					localStorage.setItem(
						"monitoringModule",
						JSON.stringify(updatedState.monitoringModule)
					);
				updatedState.ownersModule.length &&
					localStorage.setItem(
						"ownersModule",
						JSON.stringify(updatedState.ownersModule)
					);
				updatedState.customersModule.length &&
					localStorage.setItem(
						"customersModule",
						JSON.stringify(updatedState.customersModule)
					);
				updatedState.paymentModule.length &&
					localStorage.setItem(
						"paymentModule",
						JSON.stringify(updatedState.paymentModule)
					);
				updatedState.settingsModule.length &&
					localStorage.setItem(
						"settingsModule",
						JSON.stringify(updatedState.settingsModule)
					);
				updatedState.configurationModule.length &&
					localStorage.setItem(
						"configurationModule",
						JSON.stringify(updatedState.configurationModule)
					);

				localStorage.setItem("loginToken", data.authToken);
				localStorage.setItem(
					"permissionsName",
					JSON.stringify(data.permissionsName)
				);
				localStorage.setItem("userData", JSON.stringify(data));
				data.profilePicture &&
					localStorage.setItem(
						"userImage",
						JSON.stringify(data.profilePicture)
					);

				localStorage.setItem(
					"userName",
					JSON.stringify(data.firstNameEn)
				);
				window.location.href = "/dashboard";
				message.success(
					intl.formatMessage({ id: "otp.successfully" }),
					5
				);
			} else {
				const {
					payload: {
						data: { message: messageError },
					},
				} = res;
				message.error(intl.formatMessage({ id: messageError }), 10);
			}
		});
	};
	return (
		<div className="gx-app-login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					<div className="gx-app-logo-content">
						<div className="gx-app-logo">
							<img
								alt="powerlook-logo"
								src="/assets/images/confirm-login.PNG"
							/>
						</div>

						{isModelVisible && (
							<>
								<div className="gx-app-logo-wid">
									<h5 className="confirm-msg">
										<IntlMessages id="text.confirm.login" />
									</h5>
									<p className="gray-text2">
										<IntlMessages id="text.confirm" />
									</p>
								</div>

								<div className="gx-app-login-content">
									{SendOTPError ? (
										<Error
											type="error"
											message={SendOTPError?.message}
										/>
									) : methods.length ? (
										<Form
											name="basic"
											labelCol={{
												span: 24,
											}}
											className="gx-signin-form gx-form-row0"
										>
											<div
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "center",
													marginBottom: "20px",
												}}
											>
												<Form.Item>
													{methods.map((method) => {
														return (
															<Button
																key={
																	method.value
																}
																type="primary"
																onClick={() =>
																	handelSendOTP(
																		method.value
																	)
																}
																disabled={
																	method.disabled
																}
																icon={
																	<img
																		src={`${assetUrl}/icons/${method.icon}.svg`}
																		alt="powerlook-icon"
																		style={{
																			width: "30px",
																		}}
																	/>
																}
																className="button-sendOTP"
															>
																<p className="data-confirm">
																	{
																		method.label
																	}
																</p>
															</Button>
														);
													})}
												</Form.Item>
											</div>
										</Form>
									) : (
										<Loader />
									)}
								</div>
							</>
						)}

						{!isModelVisible && (
							<>
								<div className="gx-app-logo-wid">
									<h5 className="confirm-msg">
										<IntlMessages id="text.confirm.login" />
									</h5>
									<p className="gray-text2">
										<IntlMessages id="text.confirm2" />
									</p>
								</div>

								<div className="gx-app-login-content">
									{VerifyOTPError ? (
										<Error
											type="error"
											message={VerifyOTPError?.message}
										/>
									) : (
										<Form
											name="basic"
											labelCol={{
												span: 24,
											}}
											className="gx-signin-form gx-form-row0"
											onFinish={handelVerifyOTP}
										>
											<Form.Item
												label={
													<IntlMessages id="label.verifyOTP" />
												}
												name="otp"
												rules={[
													{
														required: true,
														message: (
															<IntlMessages id="error.verifyOTP" />
														),
													},
													({ getFieldValue }) => ({
														validator(_, value) {
															if (
																getFieldValue(
																	"otp"
																).length < 6
															) {
																return Promise.reject(
																	<IntlMessages id="error.verifyOTP.value" />
																);
															}
															return Promise.resolve();
														},
													}),
												]}
												hasFeedback
											>
												<OtpInput
													value={otp}
													onChange={setOtp}
													numInputs={6}
													renderSeparator={
														<span>-</span>
													}
													renderInput={(props) => (
														<input {...props} type="number" className="input"/>
													)}
													inputStyle={{
														width: "54px",
														height: "45px",
														margin: "0 5px",
														borderRadius: "4px",
														border: "1px solid rgba(0,0,0,0.3)",
														fontSize: "18px",
														boxShadow:
															"0px 4px 4px rgba(0, 0, 0, 0.25)",
													}}
													shouldAutoFocus={true}
												/>
											</Form.Item>
											<Form.Item>
												<Button
													type="primary"
													className="gx-mb-0 gx-w-100"
													htmlType="submit"
													loading={loading}
												>
													<IntlMessages id="button.send" />
												</Button>
											</Form.Item>
										</Form>
									)}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SendOTP;

import {
	IncidentType_TYPE_REQUEST,
	IncidentType_TYPE_SUCCESS,
	IncidentType_TYPE_FAILURE,
	IncidentType_ADD_FAILURE,
	IncidentType_ADD_SUCCESS,
	IncidentType_ADD_REQUEST,
	IncidentType_DELETE_REQUEST,
	IncidentType_DELETE_SUCCESS,
	IncidentType_DELETE_FAILURE,
	IncidentType_DETAILS_REQUEST,
	IncidentType_DETAILS_SUCCESS,
	IncidentType_DETAILS_FAILURE,
	IncidentType_DETAILS_RESET,
	IncidentType_UPDATE_REQUEST,
	IncidentType_UPDATE_SUCCESS,
	IncidentType_UPDATE_FAILURE,
	IncidentType_UPDATE_RESET,
	IncidentTypes_DDL_REQUEST,
	IncidentTypes_DDL_SUCCESS,
	IncidentTypes_DDL_FAILURE,
	IncidentTypes_DDL_RESET,
	IncidentTypePolicies_Add_FAILURE,
	IncidentTypePolicies_Add_SUCCESS,
	IncidentTypePolicies_Add_REQUEST,
	IncidentTypePolicies_DELETE_FAILURE,
	IncidentTypePolicies_DELETE_SUCCESS,
	IncidentTypePolicies_DELETE_REQUEST,
	IncidentTypePolicies_RESET,
	IncidentTypePolicies_FAILURE,
	IncidentTypePolicies_SUCCESS,
	IncidentTypePolicies_REQUEST,
	Incident_TYPES_ATTACHMENTS_REQUEST,
	Incident_TYPES_ATTACHMENTS_FAILURE,
	Incident_TYPES_ATTACHMENTS_SUCCESS,
} from "./IncidentTypeConstant";

export const IncidentTypeReducer = (
	initialState = {
		IncidentTypes: [],
		isHasIncidentTypes: false,
	},
	action
) => {
	switch (action.type) {
		case IncidentType_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllIncidentTypes: [],
			};
		case IncidentType_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllIncidentTypes: action.payload.result.data,
				loading: false,
			};

		case IncidentType_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//ddl
		case IncidentTypes_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				IncidentTypes: [],
			};
		case IncidentTypes_DDL_SUCCESS:
			return {
				...initialState,
				IncidentTypes: action.payload.result.data,
				loading: false,
				isHasIncidentTypes: action.payload.result.data?.length
					? true
					: false,
			};

		case IncidentTypes_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLIncidentTypesErrors: action.payload,
			};
		case IncidentTypes_DDL_RESET:
			return {
				...initialState,
				isHasIncidentTypes: false,
			};

		//add IncidentType
		case IncidentType_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case IncidentType_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case IncidentType_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete IncidentType

		case IncidentType_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case IncidentType_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case IncidentType_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//IncidentType details & edit
export const IncidentTypeDetailsReducer = (
	state = { IncidentType: {} },
	action
) => {
	switch (action.type) {
		case IncidentType_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case IncidentType_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				IncidentType: action.payload,
			};
		case IncidentType_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case IncidentType_DETAILS_RESET:
			return { ...state, IncidentType: {} };
		//IncidentType Update
		case IncidentType_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case IncidentType_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case IncidentType_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case IncidentType_UPDATE_RESET:
			return { ...state, IncidentType: {} };

		//IncidentTypePolicies
		case IncidentTypePolicies_REQUEST:
			return {
				...state,
				IncidentTypePoliciesLoading: true,
			};
		case IncidentTypePolicies_SUCCESS:
			return {
				...state,
				IncidentTypePolicies: action.payload.result.data,
				IncidentTypePoliciesLoading: false,
			};
		case IncidentTypePolicies_FAILURE:
			return {
				...state,
				IncidentTypePoliciesLoading: false,
				getIncidentTypePoliciesError: action.payload,
			};
		case IncidentTypePolicies_RESET:
			return {
				...state,
				IncidentTypePolicies: [],
			};

		/* Delete  Teams Users */
		case IncidentTypePolicies_DELETE_REQUEST:
			return {
				...state,
				deleteIncidentTypePoliciesLoading: true,
			};
		case IncidentTypePolicies_DELETE_SUCCESS:
			return {
				...state,
				deleteIncidentTypePoliciesLoading: false,
			};
		case IncidentTypePolicies_DELETE_FAILURE:
			return {
				...state,
				deleteIncidentTypePoliciesLoading: false,
				deleteIncidentTypePoliciesError: action.payload,
			};
		/* Add  Teams Users */
		case IncidentTypePolicies_Add_REQUEST:
			return {
				...state,
				addIncidentTypePoliciesLoading: true,
			};
		case IncidentTypePolicies_Add_SUCCESS:
			return {
				...state,
				addIncidentTypePoliciesLoading: false,
			};
		case IncidentTypePolicies_Add_FAILURE:
			return {
				...state,
				addIncidentTypePoliciesLoading: false,
				addIncidentTypePoliciesError: action.payload,
			};
		default:
			return state;
	}
};

//IncidentType Attachments
export const IncidentTypeAttachmentsReducer = (
	state = { data: {} },
	action
) => {
	switch (action.type) {
		case Incident_TYPES_ATTACHMENTS_REQUEST:
			return { ...state, AttachLoading: true };
		case Incident_TYPES_ATTACHMENTS_SUCCESS:
			return {
				...state,
				AttachLoading: false,
				data: action.payload,
			};
		case Incident_TYPES_ATTACHMENTS_FAILURE:
			return {
				...state,
				AttachLoading: false,
				data: action.payload,
			};
		default:
			return state;
	}
};

export const AssetType_TYPE_REQUEST = "AssetType_TYPE_REQUEST";
export const AssetType_TYPE_SUCCESS = "AssetType_TYPE_SUCCESS";
export const AssetType_TYPE_FAILURE = "AssetType_TYPE_FAILURE";

export const AssetType_ADD_REQUEST = "AssetType_ADD_REQUEST";
export const AssetType_ADD_SUCCESS = "AssetType_ADD_SUCCESS";
export const AssetType_ADD_FAILURE = "AssetType_ADD_FAILURE";

export const AssetTypes_DDL_REQUEST = "AssetTypes_DDL_REQUEST";
export const AssetTypes_DDL_SUCCESS = "AssetTypes_DDLAssetTypes_SUCCESS";
export const AssetTypes_DDL_FAILURE = "AssetTypes_DDL_FAILURE";
export const AssetTypes_DDL_RESET = "AssetTypes_DDL_RESET";

export const AssetType_DELETE_REQUEST = "AssetType_DELETE_REQUEST";
export const AssetType_DELETE_SUCCESS = "AssetType_DELETE_SUCCESS";
export const AssetType_DELETE_FAILURE = "AssetType_DELETE_FAILURE";

export const AssetType_UPDATE_REQUEST = "AssetType_UPDATE_REQUEST";
export const AssetType_UPDATE_SUCCESS = "AssetType_UPDATE_SUCCESS";
export const AssetType_UPDATE_FAILURE = "AssetType_UPDATE_FAILURE";
export const AssetType_UPDATE_RESET = "AssetType_UPDATE_RESET";

export const AssetType_DETAILS_REQUEST = "AssetType_DETAILS_REQUEST";
export const AssetType_DETAILS_SUCCESS = "AssetType_DETAILS_SUCCESS";
export const AssetType_DETAILS_FAILURE = "AssetType_DETAILS_FAILURE";
export const AssetType_DETAILS_RESET = "AssetType_DETAILS_RESET";

export const DDL_MinorAssetsType_REQUEST = "DDL_MinorAssetsType_REQUEST"
export const DDL_MinorAssetsType_FAILURE = "DDL_MinorAssetsType_FAILURE"
export const DDL_MinorAssetsType_SUCCESS = "DDL_MinorAssetsType_SUCCESS"

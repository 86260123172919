import React, { useState } from "react";
import VisitorComponent from "../../../components/VisitorComponent";
import Error from "../../../components/Message";
import { message } from "antd";
import { addSelfVisitor } from "../../../appRedux/Auth/loginAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";
import DefaultCardComponent from "../../../components/Cards/DefaultCard";

const AddVisitorsByUsers = () => {
	const { addSelfVisitorLoading, addSelfVisitorError } = useSelector(
		(state) => state.selfVisitorReducer
	);
	const dispatch = useDispatch();
	const intl = useIntl();
	const { token } = useParams();
	const [isVisible, setIsVisible] = useState(false);

	const onFinish = (values) => {
		const visitorPermitUser = {
			...values,
			tokenUrl: token,
		};
		dispatch(addSelfVisitor(visitorPermitUser)).then((res) => {
			if (res) {
				const {
					payload: {
						data: { statusCode, message: errorMessage },
					},
				} = res;
				if (statusCode === 200) {
					// message.success(
					// 	intl.formatMessage({ id: "message.add" }),
					// 	5
					// );
					setIsVisible(true);
				} else {
					message.error(intl.formatMessage({ id: errorMessage }));
				}
			}
		});
	};

	return (
		<div className="add-visitor">
			<h1
				style={{ marginBottom: "2px", textAlign: "center" }}
				className="gx-mb-4"
			>
				Welcome to add visitors by yourself
			</h1>
			{!isVisible ? (
				<>
					{addSelfVisitorError ? (
						<Error
							type={"error"}
							message={addSelfVisitorError?.message}
						/>
					) : (
						<VisitorComponent
							isSendLink={true}
							onFinish={onFinish}
							loading={addSelfVisitorLoading}
						/>
					)}
				</>
			) : (
				<DefaultCardComponent isAdd={false} title={""}>
					<div
						className="gx-mb-2"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							color: "green",
							fontSize: 25,
							fontWeight: 400,
						}}
					>
						<IntlMessages id="message.visitorAdded" />
					</div>
				</DefaultCardComponent>
			)}
		</div>
	);
};

export default AddVisitorsByUsers;

import React, { useEffect, useState } from "react";
import { Layout, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";

import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import App from "../../routes/index";
import * as SignalR from "@microsoft/signalr";

import {
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_DRAWER,
	NAV_STYLE_FIXED,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	NAV_STYLE_MINI_SIDEBAR,
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/SettingsApp/settingsActions";
import AppSidebar from "./AppSidebar";
import CheckInternetConnection from "../../components/CheckInternetConnection";
import FooterText from "../../util/config";
import api from "../../util/Api";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
	switch (navStyle) {
		case NAV_STYLE_DARK_HORIZONTAL:
			return "gx-container-wrap";
		case NAV_STYLE_DEFAULT_HORIZONTAL:
			return "gx-container-wrap";
		case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
			return "gx-container-wrap";
		case NAV_STYLE_BELOW_HEADER:
			return "gx-container-wrap";
		case NAV_STYLE_ABOVE_HEADER:
			return "gx-container-wrap";
		default:
			return "";
	}
};

const getNavStyles = (navStyle, processCommands, loading, number) => {
	switch (navStyle) {
		case NAV_STYLE_DEFAULT_HORIZONTAL:
			return <HorizontalDefault />;
		case NAV_STYLE_DARK_HORIZONTAL:
			return <HorizontalDark />;
		case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
			return <InsideHeader />;
		case NAV_STYLE_ABOVE_HEADER:
			return <AboveHeader />;
		case NAV_STYLE_BELOW_HEADER:
			return <BelowHeader />;
		case NAV_STYLE_FIXED:
			return (
				<Topbar
					processCommands={processCommands}
					loading={loading}
					number={number}
				/>
			);
		case NAV_STYLE_DRAWER:
			return (
				<Topbar
					processCommands={processCommands}
					loading={loading}
					number={number}
				/>
			);
		case NAV_STYLE_MINI_SIDEBAR:
			return (
				<Topbar
					processCommands={processCommands}
					loading={loading}
					number={number}
				/>
			);
		case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
			return <NoHeaderNotification />;
		case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
			return <NoHeaderNotification />;
		default:
			return null;
	}
};

const MainApp = () => {
	const { navStyle } = useSelector(({ settings }) => settings);
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);

	useEffect(() => {
		window.addEventListener("resize", () => {
			dispatch(updateWindowWidth(window.innerWidth));
		});
	}, [dispatch]);

	const [number, setNumber] = useState(0);
	const [loading, setLoading] = useState(false);

	const getProcessCommands = async () => {
		try {
			const {
				data: {
					statusCode,
					result: { data: count },
				},
			} = await api.post("Hardware/GetUnprocessedCommandsCount");
			if (statusCode === 200) {
				setNumber(count);
			}
			if (statusCode === 200 && count > 0) {
				setShow(true);
			}
		} catch (error) {
			message.error(error?.response?.data?.message);
		}
	};

	useEffect(() => {
		getProcessCommands();
		let connection = new SignalR.HubConnectionBuilder()
			.withUrl(process.env.REACT_APP_HUB_SOCKET + "commandHub", {
				skipNegotiation: true,
				transport: SignalR.HttpTransportType.WebSockets,
			})
			.build();
		connection.on("CommandUpdate", async (message) => {
			if (message === '"NewCommand"') {
				getProcessCommands();
			}
		});
		connection
			.start()
			.then(() => {
				//console.log("connected");
				connection.invoke("AddToGroup", "Command");
			})
			.catch();
		// return () => {
		// 	// Cleanup function
		// 	connection.off();
		//   };
	}, []);

	const processCommands = async () => {
		try {
			setLoading(true);
			const {
				data: { statusCode },
			} = await api.post("Hardware/ProcessCommands");
			if (statusCode === 200) {
				setLoading(false);
				setShow(false);
				getProcessCommands();
			}
		} catch (error) {
			message.error(error.response.data.message);
			setLoading(false);
		}
	};

	return (
		<Layout className="gx-app-layout">
			<CheckInternetConnection>
				<AppSidebar navStyle={navStyle} />
				<Layout>
					{getNavStyles(navStyle, processCommands, loading, number)}
					<Content
						className={`gx-layout-content ${getContainerClass(
							navStyle
						)} `}
					>
						<App
							match={match}
							show={show}
							setShow={setShow}
							processCommands={processCommands}
						/>
						<Footer>
							<div className="gx-layout-footer-content">
								<FooterText />
							</div>
						</Footer>
					</Content>
				</Layout>
			</CheckInternetConnection>
		</Layout>
	);
};
export default MainApp;

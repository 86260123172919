import {
	CleanUp_TYPE_FAILURE,
	CleanUp_TYPE_REQUEST,
	CleanUp_TYPE_SUCCESS,
	CleanUp_UPDATE_FAILURE,
	CleanUp_UPDATE_REQUEST,
	CleanUp_UPDATE_RESET,
	CleanUp_UPDATE_SUCCESS,
} from "./CleanUpConstant";

export const CleanUpReducer = (
	initialState = {
		CleanUps: [],
	},
	action
) => {
	switch (action.type) {
		case CleanUp_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				CleanUps: [],
			};
		case CleanUp_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				loading: false,
			};

		case CleanUp_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//CleanUp Update
		case CleanUp_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case CleanUp_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case CleanUp_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case CleanUp_UPDATE_RESET:
			return { ...initialState,CleanUp: {} };
		default:
			return initialState;
	}
};

import {
	ADD_TEMPLATE_FAILURE,
	ADD_TEMPLATE_REQUEST,
	ADD_TEMPLATE_SUCCESS,
	DELETE_TEMPLATE_FAILURE,
	DELETE_TEMPLATE_REQUEST,
	DELETE_TEMPLATE_SUCCESS,
	DETAILS_TEMPLATE_FAILURE,
	DETAILS_TEMPLATE_REQUEST,
	DETAILS_TEMPLATE_SUCCESS,
	TEMPLATE_DDL_FAILURE,
	TEMPLATE_DDL_REQUEST,
	TEMPLATE_DDL_SUCCESS,
	TEMPLATE_DDL_RESET,
	TEMPLATE_MESSAGES_FAILURE,
	TEMPLATE_MESSAGES_REQUEST,
	TEMPLATE_MESSAGES_SUCCESS,
	UPDATE_TEMPLATE_FAILURE,
	UPDATE_TEMPLATE_REQUEST,
	UPDATE_TEMPLATE_SUCCESS,
} from "./permitSettingsTypesConstants";

export const permitSettingsReducer = (
	initialState = {
		templateMessage: "",
		isHasTemplates: false
	},
	action
) => {
	switch (action.type) {
		case TEMPLATE_MESSAGES_REQUEST:
			return {
				...initialState,
				loading: true,
				templateMessage: "",
			};
		case TEMPLATE_MESSAGES_SUCCESS:
			return {
				...initialState,
				templateMessage: action.payload.data,
				loading: false,
			};

		case TEMPLATE_MESSAGES_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//Get DDL
		case TEMPLATE_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				templates: [],
			};
		case TEMPLATE_DDL_SUCCESS:
			return {
				...initialState,
				templates: action.payload.result.data,
				loading: false,
				isHasTemplates: action.payload.result.data?.length? true: false
			};

		case TEMPLATE_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case TEMPLATE_DDL_RESET:
			return {
				...initialState,isHasTemplates: false
			};
		// Add Template
		case ADD_TEMPLATE_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_TEMPLATE_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				template: action.payload.template,
			};
		case ADD_TEMPLATE_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		// Delete Template
		case DELETE_TEMPLATE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_TEMPLATE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_TEMPLATE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		// Templates Details
		case DETAILS_TEMPLATE_REQUEST:
			return { ...initialState, detailsLoading: true };
		case DETAILS_TEMPLATE_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				template: action.payload,
			};
		case DETAILS_TEMPLATE_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};

		case UPDATE_TEMPLATE_REQUEST:
			return {
				...initialState,
				updateLoading: true,
			};
		case UPDATE_TEMPLATE_SUCCESS:
			return {
				...initialState,
				template: action.payload.data,
				updateLoading: false,
			};

		case UPDATE_TEMPLATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};

		default:
			return initialState;
	}
};

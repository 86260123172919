import { Spin } from "antd";
import React from "react";

const Loader = () => (
	<div className="example">
		<Spin
			tip="Loading..."
			style={{
				width: "100%",
				margin: "20% auto",
			}}
		/>
	</div>
);

export default Loader;

import {
	AssetType_TYPE_REQUEST,
	AssetType_TYPE_SUCCESS,
	AssetType_TYPE_FAILURE,
	AssetType_ADD_FAILURE,
	AssetType_ADD_SUCCESS,
	AssetType_ADD_REQUEST,
	AssetType_DELETE_REQUEST,
	AssetType_DELETE_SUCCESS,
	AssetType_DELETE_FAILURE,
	AssetType_DETAILS_REQUEST,
	AssetType_DETAILS_SUCCESS,
	AssetType_DETAILS_FAILURE,
	AssetType_DETAILS_RESET,
	AssetType_UPDATE_REQUEST,
	AssetType_UPDATE_SUCCESS,
	AssetType_UPDATE_FAILURE,
	AssetType_UPDATE_RESET,
	AssetTypes_DDL_REQUEST,
	AssetTypes_DDL_SUCCESS,
	AssetTypes_DDL_FAILURE,
	AssetTypes_DDL_RESET,
	DDL_MinorAssetsType_REQUEST,
	DDL_MinorAssetsType_SUCCESS,
	DDL_MinorAssetsType_FAILURE,
} from "./AssetTypeTypeConstant";

export const AssetTypeReducer = (
	initialState = {
		AssetTypes: [],
		MinorAssetType: [],
		isHasAssetTypes: false,
	},
	action
) => {
	switch (action.type) {
		case AssetType_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllAssetTypes: [],
			};
		case AssetType_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllAssetTypes: action.payload.result.data,
				loading: false,
			};

		case AssetType_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//ddl
		case AssetTypes_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				AssetTypes: [],
			};
		case AssetTypes_DDL_SUCCESS:
			return {
				...initialState,
				AssetTypes: action.payload.result.data,
				loading: false,
				isHasAssetTypes: action.payload.result.data?.length
					? true
					: false,
			};

		case AssetTypes_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLAssetTypesErrors: action.payload,
			};
		case AssetTypes_DDL_RESET:
			return {
				...initialState,
				isHasAssetTypes: false,
			};

		// Minor Assets Type
		case DDL_MinorAssetsType_REQUEST:
			return {
				...initialState,
				loading: true,
				MinorAssetType: [],
			};
		case DDL_MinorAssetsType_SUCCESS:
			return {
				...initialState,
				MinorAssetType: action.payload.result.data,
				loading: false,
			};

		case DDL_MinorAssetsType_FAILURE:
			return {
				...initialState,
				loading: false,
				getMinorAssetTypesErrors: action.payload,
			};

		//add AssetType
		case AssetType_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case AssetType_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case AssetType_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete AssetType

		case AssetType_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case AssetType_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case AssetType_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//AssetType details & edit
export const AssetTypeDetailsReducer = (state = { AssetType: {} }, action) => {
	switch (action.type) {
		case AssetType_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case AssetType_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				AssetType: action.payload,
			};
		case AssetType_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case AssetType_DETAILS_RESET:
			return { ...state, AssetType: {} };
		//AssetType Update
		case AssetType_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case AssetType_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case AssetType_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case AssetType_UPDATE_RESET:
			return { ...state, AssetType: {} };
		default:
			return state;
	}
};

import {
	Teams_TYPE_REQUEST,
	Teams_TYPE_SUCCESS,
	Teams_TYPE_FAILURE,
	Teams_ADD_FAILURE,
	Teams_ADD_SUCCESS,
	Teams_ADD_REQUEST,
	Teams_DELETE_REQUEST,
	Teams_DELETE_SUCCESS,
	Teams_DELETE_FAILURE,
	Teams_DETAILS_REQUEST,
	Teams_DETAILS_SUCCESS,
	Teams_DETAILS_FAILURE,
	Teams_DETAILS_RESET,
	Teams_UPDATE_REQUEST,
	Teams_UPDATE_SUCCESS,
	Teams_UPDATE_FAILURE,
	Teams_UPDATE_RESET,
	Teams_DDL_REQUEST,
	Teams_DDL_SUCCESS,
	Teams_DDL_FAILURE,
	Teams_DDL_RESET,
	Team_USERS_DELETE_SUCCESS,
	Team_USERS_DELETE_REQUEST,
	Team_USERS_DELETE_FAILURE,
	Team_ADD_USERS_REQUEST,
	Team_ADD_USERS_SUCCESS,
	Team_ADD_USERS_FAILURE,
	Team_USERS_RESET,
	Team_USERS_FAILURE,
	Team_USERS_SUCCESS,
	Team_USERS_REQUEST,
	DDL_Team_USERS_FAILURE,
	DDL_Team_USERS_SUCCESS,
	DDL_Team_USERS_REQUEST,
} from "./teamTypeConstant";

export const TeamsReducer = (
	initialState = {
		Teams: [],
		isHasTeams: false,
	},
	action
) => {
	switch (action.type) {
		case Teams_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllTeams: [],
			};
		case Teams_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllTeams: action.payload.result.data,
				loading: false,
			};
		case Teams_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//ddl
		case Teams_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				Teams: [],
			};
		case Teams_DDL_SUCCESS:
			return {
				...initialState,
				Teams: action.payload.result.data,
				loading: false,
				isHasTeams: action.payload.result.data?.length ? true : false,
			};
		case Teams_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLTeamsErrors: action.payload,
			};
		case Teams_DDL_RESET:
			return {
				...initialState,
				isHasTeams: false,
			};

		//add Teams
		case Teams_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Teams_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Teams_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Teams
		case Teams_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Teams_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Teams_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//Teams details & edit
export const TeamsDetailsReducer = (
	state = { Teams: {}, Attachments: {} },
	action
) => {
	switch (action.type) {
		case Teams_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Teams_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				Teams: action.payload,
			};
		case Teams_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Teams_DETAILS_RESET:
			return { ...state, Teams: {} };

		//Teams Update
		case Teams_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Teams_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Teams_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Teams_UPDATE_RESET:
			return { ...state, Teams: {}, TeamUsers: [] };

		//team member
		case Team_USERS_REQUEST:
			return {
				...state,
				TeamsUserLoading: true,
			};
		case Team_USERS_SUCCESS:
			return {
				...state,
				TeamUsers: action.payload.result.data,
				TeamsUserLoading: false,
			};
		case Team_USERS_FAILURE:
			return {
				...state,
				TeamsUserLoading: false,
				getTeamError: action.payload,
			};
		case Team_USERS_RESET:
			return {
				...state,
				TeamUsers: [],
			};

		case DDL_Team_USERS_REQUEST:
			return {
				...state,
				ddlTeamsUserLoading: true,
			};
		case DDL_Team_USERS_SUCCESS:
			return {
				...state,
				TeamUsers: action.payload.result.data,
				ddlTeamsUserLoading: false,
			};
		case DDL_Team_USERS_FAILURE:
			return {
				...state,
				ddlTeamsUserLoading: false,
				getTeamError: action.payload,
			};

		/* Delete  Teams Users */
		case Team_USERS_DELETE_REQUEST:
			return {
				...state,
				deleteTeamLoading: true,
			};
		case Team_USERS_DELETE_SUCCESS:
			return {
				...state,
				deleteTeamLoading: false,
			};
		case Team_USERS_DELETE_FAILURE:
			return {
				...state,
				deleteTeamLoading: false,
				deleteTeamError: action.payload,
			};
		/* Add  Teams Users */
		case Team_ADD_USERS_REQUEST:
			return {
				...state,
				addTeamLoading: true,
			};
		case Team_ADD_USERS_SUCCESS:
			return {
				...state,
				addTeamLoading: false,
			};
		case Team_ADD_USERS_FAILURE:
			return {
				...state,
				addTeamLoading: false,
				addTeamError: action.payload,
			};
		default:
			return state;
	}
};

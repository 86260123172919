import {
	ClearUser_UPDATE_REQUEST,
	ClearUser_UPDATE_SUCCESS,
	ClearUser_UPDATE_FAILURE,
	ClearUser_UPDATE_RESET,
} from "./clearUserConstant";

export const ClearUserReducer = (
	initialState = {
		ClearUsers: [],
	},
	action
) => {
	switch (action.type) {
		//ClearUser Update
		case ClearUser_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case ClearUser_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case ClearUser_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case ClearUser_UPDATE_RESET:
			return { ...initialState,ClearUser: {} };
		default:
			return initialState;
	}
};

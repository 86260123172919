export const PAYMENT_TYPE_REQUEST = "PAYMENT_TYPE_REQUEST";
export const PAYMENT_TYPE_SUCCESS = "PAYMENT_TYPE_SUCCESS";
export const PAYMENT_TYPE_FAILURE = "PAYMENT_TYPE_FAILURE";

export const DDLModules_TYPE_REQUEST = "DDLModules_TYPE_REQUEST";
export const DDLModules_TYPE_SUCCESS = "DDLModules_TYPE_SUCCESS";
export const DDLModules_TYPE_FAILURE = "DDLModules_TYPE_FAILURE";
export const DDLModules_TYPE_RESET = "DDLModules_TYPE_RESET";

export const PAYMENT_ADD_REQUEST = "PAYMENT_ADD_REQUEST";
export const PAYMENT_ADD_SUCCESS = "PAYMENT_ADD_SUCCESS";
export const PAYMENT_ADD_FAILURE = "PAYMENT_ADD_FAILURE";

export const PAYMENT_DELETE_REQUEST = "PAYMENT_DELETE_REQUEST";
export const PAYMENT_DELETE_SUCCESS = "PAYMENT_DELETE_SUCCESS";
export const PAYMENT_DELETE_FAILURE = "PAYMENT_DELETE_FAILURE";

export const PAYMENT_UPDATE_REQUEST = "PAYMENT_UPDATE_REQUEST";
export const PAYMENT_UPDATE_SUCCESS = "PAYMENT_UPDATE_SUCCESS";
export const PAYMENT_UPDATE_FAILURE = "PAYMENT_UPDATE_FAILURE";
export const PAYMENT_UPDATE_RESET = "PAYMENT_UPDATE_RESET";

export const PAYMENT_DETAILS_REQUEST = "PAYMENT_DETAILS_REQUEST";
export const PAYMENT_DETAILS_SUCCESS = "PAYMENT_DETAILS_SUCCESS";
export const PAYMENT_DETAILS_FAILURE = "PAYMENT_DETAILS_FAILURE";
export const PAYMENT_DETAILS_RESET = "PAYMENT_DETAILS_RESET";

import { Button, Card, Col, Popover, Row, Space, Tooltip } from "antd";
import React from "react";
import {} from "recharts";
import IntlMessages from "../../../util/IntlMessages";

const assetUrl = "/assets/images";

export default function DefaultCardComponent(props) {
	if (!props.closeTitle) {
		return (
			<Card
				className={`app-card-item ${props.className}`}
				title={props.title}
				extra={
					<Space size={"middle"}>
						{props.isUpload && (
							<Button
								key={1}
								icon={
									<img
										src={`${assetUrl}/icons/import-icon.svg`}
										alt="powerlook-icon"
										className={"img-card"}
									/>
								}
								onClick={props.onUpload}
								className={"button-card"}
							>
								<IntlMessages id="card.title.import" />
							</Button>
						)}
						{props.isAddLink && (
							<Button
								key={1}
								icon={
									<img
										src={`${assetUrl}/icons/user-add.svg`}
										alt="powerlook-icon"
										className={"img-card"}
									/>
								}
								onClick={props.showSendLinkModel}
								className={"button-card"}
							>
								<IntlMessages id="button.add.link" />
							</Button>
						)}
						{props.permissionBarCode && props.action && (
							<Button
								key={2}
								icon={
									<img
										src={`${assetUrl}/icons/QrCode.svg`}
										alt="powerlook-icon"
										className={"img-card"}
									/>
								}
								onClick={props.handleOpenModal}
								className={"button-card"}
							>
								<IntlMessages id="button.generateQRCode" />
							</Button>
						)}
						{props.isDownload && (
							<Button
								key={2}
								icon={
									<img
										src={`${assetUrl}/icons/export-icon.svg`}
										alt="powerlook-icon"
										className={"img-card"}
									/>
								}
								onClick={props.onDownload}
								className={"button-card"}
							>
								<IntlMessages id="button.export" />
							</Button>
						)}
						{props.isExport && (
							<Popover
								placement="bottomRight"
								content={props.content}
								trigger="click"
								open={props.open}
								onOpenChange={props.handleOpenChange}
							>
								<Button
									key={2}
									type="link"
									icon={
										<img
											src={`${assetUrl}/icons/export-icon.svg`}
											alt="powerlook-icon"
											className={"img-card"}
										/>
									}
									className={"button-card"}
								>
									<IntlMessages id="button.export" />
								</Button>
							</Popover>
						)}
						{props.isReport && (
							<Tooltip
								title={<IntlMessages id="button.downloadPDF" />}
								color={"#c1c1c1"}
								placement={"bottom"}
							>
								<Button
									type="danger"
									icon={
										<img
											src={`${assetUrl}/icons/pdf.svg`}
											alt="powerlook-icon"
										/>
									}
									onClick={props.onReport}
								>
									PDF
								</Button>
							</Tooltip>
						)}
						{props.isExcel && (
							<Tooltip
								title={
									<IntlMessages id="button.downloadExcel" />
								}
								color={"#c1c1c1"}
								placement={"bottom"}
							>
								<Button
									style={{
										background: "#34A853",
										color: "white",
									}}
									icon={
										<img
											src={`${assetUrl}/icons/excel.svg`}
											alt="powerlook-icon"
										/>
									}
									onClick={props.onExcel}
								>
									Excel
								</Button>
							</Tooltip>
						)}
						{props.isAdd && (
							<Button
								onClick={props.onAddClick}
								icon={
									<img
										src={`${assetUrl}/icons/btn-add2.svg`}
										alt="powerlook-icon"
										className={"img-card"}
									/>
								}
								className={"button-card-add"}
							>
								<IntlMessages id={"button.add"} />
							</Button>
						)}
						<Row gutter={[16, 16]} justify="start" wrap={true}>
							{props.isSecondAdd && (
								<Col>
									<Button
										onClick={props.onAddSecondClick}
										icon={
											<img
												src={`${assetUrl}/icons/btn-add2.svg`}
												alt="powerlook-icon"
												className={"img-card"}
											/>
										}
										className={"button-card-add"}
									>
										<IntlMessages id="card.title.outside.Injured" />
									</Button>
								</Col>
							)}
							{props.isInjuredAdd && (
								<Col>
									<Button
										onClick={props.onAddClick}
										icon={
											<img
												src={`${assetUrl}/icons/btn-add2.svg`}
												alt="powerlook-icon"
												className={"img-card"}
											/>
										}
										className={"button-card-add"}
									>
										<IntlMessages id="card.title.addInjuredEmployee" />
									</Button>
								</Col>
							)}
							{props.isEmployeeWitnessAdd && (
								<Col>
									<Button
										onClick={props.onAddClick}
										icon={
											<img
												src={`${assetUrl}/icons/btn-add2.svg`}
												alt="powerlook-icon"
												className={"img-card"}
											/>
										}
										className={"button-card-add"}
									>
										<IntlMessages id="card.title.addWitessEmployee" />
									</Button>
								</Col>
							)}
							{props.isWitnessAdd && (
								<Col>
									<Button
										onClick={props.onAddSecondClick}
										icon={
											<img
												src={`${assetUrl}/icons/btn-add2.svg`}
												alt="powerlook-icon"
												className={"img-card"}
											/>
										}
										className={"button-card-add"}
									>
										<IntlMessages id="card.title.outside.witness" />
									</Button>
								</Col>
							)}
						</Row>
						{props.isCancle && (
							<Button
								onClick={props.onCancle}
								// icon={
								// 	<img
								// 		src={`${assetUrl}/icons/cancel-svgrepo-com.svg`}
								// 		alt="powerlook-icon"
								// 		className={"img-card"}
								// 	/>
								// }
								className={"button-card-add"}
							>
								<IntlMessages id="button.cancel" />
							</Button>
						)}
					</Space>
				}
			>
				{props.children}
			</Card>
		);
	}

	return (
		<Card className={`app-card-item ${props.className}`}>
			{props.children}
		</Card>
	);
}

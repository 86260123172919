export const moduleNames = {
	dashboard: "Dashboard",
	personnel: "Personnel",
	siteManagement: "Site Management",
	hardware: "Hardware",
	accessControl: "Access Control",
	visitorControl: "Visitor Control",
	canteenControl: "Canteen Control",
	bookingControl: "Booking Control",
	timeAttendance: "Time Attendance",
	incidentControl: "Incident Control",
	maintenanceControl: "Maintenance Control",
	reports: "Reports",
	monitoring: "Monitoring",
	owners: "Owners",
	customers: "Customers",
	payments: "Payments",
	settings: "Settings",
	configuration: "Configuration",
	Organizations: "Organizations",
	TeamsControl: "Teams Control",
	TaskControl: "Task Management",
	assetManagement:"Asset Management"
};

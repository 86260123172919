export const AREAS_USER_REQUEST = "AREAS_USER_REQUEST";
export const AREAS_USER_SUCCESS = "AREAS_USER_SUCCESS";
export const AREAS_USER_FAILURE = "AREAS_USER_FAILURE";

export const AREAS_DDL_REQUEST = "AREAS_DDL_REQUEST";
export const AREAS_DDL_SUCCESS = "AREAS_DDL_SUCCESS";
export const AREAS_DDL_FAILURE = "AREAS_DDL_FAILURE";
export const AREAS_DDL_RESET = "AREAS_DDL_RESET";

export const ADD_AREA_REQUEST = "ADD_AREA_REQUEST";
export const ADD_AREA_SUCCESS = "ADD_AREA_SUCCESS";
export const ADD_AREA_FAILURE = "ADD_AREA_FAILURE";

export const DELETE_AREA_REQUEST = "DELETE_AREA_REQUEST";
export const DELETE_AREA_SUCCESS = "DELETE_AREA_SUCCESS";
export const DELETE_AREA_FAILURE = "DELETE_AREA_FAILURE";

export const DETAILS_AREA_REQUEST = "DETAILS_AREA_REQUEST";
export const DETAILS_AREA_SUCCESS = "DETAILS_AREA_SUCCESS";
export const DETAILS_AREA_FAILURE = "DETAILS_AREA_FAILURE";
export const DETAILS_AREA_RESET = "DETAILS_AREA_RESET";

export const Area_Import_REQUEST = "Area_Import_REQUEST";
export const Area_Import_SUCCESS = "Area_Import_SUCCESS";
export const Area_Import_FAILURE = "Area_Import_FAILURE";

export const Area_Export_REQUEST = "Area_Export_REQUEST";
export const Area_Export_SUCCESS = "Area_Export_SUCCESS";
export const Area_Export_FAILURE = "Area_Export_FAILURE";

export const UPDATE_AREA_REQUEST = "UPDATE_AREA_REQUEST";
export const UPDATE_AREA_SUCCESS = "UPDATE_AREA_SUCCESS";
export const UPDATE_AREA_FAILURE = "UPDATE_AREA_FAILURE";
export const UPDATE_AREA_RESET = "UPDATE_AREA_RESET";

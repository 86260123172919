export const GET_BUILDINGS_REQUEST = "GET_BUILDINGS_REQUEST";
export const GET_BUILDINGS_SUCCESS = "GET_BUILDINGS_SUCCESS";
export const GET_BUILDINGS_FAILURE = "GET_BUILDINGS_FAILURE";

export const Buildings_DDL_REQUEST = "Buildings_DDL_REQUEST";
export const Buildings_DDL_SUCCESS = "Buildings_DDL_SUCCESS";
export const Buildings_DDL_FAILURE = "Buildings_DDL_FAILURE";
export const Buildings_DDL_RESET = "Buildings_DDL_RESET";

export const DELETE_BUILDING_REQUEST = "DELETE_BUILDING_REQUEST";
export const DELETE_BUILDING_SUCCESS = "DELETE_BUILDING_SUCCESS";
export const DELETE_BUILDING_FAILURE = "DELETE_BUILDING_FAILURE";

export const ADD_BUILDING_REQUEST = "ADD_BUILDING_REQUEST";
export const ADD_BUILDING_SUCCESS = "ADD_BUILDING_SUCCESS";
export const ADD_BUILDING_FAILURE = "ADD_BUILDING_FAILURE";

export const BUILDING_DETAILS_REQUEST = "BUILDING_DETAILS_REQUEST";
export const BUILDING_DETAILS_SUCCESS = "BUILDING_DETAILS_SUCCESS";
export const BUILDING_DETAILS_FAILURE = "BUILDING_DETAILS_FAILURE";
export const BUILDING_DETAILS_RESET = "BUILDING_DETAILS_RESET";

export const Buildings_Import_REQUEST = "Buildings_Import_REQUEST";
export const Buildings_Import_SUCCESS = "Buildings_Import_SUCCESS";
export const Buildings_Import_FAILURE = "Buildings_Import_FAILURE";

export const Buildings_Export_REQUEST = "Buildings_Export_REQUEST";
export const Buildings_Export_SUCCESS = "Buildings_Export_SUCCESS";
export const Buildings_Export_FAILURE = "Buildings_Export_FAILURE";

export const BUILDING_UPDATE_REQUEST = "BUILDING_UPDATE_REQUEST";
export const BUILDING_UPDATE_SUCCESS = "BUILDING_UPDATE_SUCCESS";
export const BUILDING_UPDATE_FAILURE = "BUILDING_UPDATE_FAILURE";

export const INJURED_EMPLOYEE_REQUEST = "INJURED_EMPLOYEE_REQUEST";
export const INJURED_EMPLOYEE_SUCCESS = "INJURED_EMPLOYEE_SUCCESS";
export const INJURED_EMPLOYEE_FAILURE = "INJURED_EMPLOYEE_FAILURE";
export const INJURED_EMPLOYEE_Reset = "INJURED_EMPLOYEE_Reset";

export const ADD_INJURED_EMPLOYEE_REQUEST = "ADD_INJURED_EMPLOYEE_REQUEST";
export const ADD_INJURED_EMPLOYEE_SUCCESS = "ADD_INJURED_EMPLOYEE_SUCCESS";
export const ADD_INJURED_EMPLOYEE_FAILURE = "ADD_INJURED_EMPLOYEE_FAILURE";

export const DELETE_INJURED_EMPLOYEE_REQUEST = "DELETE_INJURED_EMPLOYEE_REQUEST";
export const DELETE_INJURED_EMPLOYEE_SUCCESS = "DELETE_INJURED_EMPLOYEE_SUCCESS";
export const DELETE_INJURED_EMPLOYEE_FAILURE = "DELETE_INJURED_EMPLOYEE_FAILURE";
import { Alert, Button, Modal } from "antd";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const Message = (props) => {
	const intl = useIntl();
	const [modalVisible, setModalVisible] = useState(false);

	const showModal = () => {
		setModalVisible(true);
	};

	const handleOk = () => {
		setModalVisible(false);
	};

	const handleCancel = () => {
		setModalVisible(false);
	};
	console.log(props);

	return (
		<>
			<Alert
				style={{ display: "flex", alignItems: "center" }}
				description={
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<span>
							{props.description
								? props.description
								: intl.formatMessage({
									id: "default_error_message",
								})}
						</span>
						<Button
							type="link"
							style={{
								margin: 0,
								backgroundColor: "#f52b35",
								color: "#fff",
							}}
							onClick={showModal}
						>
							{intl.formatMessage({ id: "details" })}
						</Button>
					</div>
				}
				type={props.type}
				showIcon
			/>
			<Modal
				title={intl.formatMessage({ id: "details" })}
				open={modalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				<h3>
					{intl.formatMessage({ id: "url_error" })}:{" "}
					{props?.message?.config
						? props?.message?.config?.baseURL +
						props?.message?.config?.url
						: props?.message?.error
							? props?.message?.error?.config?.baseURL +
							props?.message?.error?.config?.url
							: intl.formatMessage({ id: "url_not_available" })}
				</h3>
				<h3>
					{intl.formatMessage({ id: "request_type" })}:{" "}
					{props?.message?.config?.method
						? props?.message?.config?.method
						: props?.message?.error
							? props?.message?.error?.config?.method
							: intl.formatMessage({ id: "method_not_available" })}
				</h3>

				<h3>
					{intl.formatMessage({ id: "status_code" })}:{" "}
					{props?.message?.status || props?.message?.error?.status
						? props?.message?.status ||
						props?.message?.error?.status
						: intl.formatMessage({ id: "no_status_code" })}
				</h3>
				<h3>
					{intl.formatMessage({ id: "status_code_text" })}:{" "}
					{props?.message?.statusText ||
						props?.message?.error?.statusText
						? props?.message?.statusText ||
						props?.message?.error?.statusText
						: intl.formatMessage({ id: "no_status_message" })}
				</h3>
				<h3>
					{intl.formatMessage({ id: "RequestData" })}:
					<pre>
						{props?.message?.config?.data ||
							props?.message?.error?.config?.data ? (
							<>{JSON.stringify(
								JSON.parse(
									props?.message?.config?.data ||
									props?.message?.error?.config?.data
								),
								null,
								2
							)}</>
						) : (
							<>{intl.formatMessage({ id: "no_data_available" })}</>
						)}
					</pre>
				</h3>
			</Modal>
		</>
	);
};

export default Message;

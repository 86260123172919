export const Teams_TYPE_REQUEST = "Teams_TYPE_REQUEST";
export const Teams_TYPE_SUCCESS = "Teams_TYPE_SUCCESS";
export const Teams_TYPE_FAILURE = "Teams_TYPE_FAILURE";

export const Teams_ADD_REQUEST = "Teams_ADD_REQUEST";
export const Teams_ADD_SUCCESS = "Teams_ADD_SUCCESS";
export const Teams_ADD_FAILURE = "Teams_ADD_FAILURE";

export const Teams_DDL_REQUEST = "Teams_DDL_REQUEST";
export const Teams_DDL_SUCCESS = "Teams_DDL_SUCCESS";
export const Teams_DDL_FAILURE = "Teams_DDL_FAILURE";
export const Teams_DDL_RESET = "Teams_DDL_RESET";

export const Teams_DELETE_REQUEST = "Teams_DELETE_REQUEST";
export const Teams_DELETE_SUCCESS = "Teams_DELETE_SUCCESS";
export const Teams_DELETE_FAILURE = "Teams_DELETE_FAILURE";

export const Teams_UPDATE_REQUEST = "Teams_UPDATE_REQUEST";
export const Teams_UPDATE_SUCCESS = "Teams_UPDATE_SUCCESS";
export const Teams_UPDATE_FAILURE = "Teams_UPDATE_FAILURE";
export const Teams_UPDATE_RESET = "Teams_UPDATE_RESET";

export const Teams_DETAILS_REQUEST = "Teams_DETAILS_REQUEST";
export const Teams_DETAILS_SUCCESS = "Teams_DETAILS_SUCCESS";
export const Teams_DETAILS_FAILURE = "Teams_DETAILS_FAILURE";
export const Teams_DETAILS_RESET = "Teams_DETAILS_RESET";

export const  Team_USERS_REQUEST = " Team_USERS_REQUEST";
export const  Team_USERS_SUCCESS = " Team_USERS_SUCCESS";
export const  Team_USERS_FAILURE = " Team_USERS_FAILURE";
export const  Team_USERS_RESET = " Team_USERS_RESET";

export const  DDL_Team_USERS_REQUEST = " DDL_Team_USERS_REQUEST";
export const  DDL_Team_USERS_SUCCESS = " DDL_Team_USERS_SUCCESS";
export const  DDL_Team_USERS_FAILURE = " DDL_Team_USERS_FAILURE";

export const  Team_USERS_DELETE_REQUEST = " Team_USERS_DELETE_REQUEST";

export const  Team_USERS_DELETE_SUCCESS = " Team_USERS_DELETE_SUCCESS";

export const  Team_USERS_DELETE_FAILURE = " Team_USERS_DELETE_FAILURE";

export const  Team_ADD_USERS_REQUEST = " Team_ADD_USERS_REQUEST";

export const  Team_ADD_USERS_SUCCESS = " Team_ADD_USERS_SUCCESS";

export const  Team_ADD_USERS_FAILURE = " Team_ADD_USERS_FAILURE";

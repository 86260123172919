export const PROFILE_USER_REQUEST = "PROFILE_USER_REQUEST";
export const PROFILE_USER_FORM_SUCCESS = "PROFILE_USER_FORM_SUCCESS";
export const PROFILE_USER_FORM_FAILURE = "PROFILE_USER_FORM_FAILURE";

export const USER_REQUEST = "USER_REQUEST";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILURE = "USER_FAILURE";

export const Import_USER_REQUEST = "Import_USER_REQUEST";
export const Import_USER_SUCCESS = "Import_USER_SUCCESS";
export const Import_USER_FAILURE = "Import_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE ";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "USER_DETAILS_FAILURE";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const LOCK_USER_REQUEST = "LOCK_USER_REQUEST";
export const LOCK_USER_SUCCESS = "LOCK_USER_SUCCESS";
export const LOCK_USER_FAILURE = "LOCK_USER_FAILURE";
export const LOCK_USER_RESET = "LOCK_USER_RESET";

export const UNLOCK_USER_REQUEST = "UNLOCK_USER_REQUEST";
export const UNLOCK_USER_SUCCESS = "UNLOCK_USER_SUCCESS";
export const UNLOCK_USER_FAILURE = "UNLOCK_USER_FAILURE";
export const UNLOCK_USER_RESET = "UNLOCK_USER_RESET";

export const CARD_USER_REQUEST = "CARD_USER_REQUEST";
export const CARD_USER_SUCCESS = "CARD_USER_SUCCESS";
export const CARD_USER_FAILURE = "CARD_USER_FAILURE";
export const CARD_USER_RESET = "CARD_USER_RESET";

export const PIN_USER_REQUEST = "PIN_USER_REQUEST";
export const PIN_USER_SUCCESS = "PIN_USER_SUCCESS";
export const PIN_USER_FAILURE = "PIN_USER_FAILURE";
export const PIN_USER_RESET = "PIN_USER_RESET";

export const ADD_CARD_REQUEST = "ADD_CARD_REQUEST";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_FAILURE = "ADD_CARD_FAILURE";
export const ADD_CARD_RESET = "ADD_CARD_RESET";

export const DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILURE = "DELETE_CARD_FAILURE";

export const PROFILE_DATA_REQUEST = "PROFILE_DATA_REQUEST";
export const PROFILE_DATA_SUCCESS = "PROFILE_DATA_SUCCESS";
export const PROFILE_DATA_FAILURE = "PROFILE_DATA_FAILURE";

export const USERS_DDL_REQUEST = "USERS_DDL_REQUEST";
export const USERS_DDL_SUCCESS = "USERS_DDL_SUCCESS";
export const USERS_DDL_FAILURE = "USERS_DDL_FAILURE";
export const USERS_DDL_RESET = "USERS_DDL_RESET";

export const SEND_SELF_LINK_REQUEST = "SEND_SELF_LINK_REQUEST";
export const SEND_SELF_LINK_SUCCESS = "SEND_SELF_LINK_SUCCESS";
export const SEND_SELF_LINK_FAILURE = "SEND_SELF_LINK_FAILURE";

export const USERS_DDL_TYPE_REQUEST = "USERS_DDL_TYPE_REQUEST";
export const USERS_DDL_TYPE_SUCCESS = "USERS_DDL_TYPE_SUCCESS";
export const USERS_DDL_TYPE_FAILURE = "USERS_DDL_TYPE_FAILURE";
export const USERS_DDL_TYPE_RESET = "USERS_DDL_TYPE_RESET";

export const USER_Import_REQUEST = "USER_Import_REQUEST";
export const USER_Import_SUCCESS = "USER_Import_SUCCESS";
export const USER_Import_FAILURE = "USER_Import_FAILURE";

export const USER_Export_REQUEST = "USER_Export_REQUEST";
export const USER_Export_SUCCESS = "USER_Export_SUCCESS";
export const USER_Export_FAILURE = "USER_Export_FAILURE";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";

import SignIn from "../SignIn";
import ForgotPassword from "../ForgetPassword";
import RecoveryPassword from "../RecoveryPassword";

import {
	LAYOUT_TYPE_BOXED,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_FULL,
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import SendOTP from "./SendOTP";
import {
	setDirectionRTL,
	switchLanguage,
} from "../../appRedux/SettingsApp/settingsActions";
import languageData from "../Topbar/languageData";
import SignUpDemo from "../SignUpDemo";
import NotFound from "../../components/NotFound";
import CustomizeRenderEmpty from "../../components/CustomizeRenderEmpty";
import AddVisitorsByUsers from "./AddVisitorsByUsers";
import AddEquipmentsByVisitors from "./AddEquipmentsByVisitors";
import AddCarsCredentialByUsers from "./AddCardCredentialByUsers";

const RestrictedRoute = ({
	component: Component,
	location,
	authUser,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			authUser ? (
				<>
					<Component {...props} />
				</>
			) : (
				<Redirect
					to={{
						pathname: "/signin",
						state: { from: location },
					}}
				/>
			)
		}
	/>
);

const setLayoutType = (layoutType) => {
	if (layoutType === LAYOUT_TYPE_FULL) {
		document.body.classList.remove("boxed-layout");
		document.body.classList.remove("framed-layout");
		document.body.classList.add("full-layout");
	} else if (layoutType === LAYOUT_TYPE_BOXED) {
		document.body.classList.remove("full-layout");
		document.body.classList.remove("framed-layout");
		document.body.classList.add("boxed-layout");
	} else if (layoutType === LAYOUT_TYPE_FRAMED) {
		document.body.classList.remove("boxed-layout");
		document.body.classList.remove("full-layout");
		document.body.classList.add("framed-layout");
	}
};

const setNavStyle = (navStyle) => {
	if (
		navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
		navStyle === NAV_STYLE_DARK_HORIZONTAL ||
		navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
		navStyle === NAV_STYLE_ABOVE_HEADER ||
		navStyle === NAV_STYLE_BELOW_HEADER
	) {
		document.body.classList.add("full-scroll");
		document.body.classList.add("horizontal-layout");
	} else {
		document.body.classList.remove("full-scroll");
		document.body.classList.remove("horizontal-layout");
	}
};

const App = (props) => {
	const dispatch = useDispatch();
	const { match, location } = props;
	const locale = useSelector(({ settings }) => settings.locale);
	const navStyle = useSelector(({ settings }) => settings.navStyle);
	const layoutType = useSelector(({ settings }) => settings.layoutType);
	const themeType = useSelector(({ settings }) => settings.themeType);
	const isDirectionRTL = useSelector(
		({ settings }) => settings.isDirectionRTL
	);
	useEffect(() => {
		if (isDirectionRTL) {
			document.documentElement.classList.add("rtl");
			document.documentElement.setAttribute("data-direction", "rtl");
		} else {
			document.documentElement.classList.remove("rtl");
			document.documentElement.setAttribute("data-direction", "ltr");
		}
	}, [isDirectionRTL]);

	useEffect(() => {
		if (locale) document.documentElement.lang = locale.locale;
	}, [locale]);

	useEffect(() => {
		if (themeType === THEME_TYPE_DARK) {
			document.body.classList.add("dark-theme");
		} else if (document.body.classList.contains("dark-theme")) {
			document.body.classList.remove("dark-theme");
		}
	}, [themeType]);

	useEffect(() => {
		setLayoutType(layoutType);
		setNavStyle(navStyle);
	}, [layoutType, navStyle]);

	useEffect(() => {
		if (localStorage.getItem("locale")) {
			if (localStorage.getItem("locale") === "en") {
				dispatch(switchLanguage(languageData[0]));
				dispatch(setDirectionRTL(false));
			}
			if (localStorage.getItem("locale") === "ar") {
				dispatch(switchLanguage(languageData[1]));
				dispatch(setDirectionRTL(true));
			}
		} else {
			localStorage.setItem("locale", "en");
			dispatch(switchLanguage(languageData[0]));
			dispatch(setDirectionRTL(false));
		}
	}, [dispatch]);

	if (location.pathname === "/") {
		return <Redirect to={"/dashboard"} />;
	}

	const authUser = localStorage.getItem("loginToken") ? true : false;
	const currentAppLocale = AppLocale[locale.locale];

	return (
		<ConfigProvider
			locale={currentAppLocale.antd}
			direction={isDirectionRTL ? "rtl" : "ltr"}
			renderEmpty={CustomizeRenderEmpty}
		>
			<IntlProvider
				locale={currentAppLocale.locale}
				messages={currentAppLocale.messages}
			>
				<Switch>
					<Route exact path="/signin" component={SignIn} />
					<Route exact path="/signup" component={SignUpDemo} />
					<Route
						exact
						path="/add-visitors/:token"
						component={AddVisitorsByUsers}
					/>
					<Route
						exact
						path="/add-equipments/:token"
						component={AddEquipmentsByVisitors}
					/>
					<Route
						exact
						path="/add-card/:tenantId/:token"
						component={AddCarsCredentialByUsers}
					/>

					<Route
						exact
						path="/forgot-password"
						component={ForgotPassword}
					/>
					<Route
						exact
						path="/recovery-password/:id"
						component={RecoveryPassword}
					/>
					<Route exact path="/sendOTP/:id" component={SendOTP} />

					<RestrictedRoute
						path={`${match.url}`}
						authUser={authUser}
						location={location}
						component={MainApp}
					/>
					<Route path="*" component={NotFound} />
				</Switch>
			</IntlProvider>
		</ConfigProvider>
	);
};

export default App;

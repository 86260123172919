import {
	DDL_Messages_FAILURE,
	DDL_Messages_REQUEST,
	DDL_Messages_RESET,
	DDL_Messages_SUCCESS,
	Messages_TYPE_FAILURE,
	Messages_TYPE_REQUEST,
	Messages_TYPE_SUCCESS,
	Messages_UPDATE_FAILURE,
	Messages_UPDATE_REQUEST,
	Messages_UPDATE_RESET,
	Messages_UPDATE_SUCCESS,
} from "./MessagesConstant";

export const MessagesReducer = (
	initialState = {
		Messages: [],
		isHasMethodMessages: false,
	},
	action
) => {
	switch (action.type) {
		case Messages_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllMessages: [],
			};
		case Messages_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				loading: false,

			};

		case Messages_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//Messages Update
		case Messages_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case Messages_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case Messages_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case Messages_UPDATE_RESET:
			return { ...initialState,Messages: {} };

		//DDL Messages
		case DDL_Messages_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case DDL_Messages_SUCCESS:
			return {
				...initialState,
				methodMessages: action.payload,
				loading: false,
				isHasMethodMessages: action.payload.length > 0 ? true : false,
			};
		case DDL_Messages_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case DDL_Messages_RESET:
			return {
				...initialState,isHasMethodMessages: true,
			};
		default:
			return initialState;
	}
};

export const Role_TYPE_REQUEST = "Role_TYPE_REQUEST";
export const Role_TYPE_SUCCESS = "Role_TYPE_SUCCESS";
export const Role_TYPE_FAILURE = "Role_TYPE_FAILURE";

export const OperatorRoles_DDL_REQUEST =
	"OperatorRoles_DDL_REQUEST";
export const OperatorRoles_DDL_SUCCESS =
	"OperatorRoles_DDL_SUCCESS";
export const OperatorRoles_DDL_FAILURE =
	"OperatorRoles_DDL_FAILURE";
export const OperatorRoles_DDL_RESET =
	"OperatorRoles_DDL_RESET";

export const Role_ADD_REQUEST = "Role_ADD_REQUEST";
export const Role_ADD_SUCCESS = "Role_ADD_SUCCESS";
export const Role_ADD_FAILURE = "Role_ADD_FAILURE";

export const Role_DELETE_REQUEST = "Role_DELETE_REQUEST";
export const Role_DELETE_SUCCESS = "Role_DELETE_SUCCESS";
export const Role_DELETE_FAILURE = "Role_DELETE_FAILURE";

export const Role_UPDATE_REQUEST = "Role_UPDATE_REQUEST";
export const Role_UPDATE_SUCCESS = "Role_UPDATE_SUCCESS";
export const Role_UPDATE_FAILURE = "Role_UPDATE_FAILURE";
export const Role_UPDATE_RESET = "Role_UPDATE_RESET";

export const Role_DETAILS_REQUEST = "Role_DETAILS_REQUEST";
export const Role_DETAILS_SUCCESS = "Role_DETAILS_SUCCESS";
export const Role_DETAILS_FAILURE = "Role_DETAILS_FAILURE";
export const Role_DETAILS_RESET = "Role_DETAILS_RESET";


export const PERMISSION_USER_REQUEST = "PERMISSION_USER_REQUEST";
export const PERMISSION_USER_SUCCESS = "PERMISSION_USER_SUCCESS";
export const PERMISSION_USER_FAILURE = "PERMISSION_USER_FAILURE";

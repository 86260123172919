import { Modal } from "antd";
import "./style.css";

const DefaultModalComponent = (props) => {
	return (
		<Modal
			okText={props.okText}
			className={`app-modal-item ${props.className}`}
			title={`${props.title}`}
			closeIcon={
				<img
					src={`/assets/images/icons/modal-close.svg`}
					alt={`powerlook-icon`}
				/>
			}
			style={props.style}
			width={props.width}
			open={props.open}
			cancelButtonProps={{
				style: {
					display: "none",
				},
			}}
			onCancel={props.onCancel}
			{...props}
		>
			{props.children}
		</Modal>
	);
};

export default DefaultModalComponent;

import {
	Add_Incident_Attachments_FAILURE,
	Add_Incident_Attachments_REQUEST,
	Add_Incident_Attachments_SUCCESS,
	Delete_Incident_Attachments_FAILURE,
	Delete_Incident_Attachments_REQUEST,
	Delete_Incident_Attachments_SUCCESS,
	GET_Incident_Attachments_FAILURE,
	GET_Incident_Attachments_REQUEST,
	GET_Incident_Attachments_SUCCESS,
} from "./IncidentAttachmentsConstantType";

export const IncidentAttachmentsReducer = (
	initialState = {
		Attachments: [],
	},
	action
) => {
	switch (action.type) {
		case GET_Incident_Attachments_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_Incident_Attachments_SUCCESS:
			return {
				...initialState,
				Attachments: action.payload.result.data,
				loading: false,
			};
		case GET_Incident_Attachments_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		case Add_Incident_Attachments_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Add_Incident_Attachments_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Add_Incident_Attachments_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		case Delete_Incident_Attachments_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Delete_Incident_Attachments_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Delete_Incident_Attachments_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

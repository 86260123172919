import { Select } from "antd";
const { Option } = Select;
const SelectboxComponent = (props) => {
	const optionSelected = props.optionselect;
	const { nameEn, ...rest } = props;
	return (
		<div style={{ width: "100%" }}>
			<Select
				showSearch
				filterOption={(input, option) =>
					option.children
						?.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}
				{...rest}
				className={`app-selectbox-item app-form-item ${props.styleName}`}
				defaultValue={props.defaultValue}
				style={{
					width: props.width ? props.width : 170,
				}}
				disabled={props.disabled}
				getPopupContainer={(trigger) => trigger.parentNode}
				type={props.type}
				placeholder={props.placeholder}
			>
				{optionSelected.length !== 0 &&
					optionSelected.map((item, index) => (
						<Option key={index} value={item.id}>
							{props.nameEn ? item.nameEn : item.type}
						</Option>
					))}
			</Select>
		</div>
	);
};

export default SelectboxComponent;

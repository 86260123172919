export const CARD_FORMATS_REQUEST = "CARD_FORMATS_REQUEST";
export const CARD_FORMATS_SUCCESS = "CARD_FORMATS_SUCCESS";
export const CARD_FORMATS_FAILURE = "CARD_FORMATS_FAILURE";

export const DDLCardFormat_REQUEST = "DDLCardFormat_REQUEST";
export const DDLCardFormat_SUCCESS = "DDLCardFormat_SUCCESS";
export const DDLCardFormat_FAILURE = "DDLCardFormat_FAILURE";
export const DDLCardFormat_RESET = "DDLCardFormat_RESET";

export const UPDATE_CARD_FORMATS_REQUEST = "UPDATE_CARD_FORMATS_REQUEST";
export const UPDATE_CARD_FORMATS_SUCCESS = "UPDATE_CARD_FORMATS_SUCCESS";
export const UPDATE_CARD_FORMATS_FAILURE = "UPDATE_CARD_FORMATS_FAILURE";

import React, { useState } from "react";
import { Avatar, Popover } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
const userData = JSON.parse(localStorage.getItem("userData"));

let userImage = null;
const defaultAvatarUrl = "/assets/images/avatar_icon.png";

if (userData !== null) {
	const { profilePicture } = userData;

	userImage = JSON.parse(localStorage.getItem("userImage"))
		? JSON.parse(localStorage.getItem("userImage"))
		: profilePicture;
}
const UserInfo = () => {
	const {
		locale: { locale },
	} = useSelector(({ settings }) => settings);
	const [open, setOpen] = useState(false);
	const hide = () => {
		setOpen(false);
	};
	const handleOpenChange = (newOpen) => {
		setOpen(newOpen);
	};

	const { userId } = userData;
	const userMenuOptions = (
		<ul className="gx-user-popover">
			<li>
				<Link to={`/profile/${userId}`} onClick={() => hide()}>
					<IntlMessages id="label.profile" />
				</Link>
			</li>
			<li>
				<Link
					to={"/signin"}
					onClick={() => {
						localStorage.clear();
						localStorage.setItem("locale", locale);
					}}
					style={{
						color: "red",
					}}
				>
					<IntlMessages id="button.logout" />
				</Link>
			</li>
		</ul>
	);

	return (
		<Popover
			overlayClassName="gx-popover-horizantal"
			placement="bottomRight"
			content={userMenuOptions}
			trigger="click"
			open={open}
			onOpenChange={handleOpenChange}
		>
			<Avatar
				src={userImage === null ? defaultAvatarUrl : userImage}
				className="gx-avatar gx-pointer"
				alt=""
			/>
		</Popover>
	);
};

export default UserInfo;

import {
	ADD_COLUMN_FAILURE,
	ADD_COLUMN_REQUEST,
	ADD_COLUMN_SUCCESS,
	COLUMNS_DDL_FAILURE,
	COLUMNS_DDL_REQUEST,
	COLUMNS_DDL_RESET,
	COLUMNS_DDL_SUCCESS,
	COLUMNS_FAILURE,
	COLUMNS_REQUEST,
	COLUMNS_SUCCESS,
	DELETE_COLUMN_FAILURE,
	DELETE_COLUMN_REQUEST,
	DELETE_COLUMN_SUCCESS,
} from "./columnsTypesConstants";

export const columnReducer = (
	initialState = {
		columns: [],
		isHasColumns: false,
	},
	action
) => {
	switch (action.type) {
		//Get Columns
		case COLUMNS_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case COLUMNS_SUCCESS:
			return {
				...initialState,
				loading: false,
			};

		case COLUMNS_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//getDDLColumns
		case COLUMNS_DDL_REQUEST:
			return {
				...initialState,
				ddlLoading: true,
			};
		case COLUMNS_DDL_SUCCESS:
			return {
				...initialState,
				columns: action.payload.result.data,
				isHasColumns:
					action.payload.result.data.length > 0 ? true : false,
				ddLoading: false,
			};

		case COLUMNS_DDL_FAILURE:
			return {
				...initialState,
				ddLoading: false,
				getErrorDDL: action.payload,
			};
		case COLUMNS_DDL_RESET:
			return {
				...initialState,
				ddLoading: false,
				isHasColumns:false
			};
		// Add COLUMN
		case ADD_COLUMN_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_COLUMN_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				column: action.payload.column,
			};
		case ADD_COLUMN_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		// Delete COLUMN
		case DELETE_COLUMN_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_COLUMN_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_COLUMN_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		default:
			return initialState;
	}
};

import {
	DeviceSettings_UPDATE_REQUEST,
	DeviceSettings_UPDATE_SUCCESS,
	DeviceSettings_UPDATE_FAILURE,
	DeviceSettings_UPDATE_RESET,
	DeviceSettings_TYPE_REQUEST,
	DeviceSettings_TYPE_SUCCESS,
	DeviceSettings_TYPE_FAILURE,
} from "./deviceSettingsConstant";

export const DeviceSettingsReducer = (
	initialState = {
		DeviceSettings: [],
	},
	action
) => {
	switch (action.type) {
		case DeviceSettings_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				DeviceSettings: [],
			};
		case DeviceSettings_TYPE_SUCCESS:
			return {
				...initialState,
				DeviceSettings: action.payload,
				loading: false,
			};

		case DeviceSettings_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//DeviceSettings Update
		case DeviceSettings_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case DeviceSettings_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case DeviceSettings_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case DeviceSettings_UPDATE_RESET:
			return { ...initialState, DeviceSettings: {} };
		default:
			return initialState;
	}
};

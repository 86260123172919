import React from "react";
import { Button } from "antd";
import IntlMessages from "../../../util/IntlMessages";

const assetUrl = "/assets/images";

const Updated = ({ processCommands, number, loading }) => {
	console.log(number);
	return (
		<>
			{number > 0 ? (
				<Button
					className="button-card-add"
					loading={loading}
					style={{
						marginTop: "5px",
						padding: "23px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					onClick={processCommands}
					icon={
						<img
							src={`${assetUrl}/icons/rotate-right.svg`}
							alt="powerlook-icon"
							className={"img-card"}
						/>
					}
				>
					<IntlMessages id="label.Updated" /> {number ? number : ""}
				</Button>
			) : null}
		</>
	);
};

export default Updated;

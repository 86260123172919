import {
	Devices_TYPE_REQUEST,
	Devices_TYPE_SUCCESS,
	Devices_TYPE_FAILURE,
	Devices_ADD_FAILURE,
	Devices_ADD_SUCCESS,
	Devices_ADD_REQUEST,
	Devices_DELETE_REQUEST,
	Devices_DELETE_SUCCESS,
	Devices_DELETE_FAILURE,
	Devices_DETAILS_REQUEST,
	Devices_DETAILS_SUCCESS,
	Devices_DETAILS_FAILURE,
	Devices_DETAILS_RESET,
	Devices_UPDATE_REQUEST,
	Devices_UPDATE_SUCCESS,
	Devices_UPDATE_FAILURE,
	Devices_UPDATE_RESET,
	DEVICES_DDL_REQUEST,
	DEVICES_DDL_SUCCESS,
	DEVICES_DDL_FAILURE,
	DEVICES_DDL_RESET,
	DEVICE_MODULE_REQUEST,
	DEVICE_MODULE_FAILURE,
	DEVICE_MODULE_SUCCESS,
	Devices_Import_REQUEST,
	Devices_Import_SUCCESS,
	Devices_Import_FAILURE,
	Devices_Export_REQUEST,
	Devices_Export_SUCCESS,
	Devices_Export_FAILURE,
} from "./devicesTypesConstsnts";

export const DevicesReducer = (
	initialState = {
		devices: [],
		isHasDevices: false,
	},
	action
) => {
	switch (action.type) {
		case Devices_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllDevices: [],
			};
		case Devices_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload.result,
				getAllDevices: action.payload.result.data,
				loading: false,
			};

		case Devices_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case DEVICES_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				devices: [],
			};
		case DEVICES_DDL_SUCCESS:
			return {
				...initialState,
				devices: action.payload.result.data,
				loading: false,
				isHasDevices: action.payload.result.data?.length ? true : false,
			};

		case DEVICES_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLDevicesError: action.payload,
			};
		case DEVICES_DDL_RESET:
			return {
				...initialState,
				isHasDevices: false,
			};
		//import
		case Devices_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Devices_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Devices_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};

		//Export
		case Devices_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Devices_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Devices_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		//add Devices
		case Devices_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Devices_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Devices_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Devices

		case Devices_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Devices_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Devices_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//Devices detailes & edit
export const DevicesDetailsReducer = (state = { device: {} }, action) => {
	switch (action.type) {
		case Devices_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Devices_DETAILS_SUCCESS:
			return { ...state, detailsLoading: false, device: action.payload };
		case Devices_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Devices_DETAILS_RESET:
			return { ...state, Devices: {} };

		//Devices Update
		case Devices_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Devices_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Devices_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Devices_UPDATE_RESET:
			return { ...state, Devices: {} };

		case DEVICE_MODULE_REQUEST:
			return { ...state, loading: true };
		case DEVICE_MODULE_SUCCESS:
			return {
				...state,
				loading: false,
				data: action.payload,
			};
		case DEVICE_MODULE_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		default:
			return state;
	}
};

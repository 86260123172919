import {
	ADD_ACCESS_POINT_FAILURE,
	ADD_ACCESS_POINT_REQUEST,
	ADD_ACCESS_POINT_SUCCESS,
	ADD_MAP_FAILURE,
	ADD_MAP_REQUEST,
	ADD_MAP_SUCCESS,
	BACKGROUND_UPDATE_FAILURE,
	BACKGROUND_UPDATE_REQUEST,
	BACKGROUND_UPDATE_SUCCESS,
	DELETE_ACCESS_POINT_FAILURE,
	DELETE_ACCESS_POINT_REQUEST,
	DELETE_ACCESS_POINT_SUCCESS,
	DELETE_MAP_FAILURE,
	DELETE_MAP_REQUEST,
	DELETE_MAP_SUCCESS,
	GET_MAPS_FAILURE,
	GET_MAPS_REQUEST,
	GET_MAPS_SUCCESS,
	MAP_DETAILS_FAILURE,
	MAP_DETAILS_REQUEST,
	MAP_DETAILS_RESET,
	MAP_DETAILS_SUCCESS,
	MAP_UPDATE_FAILURE,
	MAP_UPDATE_REQUEST,
	MAP_UPDATE_SUCCESS,
} from "./mapsTypesConstants";

export const mapsReducer = (initialState = { maps: [] }, action) => {
	switch (action.type) {
		case GET_MAPS_REQUEST:
			return {
				...initialState,
				loading: true,
				maps: [],
			};
		case GET_MAPS_SUCCESS:
			return {
				...initialState,
				maps: action.payload.result,
				loading: false,
			};
		case GET_MAPS_FAILURE:
			return {
				...initialState,
				getError: action.payload,
				loading: false,
			};

		case DELETE_MAP_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_MAP_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
				deleteSuccess: true,
			};
		case DELETE_MAP_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		case ADD_MAP_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_MAP_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case ADD_MAP_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		case MAP_DETAILS_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
			};
		case MAP_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				map: action.payload,
			};
		case MAP_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case MAP_DETAILS_RESET:
			return {
				...initialState,
				map: {},
			};
		case MAP_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case MAP_UPDATE_SUCCESS:
			return { ...initialState, updateLoading: false };
		case MAP_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case BACKGROUND_UPDATE_REQUEST:
			return { ...initialState, updateBackGroundLoading: true };
		case BACKGROUND_UPDATE_SUCCESS:
			return { ...initialState, updateBackGroundLoading: false };
		case BACKGROUND_UPDATE_FAILURE:
			return {
				...initialState,
				updateBackGroundLoading: false,
				updateBackGroundError: action.payload,
			};
		case ADD_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				addAccessPointLoading: true,
			};
		case ADD_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				addAccessPointLoading: false,
				data: action.payload,
			};
		case ADD_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				addAccessPointLoading: false,
				addAccessPointError: action.payload,
			};
		case DELETE_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				deleteAccessPointLoading: true,
			};
		case DELETE_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				deleteAccessPointLoading: false,
			};
		case DELETE_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				deleteAccessPoint: false,
				deleteAccessPointError: action.payload,
			};

		default:
			return initialState;
	}
};

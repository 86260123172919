import {
	GET_ACCESS_POINT_REQUEST,
	GET_ACCESS_POINT_SUCCESS,
	GET_ACCESS_POINT_FAILURE,
	DELETE_ACCESS_POINT_REQUEST,
	DELETE_ACCESS_POINT_SUCCESS,
	DELETE_ACCESS_POINT_FAILURE,
	ADD_ACCESS_POINT_REQUEST,
	ADD_ACCESS_POINT_SUCCESS,
	ADD_ACCESS_POINT_FAILURE,
	GET_ACCESS_POINT_DETAILS_REQUEST,
	GET_ACCESS_POINT_DETAILS_SUCCESS,
	GET_ACCESS_POINT_DETAILS_FAILURE,
	GET_ACCESS_POINT_DETAILS_RESET,
	CONFIGURE_ACCESS_POINT_REQUEST,
	CONFIGURE_ACCESS_POINT_SUCCESS,
	CONFIGURE_ACCESS_POINT_FAILURE,
	ACCESS_POINT_UPDATE_REQUEST,
	ACCESS_POINT_UPDATE_SUCCESS,
	ACCESS_POINT_UPDATE_FAILURE,
	ACCESS_POINTS_DDL_REQUEST,
	ACCESS_POINTS_DDL_SUCCESS,
	ACCESS_POINTS_DDL_FAILURE,
	ACCESS_POINTS_DDL_RESET,
	LAST_NUMBER_ACCESS_POINT_REQUEST,
	LAST_NUMBER_ACCESS_POINT_SUCCESS,
	LAST_NUMBER_ACCESS_POINT_FAILURE,
	AccessPoint_Import_REQUEST,
	AccessPoint_Import_SUCCESS,
	AccessPoint_Import_FAILURE,
	AccessPoint_Export_REQUEST,
	AccessPoint_Export_SUCCESS,
	AccessPoint_Export_FAILURE,
} from "./AccessPointTypeConstants";

export const accessPointReducer = (
	initialState = { accessPoints: [], isHasAccessPoints: false },
	action
) => {
	switch (action.type) {
		case GET_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				getAccessPoints: action.payload.result.data,
				loading: false,
			};

		case GET_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//import
		case AccessPoint_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case AccessPoint_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case AccessPoint_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		case ACCESS_POINTS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				accessPoints: [],
			};
		//Export
		case AccessPoint_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case AccessPoint_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case AccessPoint_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		case ACCESS_POINTS_DDL_SUCCESS:
			return {
				...initialState,
				accessPoints: action.payload.result.data,
				loading: false,
				isHasAccessPoints:
					action.payload.result.data?.length > 0 ? true : false,
			};

		case ACCESS_POINTS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case ACCESS_POINTS_DDL_RESET:
			return {
				...initialState,
				isHasAccessPoints: false,
			};

		case DELETE_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		case ADD_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_ACCESS_POINT_SUCCESS: {
			return {
				...initialState,
				addLoading: false,
			};
		}
		case ADD_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		case GET_ACCESS_POINT_DETAILS_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
			};
		case GET_ACCESS_POINT_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				accessPointDetails: action.payload,
			};
		case GET_ACCESS_POINT_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case GET_ACCESS_POINT_DETAILS_RESET:
			return {
				...initialState,
				accessPointDetails: {},
			};

		case CONFIGURE_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				configureLoading: true,
			};
		case CONFIGURE_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				configureLoading: false,
			};
		case CONFIGURE_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				configureLoading: false,
				error: action.payload,
			};
		case ACCESS_POINT_UPDATE_REQUEST:
			return {
				...initialState,
				updateLoading: true,
			};
		case ACCESS_POINT_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
			};
		case ACCESS_POINT_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		default:
			return initialState;
	}
};

export const accessPointLastNumberCardReducer = (
	initialState = { cardNumber: [] },
	action
) => {
	switch (action.type) {
		case LAST_NUMBER_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case LAST_NUMBER_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				cardNumber: action.payload.result?.data,
				loading: false,
			};

		case LAST_NUMBER_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		default:
			return initialState;
	}
};

export const Tasks_TYPE_REQUEST = "Tasks_TYPE_REQUEST";
export const Tasks_TYPE_SUCCESS = "Tasks_TYPE_SUCCESS";
export const Tasks_TYPE_FAILURE = "Tasks_TYPE_FAILURE";

export const Tasks_ADD_REQUEST = "Tasks_ADD_REQUEST";
export const Tasks_ADD_SUCCESS = "Tasks_ADD_SUCCESS";
export const Tasks_ADD_FAILURE = "Tasks_ADD_FAILURE";

export const Tasks_DELETE_REQUEST = "Tasks_DELETE_REQUEST";
export const Tasks_DELETE_SUCCESS = "Tasks_DELETE_SUCCESS";
export const Tasks_DELETE_FAILURE = "Tasks_DELETE_FAILURE";

export const Tasks_UPDATE_REQUEST = "Tasks_UPDATE_REQUEST";
export const Tasks_UPDATE_SUCCESS = "Tasks_UPDATE_SUCCESS";
export const Tasks_UPDATE_FAILURE = "Tasks_UPDATE_FAILURE";
export const Tasks_UPDATE_RESET = "Tasks_UPDATE_RESET";

export const Tasks_DETAILS_REQUEST = "Tasks_DETAILS_REQUEST";
export const Tasks_DETAILS_SUCCESS = "Tasks_DETAILS_SUCCESS";
export const Tasks_DETAILS_FAILURE = "Tasks_DETAILS_FAILURE";
export const Tasks_DETAILS_RESET = "Tasks_DETAILS_RESET";

export const TaskAttachment_TYPE_REQUEST = "TaskAttachment_TYPE_REQUEST";
export const TaskAttachment_TYPE_SUCCESS = "TaskAttachment_TYPE_SUCCESS";
export const TaskAttachment_TYPE_FAILURE = "TaskAttachment_TYPE_FAILURE";

export const TaskAttachment_DELETE_REQUEST = "TaskAttachment_DELETE_REQUEST";
export const TaskAttachment_DELETE_SUCCESS = "TaskAttachment_DELETE_SUCCESS";
export const TaskAttachment_DELETE_FAILURE = "TaskAttachment_DELETE_FAILURE";

export const TaskAttachment_ADD_REQUEST = "TaskAttachment_ADD_REQUEST";
export const TaskAttachment_ADD_SUCCESS = "TaskAttachment_ADD_SUCCESS";
export const TaskAttachment_ADD_FAILURE = "TaskAttachment_ADD_FAILURE";

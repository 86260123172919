import IntlMessages from "./IntlMessages";

export const patternNameAr = {
	pattern: /^[\u0600-\u06FF0-9_()-]+( [\u0600-\u06FF0-9_()-]+){0,6}$/,
	message: <IntlMessages id="error.nameAr.letter" />,
};

export const patternNameEn = [
	{
		required: true,
		message: <IntlMessages id="error.nameEn" />,
	},
	{
		pattern: /^[a-zA-Z0-9_()-]+( [a-zA-Z0-9_()-]+){0,6}$/,
		message: <IntlMessages id="error.nameEn.letter" />,
	},
];
export const unRequiredPatternNameEn = [
	{
		pattern: /^[a-zA-Z0-9_()-]+( [a-zA-Z0-9_()-]+){0,6}$/,
		message: <IntlMessages id="error.nameEn.letter" />,
	},
];
export const patternArRequired = [
	{
		required: true,
		message: <IntlMessages id="error.nameAr" />,
	},
	{
		pattern: /^[\u0600-\u06FF0-9_()-]+( [\u0600-\u06FF0-9_()-]+){0,6}$/,
		message: <IntlMessages id="error.nameAr.letter" />,
	},
];

import {
	POSITIONS_USER_REQUEST,
	POSITIONS_USER_SUCCESS,
	POSITIONS_USER_FAILURE,
	ADD_POSITION_REQUEST,
	ADD_POSITION_SUCCESS,
	ADD_POSITION_FAILURE,
	POSITION_DETAILS_REQUEST,
	POSITION_DETAILS_SUCCESS,
	POSITION_DETAILS_FAILURE,
	POSITION_DETAILS_RESET,
	POSITION_UPDATE_REQUEST,
	POSITION_UPDATE_SUCCESS,
	POSITION_UPDATE_FAILURE,
	POSITION_UPDATE_RESET,
	POSITION_DELETE_REQUEST,
	POSITION_DELETE_SUCCESS,
	POSITION_DELETE_FAILURE,
	Positions_DDL_REQUEST,
	Positions_DDL_SUCCESS,
	Positions_DDL_FAILURE,
	Positions_DDL_RESET,
	Position_Import_REQUEST,
	Position_Import_SUCCESS,
	Position_Import_FAILURE,
	Position_Export_REQUEST,
	Position_Export_SUCCESS,
	Position_Export_FAILURE,
} from "./positionsTypesContants";

export const positionsReducer = (
	initialState = {
		positions: [],
		isHasPositions: false,
	},
	action
) => {
	switch (action.type) {
		case POSITIONS_USER_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllPositions: [],
			};
		case POSITIONS_USER_SUCCESS:
			return {
				...initialState,
				getAllPositions: action.payload.result.data,
				loading: false,
			};

		case POSITIONS_USER_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//import
		case Position_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Position_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Position_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};

		//Export
		case Position_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Position_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Position_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
			//ddl
		case Positions_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				positions: [],
			};
		case Positions_DDL_SUCCESS:
			return {
				...initialState,
				positions: action.payload.result.data,
				loading: false,
				isHasPositions: action.payload.result.data?.length
					? true
					: false,
			};

		case Positions_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLPositionsError: action.payload,
			};
		case Positions_DDL_RESET:
			return {
				...initialState,
				isHasPositions: false,
			};
//add
		case ADD_POSITION_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_POSITION_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				position: action.payload.position,
			};
		case ADD_POSITION_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		// Position Details
		case POSITION_DETAILS_REQUEST:
			return { ...initialState, detailsLoading: true };
		case POSITION_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				position: action.payload,
			};
		case POSITION_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case POSITION_DETAILS_RESET:
			return { ...initialState, position: {} };

		// Update Position
		case POSITION_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case POSITION_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case POSITION_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case POSITION_UPDATE_RESET:
			return { ...initialState, position: {} };

		// Delete Position

		case POSITION_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case POSITION_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case POSITION_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

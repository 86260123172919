import {
	GET_RULES_REQUEST,
	GET_RULES_SUCCESS,
	GET_RULES_FAILURE,
	DELETE_RULES_REQUEST,
	DELETE_RULES_SUCCESS,
	DELETE_RULES_FAILURE,
	ADD_RULES_REQUEST,
	ADD_RULES_SUCCESS,
	ADD_RULES_FAILURE,
	EDIT_RULES_REQUEST,
	EDIT_RULES_SUCCESS,
	EDIT_RULES_FAILURE,
	EDIT_RULES_Reset,
	AddUser_RULES_REQUEST,
	AddUser_RULES_SUCCESS,
	AddUser_RULES_FAILURE,
	GetUser_RULES_REQUEST,
	GetUser_RULES_SUCCESS,
	GetUser_RULES_FAILURE,
	GetUser_RULES_Reset,
	DeleteUser_RULES_REQUEST,
	DeleteUser_RULES_SUCCESS,
	DeleteUser_RULES_FAILURE,
	GetUserGroup_RULES_REQUEST,
	GetUserGroup_RULES_SUCCESS,
	GetUserGroup_RULES_FAILURE,
	GetUserGroup_RULES_Reset,
	AddUserGroup_RULES_REQUEST,
	AddUserGroup_RULES_SUCCESS,
	AddUserGroup_RULES_FAILURE,
	DeleteUserGroup_RULES_REQUEST,
	DeleteUserGroup_RULES_SUCCESS,
	DeleteUserGroup_RULES_FAILURE,
	GetAccessPoints_RULES_REQUEST,
	GetAccessPoints_RULES_SUCCESS,
	GetAccessPoints_RULES_FAILURE,
	GetAccessPoints_RULES_Reset,
	AddAccessPoints_RULES_REQUEST,
	AddAccessPoints_RULES_SUCCESS,
	AddAccessPoints_RULES_FAILURE,
	DeleteAccessPoints_RULES_REQUEST,
	DeleteAccessPoints_RULES_SUCCESS,
	DeleteAccessPoints_RULES_FAILURE,
	DeleteAccessPointGroups_RULES_FAILURE,
	DeleteAccessPointGroups_RULES_SUCCESS,
	DeleteAccessPointGroups_RULES_REQUEST,
	AddAccessPointGroups_RULES_FAILURE,
	AddAccessPointGroups_RULES_SUCCESS,
	AddAccessPointGroups_RULES_REQUEST,
	GetAccessPointGroups_RULES_Reset,
	GetAccessPointGroups_RULES_FAILURE,
	GetAccessPointGroups_RULES_SUCCESS,
	GetAccessPointGroups_RULES_REQUEST,
	RULES_DDL_REQUEST,
	RULES_DDL_SUCCESS,
	RULES_DDL_FAILURE,
	RULES_DDL_RESET,
	DETAILS_RULES_REQUEST,
	DETAILS_RULES_SUCCESS,
	DETAILS_RULES_FAILURE,
	DETAILS_RULES_Reset,
	Rule_Import_REQUEST,
	Rule_Import_SUCCESS,
	Rule_Import_FAILURE,
	Rule_Export_REQUEST,
	Rule_Export_SUCCESS,
	Rule_Export_FAILURE,
} from "./RulesTypeContants";

export const rulesReducer = (
	initialState = { rules: [], isHasRules: false },
	action
) => {
	switch (action.type) {
		case GET_RULES_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllRules: [],
			};
		case GET_RULES_SUCCESS:
			return {
				...initialState,
				data: action.payload.result,
				getAllRules: action.payload.result.data,
				loading: false,
			};
		case GET_RULES_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//import
		case Rule_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Rule_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Rule_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		//Export
		case Rule_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Rule_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Rule_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		case RULES_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				rules: [],
			};
		case RULES_DDL_SUCCESS:
			return {
				...initialState,
				rules: action.payload.result.data,
				loading: false,
				isHasRules:
					action.payload.result.data?.length > 0 ? true : false,
			};

		case RULES_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case RULES_DDL_RESET:
			return {
				...initialState,
				isHasRules: false,
			};

		//delete rule
		case DELETE_RULES_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_RULES_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_RULES_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		//add
		case ADD_RULES_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_RULES_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case ADD_RULES_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		default:
			return initialState;
	}
};

export const RuleDetailsReducer = (state = { Rule: {} }, action) => {
	switch (action.type) {
		case DETAILS_RULES_REQUEST:
			return { ...state, detailsLoading: true };
		case DETAILS_RULES_SUCCESS:
			return { ...state, detailsLoading: false, Rule: action.payload };
		case DETAILS_RULES_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case DETAILS_RULES_Reset:
			return { ...state, Rule: {} };
		//Rule Update
		case EDIT_RULES_REQUEST:
			return { ...state, updateLoading: true };
		case EDIT_RULES_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case EDIT_RULES_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case EDIT_RULES_Reset:
			return { ...state, Rule: {} };
		default:
			return state;
	}
};

//user
export const RuleUserReducer = (
	initialState = {
		rule: [],
	},
	action
) => {
	switch (action.type) {
		//get
		case GetUser_RULES_REQUEST:
			return {
				...initialState,
				userRuleLoading: true,
			};
		case GetUser_RULES_SUCCESS:
			return {
				...initialState,
				userRule: action.payload.result.data,
				userRuleLoading: false,
			};
		case GetUser_RULES_FAILURE:
			return {
				...initialState,
				userRuleLoading: false,
				getError: action.payload,
			};
		case GetUser_RULES_Reset:
			return {
				...initialState,
				rule: [],
			};
		//add
		case AddUser_RULES_REQUEST:
			return {
				...initialState,
				addUserLoading: true,
			};
		case AddUser_RULES_SUCCESS:
			return {
				...initialState,
				addUserLoading: false,
			};
		case AddUser_RULES_FAILURE:
			return {
				...initialState,
				addUserLoading: false,
				addError: action.payload,
			};

		//delete
		case DeleteUser_RULES_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DeleteUser_RULES_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DeleteUser_RULES_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//user group
export const RuleUserGroupReducer = (
	initialState = {
		rule: [],
	},
	action
) => {
	switch (action.type) {
		//get
		case GetUserGroup_RULES_REQUEST:
			return {
				...initialState,
				UserGroupRuleLoading: true,
			};
		case GetUserGroup_RULES_SUCCESS:
			return {
				...initialState,
				UserGroupRule: action.payload.result.data,
				UserGroupRuleLoading: false,
			};
		case GetUserGroup_RULES_FAILURE:
			return {
				...initialState,
				UserGroupRuleLoading: false,
				getError: action.payload,
			};
		case GetUserGroup_RULES_Reset:
			return {
				...initialState,
				rule: [],
			};
		//add
		case AddUserGroup_RULES_REQUEST:
			return {
				...initialState,
				UserGroupaddLoading: true,
			};
		case AddUserGroup_RULES_SUCCESS:
			return {
				...initialState,
				UserGroupaddLoading: false,
			};
		case AddUserGroup_RULES_FAILURE:
			return {
				...initialState,
				UserGroupaddLoading: false,
				addError: action.payload,
			};

		//delete
		case DeleteUserGroup_RULES_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DeleteUserGroup_RULES_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DeleteUserGroup_RULES_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//access
export const RuleAccessPointsReducer = (
	initialState = {
		rule: [],
	},
	action
) => {
	switch (action.type) {
		//get
		case GetAccessPoints_RULES_REQUEST:
			return {
				...initialState,
				AccessPointsRuleLoading: true,
			};
		case GetAccessPoints_RULES_SUCCESS:
			return {
				...initialState,
				AccessPointsRule: action.payload.result.data,
				AccessPointsRuleLoading: false,
			};
		case GetAccessPoints_RULES_FAILURE:
			return {
				...initialState,
				AccessPointsRuleLoading: false,
				getError: action.payload,
			};
		case GetAccessPoints_RULES_Reset:
			return {
				...initialState,
				rule: [],
			};
		//add
		case AddAccessPoints_RULES_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case AddAccessPoints_RULES_SUCCESS:
			return {
				...initialState,
				addLoading: false,
			};
		case AddAccessPoints_RULES_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete
		case DeleteAccessPoints_RULES_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DeleteAccessPoints_RULES_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DeleteAccessPoints_RULES_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//ACCESS POINTS

export const RuleAccessPointGroupsReducer = (
	initialState = {
		rule: [],
	},
	action
) => {
	switch (action.type) {
		//get
		case GetAccessPointGroups_RULES_REQUEST:
			return {
				...initialState,
				AccessPointGroupsRuleLoading: true,
			};
		case GetAccessPointGroups_RULES_SUCCESS:
			return {
				...initialState,
				AccessPointGroupsRule: action.payload.result.data,
				AccessPointGroupsRuleLoading: false,
			};
		case GetAccessPointGroups_RULES_FAILURE:
			return {
				...initialState,
				AccessPointGroupsRuleLoading: false,
				getError: action.payload,
			};
		case GetAccessPointGroups_RULES_Reset:
			return {
				...initialState,
				rule: [],
			};
		//add
		case AddAccessPointGroups_RULES_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case AddAccessPointGroups_RULES_SUCCESS:
			return {
				...initialState,
				addLoading: false,
			};
		case AddAccessPointGroups_RULES_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete
		case DeleteAccessPointGroups_RULES_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DeleteAccessPointGroups_RULES_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DeleteAccessPointGroups_RULES_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

export const GET_MAPS_REQUEST = "GET_MAPS_REQUEST";
export const GET_MAPS_SUCCESS = "GET_MAPS_SUCCESS";
export const GET_MAPS_FAILURE = "GET_MAPS_FAILURE";

export const DELETE_MAP_REQUEST = "DELETE_MAP_REQUEST";
export const DELETE_MAP_SUCCESS = "DELETE_MAP_SUCCESS";
export const DELETE_MAP_FAILURE = "DELETE_MAP_FAILURE";

export const ADD_MAP_REQUEST = "ADD_MAP_REQUEST";
export const ADD_MAP_SUCCESS = "ADD_MAP_SUCCESS";
export const ADD_MAP_FAILURE = "ADD_MAP_FAILURE";

export const MAP_DETAILS_REQUEST = "MAP_DETAILS_REQUEST";
export const MAP_DETAILS_SUCCESS = "MAP_DETAILS_SUCCESS";
export const MAP_DETAILS_FAILURE = "MAP_DETAILS_FAILURE";
export const MAP_DETAILS_RESET = "MAP_DETAILS_RESET";

export const MAP_UPDATE_REQUEST = "MAP_UPDATE_REQUEST";
export const MAP_UPDATE_SUCCESS = "MAP_UPDATE_SUCCESS";
export const MAP_UPDATE_FAILURE = "MAP_UPDATE_FAILURE";

export const BACKGROUND_UPDATE_REQUEST = "BACKGROUND_UPDATE_REQUEST";
export const BACKGROUND_UPDATE_SUCCESS = "BACKGROUND_UPDATE_SUCCESS";
export const BACKGROUND_UPDATE_FAILURE = "BACKGROUND_UPDATE_FAILURE";

export const ADD_ACCESS_POINT_REQUEST = "ADD_ACCESS_POINT_REQUEST";
export const ADD_ACCESS_POINT_SUCCESS = "ADD_ACCESS_POINT_SUCCESS";
export const ADD_ACCESS_POINT_FAILURE = "ADD_ACCESS_POINT_FAILURE";

export const DELETE_ACCESS_POINT_REQUEST = "DELETE_ACCESS_POINT_REQUEST";
export const DELETE_ACCESS_POINT_SUCCESS = "DELETE_ACCESS_POINT_SUCCESS";
export const DELETE_ACCESS_POINT_FAILURE = "DELETE_ACCESS_POINT_FAILURE";

export const GET_RULES_REQUEST = "GET_RULES_REQUEST";
export const GET_RULES_SUCCESS = "GET_RULES_SUCCESS";
export const GET_RULES_FAILURE = "GET_RULES_FAILURE";

export const RULES_DDL_REQUEST = "RULES_DDL_REQUEST";
export const RULES_DDL_SUCCESS = "RULES_DDL_SUCCESS";
export const RULES_DDL_FAILURE = "RULES_DDL_FAILURE";
export const RULES_DDL_RESET = "RULES_DDL_RESET";

export const DELETE_RULES_REQUEST = "DELETE_RULES_REQUEST";
export const DELETE_RULES_SUCCESS = "DELETE_RULES_SUCCESS";
export const DELETE_RULES_FAILURE = "DELETE_RULES_FAILURE";

export const ADD_RULES_REQUEST = "ADD_RULES_REQUEST";
export const ADD_RULES_SUCCESS = "ADD_RULES_SUCCESS";
export const ADD_RULES_FAILURE = "ADD_RULES_FAILURE";

export const EDIT_RULES_REQUEST = "EDIT_RULES_REQUEST";
export const EDIT_RULES_SUCCESS = "EDIT_RULES_SUCCESS";
export const EDIT_RULES_FAILURE = "EDIT_RULES_FAILURE";
export const EDIT_RULES_Reset = "EDIT_RULES_Reset";

export const Rule_Import_REQUEST = "Rule_Import_REQUEST";
export const Rule_Import_SUCCESS = "Rule_Import_SUCCESS";
export const Rule_Import_FAILURE = "Rule_Import_FAILURE";

export const Rule_Export_REQUEST = "Rule_Export_REQUEST";
export const Rule_Export_SUCCESS = "Rule_Export_SUCCESS";
export const Rule_Export_FAILURE = "Rule_Export_FAILURE";

export const DETAILS_RULES_REQUEST = "DETAILS_RULES_REQUEST";
export const DETAILS_RULES_SUCCESS = "DETAILS_RULES_SUCCESS";
export const DETAILS_RULES_FAILURE = "DETAILS_RULES_FAILURE";
export const DETAILS_RULES_Reset = "DETAILS_RULES_Reset";

export const AddUser_RULES_REQUEST = "AddUser_RULES_REQUEST";
export const AddUser_RULES_SUCCESS = "AddUser_RULES_SUCCESS";
export const AddUser_RULES_FAILURE = "AddUser_RULES_FAILURE";
export const AddUser_RULES_Reset = "AddUser_RULES_Reset";

export const GetUser_RULES_REQUEST = "GetUser_RULES_REQUEST";
export const GetUser_RULES_SUCCESS = "GetUser_RULES_SUCCESS";
export const GetUser_RULES_FAILURE = "GetUser_RULES_FAILURE";
export const GetUser_RULES_Reset = "GetUser_RULES_Reset";

export const DeleteUser_RULES_REQUEST = "DeleteUser_RULES_REQUEST";
export const DeleteUser_RULES_SUCCESS = "DeleteUser_RULES_SUCCESS";
export const DeleteUser_RULES_FAILURE = "DeleteUser_RULES_FAILURE";
export const DeleteUser_RULES_Reset = "DeleteUser_RULES_Reset";

export const AddUserGroup_RULES_REQUEST = "AddUserGroup_RULES_REQUEST";
export const AddUserGroup_RULES_SUCCESS = "AddUserGroup_RULES_SUCCESS";
export const AddUserGroup_RULES_FAILURE = "AddUserGroup_RULES_FAILURE";
export const AddUserGroup_RULES_Reset = "AddUserGroup_RULES_Reset";

export const GetUserGroup_RULES_REQUEST = "GetUserGroup_RULES_REQUEST";
export const GetUserGroup_RULES_SUCCESS = "GetUserGroup_RULES_SUCCESS";
export const GetUserGroup_RULES_FAILURE = "GetUserGroup_RULES_FAILURE";
export const GetUserGroup_RULES_Reset = "GetUserGroup_RULES_Reset";

export const DeleteUserGroup_RULES_REQUEST = "DeleteUserGroup_RULES_REQUEST";
export const DeleteUserGroup_RULES_SUCCESS = "DeleteUserGroup_RULES_SUCCESS";
export const DeleteUserGroup_RULES_FAILURE = "DeleteUserGroup_RULES_FAILURE";
export const DeleteUserGroup_RULES_Reset = "DeleteUserGroup_RULES_Reset";

export const AddAccessPoints_RULES_REQUEST = "AddAccessPoints_RULES_REQUEST";
export const AddAccessPoints_RULES_SUCCESS = "AddAccessPoints_RULES_SUCCESS";
export const AddAccessPoints_RULES_FAILURE = "AddAccessPoints_RULES_FAILURE";
export const AddAccessPoints_RULES_Reset = "AddAccessPoints_RULES_Reset";

export const GetAccessPoints_RULES_REQUEST = "GetAccessPoints_RULES_REQUEST";
export const GetAccessPoints_RULES_SUCCESS = "GetAccessPoints_RULES_SUCCESS";
export const GetAccessPoints_RULES_FAILURE = "GetAccessPoints_RULES_FAILURE";
export const GetAccessPoints_RULES_Reset = "GetAccessPoints_RULES_Reset";

export const DeleteAccessPoints_RULES_REQUEST =
	"DeleteAccessPoints_RULES_REQUEST";
export const DeleteAccessPoints_RULES_SUCCESS =
	"DeleteAccessPoints_RULES_SUCCESS";
export const DeleteAccessPoints_RULES_FAILURE =
	"DeleteAccessPoints_RULES_FAILURE";
export const DeleteAccessPoints_RULES_Reset = "DeleteAccessPoints_RULES_Reset";

export const AddAccessPointGroups_RULES_REQUEST =
	"AddAccessPointGroups_RULES_REQUEST";
export const AddAccessPointGroups_RULES_SUCCESS =
	"AddAccessPointGroups_RULES_SUCCESS";
export const AddAccessPointGroups_RULES_FAILURE =
	"AddAccessPointGroups_RULES_FAILURE";
export const AddAccessPointGroups_RULES_Reset =
	"AddAccessPointGroups_RULES_Reset";

export const GetAccessPointGroups_RULES_REQUEST =
	"GetAccessPointGroups_RULES_REQUEST";
export const GetAccessPointGroups_RULES_SUCCESS =
	"GetAccessPointGroups_RULES_SUCCESS";
export const GetAccessPointGroups_RULES_FAILURE =
	"GetAccessPointGroups_RULES_FAILURE";
export const GetAccessPointGroups_RULES_Reset =
	"GetAccessPointGroups_RULES_Reset";

export const DeleteAccessPointGroups_RULES_REQUEST =
	"DeleteAccessPointGroups_RULES_REQUEST";
export const DeleteAccessPointGroups_RULES_SUCCESS =
	"DeleteAccessPointGroups_RULES_SUCCESS";
export const DeleteAccessPointGroups_RULES_FAILURE =
	"DeleteAccessPointGroups_RULES_FAILURE";
export const DeleteAccessPointGroups_RULES_Reset =
	"DeleteAccessPointGroups_RULES_Reset";

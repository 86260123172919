import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";

import IntlMessages from "util/IntlMessages";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../appRedux/Auth/loginAction";
import { useIntl } from "react-intl";
import Error from "../components/Message";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const intl = useIntl();
	const [show, setShow] = useState(false);
	const { loading, addError } = useSelector(
		(state) => state.forgotPasswordReducer
	);

	const onFinish = ({ username }) => {
		dispatch(
			forgotPassword({
				username,
			})
		).then((res) => {
			if (res?.payload?.data?.statusCode === 200) {
				// const {
				// 	payload: {
				// 		data: {
				// 			result: {
				// 				data: { loginToken },
				// 			},
				// 		},
				// 	},
				// } = res;
				//message.success(intl.formatMessage({ id: "pass.msg" }), 5);
				//history.push(`/recovery-password/${loginToken}`);
				setShow(true);
			} else {
				const {
					payload: {
						data: { message: messageError },
					},
				} = res;
				message.error(intl.formatMessage({ id: messageError }), 5);
			}
		});
	};

	return (
		<div className="gx-app-login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					{show ? (
						<div className="gx-app-logo-content">
							<div className="gx-app-logo">
								<img
									alt="powerlook-logo"
									src="/assets/images/forgetPassword.PNG"
								/>
							</div>
							<br />
							<br />
							<div className="gx-app-logo-wid">
								<p className="gray-text">
									<IntlMessages id="check.email" />
								</p>
							</div>
							<br />
							<br />
							<Button
								type="primary"
								className="gx-mb-0 gx-w-100"
								htmlType="submit"
								onClick={() => history.push("/signin")}
							>
								<IntlMessages id="app.userAuth.signIn" />
							</Button>
						</div>
					) : (
						<>
							<div className="gx-app-logo-content">
								<div className="gx-app-logo">
									<img
										alt="powerlook-logo"
										src="/assets/images/forgetPassword.PNG"
									/>
								</div>

								<div className="gx-app-logo-wid">
									<p className="gray-text2">
										<IntlMessages id="text.forget" />
									</p>
								</div>
							</div>
							<div className="gx-app-login-content">
								{addError ? (
									<Error
										type="error"
										message={addError?.message}
									/>
								) : (
									<Form
										name="basic"
										labelCol={{
											span: 24,
										}}
										onFinish={onFinish}
										className="gx-signin-form gx-form-row0"
									>
										<Form.Item
											label={
												<IntlMessages id="label.email" />
											}
											name={"username"}
											rules={[
												{
													required: true,
													message: (
														<IntlMessages id="error.email" />
													),
												},
											]}
										>
											<Input />
										</Form.Item>

										<Form.Item>
											<Button
												type="primary"
												className="gx-mb-0 gx-w-100"
												htmlType="submit"
												loading={loading}
											>
												<IntlMessages id="app.userAuth.send" />
											</Button>
										</Form.Item>
										<Form.Item>
											<Link
												to="/signin"
												className="login-color data-login"
											>
												<IntlMessages id="backtologin" />
											</Link>
										</Form.Item>
									</Form>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

const WrappedNormalForgotPasswordForm = ForgotPassword;

export default WrappedNormalForgotPasswordForm;

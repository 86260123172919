/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, Row, Select, Switch, message } from "antd";
import React, { useState } from "react";
import IntlMessages from "../../util/IntlMessages";
import { ScanOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getLastNumberAccessPoint } from "../../appRedux/AccessControl/AccessPoint/AccessPointActions";
import { useIntl } from "react-intl";

const { Option } = Select;

const CardCredentialForm = ({
	form,
	onFinish,
	isFromLink,
	accessPoints,
	ddlCardFormats,
	loading,
}) => {
	const [accessPointId, setAccessPointId] = useState(null);
	const [facilityCode, setFacilityCode] = useState("");
	const dispatch = useDispatch();
	const intl = useIntl();

	const onChange = (value) => {
		const { facilityCode } = ddlCardFormats?.find(
			(format) => format.id === value
		);
		setFacilityCode(facilityCode);
	};

	const onScan = () => {
		dispatch(getLastNumberAccessPoint(accessPointId)).then((res) => {
			if (res) {
				const {payload : {data : { statusCode, message: messageError, result } }}= res;
				if (statusCode === 200) {
					if (result.data) {
						form.setFieldsValue({
							cardNumber: result.data,
						});
					}
				} else {
					message.error(intl.formatMessage({ id: messageError }));
				}
			}
			form.setFieldsValue({
				accessPointId: undefined,
			});
			setAccessPointId(undefined);
		});
	};

	return (
		<Row>
			<Col span={24}>
				<Form
					id="addForm"
					form={form}
					name="basic"
					labelCol={{
						span: 24,
					}}
					onFinish={onFinish}
					autoComplete="off"
				>
					<Row style={{ width: "100%" }} gutter={[15, 10]}>
						{!isFromLink && (
							<Col span={24}>
								<Form.Item
									name="cardFormatId"
									label={<IntlMessages id="label.Format" />}
									rules={[
										{
											required: true,
											message: (
												<IntlMessages id="error.format" />
											),
										},
									]}
									hasFeedback
								>
									<Select
										showSearch
										filterOption={(input, option) =>
											option.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >=
											0
										}
										placeholder={
											<IntlMessages id="placeholder.select.card.format" />
										}
										onChange={onChange}
									>
										{ddlCardFormats?.map((format) => (
											<Select.Option
												key={format.id}
												value={format.id}
											>
												{format.nameEn}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						)}
						<Col span={16} offset={2}>
							<Form.Item
								label={
									<IntlMessages id="access-control.access-point" />
								}
								name="accessPointId"
							>
								<Select
									showSearch
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									onChange={(e) => setAccessPointId(e)}
									placeholder={
										<IntlMessages id="SelectAccessPoint" />
									}
									showArrow
								>
									{accessPoints?.map((access) => (
										<Option
											key={access.id}
											value={access.id}
										>
											{access.nameEn}
										</Option>
									))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item
								label={<IntlMessages id="button.scan" />}
							>
								<Button
									icon={
										<ScanOutlined
											style={{
												fontSize: "16px",
												color: "#08c",
											}}
										/>
									}
									style={{
										width: "100%",
									}}
									disabled={!accessPointId}
									onClick={onScan}
								>
									<IntlMessages id="button.scan" />
								</Button>
							</Form.Item>
						</Col>
						{/* )} */}

						<Col span={24}>
							<Form.Item
								name="cardNumber"
								label={<IntlMessages id="label.Card.Number" />}
								rules={[
									{
										required: true,
										message: (
											<IntlMessages id="error.card.number" />
										),
									},
									{
										pattern: new RegExp(/^[a-z0-9]*$/gim),
										message: (
											<IntlMessages id="error.card.number.value" />
										),
									},
								]}
								hasFeedback
							>
								<Input />
							</Form.Item>
						</Col>

						{!isFromLink && (
							<Col span={24}>
								<Form.Item
									label={
										<IntlMessages id="label.Facility.Code" />
									}
								>
									<Input disabled value={facilityCode} />
								</Form.Item>
							</Col>
						)}
						{!isFromLink && (
							<Col span={24}>
								<Form.Item
									valuePropName="checked"
									initialValue={false}
									name="isVirtualCard"
								>
									<Switch
										checkedChildren="Virtual"
										unCheckedChildren="Physical"
										className="switch"
									/>
								</Form.Item>
							</Col>
						)}

						{isFromLink && (
							<Form.Item
								style={{ width: "100%" }}
								className="gx-mt-3"
							>
								<Button
									type="primary"
									htmlType="submit"
									loading={loading}
									style={{ width: "100%" }}
								>
									<IntlMessages id="button.add" />
								</Button>
							</Form.Item>
						)}
					</Row>
				</Form>
			</Col>
		</Row>
	);
};

export default CardCredentialForm;

export const UserOwner_TYPE_REQUEST = "UserOwner_TYPE_REQUEST";
export const UserOwner_TYPE_SUCCESS = "UserOwner_TYPE_SUCCESS";
export const UserOwner_TYPE_FAILURE = "UserOwner_TYPE_FAILURE";

export const UserOwner_ADD_REQUEST = "UserOwner_ADD_REQUEST";
export const UserOwner_ADD_SUCCESS = "UserOwner_ADD_SUCCESS";
export const UserOwner_ADD_FAILURE = "UserOwner_ADD_FAILURE";

export const UserOwner_DELETE_REQUEST = "UserOwner_DELETE_REQUEST";
export const UserOwner_DELETE_SUCCESS = "UserOwner_DELETE_SUCCESS";
export const UserOwner_DELETE_FAILURE = "UserOwner_DELETE_FAILURE";

export const UserOwner_UPDATE_REQUEST = "UserOwner_UPDATE_REQUEST";
export const UserOwner_UPDATE_SUCCESS = "UserOwner_UPDATE_SUCCESS";
export const UserOwner_UPDATE_FAILURE = "UserOwner_UPDATE_FAILURE";
export const UserOwner_UPDATE_RESET = "UserOwner_UPDATE_RESET";

export const UserOwner_DETAILS_REQUEST = "UserOwner_DETAILS_REQUEST";
export const UserOwner_DETAILS_SUCCESS = "UserOwner_DETAILS_SUCCESS";
export const UserOwner_DETAILS_FAILURE = "UserOwner_DETAILS_FAILURE";
export const UserOwner_DETAILS_RESET = "UserOwner_DETAILS_RESET";

export const LOCK_Owner_REQUEST = "LOCK_Owner_REQUEST";
export const LOCK_Owner_SUCCESS = "LOCK_Owner_SUCCESS";
export const LOCK_Owner_FAILURE = "LOCK_Owner_FAILURE";
export const LOCK_Owner_RESET = "LOCK_Owner_RESET";

export const UNLOCK_Owner_REQUEST = "UNLOCK_Owner_REQUEST";
export const UNLOCK_Owner_SUCCESS = "UNLOCK_Owner_SUCCESS";
export const UNLOCK_Owner_FAILURE = "UNLOCK_Owner_FAILURE";
export const UNLOCK_Owner_RESET = "UNLOCK_Owner_RESET";

import {
	ADD_SELF_EQUIPMENT_FAILURE,
	ADD_SELF_EQUIPMENT_REQUEST,
	ADD_SELF_EQUIPMENT_SUCCESS,
	ADD_SELF_VISITOR_REQUEST,
	ADD_SELF_VISITOR_FAILURE,
	ADD_SELF_VISITOR_SUCCESS,
	forgotPassword_FAILURE,
	forgotPassword_REQUEST,
	forgotPassword_SUCCESS,
	Login_ADD_FAILURE,
	Login_ADD_REQUEST,
	Login_ADD_SUCCESS,
	recoveryPassword_FAILURE,
	recoveryPassword_REQUEST,
	recoveryPassword_SUCCESS,
	SendOTP_FAILURE,
	SendOTP_REQUEST,
	SendOTP_SUCCESS,
	VerifyOTP_FAILURE,
	VerifyOTP_REQUEST,
	VerifyOTP_SUCCESS,
	DDL_ACCESS_POINT_REQUEST,
	DDL_ACCESS_POINT_SUCCESS,
	DDL_ACCESS_POINT_FAILURE,
	ADD_SELF_CARD_REQUEST,
	ADD_SELF_CARD_SUCCESS,
	ADD_SELF_CARD_FAILURE,
} from "./loginTypeContants";

export const LoginReducer = (
	initialState = {
		user: [],
	},
	action
) => {
	switch (action.type) {
		//add Login
		case Login_ADD_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case Login_ADD_SUCCESS:
			return {
				...initialState,
				loading: false,
				data: action.payload,
			};
		case Login_ADD_FAILURE:
			return {
				...initialState,
				loading: false,
				addError: action.payload,
			};

		default:
			return initialState;
	}
};

export const forgotPasswordReducer = (
	initialState = {
		password: [],
	},
	action
) => {
	switch (action.type) {
		case forgotPassword_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case forgotPassword_SUCCESS:
			return {
				...initialState,
				loading: false,
				data: action.payload,
			};
		case forgotPassword_FAILURE:
			return {
				...initialState,
				loading: false,
				addError: action.payload,
			};

		default:
			return initialState;
	}
};

export const recoveryPasswordReducer = (
	initialState = {
		password: [],
	},
	action
) => {
	switch (action.type) {
		case recoveryPassword_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case recoveryPassword_SUCCESS:
			return {
				...initialState,
				loading: false,
				data: action.payload,
			};
		case recoveryPassword_FAILURE:
			return {
				...initialState,
				loading: false,
				recoveryPasswordError: action.payload,
			};

		//SendOTP
		case SendOTP_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case SendOTP_SUCCESS:
			return {
				...initialState,
				loading: false,
				data: action.payload,
			};
		case SendOTP_FAILURE:
			return {
				...initialState,
				loading: false,
				SendOTPError: action.payload,
			};

		//VerifyOTP
		case VerifyOTP_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case VerifyOTP_SUCCESS:
			return {
				...initialState,
				loading: false,
				data: action.payload,
			};
		case VerifyOTP_FAILURE:
			return {
				...initialState,
				loading: false,
				VerifyOTPError: action.payload,
			};

		default:
			return initialState;
	}
};

export const selfEquipmentsReducer = (initialState = {}, action) => {
	switch (action.type) {
		//add self equipment
		case ADD_SELF_EQUIPMENT_REQUEST:
			return {
				...initialState,
				addSelfEquipmentLoading: true,
			};
		case ADD_SELF_EQUIPMENT_SUCCESS:
			return {
				...initialState,
				addSelfEquipmentLoading: false,
				equipmentAdded: action.payload.visitor,
			};
		case ADD_SELF_EQUIPMENT_FAILURE:
			return {
				...initialState,
				addSelfVisitorLoading: false,
				addSelfEquipmentError: action.payload,
			};

		default:
			return initialState;
	}
};

export const selfVisitorReducer = (initialState = {}, action) => {
	switch (action.type) {
		// Add SELF Visitor
		case ADD_SELF_VISITOR_REQUEST:
			return {
				...initialState,
				addSelfVisitorLoading: true,
			};
		case ADD_SELF_VISITOR_SUCCESS:
			return {
				...initialState,
				addSelfVisitorLoading: false,
				visitorAdded: action.payload.visitor,
			};
		case ADD_SELF_VISITOR_FAILURE:
			return {
				...initialState,
				addSelfVisitorLoading: false,
				addSelfVisitorError: action.payload,
			};

		default:
			return initialState;
	}
};

export const accessPointByTenantReducer = (
	initialState = { accessPoints: [] },
	action
) => {
	switch (action.type) {
		case DDL_ACCESS_POINT_REQUEST:
			return {
				...initialState,
				loading: true,
				accessPoints: [],
			};
		case DDL_ACCESS_POINT_SUCCESS:
			return {
				...initialState,
				 accessPoints: action.payload.result?.data,
				loading: false,
			};

		case DDL_ACCESS_POINT_FAILURE:
			return {
				...initialState,
				loading: false,
				ddlError: action.payload,
			};

		default:
			return initialState;
	}
};

export const selfCardReducer = (initialState = {}, action) => {
	switch (action.type) {
		// Add SELF Visitor
		case ADD_SELF_CARD_REQUEST:
			return {
				...initialState,
				addSelfCardLoading: true,
			};
		case ADD_SELF_CARD_SUCCESS:
			return {
				...initialState,
				addSelfCardLoading: false,
				cardAdded: action.payload.visitor,
			};
		case ADD_SELF_CARD_FAILURE:
			return {
				...initialState,
				addSelfCardLoading: false,
				addSelfCardError: action.payload,
			};

		default:
			return initialState;
	}
};

import { Button, Divider, Form, Input, message } from "antd";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "../appRedux/Auth/loginAction";
import { useIntl } from "react-intl";
import CheckboxComponent from "../components/Forms/CheckboxComponent";
import Error from "../components/Message";

const assetUrl = "/assets/images";

const SignIn = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const intl = useIntl();

	const { loading, addError } = useSelector((state) => state.LoginReducer);

	const onFinish = ({ username, password }) => {
		dispatch(
			Login({
				username,
				password,
			})
		).then((res) => {
			if (res?.payload?.data?.statusCode === 200) {
				const {
					payload: {
						data: {
							result: {
								data: { loginToken },
							},
						},
					},
				} = res;
				history.push(`/sendOTP/${loginToken}`);
			} else {
				const {
					payload: {
						data: { message: messageError },
					},
				} = res;
				message.error(intl.formatMessage({ id: messageError }), 5);
			}
		});
	};

	return (
		<div className="gx-app-login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					<div className="gx-app-logo-content">
						<div className="gx-app-logo">
							<img
								alt="powerlook-logo"
								src="/assets/images/logo.svg"
							/>
						</div>
						<div className="gx-app-logo-wid">
							<h5 className="welcome-msg">
								<IntlMessages id="app.userAuth.logIn" />
							</h5>
							<p className="gray-text">
								<IntlMessages id="app.userAuth.getAccount" />
							</p>
						</div>
					</div>
					<div className="gx-app-login-content">
						{addError ? (
							<Error type="error" message={addError?.message} />
						) : (
							<Form
								name="basic"
								labelCol={{
									span: 24,
								}}
								onFinish={onFinish}
								className="gx-signin-form gx-form-row0"
							>
								<Form.Item
									label={<IntlMessages id="label.email" />}
									name={"username"}
									rules={[
										{
											required: true,
											message: (
												<IntlMessages id="error.emailAddress" />
											),
										},
										{
											type: "email",
											message: (
												<IntlMessages id="email.msg" />
											),
										},
									]}
								>
									<Input />
								</Form.Item>
								<Form.Item
									label={<IntlMessages id="label.password" />}
									name={"password"}
									rules={[
										{
											required: true,
											message: (
												<IntlMessages id="error.password" />
											),
										},
										{
											pattern: new RegExp(
												/^[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/i
											),
											message: (
												<IntlMessages id="error.password.value.login" />
											),
										},
									]}
								>
									<Input.Password />
								</Form.Item>

								<Form.Item>
									<CheckboxComponent
										title={
											<IntlMessages id="appModule.iAccept" />
										}
										spanStyle="check"
										className="forget-password"
									/>

									<Link
										to="/forgot-password"
										className="red-color padding-forget-password"
									>
										<IntlMessages id="app.userAuth.forgotPassword" />
									</Link>
								</Form.Item>
								<Form.Item>
									<Button
										type="primary"
										className="gx-mb-0 gx-w-100"
										htmlType="submit"
										loading={loading}
									>
										<IntlMessages id="app.userAuth.signIn" />
									</Button>
								</Form.Item>

								<Form.Item>
									<Divider className="or-login">
										<IntlMessages id="app.userAuth.or" />
									</Divider>
								</Form.Item>
								<Form.Item>
									<div className="login-extra-item">
										<Button
											type="link"
											icon={
												<img
													src={`${assetUrl}/icons/login-google.svg`}
													alt="powerlook-icon"
												/>
											}
										/>
										<Button
											type="link"
											icon={
												<img
													src={`${assetUrl}/icons/login-microsoft.svg`}
													alt="powerlook-icon"
												/>
											}
										/>
									</div>
									{/* <Form.Item>
								<Button
									type="danger"
									onClick={() => {
										history.push("/signup");
									}}
								>
									<IntlMessages id="app.userAuth.signup" />
								</Button>
							</Form.Item> */}
								</Form.Item>
								<p className="doyou">
									<IntlMessages id="app.userAuth.haveAccount" />
									<Link
										to="/signUp"
										className="red-color padding-signup"
									>
										<IntlMessages id="app.userAuth.signupNow" />
									</Link>
								</p>
							</Form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const WrappedNormalLoginForm = SignIn;

export default WrappedNormalLoginForm;

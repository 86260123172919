import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import NotFound from "../components/NotFound";
import Notification from "../components/Notification";

const userData = JSON.parse(localStorage.getItem("userData"));

const App = ({ match, show, setShow, processCommands }) => {
	const [isOwner, setIsOwner] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [isUser, setIsUser] = useState(false);
	useEffect(() => {
		const { userType } = userData;
		userType === 3 ? setIsOwner(true) : setIsOwner(false);
		userType === 1 ? setIsAdmin(true) : setIsAdmin(false);
		userType === 2 ? setIsUser(true) : setIsUser(false);
	}, [isOwner, isAdmin, isUser]);

	const permissions = useMemo(() => {
		return JSON.parse(localStorage.getItem("permissionsName"));
	}, []);

	return (
		<div className="gx-main-content-wrapper">
			<div
				style={{
					position: "fixed",
					bottom: 0,
					zIndex: 9999999,
				}}
			>
				{show && (
					<Notification
						show={show}
						setShow={setShow}
						processCommands={processCommands}
					/>
				)}
			</div>
			<Switch>
				{
					<Route
						path={`${match.url}dashboard`}
						component={asyncComponent(() => import("./Dashboard"))}
					/>
				}
				{
					<Route
						path={`${match.url}newDashboard/:name`}
						component={asyncComponent(() =>
							import("./DashboardDetail")
						)}
					/>
				}
				{(permissions.includes("customerusers.get") ||
					permissions.includes("customerusergroups.get") ||
					permissions.includes("customerpositions.get") ||
					permissions.includes("customerdepartments.get") ||
					permissions.includes("customeroperatorroles.get")) && (
					<Route
						exact
						path={`${match.url}personnel/:tab?`}
						component={asyncComponent(() => import("./Personnel"))}
					/>
				)}
				{permissions.includes("customerusers.get") && (
					<Route
						path={`${match.url}personnel/users/:id`}
						component={asyncComponent(() =>
							import("./Profile/UserInfo")
						)}
					/>
				)}
				{permissions.includes("customerusergroups.get") && (
					<Route
						path={`${match.url}personnel/user-groups/:id`}
						component={asyncComponent(() =>
							import(
								"./Personnel/components/TabContentUserGroup/EditUserGroup"
							)
						)}
					/>
				)}
				{permissions.includes("customerpositions.get") && (
					<Route
						path={`${match.url}personnel/positions/:id`}
						component={asyncComponent(() =>
							import(
								"./Personnel/components/TabContentPosition/EditPosition"
							)
						)}
					/>
				)}
				{permissions.includes("customerdepartments.get") && (
					<Route
						path={`${match.url}personnel/departments/:id`}
						component={asyncComponent(() =>
							import(
								"./Personnel/components/TabContentDepartment/EditDepartment"
							)
						)}
					/>
				)}
				{permissions.includes("customeroperatorroles.get") && (
					<Route
						path={`${match.url}personnel/operator-roles/:id`}
						component={asyncComponent(() =>
							import(
								"./Personnel/components/TabContentOperationRules/EditRole"
							)
						)}
					/>
				)}
				{permissions.includes("customers.get") && (
					<Route
						exact
						path={`${match.url}customers/:tab?`}
						component={asyncComponent(() => import("./Customers"))}
					/>
				)}
				{permissions.includes("customers.get") && (
					<Route
						path={`${match.url}customers/customers/:id`}
						component={asyncComponent(() =>
							import(
								"./Customers/components/TabContentCustomers/EditCustomer"
							)
						)}
					/>
				)}

				{permissions.includes("ownerusers.get") && (
					<Route
						exact
						path={`${match.url}owners/:tab?`}
						component={asyncComponent(() => import("./UserOwner"))}
					/>
				)}
				{permissions.includes("ownerusers.get") && (
					<Route
						path={`${match.url}owners/owners/:id`}
						component={asyncComponent(() =>
							import("./UserOwner/components/Users/EditOwner")
						)}
					/>
				)}
				{permissions.includes("ownerpayment.get") && (
					<Route
						exact
						path={`${match.url}payments/:tab?`}
						component={asyncComponent(() => import("./Payments"))}
					/>
				)}
				{permissions.includes("ownerpayment.get") && (
					<Route
						path={`${match.url}payments/payments/:id`}
						component={asyncComponent(() =>
							import(
								"./Payments/components/TabContentPayments/EditPayments"
							)
						)}
					/>
				)}

				{(permissions.includes("ownerdatacolumn.get") ||
					permissions.includes("ownerdatatable.get") ||
					permissions.includes("ownertrialaccountsetting.get")) && (
					<Route
						exact
						path={`${match.url}configuration/:tab?`}
						component={asyncComponent(() =>
							import("./Configuration")
						)}
					/>
				)}
				{permissions.includes("ownerdatatable.get") && (
					<Route
						exact
						path={`${match.url}configuration/tables/:id`}
						component={asyncComponent(() =>
							import(
								"./Configuration/components/TabTable/TableDetails"
							)
						)}
					/>
				)}

				{permissions.includes("hardware.get") && (
					<Route
						exact
						path={`${match.url}hardware/:tab?`}
						component={asyncComponent(() => import("./Hardware"))}
					/>
				)}
				<Route
					path={`${match.url}hardware/devices/:id`}
					component={asyncComponent(() =>
						import(
							"./Hardware/components/TabContentDevices/EditDevice"
						)
					)}
				/>
				{permissions.includes("customerorgbranches.get") && (
					<Route
						exact
						path={`${match.url}organizations/:tab?`}
						component={asyncComponent(() =>
							import("./Organizations")
						)}
					/>
				)}
				{permissions.includes("customerorgbranches.get") && (
					<Route
						path={`${match.url}organizations/branches/:id`}
						component={asyncComponent(() =>
							import(
								"./Organizations/components/TabContentOrganizations/OrganizationsDetails"
							)
						)}
					/>
				)}
				{(permissions.includes("customermaps.get") ||
					permissions.includes("customerareas.get") ||
					permissions.includes("customerbuildings.get")) && (
					<Route
						exact
						path={`${match.url}site-management/:tab?`}
						component={asyncComponent(() =>
							import("./SiteManagement")
						)}
					/>
				)}
				{(permissions.includes("customerassets.get") ||
					permissions.includes("customerassettypes.get")) && (
					<Route
						exact
						path={`${match.url}asset-management/:tab?`}
						component={asyncComponent(() =>
							import("./AssetManagement")
						)}
					/>
				)}
				{(permissions.includes("customertasktypes.get") ||
					permissions.includes("customertasks.get")) && (
					<Route
						exact
						path={`${match.url}task-management/:tab?`}
						component={asyncComponent(() => import("./Task"))}
					/>
				)}
				{permissions.includes("customertasks.get") && (
					<Route
						path={`${match.url}task-management/tasks/:id`}
						component={asyncComponent(() =>
							import("./Task/components/TabContentTasks/EditTask")
						)}
					/>
				)}
				{permissions.includes("customertasktypes.get") && (
					<Route
						path={`${match.url}task-management/task-types/:id`}
						component={asyncComponent(() =>
							import(
								"./Task/components/TabContentTaskTypes/EditTaskType"
							)
						)}
					/>
				)}
				{permissions.includes("customerteam.get") && (
					<Route
						exact
						path={`${match.url}teams-control/:tab?`}
						component={asyncComponent(() => import("./Team"))}
					/>
				)}
				{permissions.includes("customerteam.get") && (
					<Route
						path={`${match.url}teams-control/teams/:id`}
						component={asyncComponent(() =>
							import("./Team/components/TabContentTeams/EditTeam")
						)}
					/>
				)}
				{permissions.includes("customerareas.get") && (
					<Route
						path={`${match.url}site-management/areas/:id`}
						component={asyncComponent(() =>
							import(
								"./SiteManagement/components/TabContentArea/EditAreaPage"
							)
						)}
					/>
				)}
				{permissions.includes("customerbuildings.get") && (
					<Route
						path={`${match.url}site-management/buildings/:id`}
						component={asyncComponent(() =>
							import(
								"./SiteManagement/components/TabContentBuilding/EditBuildingPage"
							)
						)}
					/>
				)}
				{permissions.includes("customermaps.get") && (
					<Route
						path={`${match.url}site-management/maps/:id`}
						component={asyncComponent(() =>
							import(
								"./SiteManagement/components/TabContentMaps/EditMapPage"
							)
						)}
					/>
				)}

				{permissions.includes("customerpermits.get") && (
					<Route
						exact
						path={`${match.url}visitor-control/:tab?`}
						component={asyncComponent(() =>
							import("./VisitorControl")
						)}
					/>
				)}
				{(permissions.includes("customerpermits.get") ||
					permissions.includes("visitoraccesspoints.get")) && (
					<Route
						path={`${match.url}visitor-control/permits/:id`}
						component={asyncComponent(() =>
							import(
								"./VisitorControl/components/TabContentVisitorPermits/VisitorPermitDetails"
							)
						)}
					/>
				)}

				{(permissions.includes("customerreservation.get") ||
					permissions.includes("customesmartparking.get") ||
					permissions.includes("customermeetingrooms.get") ||
					permissions.includes("customesmartlookers.get")) && (
					<Route
						exact
						path={`${match.url}booking-control/:tab?`}
						component={asyncComponent(() =>
							import("./BookingControl")
						)}
					/>
				)}
				{permissions.includes("customermeetingrooms.get") && (
					<Route
						path={`${match.url}booking-control/meeting-rooms/:id`}
						component={asyncComponent(() =>
							import(
								"./BookingControl/components/TabContentMeetingRoom/MeetingRoomForm"
							)
						)}
					/>
				)}
				{permissions.includes("customesmartlookers.get") && (
					<Route
						path={`${match.url}booking-control/smart-lokers/:id`}
						component={asyncComponent(() =>
							import(
								"./BookingControl/components/TabContentSmartParking/SmartParkingForm"
							)
						)}
					/>
				)}
				{permissions.includes("customesmartparking.get") && (
					<Route
						path={`${match.url}booking-control/smart-parking/:id`}
						component={asyncComponent(() =>
							import(
								"./BookingControl/components/TabContentSmartLockers/SmartLockerForm"
							)
						)}
					/>
				)}
				{permissions.includes("customerreservation.get") && (
					<Route
						path={`${match.url}booking-control/reservation/:id`}
						component={asyncComponent(() =>
							import(
								"./BookingControl/components/TabContentReservation/Reservation"
							)
						)}
					/>
				)}
				{(isUser || isAdmin) && (
					<Route
						exact
						path={`${match.url}time-attendance/:tab?`}
						component={asyncComponent(() =>
							import("./TimeAttendance")
						)}
					/>
				)}
				<Route
					path={`${match.url}time-attendance/leave/:id`}
					component={asyncComponent(() =>
						import(
							"./TimeAttendance/components/LeavesComponent/EditLeave"
						)
					)}
				/>
				<Route
					path={`${match.url}time-attendance/EditleaveType/:id`}
					component={asyncComponent(() =>
						import(
							"./TimeAttendance/components/AttendanceSettingsComponent/components/LeaveTypesComponent/EditLeaveType"
						)
					)}
				/>
				{permissions.includes("hardware.get") && (
					<Route
						exact
						path={`${match.url}maintenance-control/:tab?`}
						component={asyncComponent(() =>
							import("./MaintenanceControl")
						)}
					/>
				)}
				{permissions.includes("customerassettypes.get") && (
					<Route
						path={`${match.url}asset-management/asset-types/:id`}
						component={asyncComponent(() =>
							import(
								"./AssetManagement/components/AssetTypes/EditAssetTypes"
							)
						)}
					/>
				)}
				{permissions.includes("customerassets.get") && (
					<Route
						path={`${match.url}asset-management/assets/:id`}
						component={asyncComponent(() =>
							import(
								"./AssetManagement/components/Assets/EditAssets"
							)
						)}
					/>
				)}
				<Route
					path={`${match.url}maintenanceControl/maintenanceTypes/:id`}
					component={asyncComponent(() =>
						import(
							"./MaintenanceControl/components/MaintenanceTypes/EditMaintenanceTypes"
						)
					)}
				/>
				<Route
					path={`${match.url}maintenanceRequests/preventiveMaintenance/:id`}
					component={asyncComponent(() =>
						import(
							"./MaintenanceControl/components/MaintenanceRequests/components/PreventiveMaintenance/EditPreventiveMaintenance"
						)
					)}
				/>
				<Route
					path={`${match.url}maintenanceRequests/demandMaintenance/:id`}
					component={asyncComponent(() =>
						import(
							"./MaintenanceControl/components/MaintenanceRequests/components/DemandMaintenance/EditDemandMaintenance"
						)
					)}
				/>
				{(permissions.includes("customerreports.get") ||
					permissions.includes("customeruserclockings.get")) && (
					<Route
						exact
						path={`${match.url}reports/:tab?`}
						component={asyncComponent(() => import("./Reports"))}
					/>
				)}
				{/* <Route
					path={`${match.url}profile`}
					component={asyncComponent(() => import("./Profile"))}
				/> */}
				{permissions.includes("customerreports.get") && (
					<Route
						exact
						path={`${match.url}monitoring/:tab?`}
						component={asyncComponent(() => import("./Monitoring"))}
					/>
				)}
				{(permissions.includes("customerincident.get") ||
					permissions.includes("customerincidenttypes.get")) && (
					<Route
						exact
						path={`${match.url}incident-control/:tab?`}
						component={asyncComponent(() =>
							import("./IncidentControl")
						)}
					/>
				)}
				{permissions.includes("customerincidenttypes.get") && (
					<Route
						path={`${match.url}incident-control/incidents/:id`}
						component={asyncComponent(() =>
							import(
								"./IncidentControl/components/TabContentIncident/EditIncident"
							)
						)}
					/>
				)}

				<Route
					path={`${match.url}incident-control/incident-types/:id`}
					component={asyncComponent(() =>
						import(
							"./IncidentControl/components/TabContentIncidentType/EditIncidentType"
						)
					)}
				/>
				<Route
					path={`${match.url}incident-report/:id`}
					component={asyncComponent(() => import("./IncidentReport"))}
				/>

				<Route
					path={`${match.url}messages`}
					component={asyncComponent(() => import("./Messages"))}
				/>
				{(permissions.includes("rules.get") ||
					permissions.includes("schedules.get") ||
					permissions.includes("accesspoints.get") ||
					permissions.includes("accesspointgroups.get")) && (
					<Route
						exact
						path={`${match.url}access-control/:tab?`}
						component={asyncComponent(() =>
							import("./AccessControl")
						)}
					/>
				)}
				{permissions.includes("rules.get") && (
					<Route
						path={`${match.url}access-control/rules/:id`}
						component={asyncComponent(() =>
							import(
								"./AccessControl/components/TabContentRules/RuleDetails"
							)
						)}
					/>
				)}
				{permissions.includes("schedules.get") && (
					<Route
						path={`${match.url}access-control/schedules/:id`}
						component={asyncComponent(() =>
							import(
								"./AccessControl/components/TabContentSchedules/SchedulesDetails"
							)
						)}
					/>
				)}
				{permissions.includes("accesspoints.get") && (
					<Route
						path={`${match.url}access-control/access-point/:id`}
						component={asyncComponent(() =>
							import(
								"./AccessControl/components/TabContentAccessPoint/AccessPointDetails"
							)
						)}
					/>
				)}
				{permissions.includes("accesspointgroups.get") && (
					<Route
						path={`${match.url}access-control/access-point-group/:id`}
						component={asyncComponent(() =>
							import(
								"./AccessControl/components/TabContentAccessPointGroup/AccessPointGroupDetails"
							)
						)}
					/>
				)}

				{(permissions.includes("canteenrules.get") ||
					permissions.includes("canteenmeals.get") ||
					permissions.includes("canteenaccesspoints.get") ||
					permissions.includes("canteenaccesspointgroups.get")) && (
					<Route
						exact
						path={`${match.url}canteen-control/:tab?`}
						component={asyncComponent(() =>
							import("./CanteenControl")
						)}
					/>
				)}
				{permissions.includes("canteenrules.get") && (
					<Route
						path={`${match.url}canteen-control/rules/:id`}
						component={asyncComponent(() =>
							import(
								"./CanteenControl/components/TabContentRulesCanteen/RuleDetails"
							)
						)}
					/>
				)}
				{permissions.includes("canteenmeals.get") && (
					<Route
						path={`${match.url}canteen-control/meals/:id`}
						component={asyncComponent(() =>
							import(
								"./CanteenControl/components/TabContentMealsCanteen/MealDetails"
							)
						)}
					/>
				)}

				{(permissions.includes("customerpinlengthsetting.get") ||
					permissions.includes(
						"customersynchronizedatetimesetting.edit"
					) ||
					permissions.includes("customerclearuserssetting.edit") ||
					permissions.includes(
						"customerwarningconfigurationsetting.get"
					) ||
					permissions.includes("customerserversetting.edit") ||
					permissions.includes("customercardformatsetting.get") ||
					permissions.includes("customercleanupsetting.get") ||
					permissions.includes("customerpermitmessagesetting.get") ||
					permissions.includes("customeractivitylog.get")) && (
					<Route
						exact
						path={`${match.url}settings/:tab?`}
						component={asyncComponent(() => import("./Settings"))}
					/>
				)}
				{permissions.includes("customerpermitmessagesetting.get") && (
					<Route
						path={`${match.url}settings/Templates/:id`}
						component={asyncComponent(() =>
							import(
								"./Settings/components/TabContentTemplates/EditTemplate"
							)
						)}
					/>
				)}
				{(isAdmin || isUser) && (
					<Route
						path={`${match.url}profile/:id`}
						component={asyncComponent(() =>
							import("./Profile/UserInfo")
						)}
					/>
				)}
				{isOwner && (
					<Route
						path={`${match.url}profile/:id`}
						component={asyncComponent(() =>
							import("./UserOwner/components/Users/EditOwner")
						)}
					/>
				)}
				<Route component={NotFound} />
			</Switch>
		</div>
	);
};

export default App;

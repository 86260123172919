import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { recoveryPassword } from "../appRedux/Auth/loginAction";

import DefaultModalComponent from "../components/ModalComponent/DefaultModalComponent";
import Error from "../components/Message";

const RecoveryPassword = (props) => {
	const { id: loginToken } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	// const intl = useIntl();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const showModal = () => {
		setIsModalVisible(true);
	};
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const { loading, recoveryPasswordError } = useSelector(
		(state) => state.recoveryPasswordReducer
	);

	const onFinish = ({ newPassword, confirmPassword }) => {
		dispatch(
			recoveryPassword({ loginToken, newPassword, confirmPassword })
		).then((res) => {
			if (res?.payload?.data?.statusCode === 200) {
				// message.success(intl.formatMessage({ id: "password.msg" }), 5);
				// history.push("/signin");
				showModal();
			} else {
				const {
					payload: {
						data: { message: messageError },
					},
				} = res;
				message.error(messageError, 5);
			}
		});
	};

	return (
		<div className="gx-app-login-wrap">
			<div className="gx-app-login-container">
				<div className="gx-app-login-main-content">
					<div className="gx-app-logo-content">
						<div className="gx-app-logo">
							<img
								alt="powerlook-logo"
								src="/assets/images/forgetPassword.PNG"
							/>
						</div>
						<div className="gx-pt-5">
							<h2 className="gx-mb-1">
								<IntlMessages id="button.reset.password" />
							</h2>
						</div>
					</div>
					<div className="gx-app-login-content">
						{recoveryPasswordError ? (
							<Error
								type="error"
								message={recoveryPasswordError?.message}
							/>
						) : (
							<Form
								name="basic"
								labelCol={{
									span: 24,
								}}
								onFinish={onFinish}
								className="gx-signin-form gx-form-row0"
							>
								<Form.Item
									name="newPassword"
									label={
										<IntlMessages id="label.Newpassword" />
									}
									rules={[
										{
											required: true,
											message: (
												<IntlMessages id="error.password.forgot" />
											),
										},
										{
											pattern: new RegExp(
												/^[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/i
											),
											message: (
												<IntlMessages id="error.password.value.login" />
											),
										},
									]}
									hasFeedback
								>
									<Input.Password />
								</Form.Item>

								<Form.Item
									name="confirmPassword"
									label={
										<IntlMessages id="Confirm.NewPassword" />
									}
									dependencies={["newPassword"]}
									hasFeedback
									rules={[
										{
											required: true,
											message: (
												<IntlMessages id="error.password.forgot.confirm" />
											),
										},
										{
											pattern: new RegExp(
												/^[a-zA-Z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/i
											),
											message: (
												<IntlMessages id="error.password.value.login" />
											),
										},
										({ getFieldValue }) => ({
											validator(rule, value) {
												if (
													!value ||
													getFieldValue(
														"newPassword"
													) === value
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													<IntlMessages id="msg.password.reject" />
												);
											},
										}),
									]}
								>
									<Input.Password />
								</Form.Item>

								<Form.Item>
									<Button
										type="primary"
										className="gx-mb-0 gx-w-100"
										htmlType="submit"
										loading={loading}
									>
										<IntlMessages id="Change Password" />
									</Button>
								</Form.Item>
							</Form>
						)}
					</div>
				</div>
			</div>
			<DefaultModalComponent
				title={<IntlMessages id="Change Password" />}
				open={isModalVisible}
				onCancel={handleCancel}
				width={400}
				footer={[
					<Button
						type="primary"
						onClick={() => history.push("/signin")}
						style={{ width: "100%" }}
					>
						<IntlMessages id="app.userAuth.signIn" />
					</Button>,
				]}
			>
				<div className="gx-app-logo-content">
					<div className="gx-app-logo">
						<img
							alt="powerlook-logo"
							src="/assets/images/logo.svg"
						/>
					</div>
					<div className="gx-app-logo-wid">
						<h5 className="welcome-msg2">
							<IntlMessages id="app.reset" />
						</h5>
						<p className="gray-text">
							<IntlMessages id="app.Account" />
						</p>
					</div>
				</div>
			</DefaultModalComponent>
		</div>
	);
};

const WrappedNormalRecoveryPasswordForm = RecoveryPassword;

export default WrappedNormalRecoveryPasswordForm;

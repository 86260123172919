import api from "../../util/Api";
import {
	PAYMENT_TYPE_REQUEST,
	PAYMENT_TYPE_SUCCESS,
	PAYMENT_TYPE_FAILURE,
	PAYMENT_ADD_REQUEST,
	PAYMENT_ADD_SUCCESS,
	PAYMENT_ADD_FAILURE,
	PAYMENT_DELETE_REQUEST,
	PAYMENT_DELETE_SUCCESS,
	PAYMENT_DELETE_FAILURE,
	PAYMENT_DETAILS_REQUEST,
	PAYMENT_DETAILS_SUCCESS,
	PAYMENT_DETAILS_FAILURE,
	PAYMENT_UPDATE_REQUEST,
	PAYMENT_UPDATE_SUCCESS,
	PAYMENT_UPDATE_FAILURE,
	DDLModules_TYPE_REQUEST,
	DDLModules_TYPE_SUCCESS,
	DDLModules_TYPE_FAILURE,
} from "./paymentTypeContants";

export const PAYMENT =
	(
		pageNumber = 1,
		pageSize = 50,
		searchText = "",
		tenantId = null,
		startDate = null,
		endDate = null
	) =>
	async (dispatch) => {
		try {
			dispatch({ type: PAYMENT_TYPE_REQUEST });
			const { data } = await api.post("Payment/GetPayments", {
				pageNumber,
				pageSize,
				searchText,
				tenantId,
				startDate,
				endDate,
			});
			return dispatch({ type: PAYMENT_TYPE_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: PAYMENT_TYPE_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};

//add PAYMENT
export const addPAYMENT = ({
	description,
	tenantId,
	startDate,
	endDate,
	paymentModules,
}) => {
	return async (dispatch) => {
		dispatch({ type: PAYMENT_ADD_REQUEST });
		try {
			const data = await api.post("Payment/AddPayment", {
				description,
				tenantId,
				startDate,
				endDate,
				paymentModules,
			});
			return dispatch({ type: PAYMENT_ADD_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: PAYMENT_ADD_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

//delete PAYMENT
export const deletePAYMENT = (paymentId) => async (dispatch) => {
	try {
		dispatch({ type: PAYMENT_DELETE_REQUEST });
		const response = await api.delete("Payment/DeletePayment", {
			data: paymentId,
		});
		return dispatch({ type: PAYMENT_DELETE_SUCCESS, response });
	} catch (error) {
		dispatch({
			type: PAYMENT_DELETE_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

//PAYMENT details
export const getPAYMENTDetails = (paymentId) => async (dispatch) => {
	try {
		dispatch({ type: PAYMENT_DETAILS_REQUEST });
		const { data } = await api.post(`Payment/GetPayment/${paymentId}`);

		return dispatch({ type: PAYMENT_DETAILS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: PAYMENT_DETAILS_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

//edit PAYMENT
export const updatePAYMENT = ({
	id,
	description,
	totalPrice,
	tenantId,
	startDate,
	endDate,
	paymentModules,
}) => {
	return async (dispatch) => {
		try {
			dispatch({ type: PAYMENT_UPDATE_REQUEST });

			const data = await api.put("Payment/UpdatePayment", {
				description,
				totalPrice,
				tenantId,
				startDate,
				endDate,
				id,
				paymentModules,
			});
			return dispatch({ type: PAYMENT_UPDATE_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: PAYMENT_UPDATE_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const GetDDLModules = (IsAllowedItemCount,isHasModules) => async (dispatch) => {
	try {
		if(isHasModules === false) {
		dispatch({ type: DDLModules_TYPE_REQUEST });
		const { data } = await api.post(
			"Common/GetDDLModules",
			IsAllowedItemCount
		);
		return dispatch({ type: DDLModules_TYPE_SUCCESS, payload: data });
		}
	} catch (error) {
		dispatch({
			type: DDLModules_TYPE_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

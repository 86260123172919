import {
	REPORT_Export_FAILURE,
	REPORT_Export_REQUEST,
	REPORT_Export_SUCCESS,
	REPORT_FAILURE,
	REPORT_REQUEST,
	REPORT_SUCCESS,
	USER_CLOCKING_FAILURE,
	USER_CLOCKING_REQUEST,
	USER_CLOCKING_SUCCESS,
} from "./reportsTypesConstants";

export const ReportReducer = (
	initialState = {
		Reports: [],
		userClocking: [],
	},
	action
) => {
	switch (action.type) {
		case REPORT_REQUEST:
			return {
				...initialState,
				loading: true,
				Reports: [],
			};
		case REPORT_SUCCESS:
			return {
				...initialState,
				Reports: action.payload.data,
				loading: false,
			};

		case REPORT_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		case USER_CLOCKING_REQUEST:
			return {
				...initialState,
				loadingUserClocking: true,
				userClocking: [],
			};
		case USER_CLOCKING_SUCCESS:
			return {
				...initialState,
				userClocking: action.payload.data,
				loadingUserClocking: false,
			};

		case USER_CLOCKING_FAILURE:
			return {
				...initialState,
				loadingUserClocking: false,
				getError: action.payload,
			};
			//Export
		case REPORT_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case REPORT_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case REPORT_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		default:
			return initialState;
	}
};

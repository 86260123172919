import {
	Customer_TYPE_FAILURE,
	Customer_TYPE_REQUEST,
	Customer_TYPE_SUCCESS,
	TENANTS_DDL_FAILURE,
	TENANTS_DDL_REQUEST,
	TENANTS_DDL_SUCCESS,
	TENANTS_DDL_RESET,
	Customer_ADD_REQUEST,
	Customer_ADD_SUCCESS,
	Customer_DELETE_REQUEST,
	Customer_ADD_FAILURE,
	Customer_DELETE_SUCCESS,
	Customer_DELETE_FAILURE,
	UNACTIVE_CUSTOMER_REQUEST,
	ACTIVE_CUSTOMER_REQUEST,
	UNACTIVE_CUSTOMER_SUCCESS,
	ACTIVE_CUSTOMER_SUCCESS,
	ACTIVE_CUSTOMER_FAILURE,
	UNACTIVE_CUSTOMER_FAILURE,
	ACTIVE_CUSTOMER_RESET,
	UNACTIVE_CUSTOMER_RESET,
	Customer_DETAILS_REQUEST,
	Customer_DETAILS_SUCCESS,
	Customer_DETAILS_FAILURE,
	GetAdminstratorUsers_REQUEST,
	GetAdminstratorUsers_SUCCESS,
	GetAdminstratorUsers_FAILURE,
	Customer_DETAILS_RESET,
	ActivePayments_DETAILS_REQUEST,
	ActivePayments_DETAILS_SUCCESS,
	ActivePayments_DETAILS_FAILURE,
	ActivePayments_DETAILS_RESET,
	Customer_UPDATE_REQUEST,
	Customer_UPDATE_SUCCESS,
	Customer_UPDATE_FAILURE,
	Customer_UPDATE_RESET,
	Customer_Import_REQUEST,
	Customer_Import_SUCCESS,
	Customer_Import_FAILURE,
	ALL_TENANTS_DDL_RESET,
	ALL_TENANTS_DDL_FAILURE,
	ALL_TENANTS_DDL_SUCCESS,
	ALL_TENANTS_DDL_REQUEST,
	Customer_Export_REQUEST,
	Customer_Export_SUCCESS,
	Customer_Export_FAILURE,
} from "./customerConstantTypes";

export const CustomerReducer = (
	initialState = {
		Customers: [],
		tenants: [],
		isHasTenants: false,
		allTenants: [],
		isHasAllTenants: false,
	},
	action
) => {
	switch (action.type) {
		case Customer_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				Customers: [],
			};
		case Customer_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				Customers: action.payload.result.data,
				loading: false,
			};

		case Customer_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//import
		case Customer_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Customer_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Customer_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		//Export
		case Customer_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Customer_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Customer_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		//ddl tenants
		case TENANTS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				tenants: [],
			};
		case TENANTS_DDL_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				tenants: action.payload.result.data,
				loading: false,
				isHasTenants: action.payload.result.data?.length ? true : false,
			};

		case TENANTS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getTENANTS_DDLError: action.payload,
			};
		case TENANTS_DDL_RESET:
			return {
				...initialState,
				isHasTenants: false,
			};
		//ddl tenants
		case ALL_TENANTS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				allTenants: [],
			};
		case ALL_TENANTS_DDL_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				allTenants: action.payload.result.data,
				loading: false,
				isHasAllTenants: action.payload.result.data?.length
					? true
					: false,
			};

		case ALL_TENANTS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getAllTENANTS_DDLError: action.payload,
			};
		case ALL_TENANTS_DDL_RESET:
			return {
				...initialState,
				isHasAllTenants: false,
			};
		//add Customer
		case Customer_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Customer_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Customer_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Customer

		case Customer_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Customer_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Customer_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		//Customer active
		case ACTIVE_CUSTOMER_REQUEST:
		case UNACTIVE_CUSTOMER_REQUEST:
			return {
				...initialState,
				lockLoading: true,
			};
		case ACTIVE_CUSTOMER_SUCCESS:
		case UNACTIVE_CUSTOMER_SUCCESS:
			return {
				...initialState,
				lockLoading: false,
				lockSuccess: true,
			};
		case ACTIVE_CUSTOMER_FAILURE:
		case UNACTIVE_CUSTOMER_FAILURE:
			return {
				...initialState,
				lockLoading: false,
				lockError: action.payload,
			};
		case ACTIVE_CUSTOMER_RESET:
		case UNACTIVE_CUSTOMER_RESET:
			return {
				Customers: {},
			};
		default:
			return initialState;
	}
};

//Customer detailes & edit
export const CustomerDetailsReducer = (
	state = { Customer: {}, ActivePayments: {}, users: [] },
	action
) => {
	switch (action.type) {
		case Customer_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Customer_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				Customer: action.payload,
			};
		case Customer_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case GetAdminstratorUsers_REQUEST:
			return { ...state, loading: true };
		case GetAdminstratorUsers_SUCCESS:
			return { ...state, loading: false, users: action.payload };
		case GetAdminstratorUsers_FAILURE:
			return { ...state, loading: false, detailsError: action.payload };
		case Customer_DETAILS_RESET:
			return { Customer: {} };
		case ActivePayments_DETAILS_REQUEST:
			return { ...state, loading: true };
		case ActivePayments_DETAILS_SUCCESS:
			return { ...state, loading: false, ActivePayments: action.payload };
		case ActivePayments_DETAILS_FAILURE:
			return { ...state, loading: false, error: action.payload };
		case ActivePayments_DETAILS_RESET:
			return { ActivePayments: {} };
		//Customer Update
		case Customer_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Customer_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Customer_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Customer_UPDATE_RESET:
			return { Customer: {} };
		default:
			return state;
	}
};

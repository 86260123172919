import {
	Asset_TYPE_REQUEST,
	Asset_TYPE_SUCCESS,
	Asset_TYPE_FAILURE,
	Asset_ADD_FAILURE,
	Asset_ADD_SUCCESS,
	Asset_ADD_REQUEST,
	Asset_DELETE_REQUEST,
	Asset_DELETE_SUCCESS,
	Asset_DELETE_FAILURE,
	Asset_DETAILS_REQUEST,
	Asset_DETAILS_SUCCESS,
	Asset_DETAILS_FAILURE,
	Asset_DETAILS_RESET,
	Asset_UPDATE_REQUEST,
	Asset_UPDATE_SUCCESS,
	Asset_UPDATE_FAILURE,
	Asset_UPDATE_RESET,
	AssetAttachment_ADD_FAILURE,
	AssetAttachment_ADD_SUCCESS,
	AssetAttachment_ADD_REQUEST,
	AssetAttachment_DELETE_FAILURE,
	AssetAttachment_DELETE_SUCCESS,
	AssetAttachment_DELETE_REQUEST,
	AssetAttachment_TYPE_FAILURE,
	AssetAttachment_TYPE_SUCCESS,
	AssetAttachment_TYPE_REQUEST,
	Asset_BarCode_FAILURE,
	Asset_BarCode_SUCCESS,
	Asset_BarCode_REQUEST,
	Assets_Import_REQUEST,
	Assets_Import_SUCCESS,
	Assets_Import_FAILURE,
	Assets_Export_REQUEST,
	Assets_Export_SUCCESS,
	Assets_Export_FAILURE,
} from "./AssetConstant";

export const AssetReducer = (
	initialState = {
		Assets: [],
		isHasAssets: false,
	},
	action
) => {
	switch (action.type) {
		case Asset_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllAssets: [],
			};
		case Asset_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllAssets: action.payload.result.data,
				loading: false,
			};

		case Asset_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		// Get Assets BarCode
		case Asset_BarCode_REQUEST:
			return {
				...initialState,
				loading: true,
				getBarCode: [],
			};
		case Asset_BarCode_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getBarCode: action.payload.result.data,
				loading: false,
			};

		case Asset_BarCode_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//add Asset
		case Asset_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Asset_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Asset_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//import
		case Assets_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Assets_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Assets_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};

		//Export
		case Assets_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Assets_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Assets_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};

		//delete Asset

		case Asset_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Asset_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Asset_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//Asset details & edit
export const AssetDetailsReducer = (state = { Asset: {} }, action) => {
	switch (action.type) {
		case Asset_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Asset_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				Asset: action.payload,
			};
		case Asset_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Asset_DETAILS_RESET:
			return { ...state, Asset: {} };
		//Asset Update
		case Asset_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Asset_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Asset_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Asset_UPDATE_RESET:
			return { ...state, Asset: {} };

		//AssetAttachment
		case AssetAttachment_TYPE_REQUEST:
			return {
				...state,
				AssetAttachmentloading: true,
				getAllAssetAttachment: [],
			};
		case AssetAttachment_TYPE_SUCCESS:
			return {
				...state,
				data: action.payload,
				getAllAssetAttachment: action.payload.result.data,
				AssetAttachmentloading: false,
			};
		case AssetAttachment_TYPE_FAILURE:
			return {
				...state,
				AssetAttachmentloading: false,
				getErrorAssetAttachment: action.payload,
			};

		case AssetAttachment_DELETE_REQUEST:
			return {
				...state,
				deleteAssetAttachmentLoading: true,
			};
		case AssetAttachment_DELETE_SUCCESS:
			return {
				...state,
				deleteAssetAttachmentLoading: false,
			};
		case AssetAttachment_DELETE_FAILURE:
			return {
				...state,
				deleteAssetAttachmentLoading: false,
				deleteAssetAttachmentError: action.payload,
			};

		case AssetAttachment_ADD_REQUEST:
			return {
				...state,
				addAssetAttachmentLoading: true,
			};
		case AssetAttachment_ADD_SUCCESS:
			return {
				...state,
				addAssetAttachmentLoading: false,
				data: action.payload,
			};
		case AssetAttachment_ADD_FAILURE:
			return {
				...state,
				addAssetAttachmentLoading: false,
				addAssetAttachmentError: action.payload,
			};

		default:
			return state;
	}
};

import React from "react";

const NotFound = () => {
	return (
		<div>
			<div
				className="notFound"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "70vh",
					flexDirection: "column",
				}}
			>
				<img
					alt="powerlook-logo"
					src="/assets/images/notfound.svg"
					style={{
						width: "80%",
						height: "80%",
					}}
				/>
				<h3
					style={{
						fontSize: "40px",
						marginTop: "40px",
						color: "#0371BA",
					}}
				>
					Page Not Found !
				</h3>
			</div>
		</div>
	);
};

export default NotFound;

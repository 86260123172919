import {
	BackgroundRequest_TYPE_FAILURE,
	BackgroundRequest_TYPE_REQUEST,
	BackgroundRequest_TYPE_SUCCESS,
} from "./BackgroundRequestTypeConstants";

export const BackgroundRequestReducer = (
	initialState = {
		BackgroundRequest: [],
	},
	action
) => {
	switch (action.type) {
		case BackgroundRequest_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				BackgroundRequest: [],
			};
		case BackgroundRequest_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				BackgroundRequest: action.payload.result.data,
				loading: false,
			};

		case BackgroundRequest_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		default:
			return initialState;
	}
};

export const Messages_TYPE_REQUEST = "Messages_TYPE_REQUEST";
export const Messages_TYPE_SUCCESS = "Messages_TYPE_SUCCESS";
export const Messages_TYPE_FAILURE = "Messages_TYPE_FAILURE";

export const Messages_UPDATE_REQUEST = "Messages_UPDATE_REQUEST";
export const Messages_UPDATE_SUCCESS = "Messages_UPDATE_SUCCESS";
export const Messages_UPDATE_FAILURE = "Messages_UPDATE_FAILURE";
export const Messages_UPDATE_RESET = "Messages_UPDATE_RESET";

export const DDL_Messages_REQUEST = "DDL_Messages_REQUEST";
export const DDL_Messages_SUCCESS = "DDL_Messages_SUCCESS";
export const DDL_Messages_FAILURE = "DDL_Messages_FAILURE";
export const DDL_Messages_RESET = "DDL_Messages_RESET";

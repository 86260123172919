import Api from "../../util/Api";
import {
	ADD_SELF_CARD_FAILURE,
	ADD_SELF_CARD_REQUEST,
	ADD_SELF_CARD_SUCCESS,
	ADD_SELF_EQUIPMENT_FAILURE,
	ADD_SELF_EQUIPMENT_REQUEST,
	ADD_SELF_EQUIPMENT_SUCCESS,
	ADD_SELF_VISITOR_REQUEST,
	ADD_SELF_VISITOR_FAILURE,
	ADD_SELF_VISITOR_SUCCESS,
	DDL_ACCESS_POINT_FAILURE,
	DDL_ACCESS_POINT_REQUEST,
	DDL_ACCESS_POINT_SUCCESS,
	forgotPassword_FAILURE,
	forgotPassword_REQUEST,
	forgotPassword_SUCCESS,
	Login_ADD_FAILURE,
	Login_ADD_REQUEST,
	Login_ADD_SUCCESS,
	recoveryPassword_FAILURE,
	recoveryPassword_REQUEST,
	recoveryPassword_SUCCESS,
	SendOTP_FAILURE,
	SendOTP_REQUEST,
	SendOTP_SUCCESS,
	VerifyOTP_FAILURE,
	VerifyOTP_REQUEST,
	VerifyOTP_SUCCESS,
} from "./loginTypeContants";

export const Login = ({ username, password }) => {
	return async (dispatch) => {
		dispatch({ type: Login_ADD_REQUEST });
		try {
			const data = await Api.post("Auth/Login", {
				username,
				password,
			});
			return dispatch({ type: Login_ADD_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: Login_ADD_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const forgotPassword = ({ username }) => {
	return async (dispatch) => {
		dispatch({ type: forgotPassword_REQUEST });
		try {
			const data = await Api.post("Auth/ForgetPassword", {
				username,
			});
			return dispatch({ type: forgotPassword_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: forgotPassword_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const recoveryPassword = ({
	loginToken,
	newPassword,
	confirmPassword,
}) => {
	return async (dispatch) => {
		dispatch({ type: recoveryPassword_REQUEST });
		try {
			const data = await Api.post("Auth/ResetPassword", {
				loginToken,
				newPassword,
				confirmPassword,
			});
			return dispatch({ type: recoveryPassword_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: recoveryPassword_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const SendOTPAction = ({ loginToken, otpChannel }) => {
	return async (dispatch) => {
		dispatch({ type: SendOTP_REQUEST });
		try {
			const data = await Api.post("Auth/SendOTP", {
				loginToken,
				otpChannel,
			});
			return dispatch({ type: SendOTP_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: SendOTP_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const VerifyOTPAction = ({ loginToken, otp }) => {
	return async (dispatch) => {
		dispatch({ type: VerifyOTP_REQUEST });
		try {
			const data = await Api.post("Auth/VerifyOTP", {
				loginToken,
				otp,
			});
			return dispatch({ type: VerifyOTP_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: VerifyOTP_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const addSelfEquipment = (equipment) => {
	return async (dispatch) => {
		dispatch({ type: ADD_SELF_EQUIPMENT_REQUEST });
		try {
			const { data } = await Api.post(
				"VisitorControl/AddEquipmentByLink",
				equipment
			);
			return dispatch({
				type: ADD_SELF_EQUIPMENT_SUCCESS,
				payload: { equipment, data },
			});
		} catch (error) {
			dispatch({
				type: ADD_SELF_EQUIPMENT_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const addSelfVisitor = (visitor) => {
	return async (dispatch) => {
		dispatch({ type: ADD_SELF_VISITOR_REQUEST });
		try {
			const { data } = await Api.post(
				"VisitorControl/AddVisitorByLink",
				visitor
			);
			return dispatch({
				type: ADD_SELF_VISITOR_SUCCESS,
				payload: { visitor, data },
			});
		} catch (error) {
			dispatch({
				type: ADD_SELF_VISITOR_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

export const addSelfCard = (card) => {
	return async (dispatch) => {
		dispatch({ type: ADD_SELF_CARD_REQUEST });
		try {
			const { data } = await Api.post(
				"personnel/AddCredentialCardByLink",
				card
			);
			return dispatch({
				type: ADD_SELF_CARD_SUCCESS,
				payload: { card, data },
			});
		} catch (error) {
			dispatch({
				type: ADD_SELF_CARD_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};
export const getAccessPointByTenant = (tenantId) => async (dispatch) => {
	try {
		dispatch({ type: DDL_ACCESS_POINT_REQUEST });
		const { data } = await Api.get(
			`AccessControl/GetDDLAccessPointsByTenant/${tenantId}`
		);

		return dispatch({ type: DDL_ACCESS_POINT_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: DDL_ACCESS_POINT_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

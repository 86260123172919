import {
	ADD_DASHBOARD_REQUEST,
	ADD_DASHBOARD_SUCCESS,
	ADD_DASHBOARD__FAILURE,
	DASHBOARD_REQUEST,
	DASHBOARD_SUCCESS,
	DASHBOARD__FAILURE,
	CARD_DASHBOARD_REQUEST,
	CARD_DASHBOARD_SUCCESS,
	CARD_DASHBOARD_FAILURE,
	ADD_CARD_DASHBOARD_REQUEST,
	ADD_CARD_DASHBOARD_SUCCESS,
	ADD_CARD_DASHBOARD__FAILURE,
	USER_DASHBOARD_REQUEST,
	USER_DASHBOARD_SUCCESS,
	USER_DASHBOARD__FAILURE,
	DELETE_USER_DASHBOARD_REQUEST,
	DELETE_USER_DASHBOARD_SUCCESS,
	DELETE_USER_DASHBOARD__FAILURE,
	DASHBOARD_ITEMS_REQUEST,
	DASHBOARD_ITEMS_SUCCESS,
	DASHBOARD_ITEMS_FAILURE,
	DELETE_CARD_DASHBOARD_REQUEST,
	DELETE_CARD_DASHBOARD_SUCCESS,
	DELETE_CARD_DASHBOARD__FAILURE,
	UpdatePlacements_DASHBOARD__FAILURE,
	UpdatePlacements_DASHBOARD_SUCCESS,
	UpdatePlacements_DASHBOARD_REQUEST,
	UpdateItemPlacements_DASHBOARD__FAILURE,
	UpdateItemPlacements_DASHBOARD_SUCCESS,
	UpdateItemPlacements_DASHBOARD_REQUEST,
	Edit_DASHBOARD_REQUEST,
	Edit_DASHBOARD_SUCCESS,
	Edit_DASHBOARD__FAILURE,
} from "./dashboardTypesConstants";

// dashboard
export const userDashboardsReducer = (
	state = { dashboards: [], dashboard: [] },
	action
) => {
	switch (action.type) {
		case DASHBOARD_REQUEST:
			return { ...state, loading: true };
		case DASHBOARD_SUCCESS:
			return {
				...state,
				loading: false,
				dashboards: action.payload.result.data,
			};
		case DASHBOARD__FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case ADD_DASHBOARD_REQUEST:
			return { ...state, addloading: true };
		case ADD_DASHBOARD_SUCCESS:
			return { ...state, addloading: false, dashboard: action.payload };
		case ADD_DASHBOARD__FAILURE:
			return {
				...state,
				addloading: false,
				addError: action.payload,
			};
		case Edit_DASHBOARD_REQUEST:
			return { ...state, editLoading: true };
		case Edit_DASHBOARD_SUCCESS:
			return { ...state, editLoading: false, dashboard: action.payload };
		case Edit_DASHBOARD__FAILURE:
			return {
				...state,
				editLoading: false,
				updateError: action.payload,
			};
		case UpdatePlacements_DASHBOARD_REQUEST:
			return { ...state, Loading: true };
		case UpdatePlacements_DASHBOARD_SUCCESS:
			return { ...state, Loading: false, dashboard: action.payload };
		case UpdatePlacements_DASHBOARD__FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		case USER_DASHBOARD_REQUEST:
			return { ...state, loading: true };
		case USER_DASHBOARD_SUCCESS:
			return {
				...state,
				loading: false,
				dashboard: action.payload.result.data,
			};
		case USER_DASHBOARD__FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case DELETE_USER_DASHBOARD_REQUEST:
			return { ...state, deleteLoading: true };
		case DELETE_USER_DASHBOARD_SUCCESS:
			return {
				...state,
				deleteLoading: false,
				dashboard: action.payload.result.data,
			};
		case DELETE_USER_DASHBOARD__FAILURE:
			return {
				...state,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return state;
	}
};

// card for dashboard
export const cardsReducer = (state = { cards: [], items: [] }, action) => {
	switch (action.type) {
		case DASHBOARD_ITEMS_REQUEST:
			return { ...state, loading: true };
		case DASHBOARD_ITEMS_SUCCESS:
			return {
				...state,
				loading: false,
				items: action.payload.result.data,
			};
		case DASHBOARD_ITEMS_FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case CARD_DASHBOARD_REQUEST:
			return { ...state, loading: true };
		case CARD_DASHBOARD_SUCCESS:
			return { ...state, loading: false, cards: action.payload };
		case CARD_DASHBOARD_FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case ADD_CARD_DASHBOARD_REQUEST:
			return { ...state, addloading: true };
		case ADD_CARD_DASHBOARD_SUCCESS:
			return { ...state, addloading: false, cards: action.payload };
		case ADD_CARD_DASHBOARD__FAILURE:
			return {
				...state,
				addloading: false,
				addError: action.payload,
			};
		case UpdateItemPlacements_DASHBOARD_REQUEST:
			return { ...state, Loading: true };
		case UpdateItemPlacements_DASHBOARD_SUCCESS:
			return { ...state, Loading: false, cards: action.payload };
		case UpdateItemPlacements_DASHBOARD__FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case DELETE_CARD_DASHBOARD_REQUEST:
			return { ...state, deleteLoading: true };
		case DELETE_CARD_DASHBOARD_SUCCESS:
			return {
				...state,
				deleteLoading: false,
				cards: action.payload.result.data,
			};
		case DELETE_CARD_DASHBOARD__FAILURE:
			return {
				...state,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return state;
	}
};

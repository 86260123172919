export const Customer_TYPE_REQUEST = "Customer_TYPE_REQUEST";
export const Customer_TYPE_SUCCESS = "Customer_TYPE_SUCCESS";
export const Customer_TYPE_FAILURE = "Customer_TYPE_FAILURE";

export const Customer_ADD_REQUEST = "Customer_ADD_REQUEST";
export const Customer_ADD_SUCCESS = "Customer_ADD_SUCCESS";
export const Customer_ADD_FAILURE = "Customer_ADD_FAILURE";

export const Customer_DELETE_REQUEST = "Customer_DELETE_REQUEST";
export const Customer_DELETE_SUCCESS = "Customer_DELETE_SUCCESS";
export const Customer_DELETE_FAILURE = "Customer_DELETE_FAILURE";

export const Customer_UPDATE_REQUEST = "Customer_UPDATE_REQUEST";
export const Customer_UPDATE_SUCCESS = "Customer_UPDATE_SUCCESS";
export const Customer_UPDATE_FAILURE = "Customer_UPDATE_FAILURE";
export const Customer_UPDATE_RESET = "Customer_UPDATE_RESET";

export const Customer_Import_REQUEST = "Customer_Import_REQUEST";
export const Customer_Import_SUCCESS = "Customer_Import_SUCCESS";
export const Customer_Import_FAILURE = "Customer_Import_FAILURE";

export const Customer_Export_REQUEST = "Customer_Export_REQUEST";
export const Customer_Export_SUCCESS = "Customer_Export_SUCCESS";
export const Customer_Export_FAILURE = "Customer_Export_FAILURE";

export const Customer_DETAILS_REQUEST = "Customer_DETAILS_REQUEST";
export const Customer_DETAILS_SUCCESS = "Customer_DETAILS_SUCCESS";
export const Customer_DETAILS_FAILURE = "Customer_DETAILS_FAILURE";
export const Customer_DETAILS_RESET = "Customer_DETAILS_RESET";

export const GetAdminstratorUsers_REQUEST = "GetAdminstratorUsers _REQUEST";
export const GetAdminstratorUsers_SUCCESS = "GetAdminstratorUsers _SUCCESS";
export const GetAdminstratorUsers_FAILURE = "GetAdminstratorUsers _FAILURE";
export const GetAdminstratorUsers_RESET = "GetAdminstratorUsers _RESET";

export const ActivePayments_DETAILS_REQUEST = "ActivePayments_DETAILS_REQUEST";
export const ActivePayments_DETAILS_SUCCESS = "ActivePayments_DETAILS_SUCCESS";
export const ActivePayments_DETAILS_FAILURE = "ActivePayments_DETAILS_FAILURE";
export const ActivePayments_DETAILS_RESET = "ActivePayments_DETAILS_RESET";

export const ACTIVE_CUSTOMER_REQUEST = "ACTIVE_CUSTOMER_REQUEST";
export const ACTIVE_CUSTOMER_SUCCESS = "ACTIVE_CUSTOMER_SUCCESS";
export const ACTIVE_CUSTOMER_FAILURE = "ACTIVE_CUSTOMER_FAILURE";
export const ACTIVE_CUSTOMER_RESET = "ACTIVE_CUSTOMER_RESET";

export const UNACTIVE_CUSTOMER_REQUEST = "UNACTIVE_CUSTOMER_REQUEST";
export const UNACTIVE_CUSTOMER_SUCCESS = "UNACTIVE_CUSTOMER_SUCCESS";
export const UNACTIVE_CUSTOMER_FAILURE = "UNACTIVE_CUSTOMER_FAILURE";
export const UNACTIVE_CUSTOMER_RESET = "UNACTIVE_CUSTOMER_RESET";

export const TENANTS_DDL_REQUEST = "TENANTS_DDL_REQUEST";
export const TENANTS_DDL_SUCCESS = "TENANTS_DDL_SUCCESS";
export const TENANTS_DDL_FAILURE = "TENANTS_DDL_FAILURE";
export const TENANTS_DDL_RESET = "TENANTS_DDL_RESET";

export const ALL_TENANTS_DDL_REQUEST = "ALL_TENANTS_DDL_REQUEST";
export const ALL_TENANTS_DDL_SUCCESS = "ALL_TENANTS_DDL_SUCCESS";
export const ALL_TENANTS_DDL_FAILURE = "ALL_TENANTS_DDL_FAILURE";
export const ALL_TENANTS_DDL_RESET = "ALL_TENANTS_DDL_RESET";


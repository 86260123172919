import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import IntlMessages from "../../util/IntlMessages";

const GeneralSelectAll = ({
	isDisabled,
	arrResult,
	form,
	arrName,
	arrLable,
	rules,
	readonly,
	placeholder,
}) => {
	const { Option } = Select;
	const [allOptions, setAllOptions] = useState(false);
	const [arrlength, setRrrlength] = useState(false);
	useEffect(() => {
		if (arrResult?.length > 3) {
			setRrrlength(true);
		}
	}, [arrResult?.length]);

	const handleSelectAll = (value) => {
		if (value.includes("all")) {
			setAllOptions(true);
			form.setFieldsValue({
				[arrName]: arrResult.map((e) => e.id),
			});
		} else if (value.includes("DeSelect")) {
			setRrrlength(false);
			form.setFieldsValue({
				[arrName]: [],
			});
			setAllOptions(false);
		}
	};
	return (
		<div>
			<Form.Item
				name={arrName}
				label={<IntlMessages id={arrLable} />}
				rules={rules}
				hasFeedback={!!rules}
			>
				<Select
					showSearch
					filterOption={(input, option) =>
						option.children
							.toLowerCase()
							.indexOf(input.toLowerCase()) >= 0
					}
					mode="multiple"
					style={{
						width: "100%",
					}}
					showArrow
					disabled={isDisabled || readonly}
					onChange={handleSelectAll}
					value={form.getFieldValue(arrName)}
					placeholder={placeholder}
				>
					{arrResult?.length > 1 && !allOptions && (
						<Option key="all" value="all">
							SELECT ALL
						</Option>
					)}
					{form.getFieldValue(arrName) &&
						form.getFieldValue(arrName)?.length !== 0 &&
						(allOptions || arrlength) && (
							<Option key="DeSelect" value="DeSelect">
								REMOVE ALL
							</Option>
						)}
					{arrResult?.map((item) => (
						<Option key={item.id} value={item.id}>
							{item.nameEn}
						</Option>
					))}
				</Select>
			</Form.Item>
		</div>
	);
};

export default GeneralSelectAll;

import {
	ADD_DAMAGED_ASSETS_FAILURE,
	ADD_DAMAGED_ASSETS_REQUEST,
	ADD_DAMAGED_ASSETS_SUCCESS,
	DAMAGED_ASSETS_FAILURE,
	DAMAGED_ASSETS_REQUEST,
	DAMAGED_ASSETS_Reset,
	DAMAGED_ASSETS_SUCCESS,
	DELETE_DAMAGED_ASSETS_FAILURE,
	DELETE_DAMAGED_ASSETS_REQUEST,
	DELETE_DAMAGED_ASSETS_SUCCESS,
} from "./DamagedAssetsConstant";

// DAMAGED ASSETS
export const DamagedAssetsReducer = (
	initialState = {
		damaged_Assets: [],
		GetAssetsLoading: false,
		addAssetsLoading: false,
		deleteAssetsLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Damaged Assets
		case DAMAGED_ASSETS_REQUEST:
			return {
				...initialState,
				GetAssetsLoading: true,
			};
		case DAMAGED_ASSETS_SUCCESS:
			return {
				...initialState,
				damaged_Assets: action.payload.result.data,
				GetAssetsLoading: false,
			};
		case DAMAGED_ASSETS_FAILURE:
			return {
				...initialState,
				GetAssetsLoading: false,
				getError: action.payload,
			};
		case DAMAGED_ASSETS_Reset:
			return {
				...initialState,
				damaged_Assets: [],
			};
		// Add Damaged Assets
		case ADD_DAMAGED_ASSETS_REQUEST:
			return {
				...initialState,
				addAssetsLoading: true,
			};
		case ADD_DAMAGED_ASSETS_SUCCESS:
			return {
				...initialState,
				addAssetsLoading: false,
			};
		case ADD_DAMAGED_ASSETS_FAILURE:
			return {
				...initialState,
				addAssetsLoading: false,
				addError: action.payload,
			};

		// Delete Damaged Assets
		case DELETE_DAMAGED_ASSETS_REQUEST:
			return {
				...initialState,
				deleteAssetsLoading: true,
			};
		case DELETE_DAMAGED_ASSETS_SUCCESS:
			return {
				...initialState,
				deleteAssetsLoading: false,
			};
		case DELETE_DAMAGED_ASSETS_FAILURE:
			return {
				...initialState,
				deleteAssetsLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

import {
	GET_BUILDINGS_REQUEST,
	GET_BUILDINGS_SUCCESS,
	GET_BUILDINGS_FAILURE,
	DELETE_BUILDING_REQUEST,
	DELETE_BUILDING_SUCCESS,
	DELETE_BUILDING_FAILURE,
	ADD_BUILDING_REQUEST,
	ADD_BUILDING_SUCCESS,
	ADD_BUILDING_FAILURE,
	BUILDING_DETAILS_REQUEST,
	BUILDING_DETAILS_SUCCESS,
	BUILDING_DETAILS_FAILURE,
	BUILDING_DETAILS_RESET,
	BUILDING_UPDATE_REQUEST,
	BUILDING_UPDATE_SUCCESS,
	BUILDING_UPDATE_FAILURE,
	Buildings_DDL_REQUEST,
	Buildings_DDL_SUCCESS,
	Buildings_DDL_FAILURE,
	Buildings_DDL_RESET,
	Buildings_Import_REQUEST,
	Buildings_Import_SUCCESS,
	Buildings_Import_FAILURE,
	Buildings_Export_REQUEST,
	Buildings_Export_SUCCESS,
	Buildings_Export_FAILURE,
} from "./buildingsTypesContants";
export const buildingsReducer = (
	initialState = { buildings: [], isHasBuildings: false },
	action
) => {
	switch (action.type) {
		case GET_BUILDINGS_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllBuildings: [],
			};
		case GET_BUILDINGS_SUCCESS:
			return {
				...initialState,
				getAllBuildings: action.payload.result.data,
				data: action.payload,
				loading: false,
			};
		case GET_BUILDINGS_FAILURE:
			return {
				...initialState,
				getError: action.payload,
				loading: false,
			};

		//import
		case Buildings_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case Buildings_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case Buildings_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};

		//Export
		case Buildings_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Buildings_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Buildings_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		//DDL Buildings
		case Buildings_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				buildings: [],
			};
		case Buildings_DDL_SUCCESS:
			return {
				...initialState,
				buildings: action.payload.result.data,
				loading: false,
				isHasBuildings: action.payload.result.data?.length
					? true
					: false,
			};

		case Buildings_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				ddlBuildingError: action.payload,
			};
		case Buildings_DDL_RESET:
			return {
				...initialState,
				isHasBuildings: false,
			};
		// delete building
		case DELETE_BUILDING_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_BUILDING_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
				deleteSuccess: true,
			};
		case DELETE_BUILDING_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		// add building
		case ADD_BUILDING_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_BUILDING_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case ADD_BUILDING_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		// building details
		case BUILDING_DETAILS_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
			};
		case BUILDING_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				building: action.payload,
			};
		case BUILDING_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case BUILDING_DETAILS_RESET:
			return {
				...initialState,
				building: {},
			};

		// Update Building
		case BUILDING_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case BUILDING_UPDATE_SUCCESS:
			return { ...initialState, updateLoading: false };
		case BUILDING_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		default:
			return initialState;
	}
};

import {
	SignUp_FAILURE,
	SignUp_REQUEST,
	SignUp_SUCCESS,
} from "./signUpTypeContants";

export const SignUpReducer = (
	initialState = {
		Customers: [],
	},
	action
) => {
	switch (action.type) {
		case SignUp_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case SignUp_SUCCESS:
			return {
				...initialState,
				loading: false,
				data: action.payload,
			};
		case SignUp_FAILURE:
			return {
				...initialState,
				loading: false,
				addError: action.payload,
			};

		default:
			return initialState;
	}
};

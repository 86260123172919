import {
	ADD_EQUIPMENT_FAILURE,
	ADD_EQUIPMENT_REQUEST,
	ADD_EQUIPMENT_SUCCESS,
	DDL_VISITORS_FAILURE,
	DDL_VISITORS_REQUEST,
	DDL_VISITORS_RESET,
	DDL_VISITORS_SUCCESS,
	DELETE_EQUIPMENTS_FAILURE,
	DELETE_EQUIPMENTS_REQUEST,
	DELETE_EQUIPMENTS_SUCCESS,
	GET_EQUIPMENTS_FAILURE,
	GET_EQUIPMENTS_REQUEST,
	GET_EQUIPMENTS_SUCCESS,
	SHARE_SELF_LINK_EQUIPMENT_FAILURE,
	SHARE_SELF_LINK_EQUIPMENT_REQUEST,
	SHARE_SELF_LINK_EQUIPMENT_SUCCESS,
	UPDATE_STATUS_EQUIPMENT_FAILURE,
	UPDATE_STATUS_EQUIPMENT_REQUEST,
	UPDATE_STATUS_EQUIPMENT_SUCCESS,
} from "./equipmentsTypesConstants";

export const visitorPermitEquipmentsReducer = (
	initialState = {
		visitors: [],
		equipments: [],
		isHasPermitVisitors: false,
	},
	action
) => {
	switch (action.type) {
		// Get Equipments

		case GET_EQUIPMENTS_REQUEST:
			return {
				...initialState,
				loading: true,
				equipments: [],
			};
		case GET_EQUIPMENTS_SUCCESS:
			return {
				...initialState,
				equipments: action.payload.result.data,
				loading: false,
			};

		case GET_EQUIPMENTS_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		// DDL Visitors
		case DDL_VISITORS_REQUEST:
			return {
				...initialState,
				loading: true,
				visitors: [],
			};
		case DDL_VISITORS_SUCCESS:
			return {
				...initialState,
				visitors: action.payload.result.data,
				loading: false,
				isHasPermitVisitors: action.payload.result.data?.length
					? true
					: false,
			};

		case DDL_VISITORS_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case DDL_VISITORS_RESET:
			return {
				...initialState,
				isHasPermitVisitors: false,
			};
		// Add Equipment
		case ADD_EQUIPMENT_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_EQUIPMENT_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				equipment: action.payload.permit,
			};
		case ADD_EQUIPMENT_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		//Update Status Equipments
		case UPDATE_STATUS_EQUIPMENT_REQUEST:
			return {
				...initialState,
				updateStatusLoading: true,
			};
		case UPDATE_STATUS_EQUIPMENT_SUCCESS:
			return {
				...initialState,
				updateStatusLoading: false,
			};
		case UPDATE_STATUS_EQUIPMENT_FAILURE:
			return {
				...initialState,
				updateStatusLoading: false,
				updateError: action.payload,
			};
		// Delete Equipment
		case DELETE_EQUIPMENTS_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_EQUIPMENTS_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_EQUIPMENTS_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		// SHare SELF Link for equipment
		case SHARE_SELF_LINK_EQUIPMENT_REQUEST:
			return {
				...initialState,
				shareLinkLoading: true,
			};
		case SHARE_SELF_LINK_EQUIPMENT_SUCCESS:
			return {
				...initialState,
				shareLinkLoading: false,
			};
		case SHARE_SELF_LINK_EQUIPMENT_FAILURE:
			return {
				...initialState,
				shareLinkLoading: false,
				shareLinkError: action.payload,
			};

		default:
			return initialState;
	}
};

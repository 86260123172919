export const IncidentType_TYPE_REQUEST = "IncidentType_TYPE_REQUEST";
export const IncidentType_TYPE_SUCCESS = "IncidentType_TYPE_SUCCESS";
export const IncidentType_TYPE_FAILURE = "IncidentType_TYPE_FAILURE";

export const IncidentType_ADD_REQUEST = "IncidentType_ADD_REQUEST";
export const IncidentType_ADD_SUCCESS = "IncidentType_ADD_SUCCESS";
export const IncidentType_ADD_FAILURE = "IncidentType_ADD_FAILURE";

export const IncidentTypes_DDL_REQUEST = "IncidentTypes_DDL_REQUEST";
export const IncidentTypes_DDL_SUCCESS =
	"IncidentTypes_DDLIncidentTypes_SUCCESS";
export const IncidentTypes_DDL_FAILURE = "IncidentTypes_DDL_FAILURE";
export const IncidentTypes_DDL_RESET = "IncidentTypes_DDL_RESET";

export const IncidentType_DELETE_REQUEST = "IncidentType_DELETE_REQUEST";
export const IncidentType_DELETE_SUCCESS = "IncidentType_DELETE_SUCCESS";
export const IncidentType_DELETE_FAILURE = "IncidentType_DELETE_FAILURE";

export const IncidentType_UPDATE_REQUEST = "IncidentType_UPDATE_REQUEST";
export const IncidentType_UPDATE_SUCCESS = "IncidentType_UPDATE_SUCCESS";
export const IncidentType_UPDATE_FAILURE = "IncidentType_UPDATE_FAILURE";
export const IncidentType_UPDATE_RESET = "IncidentType_UPDATE_RESET";

export const IncidentType_DETAILS_REQUEST = "IncidentType_DETAILS_REQUEST";
export const IncidentType_DETAILS_SUCCESS = "IncidentType_DETAILS_SUCCESS";
export const IncidentType_DETAILS_FAILURE = "IncidentType_DETAILS_FAILURE";
export const IncidentType_DETAILS_RESET = "IncidentType_DETAILS_RESET";

export const IncidentTypePolicies_REQUEST = " IncidentTypePolicies_REQUEST";
export const IncidentTypePolicies_SUCCESS = " IncidentTypePolicies_SUCCESS";
export const IncidentTypePolicies_FAILURE = " IncidentTypePolicies_FAILURE";
export const IncidentTypePolicies_RESET = " IncidentTypePolicies_RESET";

export const IncidentTypePolicies_DELETE_REQUEST =
	" IncidentTypePolicies_DELETE_REQUEST";

export const IncidentTypePolicies_DELETE_SUCCESS =
	" IncidentTypePolicies_DELETE_SUCCESS";

export const IncidentTypePolicies_DELETE_FAILURE =
	" IncidentTypePolicies_DELETE_FAILURE";

export const IncidentTypePolicies_Add_REQUEST =
	" IncidentTypePolicies_Add_REQUEST";

export const IncidentTypePolicies_Add_SUCCESS =
	" IncidentTypePolicies_Add_SUCCESS";

export const IncidentTypePolicies_Add_FAILURE =
	" IncidentTypePolicies_Add_FAILURE";

export const Incident_TYPES_ATTACHMENTS_REQUEST =
	"Incident_TYPES_ATTACHMENTS_REQUEST";
export const Incident_TYPES_ATTACHMENTS_SUCCESS =
	"Incident_TYPES_ATTACHMENTS_SUCCESS";
export const Incident_TYPES_ATTACHMENTS_FAILURE =
	"Incident_TYPES_ATTACHMENTS_FAILURE";


import { Button, Upload } from "antd";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";

const AttachmentComponent = (props) => {
    return (
        <div style={{ minWidth: 200 }}>
            <Upload name={props.name} action="/upload.do">
                <Button
                    icon={<UploadOutlined />}
                    style={{ width: "100%", minWidth: 200 }}
                >
                    Click to upload
                </Button>
            </Upload>
        </div>
    );
};

export default AttachmentComponent;

import {
	DateAndTime_UPDATE_REQUEST,
	DateAndTime_UPDATE_SUCCESS,
	DateAndTime_UPDATE_FAILURE,
	DateAndTime_UPDATE_RESET,
} from "./synchronizeD&TConstant";

export const DateAndTimeReducer = (
	initialState = {
		DateAndTimes: [],
	},
	action
) => {
	switch (action.type) {
		//DateAndTime Update
		case DateAndTime_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case DateAndTime_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case DateAndTime_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case DateAndTime_UPDATE_RESET:
			return { ...initialState,DateAndTime: {} };
		default:
			return initialState;
	}
};

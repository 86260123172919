import {
	Tasks_TYPE_REQUEST,
	Tasks_TYPE_SUCCESS,
	Tasks_TYPE_FAILURE,
	Tasks_ADD_FAILURE,
	Tasks_ADD_SUCCESS,
	Tasks_ADD_REQUEST,
	Tasks_DELETE_REQUEST,
	Tasks_DELETE_SUCCESS,
	Tasks_DELETE_FAILURE,
	Tasks_DETAILS_REQUEST,
	Tasks_DETAILS_SUCCESS,
	Tasks_DETAILS_FAILURE,
	Tasks_DETAILS_RESET,
	Tasks_UPDATE_REQUEST,
	Tasks_UPDATE_SUCCESS,
	Tasks_UPDATE_FAILURE,
	Tasks_UPDATE_RESET,
	TaskAttachment_ADD_FAILURE,
	TaskAttachment_ADD_SUCCESS,
	TaskAttachment_ADD_REQUEST,
	TaskAttachment_DELETE_FAILURE,
	TaskAttachment_DELETE_SUCCESS,
	TaskAttachment_DELETE_REQUEST,
	TaskAttachment_TYPE_FAILURE,
	TaskAttachment_TYPE_SUCCESS,
	TaskAttachment_TYPE_REQUEST,
} from "./TasksConstant";

export const TasksReducer = (
	initialState = {
		Taskss: [],
		isHasTaskss: false,
	},
	action
) => {
	switch (action.type) {
		case Tasks_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllTaskss: [],
			};
		case Tasks_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllTaskss: action.payload.result.data,
				loading: false,
			};

		case Tasks_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//add Tasks
		case Tasks_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Tasks_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Tasks_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Tasks

		case Tasks_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Tasks_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Tasks_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//Tasks details & edit
export const TasksDetailsReducer = (state = { Tasks: {} }, action) => {
	switch (action.type) {
		case Tasks_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Tasks_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				Tasks: action.payload,
			};
		case Tasks_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Tasks_DETAILS_RESET:
			return { ...state, Tasks: {} };
		//Tasks Update
		case Tasks_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Tasks_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Tasks_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Tasks_UPDATE_RESET:
			return { ...state, Tasks: {} };

		//TaskAttachment
		case TaskAttachment_TYPE_REQUEST:
			return {
				...state,
				TaskAttachmentloading: true,
				getAllTaskAttachment: [],
			};
		case TaskAttachment_TYPE_SUCCESS:
			return {
				...state,
				data: action.payload,
				getAllTaskAttachment: action.payload.result.data,
				TaskAttachmentloading: false,
			};
		case TaskAttachment_TYPE_FAILURE:
			return {
				...state,
				TaskAttachmentloading: false,
				getErrorTaskAttachment: action.payload,
			};

		case TaskAttachment_DELETE_REQUEST:
			return {
				...state,
				deleteTaskAttachmentLoading: true,
			};
		case TaskAttachment_DELETE_SUCCESS:
			return {
				...state,
				deleteTaskAttachmentLoading: false,
			};
		case TaskAttachment_DELETE_FAILURE:
			return {
				...state,
				deleteTaskAttachmentLoading: false,
				deleteTaskAttachmentError: action.payload,
			};

		case TaskAttachment_ADD_REQUEST:
			return {
				...state,
				addTaskAttachmentLoading: true,
			};
		case TaskAttachment_ADD_SUCCESS:
			return {
				...state,
				addTaskAttachmentLoading: false,
				data: action.payload,
			};
		case TaskAttachment_ADD_FAILURE:
			return {
				...state,
				addTaskAttachmentLoading: false,
				addTaskAttachmentError: action.payload,
			};

		default:
			return state;
	}
};

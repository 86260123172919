import {
	GET_SCHEDULES_REQUEST,
	GET_SCHEDULES_SUCCESS,
	GET_SCHEDULES_FAILURE,
	ADD_SCHEDULES_REQUEST,
	ADD_SCHEDULES_SUCCESS,
	ADD_SCHEDULES_FAILURE,
	DELETE_SCHEDULES_REQUEST,
	DELETE_SCHEDULES_SUCCESS,
	DELETE_SCHEDULES_FAILURE,
	SCHEDULE_DETAILS_REQUEST,
	SCHEDULE_DETAILS_SUCCESS,
	SCHEDULE_DETAILS_FAILURE,
	SCHEDULE_DETAILS_RESET,
	SCHEDULE_UPDATE_REQUEST,
	SCHEDULE_UPDATE_SUCCESS,
	SCHEDULE_UPDATE_FAILURE,
	SCHEDULE_UPDATE_RESET,
	SCHEDULES_DDL_REQUEST,
	SCHEDULES_DDL_SUCCESS,
	SCHEDULES_DDL_FAILURE,
	SCHEDULES_DDL_RESET,
	SCHEDULE_Import_REQUEST,
	SCHEDULE_Import_SUCCESS,
	SCHEDULE_Import_FAILURE,
	SCHEDULE_Export_REQUEST,
	SCHEDULE_Export_SUCCESS,
	SCHEDULE_Export_FAILURE,
} from "./SchedulesTypeConstants";

export const schedulesReducer = (
	initialState = { schedules: [], schedule: [], isHasSchedules: false },
	action
) => {
	switch (action.type) {
		case GET_SCHEDULES_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_SCHEDULES_SUCCESS:
			return {
				...initialState,
				getAllSchedules: action.payload.result.data,
				loading: false,
			};
		case GET_SCHEDULES_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case SCHEDULES_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				schedules: [],
			};
		case SCHEDULES_DDL_SUCCESS:
			return {
				...initialState,
				schedules: action.payload.result.data,
				loading: false,
				isHasSchedules:
					action.payload.result.data?.length > 0 ? true : false,
			};

		case SCHEDULES_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case SCHEDULES_DDL_RESET:
			return {
				...initialState,
				isHasSchedules: false,
			};

		case ADD_SCHEDULES_REQUEST:
			return { ...initialState, addLoading: true };
		case ADD_SCHEDULES_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				schedule: action.payload,
			};
		case ADD_SCHEDULES_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//import
		case SCHEDULE_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case SCHEDULE_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case SCHEDULE_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};

		//Export
		case SCHEDULE_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case SCHEDULE_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case SCHEDULE_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};

		case DELETE_SCHEDULES_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_SCHEDULES_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_SCHEDULES_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//schedule detailes & edit
export const scheduleDetailsReducer = (state = { schedule: {} }, action) => {
	switch (action.type) {
		case SCHEDULE_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case SCHEDULE_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				schedule: action.payload,
			};
		case SCHEDULE_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case SCHEDULE_DETAILS_RESET:
			return { ...state, schedule: {} };
		//SCHEDULE Update
		case SCHEDULE_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case SCHEDULE_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case SCHEDULE_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case SCHEDULE_UPDATE_RESET:
			return { ...state, schedule: {} };
		default:
			return state;
	}
};

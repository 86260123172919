export const GET_Incident_Attachments_REQUEST = "GET_Incident_Attachments_REQUEST";
export const GET_Incident_Attachments_SUCCESS = "GET_Incident_Attachments_SUCCESS";
export const GET_Incident_Attachments_FAILURE = "GET_Incident_Attachments_FAILURE";

export const Add_Incident_Attachments_REQUEST = "Add_Incident_Attachments_REQUEST";
export const Add_Incident_Attachments_SUCCESS = "Add_Incident_Attachments_SUCCESS";
export const Add_Incident_Attachments_FAILURE = "Add_Incident_Attachments_FAILURE";

export const Delete_Incident_Attachments_REQUEST = "Delete_Incident_Attachments_REQUEST";
export const Delete_Incident_Attachments_SUCCESS = "Delete_Incident_Attachments_SUCCESS";
export const Delete_Incident_Attachments_FAILURE = "Delete_Incident_Attachments_FAILURE";

import {
	ServerSettings_UPDATE_REQUEST,
	ServerSettings_UPDATE_SUCCESS,
	ServerSettings_UPDATE_FAILURE,
	ServerSettings_UPDATE_RESET,
} from "./serverSettingConstant";

export const ServerSettingsReducer = (
	initialState = {
		ServerSettingss: [],
	},
	action
) => {
	switch (action.type) {
		//ServerSettings Update
		case ServerSettings_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case ServerSettings_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case ServerSettings_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case ServerSettings_UPDATE_RESET:
			return { ...initialState,ServerSettings: {} };
		default:
			return initialState;
	}
};

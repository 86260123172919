export const COLUMNS_REQUEST = "COLUMNS_REQUEST";
export const COLUMNS_SUCCESS = "COLUMNS_SUCCESS";
export const COLUMNS_FAILURE = "COLUMNS_FAILURE";

export const COLUMNS_DDL_REQUEST = "COLUMNS_DDL_REQUEST";
export const COLUMNS_DDL_SUCCESS = "COLUMNS_DDL_SUCCESS";
export const COLUMNS_DDL_FAILURE = "COLUMNS_DDL_FAILURE";
export const COLUMNS_DDL_RESET = "COLUMNS_DDL_RESET";

export const ADD_COLUMN_REQUEST = "ADD_COLUMN_REQUEST";
export const ADD_COLUMN_SUCCESS = "ADD_COLUMN_SUCCESS";
export const ADD_COLUMN_FAILURE = "ADD_COLUMN_FAILURE";

export const DELETE_COLUMN_REQUEST = "DELETE_COLUMN_REQUEST";
export const DELETE_COLUMN_SUCCESS = "DELETE_COLUMN_SUCCESS";
export const DELETE_COLUMN_FAILURE = "DELETE_COLUMN_FAILURE";

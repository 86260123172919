export const DEPARTMENT_TYPE_REQUEST = "DEPARTMENT_TYPE_REQUEST";
export const DEPARTMENT_TYPE_SUCCESS = "DEPARTMENT_TYPE_SUCCESS";
export const DEPARTMENT_TYPE_FAILURE = "DEPARTMENT_TYPE_FAILURE";

export const DEPARTMENT_ADD_REQUEST = "DEPARTMENT_ADD_REQUEST";
export const DEPARTMENT_ADD_SUCCESS = "DEPARTMENT_ADD_SUCCESS";
export const DEPARTMENT_ADD_FAILURE = "DEPARTMENT_ADD_FAILURE";

export const Departments_DDL_REQUEST = "Departments_DDL_REQUEST";
export const Departments_DDL_SUCCESS = "Departments_DDLDepartments_SUCCESS";
export const Departments_DDL_FAILURE = "Departments_DDL_FAILURE";
export const Departments_DDL_RESET = "Departments_DDL_RESET";

export const DEPARTMENT_DELETE_REQUEST = "DEPARTMENT_DELETE_REQUEST";
export const DEPARTMENT_DELETE_SUCCESS = "DEPARTMENT_DELETE_SUCCESS";
export const DEPARTMENT_DELETE_FAILURE = "DEPARTMENT_DELETE_FAILURE";

export const DEPARTMENT_UPDATE_REQUEST = "DEPARTMENT_UPDATE_REQUEST";
export const DEPARTMENT_UPDATE_SUCCESS = "DEPARTMENT_UPDATE_SUCCESS";
export const DEPARTMENT_UPDATE_FAILURE = "DEPARTMENT_UPDATE_FAILURE";
export const DEPARTMENT_UPDATE_RESET = "DEPARTMENT_UPDATE_RESET";

export const DEPARTMENT_DETAILS_REQUEST = "DEPARTMENT_DETAILS_REQUEST";
export const DEPARTMENT_DETAILS_SUCCESS = "DEPARTMENT_DETAILS_SUCCESS";
export const DEPARTMENT_DETAILS_FAILURE = "DEPARTMENT_DETAILS_FAILURE";
export const DEPARTMENT_DETAILS_RESET = "DEPARTMENT_DETAILS_RESET";

export const DEPARTMENT_Import_REQUEST = "DEPARTMENT_Import_REQUEST";
export const DEPARTMENT_Import_SUCCESS = "DEPARTMENT_Import_SUCCESS";
export const DEPARTMENT_Import_FAILURE = "DEPARTMENT_Import_FAILURE";

export const DEPARTMENT_Export_REQUEST = "DEPARTMENT_Export_REQUEST";
export const DEPARTMENT_Export_SUCCESS = "DEPARTMENT_Export_SUCCESS";
export const DEPARTMENT_Export_FAILURE = "DEPARTMENT_Export_FAILURE";

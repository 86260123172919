import {
	GET_ACCESS_POINT_VISITOR_REQUEST,
	GET_ACCESS_POINT_VISITOR_SUCCESS,
	GET_ACCESS_POINT_VISITOR_FAILURE,
	DELETE_ACCESS_POINT_VISITOR_REQUEST,
	DELETE_ACCESS_POINT_VISITOR_SUCCESS,
	DELETE_ACCESS_POINT_VISITOR_FAILURE,
	ADD_ACCESS_POINT_VISITOR_REQUEST,
	ADD_ACCESS_POINT_VISITOR_SUCCESS,
	ADD_ACCESS_POINT_VISITOR_FAILURE,
	AccessPoints_DDLVisitor_REQUEST,
	AccessPoints_DDLVisitor_SUCCESS,
	AccessPoints_DDLVisitor_FAILURE,
	AccessPoints_DDLVisitor_RESET,
} from "./AccessPointsVisitorTypeConstants";

export const accessPointVisitorReducer = (
	initialState = { visitorAccessPoints: [], isHasAccessPointVisitor: false },
	action
) => {
	switch (action.type) {
		case GET_ACCESS_POINT_VISITOR_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_ACCESS_POINT_VISITOR_SUCCESS:
			return {
				...initialState,
				accessPoint: action.payload.result.data,
				loading: false,
			};

		case GET_ACCESS_POINT_VISITOR_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case AccessPoints_DDLVisitor_REQUEST:
			return {
				...initialState,
				loading: true,
				visitorAccessPoints: [],
			};
		case AccessPoints_DDLVisitor_SUCCESS:
			return {
				...initialState,
				visitorAccessPoints: action.payload.result.data,
				loading: false,
				isHasAccessPointVisitor: action.payload.result.data?.length? true : false
			};

		case AccessPoints_DDLVisitor_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case AccessPoints_DDLVisitor_RESET:
			return {
				...initialState,isHasAccessPointVisitor: false
			};

		case DELETE_ACCESS_POINT_VISITOR_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_ACCESS_POINT_VISITOR_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_ACCESS_POINT_VISITOR_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		case ADD_ACCESS_POINT_VISITOR_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_ACCESS_POINT_VISITOR_SUCCESS: {
			return {
				...initialState,
				addLoading: false,
			};
		}
		case ADD_ACCESS_POINT_VISITOR_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		default:
			return initialState;
	}
};

export const GET_ACCESS_POINT_GROUP_REQUEST = "GET_ACCESS_POINT_GROUP_REQUEST";
export const GET_ACCESS_POINT_GROUP_SUCCESS = "GET_ACCESS_POINT_GROUP_SUCCESS";
export const GET_ACCESS_POINT_GROUP_FAILURE = "GET_ACCESS_POINT_GROUP_FAILURE";

export const DELETE_ACCESS_POINT_GROUPS_REQUEST =
	"DELETE_ACCESS_POINT_GROUPS_REQUEST";
export const DELETE_ACCESS_POINT_GROUPS_SUCCESS =
	"DELETE_ACCESS_POINT_GROUPS_SUCCESS";
export const DELETE_ACCESS_POINT_GROUPS_FAILURE =
	"DELETE_ACCESS_POINT_GROUPS_FAILURE";
export const DELETE_ACCESS_POINT_GROUPS_RESET =
	"DELETE_ACCESS_POINT_GROUPS_RESET";

export const ACCESS_POINT_GROUPS_DDL_REQUEST =
	"ACCESS_POINT_GROUPS_DDL_REQUEST";
export const ACCESS_POINT_GROUPS_DDL_SUCCESS =
	"ACCESS_POINT_GROUPS_DDL_SUCCESS";
export const ACCESS_POINT_GROUPS_DDL_FAILURE =
	"ACCESS_POINT_GROUPS_DDL_FAILURE";
export const ACCESS_POINT_GROUPS_DDL_RESET = "ACCESS_POINT_GROUPS_DDL_RESET";

export const AccessPointGroup_Import_REQUEST =
	"AccessPointGroup_Import_REQUEST";
export const AccessPointGroup_Import_SUCCESS =
	"AccessPointGroup_Import_SUCCESS";
export const AccessPointGroup_Import_FAILURE =
	"AccessPointGroup_Import_FAILURE";

export const AccessPointGroup_Export_REQUEST =
	"AccessPointGroup_Export_REQUEST";
export const AccessPointGroup_Export_SUCCESS =
	"AccessPointGroup_Export_SUCCESS";
export const AccessPointGroup_Export_FAILURE =
	"AccessPointGroup_Export_FAILURE";
export const ADD_ACCESS_POINT_GROUP_REQUEST = "ADD_ACCESS_POINT_GROUP_REQUEST";
export const ADD_ACCESS_POINT_GROUP_SUCCESS = "ADD_ACCESS_POINT_GROUP_SUCCESS";
export const ADD_ACCESS_POINT_GROUP_FAILURE = "ADD_ACCESS_POINT_GROUP_FAILURE";

export const GET_ACESS_POINT_GROUP_DETAILS_REQUEST =
	"GET_ACESS_POINT_GROUP_DETAILS_REQUEST";
export const GET_ACESS_POINT_GROUP_DETAILS_SUCCESS =
	"GET_ACESS_POINT_GROUP_DETAILS_SUCCESS";
export const GET_ACESS_POINT_GROUP_DETAILS_FAILURE =
	"GET_ACESS_POINT_GROUP_DETAILS_FAILURE";
export const GET_ACESS_POINT_GROUP_DETAILS_RESET =
	"GET_ACESS_POINT_GROUP_DETAILS_RESET";

export const ACCESS_POINT_GROUP_UPDATE_REQUEST =
	"ACCESS_POINT_GROUP_UPDATE_REQUEST";
export const ACCESS_POINT_GROUP_UPDATE_SUCCESS =
	"ACCESS_POINT_GROUP_UPDATE_SUCCESS";
export const ACCESS_POINT_GROUP_UPDATE_FAILURE =
	"ACCESS_POINT_GROUP_UPDATE_FAILURE";

export const ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST =
	"ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST";
export const ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS =
	"ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS";
export const ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE =
	"ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE";
export const ACCESS_POINT_GROUP_ACCESSPOINTS_RESET =
	"ACCESS_POINT_GROUP_ACCESSPOINTS_RESET";

export const ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST =
	"ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST";
export const ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS =
	"ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS";
export const ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE =
	"ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE";

export const DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_REQUEST =
	"DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_REQUEST";
export const DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_SUCCESS =
	"DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_SUCCESS";
export const DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_FAILURE =
	"DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_FAILURE";

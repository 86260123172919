import {
	PAYMENT_TYPE_REQUEST,
	PAYMENT_TYPE_SUCCESS,
	PAYMENT_TYPE_FAILURE,
	PAYMENT_ADD_FAILURE,
	PAYMENT_ADD_SUCCESS,
	PAYMENT_ADD_REQUEST,
	PAYMENT_DELETE_REQUEST,
	PAYMENT_DELETE_SUCCESS,
	PAYMENT_DELETE_FAILURE,
	PAYMENT_DETAILS_REQUEST,
	PAYMENT_DETAILS_SUCCESS,
	PAYMENT_DETAILS_FAILURE,
	PAYMENT_DETAILS_RESET,
	PAYMENT_UPDATE_REQUEST,
	PAYMENT_UPDATE_SUCCESS,
	PAYMENT_UPDATE_FAILURE,
	PAYMENT_UPDATE_RESET,
	DDLModules_TYPE_FAILURE,
	DDLModules_TYPE_SUCCESS,
	DDLModules_TYPE_REQUEST,
	DDLModules_TYPE_RESET,
} from "./paymentTypeContants";

export const PAYMENTReducer = (
	initialState = {
		payments: [],
	},
	action
) => {
	switch (action.type) {
		case PAYMENT_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				payments: [],
			};
		case PAYMENT_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				payments: action.payload.result.data,
				loading: false,
			};

		case PAYMENT_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//add payments
		case PAYMENT_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case PAYMENT_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case PAYMENT_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete payments

		case PAYMENT_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case PAYMENT_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case PAYMENT_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//payments detailes & edit
export const paymentsDetailsReducer = (state = { payment: {} }, action) => {
	switch (action.type) {
		case PAYMENT_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case PAYMENT_DETAILS_SUCCESS:
			return { ...state, detailsLoading: false, payment: action.payload };
		case PAYMENT_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case PAYMENT_DETAILS_RESET:
			return { ...state, payment: {} };
		//payments Update
		case PAYMENT_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case PAYMENT_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case PAYMENT_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case PAYMENT_UPDATE_RESET:
			return { ...state, payment: {} };
		default:
			return state;
	}
};

export const DDLModulesReducer = (
	initialState = {
		DDLModules: [],
		isHasModules: false,
	},
	action
) => {
	switch (action.type) {
		case DDLModules_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				DDLModules: [],
			};
		case DDLModules_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				DDLModules: action.payload.result.data,
				loading: false,
				isHasModules: action.payload.result.data?.length ? true : false,
			};

		case DDLModules_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case DDLModules_TYPE_RESET:
			return {
				...initialState,
				isHasModules: false,
			};

		default:
			return initialState;
	}
};

import {
	Branches_TYPE_REQUEST,
	Branches_TYPE_SUCCESS,
	Branches_TYPE_FAILURE,
	Branches_ADD_FAILURE,
	Branches_ADD_SUCCESS,
	Branches_ADD_REQUEST,
	Branches_DELETE_REQUEST,
	Branches_DELETE_SUCCESS,
	Branches_DELETE_FAILURE,
	Branches_DETAILS_REQUEST,
	Branches_DETAILS_SUCCESS,
	Branches_DETAILS_FAILURE,
	Branches_DETAILS_RESET,
	Branches_UPDATE_REQUEST,
	Branches_UPDATE_SUCCESS,
	Branches_UPDATE_FAILURE,
	Branches_UPDATE_RESET,
	Branches_DDL_REQUEST,
	Branches_DDL_SUCCESS,
	Branches_DDL_FAILURE,
	Branches_DDL_RESET,
	CommercialRegisterBranchAttachment_TYPE_FAILURE,
	CommercialRegisterBranchAttachment_TYPE_SUCCESS,
	CommercialRegisterBranchAttachment_TYPE_REQUEST,
	CommercialRegisterBranchAttachment_DELETE_FAILURE,
	CommercialRegisterBranchAttachment_DELETE_SUCCESS,
	CommercialRegisterBranchAttachment_DELETE_REQUEST,
	CommercialRegisterBranchAttachment_ADD_REQUEST,
	CommercialRegisterBranchAttachment_ADD_SUCCESS,
	CommercialRegisterBranchAttachment_ADD_FAILURE,
	InsurancePoicyBranchAttachment_TYPE_REQUEST,
	InsurancePoicyBranchAttachment_TYPE_SUCCESS,
	InsurancePoicyBranchAttachment_TYPE_FAILURE,
	InsurancePoicyBranchAttachment_DELETE_REQUEST,
	InsurancePoicyBranchAttachment_DELETE_SUCCESS,
	InsurancePoicyBranchAttachment_DELETE_FAILURE,
	InsurancePoicyBranchAttachment_ADD_REQUEST,
	InsurancePoicyBranchAttachment_ADD_SUCCESS,
	InsurancePoicyBranchAttachment_ADD_FAILURE,
	InsurancePoicyBranchAttachment_Edit_FAILURE,
	InsurancePoicyBranchAttachment_Edit_SUCCESS,
	InsurancePoicyBranchAttachment_Edit_REQUEST,
} from "./BranchesTypeConstant";

export const BranchesReducer = (
	initialState = {
		Branches: [],
		getAllBranches:[],
		isHasBranches: false,
	},
	action
) => {
	switch (action.type) {
		case Branches_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllBranches: [],
			};
		case Branches_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllBranches: action.payload.result.data,
				loading: false,
			};
		case Branches_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		//ddl
		case Branches_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				Branches: [],
			};
		case Branches_DDL_SUCCESS:
			return {
				...initialState,
				Branches: action.payload.result.data,
				loading: false,
				isHasBranches: action.payload.result.data?.length
					? true
					: false,
			};
		case Branches_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLBranchesErrors: action.payload,
			};
		case Branches_DDL_RESET:
			return {
				...initialState,
				isHasBranches: false,
			};

		//add Branches
		case Branches_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Branches_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Branches_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Branches
		case Branches_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Branches_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Branches_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//Branches details & edit
export const BranchesDetailsReducer = (
	state = { Branches: {}, CommercialRegisterBranchAttachment: {} },
	action
) => {
	switch (action.type) {
		case Branches_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Branches_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				Branches: action.payload,
			};
		case Branches_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Branches_DETAILS_RESET:
			return { ...state, Branches: {} };

		//Branches Update
		case Branches_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Branches_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Branches_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Branches_UPDATE_RESET:
			return { ...state, Branches: {} };

		//CommercialRegisterBranchAttachment
		case CommercialRegisterBranchAttachment_TYPE_REQUEST:
			return {
				...state,
				CommercialRegisterBranchAttachmentloading: true,
				getAllCommercialRegisterBranchAttachment: [],
			};
		case CommercialRegisterBranchAttachment_TYPE_SUCCESS:
			return {
				...state,
				data: action.payload,
				getAllCommercialRegisterBranchAttachment:
					action.payload.result.data,
				CommercialRegisterBranchAttachmentloading: false,
			};
		case CommercialRegisterBranchAttachment_TYPE_FAILURE:
			return {
				...state,
				CommercialRegisterBranchAttachmentloading: false,
				getErrorCommercialRegisterBranchAttachment: action.payload,
			};

		case CommercialRegisterBranchAttachment_DELETE_REQUEST:
			return {
				...state,
				deleteCommercialRegisterBranchAttachmentLoading: true,
			};
		case CommercialRegisterBranchAttachment_DELETE_SUCCESS:
			return {
				...state,
				deleteCommercialRegisterBranchAttachmentLoading: false,
			};
		case CommercialRegisterBranchAttachment_DELETE_FAILURE:
			return {
				...state,
				deleteCommercialRegisterBranchAttachmentLoading: false,
				deleteCommercialRegisterBranchAttachmentError: action.payload,
			};

		case CommercialRegisterBranchAttachment_ADD_REQUEST:
			return {
				...state,
				addCommercialRegisterBranchAttachmentLoading: true,
			};
		case CommercialRegisterBranchAttachment_ADD_SUCCESS:
			return {
				...state,
				addCommercialRegisterBranchAttachmentLoading: false,
				data: action.payload,
			};
		case CommercialRegisterBranchAttachment_ADD_FAILURE:
			return {
				...state,
				addCommercialRegisterBranchAttachmentLoading: false,
				addCommercialRegisterBranchAttachmentError: action.payload,
			};

		//InsurancePoicyBranchAttachment
		case InsurancePoicyBranchAttachment_TYPE_REQUEST:
			return {
				...state,
				InsurancePoicyBranchAttachmentloading: true,
				getAllInsurancePoicyBranchAttachment: [],
			};
		case InsurancePoicyBranchAttachment_TYPE_SUCCESS:
			return {
				...state,
				data: action.payload,
				getAllInsurancePoicyBranchAttachment:
					action.payload.result.data,
				InsurancePoicyBranchAttachmentloading: false,
			};
		case InsurancePoicyBranchAttachment_TYPE_FAILURE:
			return {
				...state,
				InsurancePoicyBranchAttachmentloading: false,
				getErrorInsurancePoicyBranchAttachment: action.payload,
			};

		case InsurancePoicyBranchAttachment_DELETE_REQUEST:
			return {
				...state,
				deleteInsurancePoicyBranchAttachmentLoading: true,
			};
		case InsurancePoicyBranchAttachment_DELETE_SUCCESS:
			return {
				...state,
				deleteInsurancePoicyBranchAttachmentLoading: false,
			};
		case InsurancePoicyBranchAttachment_DELETE_FAILURE:
			return {
				...state,
				deleteInsurancePoicyBranchAttachmentLoading: false,
				deleteInsurancePoicyBranchAttachmentError: action.payload,
			};

		case InsurancePoicyBranchAttachment_ADD_REQUEST:
			return {
				...state,
				addInsurancePoicyBranchAttachmentLoading: true,
			};
		case InsurancePoicyBranchAttachment_ADD_SUCCESS:
			return {
				...state,
				addInsurancePoicyBranchAttachmentLoading: false,
				data: action.payload,
			};
		case InsurancePoicyBranchAttachment_ADD_FAILURE:
			return {
				...state,
				addInsurancePoicyBranchAttachmentLoading: false,
				addInsurancePoicyBranchAttachmentError: action.payload,
			};
		case InsurancePoicyBranchAttachment_Edit_REQUEST:
			return {
				...state,
				EditInsurancePoicyBranchAttachmentLoading: true,
			};
		case InsurancePoicyBranchAttachment_Edit_SUCCESS:
			return {
				...state,
				EditInsurancePoicyBranchAttachmentLoading: false,
				data: action.payload,
			};
		case InsurancePoicyBranchAttachment_Edit_FAILURE:
			return {
				...state,
				EditInsurancePoicyBranchAttachmentLoading: false,
				EditInsurancePoicyBranchAttachmentError: action.payload,
			};
		default:
			return state;
	}
};

import { Input } from "antd";
import AttachmentComponent from "../AttachmentComponent";
import SelectboxComponent from "../SelectboxComponent";
import './style.css'

const InputComponent = (props) => {
    if (props.type === "textarea") {
        return <Input.TextArea className="app-input-item" {...props}  />;
    }

    if (props.type === "password") {
        return <Input.Password className="app-input-item" {...props} style={{ width: props.width ? props.width : "100%"	 }} />;
    }
    if (props.type === "select") {
        return <SelectboxComponent {...props} width="100%" />;
    }
    if (props.type === "file") {
        return <AttachmentComponent {...props} />;
    }

    return <Input className="app-input-item" {...props} style={{ width: props.width ? props.width : "100%"	 }} />;
};

export default InputComponent;

export const TaskAttachmentReport_get_REQUEST =
	"TaskAttachmentReport_get_REQUEST";
export const TaskAttachmentReport_get_SUCCESS =
	"TaskAttachmentReport_get_SUCCESS";
export const TaskAttachmentReport_get_FAILURE =
	"TaskAttachmentReport_get_FAILURE";

export const TaskAttachmentReport_DELETE_REQUEST =
	"TaskAttachmentReport_DELETE_REQUEST";
export const TaskAttachmentReport_DELETE_SUCCESS =
	"TaskAttachmentReport_DELETE_SUCCESS";
export const TaskAttachmentReport_DELETE_FAILURE =
	"TaskAttachmentReport_DELETE_FAILURE";

export const TaskAttachmentReport_ADD_REQUEST =
	"TaskAttachmentReport_ADD_REQUEST";
export const TaskAttachmentReport_ADD_SUCCESS =
	"TaskAttachmentReport_ADD_SUCCESS";
export const TaskAttachmentReport_ADD_FAILURE =
	"TaskAttachmentReport_ADD_FAILURE";

import React from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import IntlMessages from "../../util/IntlMessages";
import DefaultCardComponent from "../Cards/DefaultCard";
import { useIntl } from "react-intl";
import TextArea from "antd/lib/input/TextArea";
import { patternNameAr, patternNameEn } from "../../util/regexPattern";

const EquipmentComponent = ({
	onFinish,
	isSendLink,
	hideAddModal,
	visitors,
	loading,
}) => {
	const intl = useIntl();

	return (
		<DefaultCardComponent
			isAdd={false}
			title={<IntlMessages id="card.title.addVisitorEquipment" />}
		>
			<Form
				name="basic"
				labelCol={{
					span: 24,
				}}
				onFinish={onFinish}
				autoComplete="off"
			>
				<Row>
					<Col xs={24} md={12}>
						<Form.Item
							name="nameEn"
							label={<IntlMessages id="label.nameEn" />}
							rules={patternNameEn}
							hasFeedback
						>
							<Input
								placeholder={intl.formatMessage({
									id: "placeholder.name",
								})}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} md={12}>
						<Form.Item
							name="nameAr"
							label={<IntlMessages id="label.nameAr" />}
							rules={[patternNameAr]}
							hasFeedback
						>
							<Input
								placeholder={intl.formatMessage({
									id: "placeholder.nameAr",
								})}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					{!isSendLink && (
						<Col xs={24} md={12}>
							<Form.Item
								name="visitorId"
								label={<IntlMessages id="label.visitor" />}
								rules={[
									{
										required: true,
										message: (
											<IntlMessages id="error.visitor" />
										),
									},
								]}
								hasFeedback
							>
								<Select
									showSearch
									filterOption={(input, option) =>
										option.children
											.toLowerCase()
											.indexOf(input.toLowerCase()) >= 0
									}
									style={{
										width: "100%",
									}}
									name="visitorId"
									placeholder={intl.formatMessage({
										id: "placeholder.selectVisitor",
									})}
								>
									{visitors?.map((visitor) => {
										return (
											<Select.Option
												key={visitor.id}
												value={visitor.id}
											>
												{visitor.nameEn}
											</Select.Option>
										);
									})}
								</Select>
							</Form.Item>
						</Col>
					)}
					<Col xs={24} md={!isSendLink ? 12 : 24}>
						<Form.Item
							name="serialNumber"
							label={<IntlMessages id="label.serialNumber" />}
							rules={[
								{
									required: true,
									message: (
										<IntlMessages id="error.serialNumber" />
									),
								},
							]}
						>
							<Input
								placeholder={intl.formatMessage({
									id: "placeholder.serialNumber",
								})}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col xs={24}>
						<Form.Item
							name="reason"
							label={<IntlMessages id="label.reason" />}
							rules={[
								{
									required: true,
									message: <IntlMessages id="error.reason" />,
								},
							]}
							hasFeedback
						>
							<TextArea
								rows={6}
								style={{ width: "100%" }}
								placeholder={intl.formatMessage({
									id: "placeholder.reason",
								})}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row className="gx-mt-3">
					<Col className="gutter-row" span={isSendLink ? 24 : 12}>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								style={{ width: "100%" }}
								className="button-rtl"
							>
								<IntlMessages id="button.add" />
							</Button>
						</Form.Item>
					</Col>
					{!isSendLink && (
						<Col span={12}>
							<Form.Item>
								<Button
									onClick={hideAddModal}
									style={{ width: "100%" }}
								>
									<IntlMessages id="button.cancel" />
								</Button>
							</Form.Item>
						</Col>
					)}
				</Row>
			</Form>
		</DefaultCardComponent>
	);
};

export default EquipmentComponent;

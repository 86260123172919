import Api from "../../../util/Api";
import {
	SignUp_FAILURE,
	SignUp_REQUEST,
	SignUp_SUCCESS,
} from "./signUpTypeContants";

export const SignUp = ({
	nameEn,
	nameAr,
	firstNameEn,
	lastNameEn,
	firstNameAr,
	lastNameAr,
	address,
	mobileNumber,
	emailAddress,
	modules,
}) => {
	return async (dispatch) => {
		dispatch({ type: SignUp_REQUEST });
		try {
			const data = await Api.post("Auth/SignUp", {
				nameEn,
				nameAr,
				firstNameEn,
				lastNameEn,
				firstNameAr,
				lastNameAr,
				address,
				mobileNumber,
				emailAddress,
				modules,
			});
			return dispatch({ type: SignUp_SUCCESS, payload: data });
		} catch (error) {
			dispatch({
				type: SignUp_FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};
};

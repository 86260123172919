import {
	GET_ACCESS_POINT_GROUP_REQUEST,
	GET_ACCESS_POINT_GROUP_SUCCESS,
	GET_ACCESS_POINT_GROUP_FAILURE,
	DELETE_ACCESS_POINT_GROUPS_REQUEST,
	DELETE_ACCESS_POINT_GROUPS_SUCCESS,
	DELETE_ACCESS_POINT_GROUPS_FAILURE,
	ADD_ACCESS_POINT_GROUP_REQUEST,
	ADD_ACCESS_POINT_GROUP_SUCCESS,
	ADD_ACCESS_POINT_GROUP_FAILURE,
	GET_ACESS_POINT_GROUP_DETAILS_REQUEST,
	GET_ACESS_POINT_GROUP_DETAILS_SUCCESS,
	GET_ACESS_POINT_GROUP_DETAILS_FAILURE,
	GET_ACESS_POINT_GROUP_DETAILS_RESET,
	ACCESS_POINT_GROUP_UPDATE_REQUEST,
	ACCESS_POINT_GROUP_UPDATE_SUCCESS,
	ACCESS_POINT_GROUP_UPDATE_FAILURE,
	ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST,
	ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS,
	ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE,
	ACCESS_POINT_GROUP_ACCESSPOINTS_RESET,
	ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST,
	ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS,
	ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE,
	DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_REQUEST,
	DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_SUCCESS,
	DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_FAILURE,
	ACCESS_POINT_GROUPS_DDL_REQUEST,
	ACCESS_POINT_GROUPS_DDL_SUCCESS,
	ACCESS_POINT_GROUPS_DDL_FAILURE,
	ACCESS_POINT_GROUPS_DDL_RESET,
	AccessPointGroup_Import_REQUEST,
	AccessPointGroup_Import_SUCCESS,
	AccessPointGroup_Import_FAILURE,
	AccessPointGroup_Export_REQUEST,
	AccessPointGroup_Export_SUCCESS,
	AccessPointGroup_Export_FAILURE,
} from "./AccessPointGroupTypeConstants";

export const accessPointGroupsReducer = (
	initialState = { accessPointGroups: [], isHasAccessPointGroups: false },
	action
) => {
	switch (action.type) {
		case GET_ACCESS_POINT_GROUP_REQUEST:
			return {
				...initialState,
				loading: true,
			};
		case GET_ACCESS_POINT_GROUP_SUCCESS:
			return {
				...initialState,
				getAccessPointGroups: action.payload.result.data,
				loading: false,
			};
		case GET_ACCESS_POINT_GROUP_FAILURE:
			return {
				...initialState,
				getError: action.payload,
				loading: false,
			};
		//import
		case AccessPointGroup_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case AccessPointGroup_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case AccessPointGroup_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};
		//Export
		case AccessPointGroup_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case AccessPointGroup_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case AccessPointGroup_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		case ACCESS_POINT_GROUPS_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				accessPointGroups: [],
			};
		case ACCESS_POINT_GROUPS_DDL_SUCCESS:
			return {
				...initialState,
				accessPointGroups: action.payload.result.data,
				loading: false,
				isHasAccessPointGroups:
					action.payload.result.data?.length > 0 ? true : false,
			};

		case ACCESS_POINT_GROUPS_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case ACCESS_POINT_GROUPS_DDL_RESET:
			return {
				...initialState,
				isHasAccessPointGroups: false,
			};
		/* Delete Access Point Groups */
		case DELETE_ACCESS_POINT_GROUPS_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_ACCESS_POINT_GROUPS_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_ACCESS_POINT_GROUPS_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		/* Add Access Point Group */
		case ADD_ACCESS_POINT_GROUP_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_ACCESS_POINT_GROUP_SUCCESS:
			return {
				...initialState,
				addLoading: false,
			};
		case ADD_ACCESS_POINT_GROUP_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		/* Get Access Point Group Details */
		case GET_ACESS_POINT_GROUP_DETAILS_REQUEST:
			return {
				...initialState,
				detailsLoading: true,
			};
		case GET_ACESS_POINT_GROUP_DETAILS_SUCCESS:
			return {
				...initialState,
				detailsLoading: false,
				accessPointGroup: action.payload,
			};
		case GET_ACESS_POINT_GROUP_DETAILS_FAILURE:
			return {
				...initialState,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case GET_ACESS_POINT_GROUP_DETAILS_RESET:
			return {
				...initialState,
				accessPointGroup: {},
			};
		/*  Update Access Point Group */
		case ACCESS_POINT_GROUP_UPDATE_REQUEST:
			return {
				...initialState,
				updateLoading: true,
			};
		case ACCESS_POINT_GROUP_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case ACCESS_POINT_GROUP_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		/* Get Access Point Group Access Points  */

		case ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST:
			return {
				...initialState,
				accessPointsLoading: true,
			};
		case ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS:
			return {
				...initialState,
				accessPointsLoading: false,
				accessPointForGroups: action.payload.result.data,
			};
		case ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE:
			return {
				...initialState,
				accessPointsLoading: false,
				getError: action.payload,
			};
		case ACCESS_POINT_GROUP_ACCESSPOINTS_RESET:
			return {
				...initialState,
				accessPointForGroups: [],
			};
		/* Add Access Point Group AccessPoints */
		case ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_REQUEST:
			return {
				...initialState,
				addAccessPointLoading: true,
			};
		case ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_SUCCESS:
			return {
				...initialState,
				addAccessPointLoading: false,
			};
		case ADD_ACCESS_POINT_GROUP_ACCESSPOINTS_FAILURE:
			return {
				...initialState,
				addAccessPointLoading: false,
				addError: action.payload,
			};
		/* Delete Access Point Group AccessPoints */
		case DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_REQUEST:
			return {
				...initialState,
				deleteAccessPointLoading: true,
			};
		case DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_SUCCESS:
			return {
				...initialState,
				deleteAccessPointLoading: false,
			};
		case DELETE_ACCESS_POINT_GROUPS_ACCESSPOINTS_FAILURE:
			return {
				...initialState,
				deleteAccessPointLoading: false,
				deleteError: action.payload,
			};

		default:
			return initialState;
	}
};

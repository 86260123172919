import {
	ADD_INJURED_EMPLOYEE_FAILURE,
	ADD_INJURED_EMPLOYEE_REQUEST,
	ADD_INJURED_EMPLOYEE_SUCCESS,
	DELETE_INJURED_EMPLOYEE_FAILURE,
	DELETE_INJURED_EMPLOYEE_REQUEST,
	DELETE_INJURED_EMPLOYEE_SUCCESS,
	INJURED_EMPLOYEE_FAILURE,
	INJURED_EMPLOYEE_REQUEST,
	INJURED_EMPLOYEE_Reset,
	INJURED_EMPLOYEE_SUCCESS,
} from "./InjuredEmployeeConstant";

// Injured Employees
export const InjuredEmployeesReducer = (
	initialState = {
		injuredEmployess: [],
		GetInjuredEmployeesLoading: false,
		addInjuredEmployeesLoading: false,
		deleteInjuredEmployeesLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Injured Employees
		case INJURED_EMPLOYEE_REQUEST:
			return {
				...initialState,
				GetInjuredEmployeesLoading: true,
			};
		case INJURED_EMPLOYEE_SUCCESS:
			return {
				...initialState,
				injuredEmployess: action.payload.result.data,
				GetInjuredEmployeesLoading: false,
			};
		case INJURED_EMPLOYEE_FAILURE:
			return {
				...initialState,
				GetInjuredEmployeesLoading: false,
				getError: action.payload,
			};
		case INJURED_EMPLOYEE_Reset:
			return {
				...initialState,
				injuredEmployess: [],
			};
		// Add Injured Employees
		case ADD_INJURED_EMPLOYEE_REQUEST:
			return {
				...initialState,
				addInjuredEmployeesLoading: true,
			};
		case ADD_INJURED_EMPLOYEE_SUCCESS:
			return {
				...initialState,
				addInjuredEmployeesLoading: false,
			};
		case ADD_INJURED_EMPLOYEE_FAILURE:
			return {
				...initialState,
				addInjuredEmployeesLoading: false,
				addError: action.payload,
			};

		// Delete Injured Employees
		case DELETE_INJURED_EMPLOYEE_REQUEST:
			return {
				...initialState,
				deleteInjuredEmployeesLoading: true,
			};
		case DELETE_INJURED_EMPLOYEE_SUCCESS:
			return {
				...initialState,
				deleteInjuredEmployeesLoading: false,
			};
		case DELETE_INJURED_EMPLOYEE_FAILURE:
			return {
				...initialState,
				deleteInjuredEmployeesLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

import api from "../../util/Api";
import {
	ADD_DASHBOARD_REQUEST,
	ADD_DASHBOARD_SUCCESS,
	ADD_DASHBOARD__FAILURE,
	DASHBOARD_REQUEST,
	DASHBOARD_SUCCESS,
	DASHBOARD__FAILURE,
	ADD_CARD_DASHBOARD_REQUEST,
	ADD_CARD_DASHBOARD_SUCCESS,
	ADD_CARD_DASHBOARD__FAILURE,
	USER_DASHBOARD_REQUEST,
	USER_DASHBOARD_SUCCESS,
	USER_DASHBOARD__FAILURE,
	DELETE_USER_DASHBOARD_REQUEST,
	DELETE_USER_DASHBOARD_SUCCESS,
	DELETE_USER_DASHBOARD__FAILURE,
	DASHBOARD_ITEMS_REQUEST,
	DASHBOARD_ITEMS_SUCCESS,
	DASHBOARD_ITEMS_FAILURE,
	DELETE_CARD_DASHBOARD_REQUEST,
	DELETE_CARD_DASHBOARD_SUCCESS,
	DELETE_CARD_DASHBOARD__FAILURE,
	UpdatePlacements_DASHBOARD__FAILURE,
	UpdatePlacements_DASHBOARD_SUCCESS,
	UpdatePlacements_DASHBOARD_REQUEST,
	UpdateItemPlacements_DASHBOARD_REQUEST,
	UpdateItemPlacements_DASHBOARD_SUCCESS,
	UpdateItemPlacements_DASHBOARD__FAILURE,
	Edit_DASHBOARD_REQUEST,
	Edit_DASHBOARD_SUCCESS,
	Edit_DASHBOARD__FAILURE,
} from "./dashboardTypesConstants";

export const addUserDashboard = (name, cardColor) => async (dispatch) => {
	try {
		dispatch({ type: ADD_DASHBOARD_REQUEST });
		const { data: dataResponse } = await api.post(
			"UserDashboard/AddUserDashboard",
			{
				name,
				cardColor,
			}
		);
		return dispatch({ type: ADD_DASHBOARD_SUCCESS, payload: dataResponse });
	} catch (error) {
		dispatch({
			type: ADD_DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const EditUserDashboard = (name, cardColor, id) => async (dispatch) => {
	try {
		dispatch({ type: Edit_DASHBOARD_REQUEST });
		const { data: dataResponse } = await api.put(
			"UserDashboard/UpdateUserDashboard",
			{
				name,
				cardColor,
				id,
			}
		);
		return dispatch({
			type: Edit_DASHBOARD_SUCCESS,
			payload: dataResponse,
		});
	} catch (error) {
		dispatch({
			type: Edit_DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};
export const getAllUserDashboards = () => async (dispatch) => {
	try {
		dispatch({ type: DASHBOARD_REQUEST });
		const { data } = await api.get("UserDashboard/GetUserDashboards");
		dispatch({ type: DASHBOARD_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};
export const getUserDashboard = (dataDetailes) => async (dispatch) => {
	try {
		dispatch({ type: USER_DASHBOARD_REQUEST });
		const { data } = await api.post(
			`UserDashboard/GetUserDashboardItems`,
			dataDetailes
		);
		return dispatch({ type: USER_DASHBOARD_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: USER_DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const deleteUserDashboard = (id) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_USER_DASHBOARD_REQUEST });
		const res = await api.delete(`UserDashboard/DeleteUserDashboard`, {
			data: id,
		});
		return dispatch({
			type: DELETE_USER_DASHBOARD_SUCCESS,
			payload: res.data,
		});
	} catch (error) {
		dispatch({
			type: DELETE_USER_DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const getDashboardItems = () => async (dispatch) => {
	try {
		dispatch({ type: DASHBOARD_ITEMS_REQUEST });
		const { data } = await api.get("UserDashboard/GetDashboardItemsType");
		dispatch({ type: DASHBOARD_ITEMS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: DASHBOARD_ITEMS_FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};
export const addCard = (UserDashboardId, data) => async (dispatch) => {
	try {
		dispatch({ type: ADD_CARD_DASHBOARD_REQUEST });
		const res = await api.post(
			`UserDashboard/AddUserDashboardItems/${UserDashboardId}`,
			data
		);
		return dispatch({
			type: ADD_CARD_DASHBOARD_SUCCESS,
			payload: res.data,
		});
	} catch (error) {
		dispatch({
			type: ADD_CARD_DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const deleteCardFromDashboard = (id) => async (dispatch) => {
	try {
		dispatch({ type: DELETE_CARD_DASHBOARD_REQUEST });
		const res = await api.delete(
			`UserDashboard/DeleteUserDashboardItemDetails`,
			{
				data: id,
			}
		);
		return dispatch({
			type: DELETE_CARD_DASHBOARD_SUCCESS,
			payload: res.data,
		});
	} catch (error) {
		dispatch({
			type: DELETE_CARD_DASHBOARD__FAILURE,
			payload: error
				? { message: error.statusText, status: error.status, error: error }
				: "",
		});
	}
};

export const UpdateUserDashboardPlacements =
	(dashboards) => async (dispatch) => {
		try {
			dispatch({ type: UpdatePlacements_DASHBOARD_REQUEST });
			const { data: dataResponse } = await api.post(
				"UserDashboard/UpdateUserDashboardPlacements",
				{
					dashboards: { ...dashboards },
				}
			);
			return dispatch({
				type: UpdatePlacements_DASHBOARD_SUCCESS,
				payload: dataResponse,
			});
		} catch (error) {
			dispatch({
				type: UpdatePlacements_DASHBOARD__FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};

export const UpdateUserDashboardItemPlacements =
	(dashboardId, dashboardItems) => async (dispatch) => {
		try {
			dispatch({ type: UpdateItemPlacements_DASHBOARD_REQUEST });
			const { data: dataResponse } = await api.post(
				"UserDashboard/UpdateUserDashboardItemPlacements",
				{
					dashboardId,
					dashboardItems,
				}
			);
			return dispatch({
				type: UpdateItemPlacements_DASHBOARD_SUCCESS,
				payload: dataResponse,
			});
		} catch (error) {
			dispatch({
				type: UpdateItemPlacements_DASHBOARD__FAILURE,
				payload: error
					? { message: error.statusText, status: error.status, error: error }
					: "",
			});
		}
	};

export const DAMAGED_ASSETS_REQUEST = "DAMAGED_ASSETS_REQUEST";
export const DAMAGED_ASSETS_SUCCESS = "DAMAGED_ASSETS_SUCCESS";
export const DAMAGED_ASSETS_FAILURE = "DAMAGED_ASSETS_FAILURE";
export const DAMAGED_ASSETS_Reset = "DAMAGED_ASSETS_Reset";

export const ADD_DAMAGED_ASSETS_REQUEST = "ADD_DAMAGED_ASSETS_REQUEST";
export const ADD_DAMAGED_ASSETS_SUCCESS = "ADD_DAMAGED_ASSETS_SUCCESS";
export const ADD_DAMAGED_ASSETS_FAILURE = "ADD_DAMAGED_ASSETS_FAILURE";

export const DELETE_DAMAGED_ASSETS_REQUEST = "DELETE_DAMAGED_ASSETS_REQUEST";
export const DELETE_DAMAGED_ASSETS_SUCCESS = "DELETE_DAMAGED_ASSETS_SUCCESS";
export const DELETE_DAMAGED_ASSETS_FAILURE = "DELETE_DAMAGED_ASSETS_FAILURE";
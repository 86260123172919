import {
	ADD_OUTSIDE_INJURED_FAILURE,
	ADD_OUTSIDE_INJURED_REQUEST,
	ADD_OUTSIDE_INJURED_SUCCESS,
	DELETE_OUTSIDE_INJURED_FAILURE,
	DELETE_OUTSIDE_INJURED_REQUEST,
	DELETE_OUTSIDE_INJURED_SUCCESS,
	OUTSIDE_INJURED_FAILURE,
	OUTSIDE_INJURED_REQUEST,
	OUTSIDE_INJURED_Reset,
	OUTSIDE_INJURED_SUCCESS,
} from "./OutsideInjuredConstant";

// Outside Injuired
export const OutsideInjuredReducer = (
	initialState = {
		OutsideInjured: [],
		GetOutsideInjuredLoading: false,
		addOutsideInjuredLoading: false,
		deleteOutsideInjuredLoading: false,
		getError: null,
		addError: null,
		deleteError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Outside Injured
		case OUTSIDE_INJURED_REQUEST:
			return {
				...initialState,
				GetOutsideInjuredLoading: true,
			};
		case OUTSIDE_INJURED_SUCCESS:
			return {
				...initialState,
				OutsideInjured: action.payload.result.data,
				GetOutsideInjuredLoading: false,
			};
		case OUTSIDE_INJURED_FAILURE:
			return {
				...initialState,
				GetOutsideInjuredLoading: false,
				getError: action.payload,
			};
		case OUTSIDE_INJURED_Reset:
			return {
				...initialState,
				OutsideInjured: [],
			};
		// Add Outside Injured
		case ADD_OUTSIDE_INJURED_REQUEST:
			return {
				...initialState,
				addOutsideInjuredLoading: true,
			};
		case ADD_OUTSIDE_INJURED_SUCCESS:
			return {
				...initialState,
				addOutsideInjuredLoading: false,
			};
		case ADD_OUTSIDE_INJURED_FAILURE:
			return {
				...initialState,
				addOutsideInjuredLoading: false,
				addError: action.payload,
			};

		// Delete Outside Injured
		case DELETE_OUTSIDE_INJURED_REQUEST:
			return {
				...initialState,
				deleteOutsideInjuredLoading: true,
			};
		case DELETE_OUTSIDE_INJURED_SUCCESS:
			return {
				...initialState,
				deleteOutsideInjuredLoading: false,
			};
		case DELETE_OUTSIDE_INJURED_FAILURE:
			return {
				...initialState,
				deleteOutsideInjuredLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

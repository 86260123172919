/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form, Input, message, Row } from "antd";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { SignUp } from "../appRedux/Auth/SignUp/signupAction";
import { GetDDLModules } from "../appRedux/Payment/paymentAction";
import Error from "../components/Message";
import GeneralSelectAll from "../components/GeneralSelectAll";
import { patternArRequired, patternNameEn } from "../util/regexPattern";

const SignUpDemo = () => {
	const history = useHistory();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const intl = useIntl();
	const IsAllowedItemCount = true;
	const { DDLModules } = useSelector((state) => state.DDLModulesReducer);
	useEffect(() => {
		dispatch(GetDDLModules(IsAllowedItemCount, false));
	}, []);

	const { loading, addError } = useSelector((state) => state.SignUpReducer);

	const onFinish = ({
		nameEn,
		nameAr,
		firstNameEn,
		lastNameEn,
		firstNameAr,
		lastNameAr,
		address,
		mobileNumber,
		emailAddress,
		modules,
	}) => {
		dispatch(
			SignUp({
				nameEn,
				nameAr,
				firstNameEn,
				lastNameEn,
				firstNameAr,
				lastNameAr,
				address,
				mobileNumber,
				emailAddress,
				modules,
			})
		).then((res) => {
			if (res?.payload?.data?.statusCode === 200) {
				message.success(
					intl.formatMessage({ id: "message.signup" }),
					5
				);
				history.push("/signin");
			} else {
				const {
					payload: {
						data: { message: messageError },
					},
				} = res;
				message.error(intl.formatMessage({ id: messageError }), 5);
			}
		});
	};

	return (
		<div className="gx-app-login-wrap">
			{/* gx-app-login-container */}
			<div className="signup-form">
				<div className="gx-app-login-main-content">
					<div className="gx-app-logo-content">
						<div className="gx-app-logo">
							<img
								alt="powerlook-logo"
								src="/assets/images/logo.svg"
							/>
						</div>
						<div className="gx-app-logo-wid">
							<h5 className="welcome-msg">
								<IntlMessages id="signup.msg" />
							</h5>

							{!DDLModules.length ? (
								<p
									className="gray-text"
									style={{
										color: "red",
									}}
								>
									<IntlMessages id="app.select.modules" />
								</p>
							) : (
								<p className="gray-text">
									<IntlMessages id="app.userAuth.addAccount" />
								</p>
							)}
						</div>
					</div>
					<div className="gx-app-login-content">
						{addError ? (
							<Error type="error" message={addError?.message} />
						) : (
							<Form
								form={form}
								className="profile-form-item"
								name="basic"
								labelCol={{
									span: 24,
								}}
								initialValues={{
									remember: true,
								}}
								onFinish={onFinish}
								autoComplete="off"
							>
								<Row gutter={[24, 0]}>
									<Col xs={24} md={12}>
										<Form.Item
											name="nameEn"
											label={
												<IntlMessages id="company.name.en" />
											}
											rules={patternNameEn}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="nameAr"
											label={
												<IntlMessages id="company.name.ar" />
											}
											rules={patternArRequired}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="firstNameEn"
											label={
												<IntlMessages id="label.firstNameEn" />
											}
											rules={patternNameEn}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="lastNameEn"
											label={
												<IntlMessages id="label.lastNameEn" />
											}
											rules={patternNameEn}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="firstNameAr"
											label={
												<IntlMessages id="label.firstNameAr" />
											}
											rules={patternArRequired}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="lastNameAr"
											label={
												<IntlMessages id="label.lastNameAr" />
											}
											rules={patternArRequired}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="address"
											label={
												<IntlMessages id="card.title.Address" />
											}
											rules={[
												{
													required: true,
													message: (
														<IntlMessages id="card.title.EnterAddress" />
													),
												},
											]}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="mobileNumber"
											label={
												<IntlMessages id="mobile.num" />
											}
											rules={[
												{
													required: true,
													message: (
														<IntlMessages id="enter.mobile.num" />
													),
												},
												{
													pattern:
														/^(00|\+)(\d{3})(\d{9})$/,
													message: (
														<IntlMessages id="error.mobileNumber.pattern" />
													),
												},
											]}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<Form.Item
											name="emailAddress"
											label={
												<IntlMessages id="email.address" />
											}
											rules={[
												{
													required: true,
													message: (
														<IntlMessages id="enter.email.address" />
													),
												},
												{
													type: "email",
													message: (
														<IntlMessages id="email.msg" />
													),
												},
											]}
											hasFeedback
										>
											<Input />
										</Form.Item>
									</Col>
									<Col xs={24} md={12}>
										<GeneralSelectAll
											arrResult={DDLModules}
											form={form}
											arrName={"modules"}
											arrLable={"modules"}
											rules={[
												{
													required: true,
													message: (
														<IntlMessages id="sidebar.Selectmodules" />
													),
												},
											]}
										/>
									</Col>
								</Row>
								<Form.Item
									style={{ width: "100%" }}
									className="gx-mt-3"
								>
									<Button
										loading={loading}
										type="primary"
										className="gx-mb-0 gx-w-100"
										htmlType="submit"
										style={{ width: "100%" }}
									>
										<IntlMessages id="app.userAuth.signup" />
									</Button>
								</Form.Item>
								<p className="doyou">
									<IntlMessages id="app.userAuth.alreadyAccount" />
									<Link
										to="/signin"
										className="red-color padding-signup"
									>
										<IntlMessages id="app.userAuth.signinNow" />
									</Link>
								</p>
							</Form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const WrappedNormalLoginForm = SignUpDemo;

export default WrappedNormalLoginForm;

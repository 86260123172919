export const REPORT_REQUEST = "REPORT_REQUEST";
export const REPORT_SUCCESS = "REPORT_SUCCESS";
export const REPORT_FAILURE = "REPORT_FAILURE";

export const USER_CLOCKING_REQUEST = "USER_CLOCKING_REQUEST";
export const USER_CLOCKING_SUCCESS = "USER_CLOCKING_SUCCESS";
export const USER_CLOCKING_FAILURE = "USER_CLOCKING_FAILURE";

export const REPORT_Export_REQUEST = "REPORT_Export_REQUEST";
export const REPORT_Export_SUCCESS = "REPORT_Export_SUCCESS";
export const REPORT_Export_FAILURE = "REPORT_Export_FAILURE";

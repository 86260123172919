import {
	CARD_FORMATS_FAILURE,
	CARD_FORMATS_REQUEST,
	CARD_FORMATS_SUCCESS,
	DDLCardFormat_FAILURE,
	DDLCardFormat_REQUEST,
	DDLCardFormat_RESET,
	DDLCardFormat_SUCCESS,
	UPDATE_CARD_FORMATS_FAILURE,
	UPDATE_CARD_FORMATS_REQUEST,
	UPDATE_CARD_FORMATS_SUCCESS,
} from "./generalTypesConstants";

export const generalReducer = (
	initialState = {
		cardFormats: [],
		isHasCardFormats: false,
	},
	action
) => {
	switch (action.type) {
		case CARD_FORMATS_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllCardFormats: [],
			};
		case CARD_FORMATS_SUCCESS:
			return {
				...initialState,
				getAllCardFormats: action.payload.data,
				loading: false,
			};

		case CARD_FORMATS_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case DDLCardFormat_REQUEST:
			return {
				loading: true,
				ddlCardFormats: [],
			};
		case DDLCardFormat_SUCCESS:
			return {
				...initialState,
				ddlCardFormats: action.payload.data,
				loading: false,
				isHasCardFormats: action.payload.data?.length ? true : false,
			};

		case DDLCardFormat_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case DDLCardFormat_RESET:
			return {
				...initialState,
				isHasCardFormats: false,
			};

		case UPDATE_CARD_FORMATS_REQUEST:
			return {
				...initialState,
				updateLoading: true,
			};
		case UPDATE_CARD_FORMATS_SUCCESS:
			return {
				...initialState,
				card: action.payload.data,
				updateLoading: false,
			};

		case UPDATE_CARD_FORMATS_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};

		default:
			return initialState;
	}
};

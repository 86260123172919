import {
	LOGS_TYPE_FAILURE,
	LOGS_TYPE_REQUEST,
	LOGS_TYPE_SUCCESS,
	Logs_Export_FAILURE,
	Logs_Export_REQUEST,
	Logs_Export_SUCCESS,
} from "./logsTypeConstants";

export const logsReducer = (
	initialState = {
		logs: [],
	},
	action
) => {
	switch (action.type) {
		case LOGS_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				logs: [],
			};
		case LOGS_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				logs: action.payload.result.data,
				loading: false,
			};

		case LOGS_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//Export
		case Logs_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case Logs_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case Logs_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};
		default:
			return initialState;
	}
};

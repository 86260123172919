export const OUTSIDE_WITNESS_REQUEST = "OUTSIDE_WITNESS_REQUEST";
export const OUTSIDE_WITNESS_SUCCESS = "OUTSIDE_WITNESS_SUCCESS";
export const OUTSIDE_WITNESS_FAILURE = "OUTSIDE_WITNESS_FAILURE";
export const OUTSIDE_WITNESS_Reset = "OUTSIDE_WITNESS_Reset";

export const ADD_OUTSIDE_WITNESS_REQUEST = "ADD_OUTSIDE_WITNESS_REQUEST";
export const ADD_OUTSIDE_WITNESS_SUCCESS = "ADD_OUTSIDE_WITNESS_SUCCESS";
export const ADD_OUTSIDE_WITNESS_FAILURE = "ADD_OUTSIDE_WITNESS_FAILURE";

export const DELETE_OUTSIDE_WITNESS_REQUEST = "DELETE_OUTSIDE_WITNESS_REQUEST";
export const DELETE_OUTSIDE_WITNESS_SUCCESS = "DELETE_OUTSIDE_WITNESS_SUCCESS";
export const DELETE_OUTSIDE_WITNESS_FAILURE = "DELETE_OUTSIDE_WITNESS_FAILURE";
import {
	TABLES_REQUEST,
	TABLES_SUCCESS,
	TABLES_FAILURE,
	ADD_TABLE_REQUEST,
	ADD_TABLE_SUCCESS,
	ADD_TABLE_FAILURE,
	DELETE_TABLE_REQUEST,
	DELETE_TABLE_SUCCESS,
	DELETE_TABLE_FAILURE,
	TABLE_COLUMNS_REQUEST,
	TABLE_COLUMNS_SUCCESS,
	TABLE_COLUMNS_FAILURE,
	DELETE_TABLE_COLUMN_FAILURE,
	DELETE_TABLE_COLUMN_SUCCESS,
	DELETE_TABLE_COLUMN_REQUEST,
	UPDATE_TABLE_COLUMNS_REQUEST,
	UPDATE_TABLE_COLUMNS_SUCCESS,
	UPDATE_TABLE_COLUMNS_FAILURE,
	TABLE_COLUMNS_OWNER_REQUEST,
	TABLE_COLUMNS_OWNER_SUCCESS,
	TABLE_COLUMNS_OWNER_FAILURE,
	UPDATE_TABLE_COLUMNS_OWNER_REQUEST,
	UPDATE_TABLE_COLUMNS_OWNER_SUCCESS,
	UPDATE_TABLE_COLUMNS_OWNER_FAILURE,
	UPDATE_TABLE_COLUMNS_TABLE_FAILURE,
	UPDATE_TABLE_COLUMNS_TABLE_SUCCESS,
	UPDATE_TABLE_COLUMNS_TABLE_REQUEST,
} from "./tablesTypesConstant";

export const tableReducer = (
	initialState = {
		tables: [],
		columns: [],
		selectedColumnsIds: [],
		tableColumns: [],
		tableColumnsOwner: [],
	},
	action
) => {
	switch (action.type) {
		//Get Tables
		case TABLES_REQUEST:
			return {
				...initialState,
				loading: true,
				tables: [],
			};
		case TABLES_SUCCESS:
			return {
				...initialState,
				tables: action.payload.result.data,
				loading: false,
			};

		case TABLES_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		// Add Table
		case ADD_TABLE_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_TABLE_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				table: action.payload.table,
			};
		case ADD_TABLE_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		// Delete Table
		case DELETE_TABLE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_TABLE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_TABLE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		//Get Table Columns
		case TABLE_COLUMNS_REQUEST:
			return {
				...initialState,
				loadingTableColumns: true,
				columns: [],
			};
		case TABLE_COLUMNS_SUCCESS:
			return {
				...initialState,
				columns: action.payload,
				loadingTableColumns: false,
			};
		case TABLE_COLUMNS_FAILURE:
			return {
				...initialState,
				loadingTableColumns: false,
				errorTableColumns: action.payload,
			};

		// Delete Table Column
		case DELETE_TABLE_COLUMN_REQUEST:
			return {
				...initialState,
				deleteTableColumnLoading: true,
			};
		case DELETE_TABLE_COLUMN_SUCCESS:
			return {
				...initialState,
				deleteTableColumnLoading: false,
			};
		case DELETE_TABLE_COLUMN_FAILURE:
			return {
				...initialState,
				deleteTableColumnLoading: false,
				errorDeleteTableColumn: action.payload,
			};

		// Update Table Column
		case UPDATE_TABLE_COLUMNS_REQUEST:
			return {
				...initialState,
				updateLoading: true,
			};
		case UPDATE_TABLE_COLUMNS_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				tableColumns: action.payload.table,
			};
		case UPDATE_TABLE_COLUMNS_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateColumnError: action.payload,
			};

		//Get Table Columns Owner
		case TABLE_COLUMNS_OWNER_REQUEST:
			return {
				initialState,
				loadingTableColumnsOwner: true,
				tableColumnsOwner: [],
			};
		case TABLE_COLUMNS_OWNER_SUCCESS:
			return {
				...initialState,
				tableColumnsOwner: action.payload,
				loadingTableColumnsOwner: false,
			};
		case TABLE_COLUMNS_OWNER_FAILURE:
			return {
				...initialState,
				loadingTableColumnsOwner: false,
				errorTableColumnsOwner: action.payload,
			};

		// Update Table Column table
		case UPDATE_TABLE_COLUMNS_TABLE_REQUEST:
			return {
				...initialState,
				updateLoadingTable: true,
			};
		case UPDATE_TABLE_COLUMNS_TABLE_SUCCESS:
			return {
				...initialState,
				updateLoadingTable: false,
				tableColumn: action.payload.table,
			};
		case UPDATE_TABLE_COLUMNS_TABLE_FAILURE:
			return {
				...initialState,
				updateLoadingTable: false,
				updateErrorTable: action.payload,
			};

		// Update Table Column Owner
		case UPDATE_TABLE_COLUMNS_OWNER_REQUEST:
			return {
				...initialState,
				updateLoadingOwner: true,
			};
		case UPDATE_TABLE_COLUMNS_OWNER_SUCCESS:
			return {
				...initialState,
				updateLoadingOwner: false,
				tableColumnOwner: action.payload.table,
			};
		case UPDATE_TABLE_COLUMNS_OWNER_FAILURE:
			return {
				...initialState,
				updateLoadingOwner: false,
				updateErrorOwner: action.payload,
			};

		default:
			return initialState;
	}
};

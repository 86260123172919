import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Form, message } from "antd";
import Error from "../../../components/Message";
import CardCredentialForm from "../../../components/CardCredentialForm";
import { useSelector } from "react-redux";
import {
	addSelfCard,
	getAccessPointByTenant,
} from "../../../appRedux/Auth/loginAction";
import IntlMessages from "../../../util/IntlMessages";
import DefaultCardComponent from "../../../components/Cards/DefaultCard";

const AddCarsCredentialByUsers = () => {
	const { token, tenantId } = useParams();
	const dispatch = useDispatch();
	const intl = useIntl();
	const [form] = Form.useForm();

	const { ddlError, accessPoints } = useSelector(
		(state) => state.accessPointByTenantReducer
	);
	const { addSelfCardLoading, addSelfCardError } = useSelector(
		(state) => state.selfCardReducer
	);
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		dispatch(getAccessPointByTenant(tenantId)).then((res) => {
			if (res) {
				const {
					payload: { statusCode, message: errorMessage },
				} = res;
				if (statusCode !== 200) {
					message.error(intl.formatMessage({ id: errorMessage }));
				}
			}
		});
	}, [dispatch, tenantId, intl]);

	const onFinish = (values) => {
		const card = {
			...values,
			tokenUrl: token,
		};
		dispatch(addSelfCard(card)).then((res) => {
			if (res) {
				const {
					payload: {
						data: { statusCode, message: errorMessage },
					},
				} = res;
				if (statusCode === 200) {
					// message.success(
					// 	intl.formatMessage({ id: "message.add" }),
					// 	5
					// );
					setIsVisible(true);
				} else {
					message.error(intl.formatMessage({ id: errorMessage }));
				}
			}
		});
	};

	return (
		<div className="add-visitor">
			<h1
				style={{ marginBottom: "2px", textAlign: "center" }}
				className="gx-mb-4"
			>
				Welcome to add card credentials by yourself
			</h1>
			{!isVisible ? (
				<>
					{ddlError || addSelfCardError ? (
						<Error
							type="error"
							message={
								ddlError?.message || addSelfCardError?.message
							}
						/>
					) : (
						<CardCredentialForm
							onFinish={onFinish}
							form={form}
							isFromLink={true}
							accessPoints={accessPoints}
							loading={addSelfCardLoading}
						/>
					)}
				</>
			) : (
				<DefaultCardComponent isAdd={false} title={""}>
					<div
						className="gx-mb-2"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							color: "green",
							fontSize: 25,
							fontWeight: 400,
						}}
					>
						<IntlMessages id="message.add.card" />
					</div>
				</DefaultCardComponent>
			)}
		</div>
	);
};

export default AddCarsCredentialByUsers;

import { Col, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
//import { Detector } from "react-detect-offline";
import IntlMessages from "../../util/IntlMessages";

const CheckInternetConnection = (props) => {
	// return (
	// 	<>
	// 		<Detector
	// 			render={({ online }) =>
	// 				online ? (
	// 					props.children
	// 				) : (
	// 					<Row
	// 						justify="center"
	// 						align="middle"
	// 						textAlign="center"
	// 						style={{ height: "100vh" }}
	// 					>
	// 						<Col sm={12} className="center-content">
	// 							<img
	// 								src="/assets/images/no-wifi.png"
	// 								className="connection-img"
	// 								alt="no-wifi"
	// 							/>
	// 							<Typography.Title
	// 								level={4}
	// 								style={{ textAlign: "center" }}
	// 							>
	// 								<IntlMessages id="error.noConnection" />
	// 							</Typography.Title>
	// 							<Typography.Title
	// 								level={5}
	// 								style={{ textAlign: "center" }}
	// 							>
	// 								<IntlMessages id="error.checkConnection" />
	// 							</Typography.Title>
	// 						</Col>
	// 					</Row>
	// 				)
	// 			}
	// 		/>
	// 	</>
	// );
	const [isOnline, setOnline] = useState(true);

	useEffect(() => {
		setOnline(navigator.onLine);
	}, []);

	window.addEventListener("online", () => {
		setOnline(true);
	});

	window.addEventListener("offline", () => {
		setOnline(false);
	});

	if (isOnline) {
		return props.children;
	} else {
		return (
			<Row
				justify="center"
				align="middle"
				textAlign="center"
				style={{ height: "100vh" }}
			>
				<Col sm={12} className="center-content">
					<img
						src="/assets/images/no-wifi.png"
						className="connection-img"
						alt="no-wifi"
					/>
					<Typography.Title level={4} style={{ textAlign: "center" }}>
						<IntlMessages id="error.noConnection" />
					</Typography.Title>
					<Typography.Title level={5} style={{ textAlign: "center" }}>
						<IntlMessages id="error.checkConnection" />
					</Typography.Title>
				</Col>
			</Row>
		);
	}
};

export default CheckInternetConnection;

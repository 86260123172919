import {
	DEPARTMENT_TYPE_REQUEST,
	DEPARTMENT_TYPE_SUCCESS,
	DEPARTMENT_TYPE_FAILURE,
	DEPARTMENT_ADD_FAILURE,
	DEPARTMENT_ADD_SUCCESS,
	DEPARTMENT_ADD_REQUEST,
	DEPARTMENT_DELETE_REQUEST,
	DEPARTMENT_DELETE_SUCCESS,
	DEPARTMENT_DELETE_FAILURE,
	DEPARTMENT_DETAILS_REQUEST,
	DEPARTMENT_DETAILS_SUCCESS,
	DEPARTMENT_DETAILS_FAILURE,
	DEPARTMENT_DETAILS_RESET,
	DEPARTMENT_UPDATE_REQUEST,
	DEPARTMENT_UPDATE_SUCCESS,
	DEPARTMENT_UPDATE_FAILURE,
	DEPARTMENT_UPDATE_RESET,
	Departments_DDL_REQUEST,
	Departments_DDL_SUCCESS,
	Departments_DDL_FAILURE,
	Departments_DDL_RESET,
	DEPARTMENT_Import_REQUEST,
	DEPARTMENT_Import_SUCCESS,
	DEPARTMENT_Import_FAILURE,
	DEPARTMENT_Export_REQUEST,
	DEPARTMENT_Export_SUCCESS,
	DEPARTMENT_Export_FAILURE,
} from "./DepartmentTypesConstants";

export const departmentReducer = (
	initialState = {
		departments: [],
		isHasDepartments: false,
	},
	action
) => {
	switch (action.type) {
		case DEPARTMENT_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllDepartments: [],
			};
		case DEPARTMENT_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllDepartments: action.payload.result.data,
				loading: false,
			};

		case DEPARTMENT_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
//ddl
		case Departments_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				departments: [],
			};
		case Departments_DDL_SUCCESS:
			return {
				...initialState,
				departments: action.payload.result.data,
				loading: false,
				isHasDepartments: action.payload.result.data?.length
					? true
					: false,
			};

		case Departments_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLDepartmentsErrors: action.payload,
			};
		case Departments_DDL_RESET:
			return {
				...initialState,
				isHasDepartments: false,
			};

		//add department
		case DEPARTMENT_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case DEPARTMENT_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case DEPARTMENT_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//import
		case DEPARTMENT_Import_REQUEST:
			return {
				...initialState,
				importLoading: true,
			};
		case DEPARTMENT_Import_SUCCESS:
			return {
				...initialState,
				importLoading: false,
				data: action.payload,
			};
		case DEPARTMENT_Import_FAILURE:
			return {
				...initialState,
				importLoading: false,
				importError: action.payload,
			};

		//Export
		case DEPARTMENT_Export_REQUEST:
			return {
				...initialState,
				exportLoading: true,
			};
		case DEPARTMENT_Export_SUCCESS:
			return {
				...initialState,
				exportLoading: false,
				data: action.payload,
			};
		case DEPARTMENT_Export_FAILURE:
			return {
				...initialState,
				exportLoading: false,
				exportError: action.payload,
			};

		//delete department

		case DEPARTMENT_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DEPARTMENT_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DEPARTMENT_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//department details & edit
export const departmentDetailsReducer = (
	state = { department: {} },
	action
) => {
	switch (action.type) {
		case DEPARTMENT_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case DEPARTMENT_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				department: action.payload,
			};
		case DEPARTMENT_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case DEPARTMENT_DETAILS_RESET:
			return { ...state, department: {} };
		//department Update
		case DEPARTMENT_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case DEPARTMENT_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case DEPARTMENT_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case DEPARTMENT_UPDATE_RESET:
			return { ...state, department: {} };
		default:
			return state;
	}
};

export const POSITIONS_USER_REQUEST = "POSITIONS_USER_REQUEST";
export const POSITIONS_USER_SUCCESS = "POSITIONS_USER_SUCCESS";
export const POSITIONS_USER_FAILURE = "POSITIONS_USER_FAILURE";

export const Positions_DDL_REQUEST = "Positions_DDL_REQUEST";
export const Positions_DDL_SUCCESS = "Positions_DDL_SUCCESS";
export const Positions_DDL_FAILURE = "Positions_DDL_FAILURE";
export const Positions_DDL_RESET = "Positions_DDL_RESET";

export const ADD_POSITION_REQUEST = "ADD_POSITION_REQUEST";
export const ADD_POSITION_SUCCESS = "ADD_POSITION_SUCCESS";
export const ADD_POSITION_FAILURE = "ADD_POSITION_FAILURE";

export const POSITION_DETAILS_REQUEST = "POSITION_DETAILS_REQUEST";
export const POSITION_DETAILS_SUCCESS = "POSITION_DETAILS_SUCCESS";
export const POSITION_DETAILS_FAILURE = "POSITION_DETAILS_FAILURE";
export const POSITION_DETAILS_RESET = "POSITION_DETAILS_RESET";

export const POSITION_UPDATE_REQUEST = "POSITION_UPDATE_REQUEST";
export const POSITION_UPDATE_SUCCESS = "POSITION_UPDATE_SUCCESS";
export const POSITION_UPDATE_FAILURE = "POSITION_UPDATE_FAILURE";
export const POSITION_UPDATE_RESET = "POSITION_UPDATE_RESET";

export const POSITION_DELETE_REQUEST = "POSITION_DELETE_REQUEST";
export const POSITION_DELETE_SUCCESS = "POSITION_DELETE_SUCCESS";
export const POSITION_DELETE_FAILURE = "POSITION_DELETE_FAILURE";

export const Position_Import_REQUEST = "Position_Import_REQUEST";
export const Position_Import_SUCCESS = "Position_Import_SUCCESS";
export const Position_Import_FAILURE = "Position_Import_FAILURE";

export const Position_Export_REQUEST = "Position_Export_REQUEST";
export const Position_Export_SUCCESS = "Position_Export_SUCCESS";
export const Position_Export_FAILURE = "Position_Export_FAILURE";

import {
	Add_Tasks_Members_FAILURE,
	Add_Tasks_Members_REQUEST,
	Add_Tasks_Members_SUCCESS,
	Delete_Tasks_Members_FAILURE,
	Delete_Tasks_Members_REQUEST,
	Delete_Tasks_Members_SUCCESS,
	Tasks_Members_FAILURE,
	Tasks_Members_REQUEST,
	Tasks_Members_SUCCESS,
} from "./TasksMembersConstant";

export const TasksMembersReducer = (
	initialState = {
		GetTaskMembersLoading: false,
		AddTaskMembersLoading: false,
		DeleteTaskMembersLoading: false,
		TaskMembers: [],
	},
	action
) => {
	switch (action.type) {
		// get Tasks Members
		case Tasks_Members_REQUEST:
			return {
				...initialState,
				GetTaskMembersLoading: true,
			};
		case Tasks_Members_SUCCESS:
			return {
				...initialState,
				TaskMembers: action.payload.result.data,
				GetTaskMembersLoading: false,
			};

		case Tasks_Members_FAILURE:
			return {
				...initialState,
				GetTaskMembersLoading: false,
				getError: action.payload,
			};

		// add Task Members
		case Add_Tasks_Members_REQUEST:
			return {
				...initialState,
				AddTaskMembersLoading: true,
			};
		case Add_Tasks_Members_SUCCESS:
			return {
				...initialState,
				AddTaskMembersLoading: false,
				data: action.payload,
			};
		case Add_Tasks_Members_FAILURE:
			return {
				...initialState,
				AddTaskMembersLoading: false,
				addError: action.payload,
			};

		//delete Task Members
		case Delete_Tasks_Members_REQUEST:
			return {
				...initialState,
				DeleteTaskMembersLoading: true,
			};
		case Delete_Tasks_Members_SUCCESS:
			return {
				...initialState,
				DeleteTaskMembersLoading: false,
			};
		case Delete_Tasks_Members_FAILURE:
			return {
				...initialState,
				DeleteTaskMembersLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

export const ADD_DASHBOARD_REQUEST = "ADD_DASHBOARD_REQUEST";
export const ADD_DASHBOARD_SUCCESS = "ADD_DASHBOARD_SUCCESS";
export const ADD_DASHBOARD__FAILURE = "ADD_DASHBOARD__FAILURE";

export const Edit_DASHBOARD_REQUEST = "Edit_DASHBOARD_REQUEST";
export const Edit_DASHBOARD_SUCCESS = "Edit_DASHBOARD_SUCCESS";
export const Edit_DASHBOARD__FAILURE = "Edit_DASHBOARD__FAILURE";

export const UpdatePlacements_DASHBOARD_REQUEST =
	"UpdatePlacements_DASHBOARD_REQUEST";
export const UpdatePlacements_DASHBOARD_SUCCESS =
	"UpdatePlacements_DASHBOARD_SUCCESS";
export const UpdatePlacements_DASHBOARD__FAILURE =
	"UpdatePlacements_DASHBOARD__FAILURE";

export const UpdateItemPlacements_DASHBOARD_REQUEST =
	"UpdateItemPlacements_DASHBOARD_REQUEST";
export const UpdateItemPlacements_DASHBOARD_SUCCESS =
	"UpdateItemPlacements_DASHBOARD_SUCCESS";
export const UpdateItemPlacements_DASHBOARD__FAILURE =
	"UpdateItemPlacements_DASHBOARD__FAILURE";

export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD__FAILURE = "DASHBOARD__FAILURE";

export const CARD_DASHBOARD_REQUEST = "CARD_DASHBOARD_REQUEST";
export const CARD_DASHBOARD_SUCCESS = "CARD_DASHBOARD_SUCCESS";
export const CARD_DASHBOARD_FAILURE = "CARD_DASHBOARD_FAILURE";

export const ADD_CARD_DASHBOARD_REQUEST = "ADD_CARD_DASHBOARD_REQUEST";
export const ADD_CARD_DASHBOARD_SUCCESS = "ADD_CARD_DASHBOARD_SUCCESS";
export const ADD_CARD_DASHBOARD__FAILURE = "ADD_CARD_DASHBOARD__FAILURE";

export const USER_DASHBOARD_REQUEST = "USER_DASHBOARD_REQUEST";
export const USER_DASHBOARD_SUCCESS = "USER_DASHBOARD_SUCCESS";
export const USER_DASHBOARD__FAILURE = "USER_DASHBOARD__FAILURE";

export const DELETE_USER_DASHBOARD_REQUEST = "DELETE_USER_DASHBOARD_REQUEST";
export const DELETE_USER_DASHBOARD_SUCCESS = "DELETE_USER_DASHBOARD_SUCCESS";
export const DELETE_USER_DASHBOARD__FAILURE = "DELETE_USER_DASHBOARD__FAILURE";

export const DASHBOARD_ITEMS_REQUEST = "DASHBOARD_ITEMS_REQUEST";
export const DASHBOARD_ITEMS_SUCCESS = "DASHBOARD_ITEMS_SUCCESS";
export const DASHBOARD_ITEMS_FAILURE = "DASHBOARD_ITEMS_FAILURE";

export const DELETE_CARD_DASHBOARD_REQUEST = "DELETE_CARD_DASHBOARD_REQUEST";
export const DELETE_CARD_DASHBOARD_SUCCESS = "DELETE_CARD_DASHBOARD_SUCCESS";
export const DELETE_CARD_DASHBOARD__FAILURE = "DELETE_CARD_DASHBOARD__FAILURE";

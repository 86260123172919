export const GET_SCHEDULES_REQUEST = "GET_SCHEDULES_REQUEST";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_FAILURE = "GET_SCHEDULES_FAILURE";

export const SCHEDULES_DDL_REQUEST = "SCHEDULES_DDL_REQUEST";
export const SCHEDULES_DDL_SUCCESS = "SCHEDULES_DDL_SUCCESS";
export const SCHEDULES_DDL_FAILURE = "SCHEDULES_DDL_FAILURE";
export const SCHEDULES_DDL_RESET = "SCHEDULES_DDL_RESET";

export const ADD_SCHEDULES_REQUEST = "ADD_SCHEDULES_REQUEST";
export const ADD_SCHEDULES_SUCCESS = "ADD_SCHEDULES_SUCCESS";
export const ADD_SCHEDULES_FAILURE = "ADD_SCHEDULES_FAILURE";
export const DELETE_SCHEDULES_REQUEST = "DELETE_SCHEDULES_REQUEST";
export const DELETE_SCHEDULES_SUCCESS = "DELETE_SCHEDULES_SUCCESS";
export const DELETE_SCHEDULES_FAILURE = "DELETE_SCHEDULES_FAILURE";

export const SCHEDULE_UPDATE_REQUEST = "SCHEDULE_UPDATE_REQUEST";
export const SCHEDULE_UPDATE_SUCCESS = "SCHEDULE_UPDATE_SUCCESS";
export const SCHEDULE_UPDATE_FAILURE = "SCHEDULE_UPDATE_FAILURE";
export const SCHEDULE_UPDATE_RESET = "SCHEDULE_UPDATE_RESET";

export const SCHEDULE_Import_REQUEST = "SCHEDULE_Import_REQUEST";
export const SCHEDULE_Import_SUCCESS = "SCHEDULE_Import_SUCCESS";
export const SCHEDULE_Import_FAILURE = "SCHEDULE_Import_FAILURE";

export const SCHEDULE_Export_REQUEST = "SCHEDULE_Export_REQUEST";
export const SCHEDULE_Export_SUCCESS = "SCHEDULE_Export_SUCCESS";
export const SCHEDULE_Export_FAILURE = "SCHEDULE_Export_FAILURE";

export const SCHEDULE_DETAILS_RESET = "SCHEDULE_DETAILS_RESET";
export const SCHEDULE_DETAILS_REQUEST = "SCHEDULE_DETAILS_REQUEST";
export const SCHEDULE_DETAILS_SUCCESS = "SCHEDULE_DETAILS_SUCCESS";
export const SCHEDULE_DETAILS_FAILURE = "SCHEDULE_DETAILS_FAILURE";

import React, { useState } from "react";
import { addSelfEquipment } from "../../../appRedux/Auth/loginAction";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, message } from "antd";
import Error from "../../../components/Message";
import EquipmentComponent from "../../../components/EquipmentComponent";
import DefaultModalComponent from "../../../components/ModalComponent/DefaultModalComponent";
import IntlMessages from "../../../util/IntlMessages";
import DefaultCardComponent from "../../../components/Cards/DefaultCard";

const AddEquipmentsByVisitors = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	const intl = useIntl();
	const [isVisible, setIsVisible] = useState(false);
	const [isVisibleModel, setIsVisibleModel] = useState(false);

	const cancel = () => {
		setIsVisible(false);
	};
	const { addSelfEquipmentLoading, addSelfEquipmentError } = useSelector(
		(state) => state.selfEquipmentsReducer
	);

	const onFinish = (values) => {
		const equipment = { ...values, tokenUrl: token };
		dispatch(addSelfEquipment(equipment)).then((res) => {
			if (res) {
				const {
					payload: {
						data: { statusCode, message: errorMessage },
					},
				} = res;
				if (statusCode === 200) {
					// message.success(
					// 	intl.formatMessage({ id: "message.add" }),
					// 	5
					// );
					setIsVisible(true);
				} else {
					message.error(intl.formatMessage({ id: errorMessage }));
				}
			}
		});
	};

	return (
		<>
			{!isVisible && (
				<div className="add-visitor">
					<h1
						style={{ marginBottom: "2px", textAlign: "center" }}
						className="gx-mb-4"
					>
						Welcome to add equipments by yourself
					</h1>
					{!isVisibleModel ? (
						<>
							{addSelfEquipmentError ? (
								<Error
									type="error"
									message={addSelfEquipmentError?.message}
								/>
							) : (
								<EquipmentComponent
									onFinish={onFinish}
									loading={addSelfEquipmentLoading}
									isSendLink={true}
								/>
							)}
						</>
					) : (
						<DefaultCardComponent isAdd={false} title={""}>
							<div
								className="gx-mb-2"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
									color: "green",
									fontSize: 25,
									fontWeight: 400,
								}}
							>
								<IntlMessages id="message.add.equipments" />
							</div>
						</DefaultCardComponent>
					)}
				</div>
			)}
			<DefaultModalComponent
				title={null}
				open={isVisible}
				onCancel={cancel}
				footer={null}
				width={400}
			>
				<div className="wrapper-card-model">
					<img
						alt="lo"
						src={"/assets/images/logoBlack.svg"}
						className="gx-mb-2"
					/>
					<div
						className="gx-mb-2"
						style={{
							textAlign: "center",
							color: "green",
							marginBottom: "100px",
						}}
					>
						<IntlMessages id="message.add.equipments2" />
					</div>
					<div>
						<Button
							className="gx-btn gx-btn-primary gx-btn-md"
							onClick={() => {
								setIsVisible(false);
							}}
						>
							<IntlMessages id="button.yes" />
						</Button>

						<Button
							className="gx-btn gx-btn-light gx-btn-md"
							onClick={() => {
								setIsVisibleModel(true);
								setIsVisible(false);
							}}
						>
							<IntlMessages id="button.no" />
						</Button>
					</div>
				</div>
			</DefaultModalComponent>
		</>
	);
};

export default AddEquipmentsByVisitors;

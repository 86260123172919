import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, Layout, Popover } from "antd";

import CustomScrollbars from "util/CustomScrollbars";
import languageData from "./languageData";
import {
	setDirectionRTL,
	switchLanguage,
	toggleCollapsedSideNav,
} from "../../appRedux/SettingsApp/settingsActions";
import UserInfo from "../../components/UserInfo";
// import AppNotification from "../../components/AppNotification";
import Auxiliary from "util/Auxiliary";

import {
	NAV_STYLE_DRAWER,
	NAV_STYLE_FIXED,
	NAV_STYLE_MINI_SIDEBAR,
	TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import IntlMessages from "../../util/IntlMessages";
import { displayDropmenuItems } from "../../util/displayColumns";
import { Link, useHistory, useParams } from "react-router-dom";
import { getAllUserDashboards } from "../../appRedux/Dashboard/dashboardActions";
import Updated from "./updated";
import { DownOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

const userData = JSON.parse(localStorage.getItem("userData"));

let nameEn = "";

if (userData !== null) {
	const { firstNameEn } = userData;

	nameEn = JSON.parse(localStorage.getItem("userName"))
		? JSON.parse(localStorage.getItem("userName"))
		: firstNameEn;
}

const { Header } = Layout;

const Topbar = ({ loading, number, processCommands }) => {
	const intl = useIntl();
	const { locale, navStyle } = useSelector(({ settings }) => settings);
	const navCollapsed = useSelector(({ common }) => common.navCollapsed);
	const width = useSelector(({ common }) => common.width);
	const dispatch = useDispatch();
	// const [darkMode, setDarkMode] = useState(false);
	// const dashbaord = localStorage.getItem("dashboard");
	const [showDropDown, setShowDropDown] = useState(false);
	const history = useHistory();

	const permissions = useMemo(() => {
		return JSON.parse(localStorage.getItem("permissionsName"));
	}, []);

	// useEffect(() => {
	// 	if (darkMode === true) {
	// 		document.body.classList.add("dark-theme");
	// 	} else {
	// 		document.body.classList.remove("dark-theme");
	// 	}
	// }, [darkMode]);

	// function handleModeChange(e) {
	// 	setDarkMode(e);
	// }

	const languageMenu = () => (
		<CustomScrollbars className="gx-popover-lang-scroll ">
			{/* {showDropDown && ( */}
			<ul className="gx-sub-popover">
				{languageData.map((language) => (
					<li
						className="gx-media gx-pointer"
						key={JSON.stringify(language)}
						onClick={() => {
							dispatch(switchLanguage(language));
							if (language.locale === "ar") {
								localStorage.setItem("locale", language.locale);
								dispatch(setDirectionRTL(true));
								setShowDropDown(false);
							} else {
								localStorage.setItem("locale", language.locale);
								dispatch(setDirectionRTL(false));
								setShowDropDown(false);
							}
						}}
					>
						<i
							className={`flag flag-24 gx-mr-2 flag-${language.icon}`}
						/>
						<span className="gx-language-text">
							{language.name}
						</span>
					</li>
				))}
			</ul>
			{/* )
			} */}
		</CustomScrollbars>
	);

	// const handleButtonClick = (e) => {
	// 	message.info("Click on left button.");
	// };
	const { name: dashboardId } = useParams();
	const { dashboards } = useSelector((state) => state.userDashboards);
	const { ownerDashboards } = useSelector((state) => state.ownerDashboards);
	useEffect(() => {
		if (permissions.includes("customerdashboarduser.get")) {
			if (width > 768)
				userData.userType !== 3 && dispatch(getAllUserDashboards());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, dashboardId]);
	const opts =
		userData.userType === 3
			? displayDropmenuItems(ownerDashboards)
			: displayDropmenuItems(dashboards);
	const [finalDashboards, setFinalDashboards] = useState(
		userData.userType === 3 ? ownerDashboards : dashboards
	);
	useEffect(() => {
		setFinalDashboards(
			userData.userType === 3 ? ownerDashboards : dashboards
		);
	}, [dashboards, ownerDashboards]);
	const handleMenuClick = (e) => {
		// eslint-disable-next-line array-callback-return
		finalDashboards?.map((item) => {
			if (item.id === e.key) {
				localStorage.setItem(
					"dashboardDetails",
					JSON.stringify({
						dashboardName: item.name,
						dashboardID: item.id,
					})
				);
			}
		});
		history.push(`/newDashboard/${e.key}`);
	};
	const dashboard = JSON.parse(localStorage.getItem("dashboardDetails"));

	const renderDashboardName = (e) => {
		if (dashboard == null || history.location.pathname === `/dashboard`) {
			return (
				<div className="dashboard-button">
					<Dropdown.Button
						menu={
							opts.length === 0
								? {
										items: [
											{
												label: intl.formatMessage({
													id: "no.dashboard",
												}),
												key: "0",
											},
										],
								  }
								: { items: opts, onClick: handleMenuClick }
						}
						icon={<DownOutlined />}
						style={{ width: "50px" }}
					>
						<Link to={`/dashboard`}>
							<IntlMessages id="sidebar.dashboard" />
						</Link>
					</Dropdown.Button>
				</div>
			);
		} else {
			return (
				<div className="dashboard-button">
					<Dropdown.Button
						menu={{ items: opts, onClick: handleMenuClick }}
						style={{ width: "50px" }}
						icon={<DownOutlined />}
					>
						<Link to={`/newDashboard/${dashboard.dashboardID}`}>
							{dashboard.dashboardName}
						</Link>
					</Dropdown.Button>
				</div>
			);
		}
	};

	return (
		<Header>
			<div
				className="gx-d-flex gx-justify-content-between gx-align-items-center"
				style={{ width: "100%" }}
			>
				<div className="gx-d-flex gx-align-items-center">
					{navStyle === NAV_STYLE_DRAWER ||
					((navStyle === NAV_STYLE_FIXED ||
						navStyle === NAV_STYLE_MINI_SIDEBAR) &&
						width < TAB_SIZE) ? (
						<div className="gx-linebar gx-mr-3">
							<i
								className="gx-icon-btn icon icon-menu"
								onClick={() => {
									dispatch(
										toggleCollapsedSideNav(!navCollapsed)
									);
								}}
							/>
						</div>
					) : null}

					<h1 className="gx-pt-3 dashboard-button">
						{renderDashboardName()}
					</h1>
				</div>
				{userData.userType !== 3 && (
					<Updated
						processCommands={processCommands}
						number={number}
						loading={loading}
					/>
				)}
				<div
					style={{
						display: "flex",
						alignItems: "center",
						margin: 0,
						paddingLeft: 0,
						paddingRight: 0,
						gap: "50px",
					}}
				>
					{/* <ul className="gx-header-notifications"> */}
					{/* <li className="gx-switch">
						<span className="gx-pointer gx-flex-row gx-align-items-center">
							<span className="theme-switch-item">
								{darkMode ? "Dark" : "Light"}
							</span>
							<Switch
								checkedChildren={
									<img
										src="/assets/images/icons/star.svg"
										alt="powerlook-icon"
									/>
								}
								unCheckedChildren={
									<img
										src="/assets/images/icons/star.svg"
										alt="powerlook-icon"
									/>
								}
								defaultChecked={false}
								onChange={handleModeChange}
							/>
						</span>
					</li> */}
					<div className="gx-language">
						<Popover
							overlayClassName="gx-popover-horizantal"
							placement={showDropDown ? "bottomRight" : null}
							content={showDropDown ? languageMenu() : null}
							trigger="click"
						>
							<span className="gx-pointer gx-flex-row gx-align-items-center">
								<img
									src="/assets/images/icons/language.svg"
									alt="powerlook-icon"
									onClick={() => {
										setShowDropDown(true);
									}}
								/>
								<span className="gx-pl-2 gx-language-name">
									{locale.locale}
								</span>
							</span>
						</Popover>
					</div>
					{/* <Auxiliary>
						<li className="gx-notify">
							<Popover
								overlayClassName="gx-popover-horizantal"
								placement="bottomRight"
								content={<AppNotification />}
								trigger="click"
							>
								<span className="gx-pointer gx-d-block">
									<img
										src="/assets/images/icons/notifications.svg"
										alt="powerlook-icon"
									/>
								</span>
							</Popover>
						</li>
					</Auxiliary> */}
					<Auxiliary>
						<div className="gx-user-nav">
							<UserInfo />
						</div>
					</Auxiliary>
					<h5 className="welcome-name">
						<IntlMessages id="label.Welcome" />, {nameEn}
					</h5>
					{/* </ul> */}
				</div>
			</div>
		</Header>
	);
};

export default Topbar;

import {
	Role_TYPE_REQUEST,
	Role_TYPE_SUCCESS,
	Role_TYPE_FAILURE,
	Role_ADD_FAILURE,
	Role_ADD_SUCCESS,
	Role_ADD_REQUEST,
	Role_DELETE_REQUEST,
	Role_DELETE_SUCCESS,
	Role_DELETE_FAILURE,
	Role_DETAILS_REQUEST,
	Role_DETAILS_SUCCESS,
	Role_DETAILS_FAILURE,
	Role_DETAILS_RESET,
	Role_UPDATE_REQUEST,
	Role_UPDATE_SUCCESS,
	Role_UPDATE_FAILURE,
	Role_UPDATE_RESET,
	OperatorRoles_DDL_REQUEST,
	OperatorRoles_DDL_SUCCESS,
	OperatorRoles_DDL_FAILURE,
	PERMISSION_USER_REQUEST,
	PERMISSION_USER_SUCCESS,
	PERMISSION_USER_FAILURE,
	OperatorRoles_DDL_RESET,
} from "./RoleTypesConstants";

export const RoleReducer = (
	initialState = {
		operatorRoles: [],
		isHasOperatorRoles: false,
	},
	action
) => {
	switch (action.type) {
		//get Role
		case Role_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllOperatorRoles: [],
			};
		case Role_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllOperatorRoles: action.payload.result.data,
				loading: false,
			};

		case Role_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
//ddl
		case OperatorRoles_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				operatorRoles: [],
			};
		case OperatorRoles_DDL_SUCCESS:
			return {
				...initialState,
				operatorRoles: action.payload.result.data,
				loading: false,
				isHasOperatorRoles: action.payload.result.data?.length
					? true
					: false,
			};

		case OperatorRoles_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLOperatorRolesError: action.payload,
			};
		case OperatorRoles_DDL_RESET:
			return {
				...initialState,
				isHasOperatorRoles: false,
			};

		//add Role
		case Role_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Role_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Role_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Role

		case Role_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Role_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Role_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};

		case PERMISSION_USER_REQUEST:
			return {
				...initialState,
				loadingPermission: true,
			};
		case PERMISSION_USER_SUCCESS:
			return {
				...initialState,
				loadingPermission: false,
				modulePermissions: action.payload,
			};
		case PERMISSION_USER_FAILURE:
			return {
				...initialState,
				loadingPermission: false,
				error: action.payload,
			};
		default:
			return initialState;
	}
};

//Role detailes & edit
export const RoleDetailsReducer = (state = { role: {} }, action) => {
	switch (action.type) {
		case Role_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Role_DETAILS_SUCCESS:
			return { ...state, detailsLoading: false, role: action.payload };
		case Role_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Role_DETAILS_RESET:
			return { ...state, role: {} };
		//Role Update
		case Role_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Role_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Role_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Role_UPDATE_RESET:
			return { ...state, Role: {} };
		default:
			return state;
	}
};

export const dataChannels = {
	11: {
		icon: "whatsapp1",
		iconWhite: "whatsappWhite",
		channelId: 2,
	},
	9: {
		icon: "sms1",
		iconWhite: "smsWhite",
		channelId: 1,
	},
	10: {
		icon: "email1",
		iconWhite: "emailWhite",
		channelId: 3,
	},
};

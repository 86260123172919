export const TABLES_REQUEST = 'TABLES_REQUEST';
export const TABLES_SUCCESS = 'TABLES_SUCCESS';
export const TABLES_FAILURE = 'TABLES_FAILURE';

export const ADD_TABLE_REQUEST = "ADD_TABLE_REQUEST";
export const ADD_TABLE_SUCCESS = "ADD_TABLE_SUCCESS";
export const ADD_TABLE_FAILURE = "ADD_TABLE_FAILURE";

export const DELETE_TABLE_REQUEST = "DELETE_TABLE_REQUEST";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
export const DELETE_TABLE_FAILURE = "DELETE_TABLE_FAILURE";

export const TABLE_COLUMNS_REQUEST = 'TABLE_COLUMNS_REQUEST';
export const TABLE_COLUMNS_SUCCESS = 'TABLE_COLUMNS_SUCCESS';
export const TABLE_COLUMNS_FAILURE = 'TABLE_COLUMNS_FAILURE';

export const UPDATE_TABLE_COLUMNS_REQUEST = "UPDATE_TABLE_COLUMNS_REQUEST";
export const UPDATE_TABLE_COLUMNS_SUCCESS = "UPDATE_TABLE_COLUMNS_SUCCESS";
export const UPDATE_TABLE_COLUMNS_FAILURE = "UPDATE_TABLE_COLUMNS_FAILURE";

export const DELETE_TABLE_COLUMN_REQUEST = "DELETE_TABLE_COLUMN_REQUEST";
export const DELETE_TABLE_COLUMN_SUCCESS = "DELETE_TABLE_COLUMN_SUCCESS";
export const DELETE_TABLE_COLUMN_FAILURE = "DELETE_TABLE_COLUMN_FAILURE";

export const TABLE_COLUMNS_OWNER_REQUEST = 'TABLE_COLUMNS_OWNER_REQUEST';
export const TABLE_COLUMNS_OWNER_SUCCESS = 'TABLE_COLUMNS_OWNER_SUCCESS';
export const TABLE_COLUMNS_OWNER_FAILURE = 'TABLE_COLUMNS_OWNER_FAILURE';

export const UPDATE_TABLE_COLUMNS_TABLE_REQUEST = "UPDATE_TABLE_COLUMNS_TABLE_REQUEST";
export const UPDATE_TABLE_COLUMNS_TABLE_SUCCESS = "UPDATE_TABLE_COLUMNS_TABLE_SUCCESS";
export const UPDATE_TABLE_COLUMNS_TABLE_FAILURE = "UPDATE_TABLE_COLUMNS_TABLE_FAILURE";

export const UPDATE_TABLE_COLUMNS_OWNER_REQUEST = "UPDATE_TABLE_COLUMNS_OWNER_REQUEST";
export const UPDATE_TABLE_COLUMNS_OWNER_SUCCESS = "UPDATE_TABLE_COLUMNS_OWNER_SUCCESS";
export const UPDATE_TABLE_COLUMNS_OWNER_FAILURE = "UPDATE_TABLE_COLUMNS_OWNER_FAILURE";

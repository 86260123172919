import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import "styles/scss/main.scss";
import "leaflet/dist/leaflet.css";

import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import NotFound from "./components/NotFound";

const store = configureStore();

const NextApp = () => (
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Switch>
				<Route path="/" component={App} />
				<Route path="*" exact={true} component={NotFound} />
			</Switch>
		</ConnectedRouter>
	</Provider>
);

export default NextApp;

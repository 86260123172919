import {
	DemoSettings_TYPE_FAILURE,
	DemoSettings_TYPE_REQUEST,
	DemoSettings_TYPE_SUCCESS,
	DemoSettings_UPDATE_FAILURE,
	DemoSettings_UPDATE_REQUEST,
	DemoSettings_UPDATE_RESET,
	DemoSettings_UPDATE_SUCCESS,
} from "./DemoSettingsConstsnt";

export const DemoSettingsReducer = (
	initialState = {
		DemoSettingss: [],
	},
	action
) => {
	switch (action.type) {
		case DemoSettings_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				DemoSettingss: [],
			};
		case DemoSettings_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				loading: false,
			};

		case DemoSettings_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//DemoSettings Update
		case DemoSettings_UPDATE_REQUEST:
			return { ...initialState, updateLoading: true };
		case DemoSettings_UPDATE_SUCCESS:
			return {
				...initialState,
				updateLoading: false,
				updateSuccess: true,
			};
		case DemoSettings_UPDATE_FAILURE:
			return {
				...initialState,
				updateLoading: false,
				updateError: action.payload,
			};
		case DemoSettings_UPDATE_RESET:
			return { ...initialState,DemoSettings: {} };
		default:
			return initialState;
	}
};

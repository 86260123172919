export const Login_ADD_REQUEST = "Login_ADD_REQUEST";
export const Login_ADD_SUCCESS = "Login_ADD_SUCCESS";
export const Login_ADD_FAILURE = "Login_ADD_FAILURE";

export const forgotPassword_REQUEST = "forgotPassword_REQUEST";
export const forgotPassword_SUCCESS = "forgotPassword_SUCCESS";
export const forgotPassword_FAILURE = "forgotPassword_FAILURE";

export const recoveryPassword_REQUEST = "recoveryPassword_REQUEST";
export const recoveryPassword_SUCCESS = "recoveryPassword_SUCCESS";
export const recoveryPassword_FAILURE = "recoveryPassword_FAILURE";

export const SendOTP_REQUEST = "SendOTP_REQUEST";
export const SendOTP_SUCCESS = "SendOTP_SUCCESS";
export const SendOTP_FAILURE = "SendOTP_FAILURE";

export const VerifyOTP_REQUEST = "VerifyOTP_REQUEST";
export const VerifyOTP_SUCCESS = "VerifyOTP_SUCCESS";
export const VerifyOTP_FAILURE = "VerifyOTP_FAILURE";

export const ADD_SELF_EQUIPMENT_REQUEST = "ADD_SELF_EQUIPMENT_REQUEST";
export const ADD_SELF_EQUIPMENT_SUCCESS = "ADD_SELF_EQUIPMENT_SUCCESS";
export const ADD_SELF_EQUIPMENT_FAILURE = "ADD_SELF_EQUIPMENT_FAILURE";

export const ADD_SELF_VISITOR_REQUEST = "ADD_SELF_VISITOR_REQUEST";
export const ADD_SELF_VISITOR_SUCCESS = "ADD_SELF_VISITOR_SUCCESS";
export const ADD_SELF_VISITOR_FAILURE = "ADD_SELF_VISITOR_FAILURE";

export const ADD_SELF_CARD_REQUEST = "ADD_SELF_CARD_REQUEST";
export const ADD_SELF_CARD_SUCCESS = "ADD_SELF_CARD_SUCCESS";
export const ADD_SELF_CARD_FAILURE = "ADD_SELF_CARD_FAILURE";

export const DDL_ACCESS_POINT_REQUEST = "DDL_ACCESS_POINT_REQUEST";
export const DDL_ACCESS_POINT_SUCCESS = "DDL_ACCESS_POINT_SUCCESS";
export const DDL_ACCESS_POINT_FAILURE = "DDL_ACCESS_POINT_FAILURE";

import React from "react";
import { Button, Col, Form, Row } from "antd";
import IntlMessages from "../../util/IntlMessages";
import { useIntl } from "react-intl";

import InputComponent from "../Forms/InputComponent";
import { patternArRequired, patternNameEn } from "../../util/regexPattern";

const assetUrl = "/assets/images";

const VisitorComponent = ({ onFinish, isSendLink, loading, form }) => {
	const intl = useIntl();

	return (
		<>
			{!isSendLink && (
				<div className="gx-app-logo-content">
					<div className="gx-app-logo">
						<img
							alt="powerlook-logo"
							src={`${assetUrl}/icons/add-visitor.png`}
						/>
					</div>
					<div className="gx-app-logo-wid">
						<h5 className="welcome-msg2">
							<IntlMessages id="add.visitor.permit.user" />
						</h5>
					</div>
				</div>
			)}
			<Form
				name="basic"
				labelCol={{
					span: 24,
				}}
				form={form}
				onFinish={onFinish}
				autoComplete="off"
			>
				<Row
					style={{
						width: "100%",
					}}
					gutter={[20, 10]}
				>
					<Col span={12}>
						<Form.Item
							label={<IntlMessages id="label.firstNameEn" />}
							name="firstNameEn"
							rules={patternNameEn}
							hasFeedback
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "placeholder.firstName.En",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={<IntlMessages id="label.lastNameEn" />}
							name="lastNameEn"
							rules={patternNameEn}
							hasFeedback
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "placeholder.lastName.En",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={<IntlMessages id="label.firstNameAr" />}
							name="firstNameAr"
							rules={patternArRequired}
							hasFeedback
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "placeholder.firstName.Ar",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={<IntlMessages id="label.lastNameAr" />}
							name="lastNameAr"
							rules={patternArRequired}
							hasFeedback
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "placeholder.lastName.Ar",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={
								<IntlMessages id="label.identificationNumber" />
							}
							name="identificationNumber"
							rules={[
								{
									required: true,
									message: (
										<IntlMessages id="error.identification.Number" />
									),
								},
							]}
							hasFeedback
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "placeholder.identification.number",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={<IntlMessages id="label.mobileNumber" />}
							name="mobileNumber"
							rules={[
								{
									required: true,
									message: (
										<IntlMessages id="error.mobileNumber" />
									),
								},
							]}
							hasFeedback
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "enter.mobile.num",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={<IntlMessages id="label.email" />}
							name="emailAddress"
							rules={[
								{
									required: true,
									message: (
										<IntlMessages id="error.emailAddress" />
									),
								},
								{
									type: "email",
									message: (
										<IntlMessages id="error.emailAddressCorrect" />
									),
								},
							]}
							hasFeedback
						>
							<InputComponent
								type="email"
								placeholder={intl.formatMessage({
									id: "enter.email.address",
								})}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label={
								<IntlMessages id="label.licensePlateNumber" />
							}
							name="licensePlateNumber"
						>
							<InputComponent
								placeholder={intl.formatMessage({
									id: "placeholder.licensePlateNumber",
								})}
							/>
						</Form.Item>
					</Col>

					{isSendLink && (
						<Form.Item
							style={{ width: "100%" }}
							className="gx-mt-3"
						>
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								style={{ width: "100%" }}
							>
								<IntlMessages id="button.add" />
							</Button>
						</Form.Item>
					)}
				</Row>
			</Form>
		</>
	);
};

export default VisitorComponent;

import { Checkbox } from "antd";

const CheckboxComponent = (props) => (
	<Checkbox className={props.className} {...props} disabled={props.disabled}>
		
		<span>{props.img}</span>
		<span className={props.spanStyle}>{props.title} </span>
		{props.input}
		<span>{props.title2}</span>
		{props.input2}
		<span>{props.title3}</span>
	</Checkbox>
);

export default CheckboxComponent;

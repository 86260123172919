export const Tasks_Members_REQUEST = "Tasks_Members_REQUEST";
export const Tasks_Members_SUCCESS = "Tasks_Members_SUCCESS";
export const Tasks_Members_FAILURE = "Tasks_Members_FAILURE";

export const Add_Tasks_Members_REQUEST = "Add_Tasks_Members_REQUEST";
export const Add_Tasks_Members_SUCCESS = "Add_Tasks_Members_SUCCESS";
export const Add_Tasks_Members_FAILURE = "Add_Tasks_Members_FAILURE";

export const Delete_Tasks_Members_REQUEST = "Delete_Tasks_Members_REQUEST";
export const Delete_Tasks_Members_SUCCESS = "Delete_Tasks_Members_SUCCESS";
export const Delete_Tasks_Members_FAILURE = "Delete_Tasks_Members_FAILURE";
import React from "react";

const SearchBox = ({ styleName, placeholder, onChange, onSearch, value }) => {
    return (
        <div className={`gx-search-bar ${styleName}`}>
            <div className="gx-form-group">
                <input
                    className="ant-input"
                    type="search"
                    placeholder={placeholder}
                    onChange={(event) => onChange(event.target.value)}
                    value={value}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") onSearch();
                    }}
                    onSearch={onSearch}
                />

                <span className="gx-search-icon gx-pointer">
                    <i className="icon icon-search" onClick={onSearch} />
                </span>
            </div>
        </div>
    );
};
export default SearchBox;

SearchBox.defaultProps = {
    styleName: "",
    value: "",
};

import {
	INCIDENT_REPORT_FAILURE,
	INCIDENT_REPORT_REQUEST,
	INCIDENT_REPORT_SUCCESS,
} from "./IncidentReportConstant";

export const IncidentReportReducer = (
	initialState = {
		IncidentReport: [],
		GetIncidentReportLoading: false,
		getError: null,
	},
	action
) => {
	switch (action.type) {
		// Get Incident Report
		case INCIDENT_REPORT_REQUEST:
			return {
				...initialState,
				GetIncidentReportLoading: true,
			};
		case INCIDENT_REPORT_SUCCESS:
			return {
				...initialState,
				IncidentReport: action.payload.result.data,
				GetIncidentReportLoading: false,
			};
		case INCIDENT_REPORT_FAILURE:
			return {
				...initialState,
				GetIncidentReportLoading: false,
				getError: action.payload,
			};
		default:
			return initialState;
	}
};

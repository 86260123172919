import {
	GET_RULES_CANTEEN_REQUEST,
	GET_RULES_CANTEEN_SUCCESS,
	GET_RULES_CANTEEN_FAILURE,
	DELETE_RULES_CANTEEN_REQUEST,
	DELETE_RULES_CANTEEN_SUCCESS,
	DELETE_RULES_CANTEEN_FAILURE,
	ADD_RULES_CANTEEN_REQUEST,
	ADD_RULES_CANTEEN_SUCCESS,
	ADD_RULES_CANTEEN_FAILURE,
	DETAILS_RULES_CANTEEN_Reset,
	DETAILS_RULES_CANTEEN_FAILURE,
	DETAILS_RULES_CANTEEN_SUCCESS,
	DETAILS_RULES_CANTEEN_REQUEST,
	EDIT_RULES_CANTEEN_REQUEST,
	EDIT_RULES_CANTEEN_SUCCESS,
	EDIT_RULES_CANTEEN_FAILURE,
	EDIT_RULES_CANTEEN_Reset,
	AddUser_RULES_CANTEEN_REQUEST,
	AddUser_RULES_CANTEEN_SUCCESS,
	AddUser_RULES_CANTEEN_FAILURE,
	GetUser_RULES_CANTEEN_REQUEST,
	GetUser_RULES_CANTEEN_SUCCESS,
	GetUser_RULES_CANTEEN_FAILURE,
	GetUser_RULES_CANTEEN_Reset,
	DeleteUser_RULES_CANTEEN_REQUEST,
	DeleteUser_RULES_CANTEEN_SUCCESS,
	DeleteUser_RULES_CANTEEN_FAILURE,
	GetAccessPoints_RULES_CANTEEN_REQUEST,
	GetAccessPoints_RULES_CANTEEN_SUCCESS,
	GetAccessPoints_RULES_CANTEEN_FAILURE,
	GetAccessPoints_RULES_CANTEEN_Reset,
	AddAccessPoints_RULES_CANTEEN_REQUEST,
	AddAccessPoints_RULES_CANTEEN_SUCCESS,
	AddAccessPoints_RULES_CANTEEN_FAILURE,
	DeleteAccessPoints_RULES_CANTEEN_REQUEST,
	DeleteAccessPoints_RULES_CANTEEN_SUCCESS,
	DeleteAccessPoints_RULES_CANTEEN_FAILURE,
	RULES_CANTEEN_DDL_REQUEST,
	RULES_CANTEEN_DDL_SUCCESS,
	RULES_CANTEEN_DDL_FAILURE,
	RULES_CANTEEN_DDL_RESET,
} from "./RulesCanteenTypeContants";

export const rulesCanteenReducer = (
	initialState = { rules: [], isHasRulesCanteen: false },
	action
) => {
	switch (action.type) {
		case GET_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllCanteenRules: [],
			};
		case GET_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				data: action.payload.result,
				getAllCanteenRules: action.payload.result.data,
				loading: false,
			};
		case GET_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};

		case RULES_CANTEEN_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				canteenRules: [],
			};
		case RULES_CANTEEN_DDL_SUCCESS:
			return {
				...initialState,
				canteenRules: action.payload.result.data,
				loading: false,
				isHasRulesCanteen: action.payload.result.data?.length
					? true
					: false,
			};

		case RULES_CANTEEN_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				error: action.payload,
			};
		case RULES_CANTEEN_DDL_RESET:
			return {
				...initialState,
				isHasRulesCanteen: false,
			};

		//delete rule
		case DELETE_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DELETE_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DELETE_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		//add
		case ADD_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case ADD_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case ADD_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};
		default:
			return initialState;
	}
};

export const RuleDetailsCanteenReducer = (state = { Rule: {} }, action) => {
	switch (action.type) {
		case DETAILS_RULES_CANTEEN_REQUEST:
			return { ...state, detailsLoading: true };
		case DETAILS_RULES_CANTEEN_SUCCESS:
			return { ...state, detailsLoading: false, Rule: action.payload };
		case DETAILS_RULES_CANTEEN_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case DETAILS_RULES_CANTEEN_Reset:
			return { ...state, Rule: {} };
		//Rule Update
		case EDIT_RULES_CANTEEN_REQUEST:
			return { ...state, updateLoading: true };
		case EDIT_RULES_CANTEEN_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case EDIT_RULES_CANTEEN_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case EDIT_RULES_CANTEEN_Reset:
			return { ...state, Rule: {} };
		default:
			return state;
	}
};

//user
export const RuleUserCanteenReducer = (
	initialState = {
		rule: [],
	},
	action
) => {
	switch (action.type) {
		//get
		case GetUser_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				userRuleLoading: true,
			};
		case GetUser_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				userRule: action.payload.result.data,
				userRuleLoading: false,
			};
		case GetUser_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				userRuleLoading: false,
				getError: action.payload,
			};
		case GetUser_RULES_CANTEEN_Reset:
			return {
				...initialState,
				rule: [],
			};
		//add
		case AddUser_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				addUserLoading: true,
			};
		case AddUser_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				addUserLoading: false,
			};
		case AddUser_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				addUserLoading: false,
				addError: action.payload,
			};

		//delete
		case DeleteUser_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DeleteUser_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DeleteUser_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//access
export const RuleAccessPointsCanteenReducer = (
	initialState = {
		AccessPointsRule: [],
	},
	action
) => {
	switch (action.type) {
		//get
		case GetAccessPoints_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				AccessPointsRuleLoading: true,
			};
		case GetAccessPoints_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				AccessPointsRule: action.payload.result.data,
				AccessPointsRuleLoading: false,
			};
		case GetAccessPoints_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				AccessPointsRuleLoading: false,
				getError: action.payload,
			};
		case GetAccessPoints_RULES_CANTEEN_Reset:
			return {
				...initialState,
				AccessPointsRule: [],
			};
		//add
		case AddAccessPoints_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				AccessPointsRuleLoading: true,
			};
		case AddAccessPoints_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				AccessPointsRuleLoading: false,
			};
		case AddAccessPoints_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				AccessPointsRuleLoading: false,
				addError: action.payload,
			};

		//delete
		case DeleteAccessPoints_RULES_CANTEEN_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case DeleteAccessPoints_RULES_CANTEEN_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case DeleteAccessPoints_RULES_CANTEEN_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

import {
	UserOwner_TYPE_REQUEST,
	UserOwner_TYPE_SUCCESS,
	UserOwner_TYPE_FAILURE,
	UserOwner_ADD_FAILURE,
	UserOwner_ADD_SUCCESS,
	UserOwner_ADD_REQUEST,
	UserOwner_DELETE_REQUEST,
	UserOwner_DELETE_SUCCESS,
	UserOwner_DELETE_FAILURE,
	UserOwner_DETAILS_REQUEST,
	UserOwner_DETAILS_SUCCESS,
	UserOwner_DETAILS_FAILURE,
	UserOwner_DETAILS_RESET,
	UserOwner_UPDATE_REQUEST,
	UserOwner_UPDATE_SUCCESS,
	UserOwner_UPDATE_FAILURE,
	UserOwner_UPDATE_RESET,
	LOCK_Owner_RESET,
	UNLOCK_Owner_RESET,
	UNLOCK_Owner_FAILURE,
	LOCK_Owner_FAILURE,
	UNLOCK_Owner_SUCCESS,
	LOCK_Owner_SUCCESS,
	LOCK_Owner_REQUEST,
	UNLOCK_Owner_REQUEST,
} from "./userOwnerConstants";

export const userOwnerReducer = (
	initialState = {
		userOwners: [],
	},
	action
) => {
	switch (action.type) {
		case UserOwner_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				userOwners: [],
			};
		case UserOwner_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				userOwners: action.payload.data,
				loading: false,
			};

		case UserOwner_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//add userOwner
		case UserOwner_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case UserOwner_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case UserOwner_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete userOwner

		case UserOwner_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case UserOwner_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case UserOwner_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//userOwner detailes & edit
export const userOwnerDetailsReducer = (state = { userOwner: {} }, action) => {
	switch (action.type) {
		case UserOwner_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case UserOwner_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				userOwner: action.payload,
			};
		case UserOwner_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case UserOwner_DETAILS_RESET:
			return { ...state, userOwner: {} };
		//userOwner Update
		case UserOwner_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case UserOwner_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case UserOwner_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case UserOwner_UPDATE_RESET:
			return { ...state, userOwner: {} };
		//lock
		case LOCK_Owner_REQUEST:
		case UNLOCK_Owner_REQUEST:
			return {
				...state,
				lockLoading: true,
			};
		case LOCK_Owner_SUCCESS:
		case UNLOCK_Owner_SUCCESS:
			return {
				...state,
				lockLoading: false,
				lockSuccess: true,
			};
		case LOCK_Owner_FAILURE:
		case UNLOCK_Owner_FAILURE:
			return {
				...state,
				lockLoading: false,
				lockError: action.payload,
			};
		case LOCK_Owner_RESET:
		case UNLOCK_Owner_RESET:
			return {
				...state,
				userOwner: {},
			};
		default:
			return state;
	}
};

import {
	ADDOwner_DASHBOARD_REQUEST,
	ADDOwner_DASHBOARD_SUCCESS,
	ADDOwner_DASHBOARD__FAILURE,
	ADD_CARD_DASHBOARDOWNER_REQUEST,
	ADD_CARD_DASHBOARDOWNER_SUCCESS,
	ADD_CARD_DASHBOARDOWNER__FAILURE,
	DASHBOARDOWNER_ITEMS_FAILURE,
	DASHBOARDOWNER_ITEMS_REQUEST,
	DASHBOARDOWNER_ITEMS_SUCCESS,
	DASHBOARD_OWNER_REQUEST,
	DASHBOARD_OWNER_SUCCESS,
	DASHBOARD__OWNER_FAILURE,
	DELETE_CARD_DASHBOARDOWNER_REQUEST,
	DELETE_CARD_DASHBOARDOWNER_SUCCESS,
	DELETE_CARD_DASHBOARDOWNER__FAILURE,
	DELETE_Owner_DASHBOARD_REQUEST,
	DELETE_Owner_DASHBOARD_SUCCESS,
	DELETE_Owner_DASHBOARD__FAILURE,
	Owner_DASHBOARD_REQUEST,
	Owner_DASHBOARD_SUCCESS,
	Owner_DASHBOARD__FAILURE,
	UpdateOwnerItemPlacements_DASHBOARD_REQUEST,
	UpdateOwnerItemPlacements_DASHBOARD_SUCCESS,
	UpdateOwnerItemPlacements_DASHBOARD__FAILURE,
	UpdateOwnerPlacements_DASHBOARD_REQUEST,
	UpdateOwnerPlacements_DASHBOARD_SUCCESS,
	UpdateOwnerPlacements_DASHBOARD__FAILURE,
} from "./dashboardTypeContants";

export const OwnerDashboardsReducer = (
	state = { ownerDashboards: [], ownerDashboard: [] },
	action
) => {
	switch (action.type) {
		case DASHBOARD_OWNER_REQUEST:
			return { ...state, loading: true };
		case DASHBOARD_OWNER_SUCCESS:
			return {
				...state,
				loading: false,
				ownerDashboards: action.payload.result.data,
			};
		case DASHBOARD__OWNER_FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case ADDOwner_DASHBOARD_REQUEST:
			return { ...state, addLoading: true };
		case ADDOwner_DASHBOARD_SUCCESS:
			return {
				...state,
				addLoading: false,
				ownerDashboard: action.payload,
			};
		case ADDOwner_DASHBOARD__FAILURE:
			return {
				...state,
				addLoading: false,
				addError: action.payload,
			};
		case UpdateOwnerPlacements_DASHBOARD_REQUEST:
			return { ...state, Loading: true };
		case UpdateOwnerPlacements_DASHBOARD_SUCCESS:
			return { ...state, Loading: false, dashboard: action.payload };
		case UpdateOwnerPlacements_DASHBOARD__FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case Owner_DASHBOARD_REQUEST:
			return { ...state, loading: true };
		case Owner_DASHBOARD_SUCCESS:
			return {
				...state,
				loading: false,
				ownerDashboard: action.payload.result.data,
			};
		case Owner_DASHBOARD__FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case DELETE_Owner_DASHBOARD_REQUEST:
			return { ...state, deleteLoading: true };
		case DELETE_Owner_DASHBOARD_SUCCESS:
			return {
				...state,
				deleteLoading: false,
				ownerDashboard: action.payload.result.data,
			};
		case DELETE_Owner_DASHBOARD__FAILURE:
			return {
				...state,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return state;
	}
};

export const cardsOwnerReducer = (state = { cards: [], items: [] }, action) => {
	switch (action.type) {
		case DASHBOARDOWNER_ITEMS_REQUEST:
			return { ...state, loading: true };
		case DASHBOARDOWNER_ITEMS_SUCCESS:
			return {
				...state,
				loading: false,
				items: action.payload.result.data,
			};
		case DASHBOARDOWNER_ITEMS_FAILURE:
			return { ...state, loading: false, getError: action.payload };
		case ADD_CARD_DASHBOARDOWNER_REQUEST:
			return { ...state, addloading: true };
		case ADD_CARD_DASHBOARDOWNER_SUCCESS:
			return { ...state, addloading: false, cards: action.payload };
		case ADD_CARD_DASHBOARDOWNER__FAILURE:
			return {
				...state,
				addloading: false,
				addError: action.payload,
			};
		case UpdateOwnerItemPlacements_DASHBOARD_REQUEST:
			return { ...state, Loading: true };
		case UpdateOwnerItemPlacements_DASHBOARD_SUCCESS:
			return { ...state, Loading: false, cards: action.payload };
		case UpdateOwnerItemPlacements_DASHBOARD__FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};
		case DELETE_CARD_DASHBOARDOWNER_REQUEST:
			return { ...state, deleteLoading: true };
		case DELETE_CARD_DASHBOARDOWNER_SUCCESS:
			return {
				...state,
				deleteLoading: false,
				cards: action.payload.result.data,
			};
		case DELETE_CARD_DASHBOARDOWNER__FAILURE:
			return {
				...state,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return state;
	}
};

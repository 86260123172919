import {
	Incident_TYPE_REQUEST,
	Incident_TYPE_SUCCESS,
	Incident_TYPE_FAILURE,
	Incident_ADD_FAILURE,
	Incident_ADD_SUCCESS,
	Incident_ADD_REQUEST,
	Incident_DELETE_REQUEST,
	Incident_DELETE_SUCCESS,
	Incident_DELETE_FAILURE,
	Incident_DETAILS_REQUEST,
	Incident_DETAILS_SUCCESS,
	Incident_DETAILS_FAILURE,
	Incident_DETAILS_RESET,
	Incident_UPDATE_REQUEST,
	Incident_UPDATE_SUCCESS,
	Incident_UPDATE_FAILURE,
	Incident_UPDATE_RESET,
	Incident_DDL_REQUEST,
	Incident_DDL_SUCCESS,
	Incident_DDL_FAILURE,
	Incident_DDL_RESET,
} from "./IncidentConstantType";

export const IncidentReducer = (
	initialState = {
		Incident: [],
		isHasIncident: false,
	},
	action
) => {
	switch (action.type) {
		case Incident_TYPE_REQUEST:
			return {
				...initialState,
				loading: true,
				getAllIncident: [],
			};
		case Incident_TYPE_SUCCESS:
			return {
				...initialState,
				data: action.payload,
				getAllIncident: action.payload.result.data,
				loading: false,
			};

		case Incident_TYPE_FAILURE:
			return {
				...initialState,
				loading: false,
				getError: action.payload,
			};
		//ddl
		case Incident_DDL_REQUEST:
			return {
				...initialState,
				loading: true,
				Incident: [],
			};
		case Incident_DDL_SUCCESS:
			return {
				...initialState,
				Incident: action.payload.result.data,
				loading: false,
				isHasIncident: action.payload.result.data?.length
					? true
					: false,
			};

		case Incident_DDL_FAILURE:
			return {
				...initialState,
				loading: false,
				getDDLIncidentErrors: action.payload,
			};
		case Incident_DDL_RESET:
			return {
				...initialState,
				isHasIncident: false,
			};

		//add Incident
		case Incident_ADD_REQUEST:
			return {
				...initialState,
				addLoading: true,
			};
		case Incident_ADD_SUCCESS:
			return {
				...initialState,
				addLoading: false,
				data: action.payload,
			};
		case Incident_ADD_FAILURE:
			return {
				...initialState,
				addLoading: false,
				addError: action.payload,
			};

		//delete Incident

		case Incident_DELETE_REQUEST:
			return {
				...initialState,
				deleteLoading: true,
			};
		case Incident_DELETE_SUCCESS:
			return {
				...initialState,
				deleteLoading: false,
			};
		case Incident_DELETE_FAILURE:
			return {
				...initialState,
				deleteLoading: false,
				deleteError: action.payload,
			};
		default:
			return initialState;
	}
};

//Incident details & edit
export const IncidentDetailsReducer = (state = { Incident: {} }, action) => {
	switch (action.type) {
		case Incident_DETAILS_REQUEST:
			return { ...state, detailsLoading: true };
		case Incident_DETAILS_SUCCESS:
			return {
				...state,
				detailsLoading: false,
				Incident: action.payload,
			};
		case Incident_DETAILS_FAILURE:
			return {
				...state,
				detailsLoading: false,
				detailsError: action.payload,
			};
		case Incident_DETAILS_RESET:
			return { ...state, Incident: {} };
		//Incident Update
		case Incident_UPDATE_REQUEST:
			return { ...state, updateLoading: true };
		case Incident_UPDATE_SUCCESS:
			return { ...state, updateLoading: false, updateSuccess: true };
		case Incident_UPDATE_FAILURE:
			return {
				...state,
				updateLoading: false,
				updateError: action.payload,
			};
		case Incident_UPDATE_RESET:
			return { ...state, Incident: {} };
		default:
			return state;
	}
};

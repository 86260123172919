export const GET_RULES_CANTEEN_REQUEST = "GET_RULES_CANTEEN_REQUEST";
export const GET_RULES_CANTEEN_SUCCESS = "GET_RULES_CANTEEN_SUCCESS";
export const GET_RULES_CANTEEN_FAILURE = "GET_RULES_CANTEEN_FAILURE";

export const RULES_CANTEEN_DDL_REQUEST = "RULES_CANTEEN_DDL_REQUEST";
export const RULES_CANTEEN_DDL_SUCCESS = "RULES_CANTEEN_DDL_SUCCESS";
export const RULES_CANTEEN_DDL_FAILURE = "RULES_CANTEEN_DDL_FAILURE";
export const RULES_CANTEEN_DDL_RESET = "RULES_CANTEEN_DDL_RESET";

export const DELETE_RULES_CANTEEN_REQUEST = "DELETE_RULES_CANTEEN_REQUEST";
export const DELETE_RULES_CANTEEN_SUCCESS = "DELETE_RULES_CANTEEN_SUCCESS";
export const DELETE_RULES_CANTEEN_FAILURE = "DELETE_RULES_CANTEEN_FAILURE";

export const ADD_RULES_CANTEEN_REQUEST = "ADD_RULES_CANTEEN_REQUEST";
export const ADD_RULES_CANTEEN_SUCCESS = "ADD_RULES_CANTEEN_SUCCESS";
export const ADD_RULES_CANTEEN_FAILURE = "ADD_RULES_CANTEEN_FAILURE";

export const EDIT_RULES_CANTEEN_REQUEST = "EDIT_RULES_CANTEEN_REQUEST";
export const EDIT_RULES_CANTEEN_SUCCESS = "EDIT_RULES_CANTEEN_SUCCESS";
export const EDIT_RULES_CANTEEN_FAILURE = "EDIT_RULES_CANTEEN_FAILURE";
export const EDIT_RULES_CANTEEN_Reset = "EDIT_RULES_CANTEEN_Reset";

export const DETAILS_RULES_CANTEEN_REQUEST = "DETAILS_RULES_CANTEEN_REQUEST";
export const DETAILS_RULES_CANTEEN_SUCCESS = "DETAILS_RULES_CANTEEN_SUCCESS";
export const DETAILS_RULES_CANTEEN_FAILURE = "DETAILS_RULES_CANTEEN_FAILURE";
export const DETAILS_RULES_CANTEEN_Reset = "DETAILS_RULES_CANTEEN_Reset";

export const AddUser_RULES_CANTEEN_REQUEST = "AddUser_RULES_CANTEEN_REQUEST";
export const AddUser_RULES_CANTEEN_SUCCESS = "AddUser_RULES_CANTEEN_SUCCESS";
export const AddUser_RULES_CANTEEN_FAILURE = "AddUser_RULES_CANTEEN_FAILURE";
export const AddUser_RULES_CANTEEN_Reset = "AddUser_RULES_CANTEEN_Reset";

export const GetUser_RULES_CANTEEN_REQUEST = "GetUser_RULES_CANTEEN_REQUEST";
export const GetUser_RULES_CANTEEN_SUCCESS = "GetUser_RULES_CANTEEN_SUCCESS";
export const GetUser_RULES_CANTEEN_FAILURE = "GetUser_RULES_CANTEEN_FAILURE";
export const GetUser_RULES_CANTEEN_Reset = "GetUser_RULES_CANTEEN_Reset";

export const DeleteUser_RULES_CANTEEN_REQUEST = "DeleteUser_RULES_CANTEEN_REQUEST";
export const DeleteUser_RULES_CANTEEN_SUCCESS = "DeleteUser_RULES_CANTEEN_SUCCESS";
export const DeleteUser_RULES_CANTEEN_FAILURE = "DeleteUser_RULES_CANTEEN_FAILURE";
export const DeleteUser_RULES_CANTEEN_Reset = "DeleteUser_RULES_CANTEEN_Reset";

export const AddAccessPoints_RULES_CANTEEN_REQUEST = "AddAccessPoints_RULES_REQUEST";
export const AddAccessPoints_RULES_CANTEEN_SUCCESS = "AddAccessPoints_RULES_SUCCESS";
export const AddAccessPoints_RULES_CANTEEN_FAILURE = "AddAccessPoints_RULES_FAILURE";
export const AddAccessPoints_RULES_CANTEEN_Reset = "AddAccessPoints_RULES_Reset";

export const GetAccessPoints_RULES_CANTEEN_REQUEST = "GetAccessPoints_RULES_CANTEEN_REQUEST";
export const GetAccessPoints_RULES_CANTEEN_SUCCESS = "GetAccessPoints_RULES_CANTEEN_SUCCESS";
export const GetAccessPoints_RULES_CANTEEN_FAILURE = "GetAccessPoints_RULES_CANTEEN_FAILURE";
export const GetAccessPoints_RULES_CANTEEN_Reset = "GetAccessPoints_RULES_CANTEEN_Reset";

export const DeleteAccessPoints_RULES_CANTEEN_REQUEST =
	"DeleteAccessPoints_RULES_CANTEEN_REQUEST";
export const DeleteAccessPoints_RULES_CANTEEN_SUCCESS =
	"DeleteAccessPoints_RULES_CANTEEN_SUCCESS";
export const DeleteAccessPoints_RULES_CANTEEN_FAILURE =
	"DeleteAccessPoints_RULES_CANTEEN_FAILURE";
export const DeleteAccessPoints_RULES_CANTEEN_Reset = "DeleteAccessPoints_RULES_CANTEEN_Reset";



export const TEMPLATE_MESSAGES_REQUEST = "TEMPLATE_MESSAGES_REQUEST";
export const TEMPLATE_MESSAGES_SUCCESS = "TEMPLATE_MESSAGES_SUCCESS";
export const TEMPLATE_MESSAGES_FAILURE = "TEMPLATE_MESSAGES_FAILURE";

export const TEMPLATE_DDL_REQUEST = "TEMPLATE_DDL_REQUEST";
export const TEMPLATE_DDL_SUCCESS = "TEMPLATE_DDL_SUCCESS";
export const TEMPLATE_DDL_FAILURE = "TEMPLATE_DDL_FAILURE";
export const TEMPLATE_DDL_RESET = "TEMPLATE_DDL_RESET";

export const ADD_TEMPLATE_REQUEST = "ADD_TEMPLATE_REQUEST";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const ADD_TEMPLATE_FAILURE = "ADD_TEMPLATE_FAILURE";

export const UPDATE_TEMPLATE_REQUEST = "UPDATE_TEMPLATE_REQUEST";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_FAILURE = "UPDATE_TEMPLATE_FAILURE";

export const DELETE_TEMPLATE_REQUEST = "DELETE_TEMPLATE_REQUEST";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAILURE = "DELETE_TEMPLATE_FAILURE";

export const EDIT_TEMPLATE_REQUEST = "EDIT_TEMPLATE_REQUEST";
export const EDIT_TEMPLATE_SUCCESS = "EDIT_TEMPLATE_SUCCESS";
export const EDIT_TEMPLATE_FAILURE = "EDIT_TEMPLATE_FAILURE";

export const DETAILS_TEMPLATE_REQUEST = "DETAILS_TEMPLATE_REQUEST";
export const DETAILS_TEMPLATE_SUCCESS = "DETAILS_TEMPLATE_SUCCESS";
export const DETAILS_TEMPLATE_FAILURE = "DETAILS_TEMPLATE_FAILURE";


